import React, { useEffect, useRef, useState } from 'react'
import { ContainerInputs } from './styled'
import Input from '../../../CustomUi/input'
import {
  faBuilding,
  faEnvelope,
  faGlobe,
  faHashtag,
  faHouse,
  faHouseChimney,
  faHouseChimneyWindow,
  faHouseUser,
  faLocationDot,
  faMap,
  faMapMarkedAlt,
  faMobileScreenButton,
  faPhone,
  faPlus,
  faRoad,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  buscaCidade,
  listEscolaridade,
  listEstadoCivil,
  listProfissao,
  listUf,
  tryError,
} from '../../../../services/Afins'
import DatePicker from '../../../DatePiker'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { Radio } from 'antd/lib'
import { Select } from '../../../CustomUi/Select'
import dayjs, { Dayjs } from 'dayjs'
import { useAccess } from '../../../../context/useAccess'
import { cepMask, phoneMask } from '../../../../services/Afins'
import { listaEscolaridade } from '../../meta/index '
import { destroyAlert, loadingAlert } from '../../../../utils/alert'

interface FormProps {
  permissions?: ItensPerUserLogged
  nomeCompleto?: string
  setNomeCompleto?: React.Dispatch<React.SetStateAction<string>>
  email?: string
  setEmail?: React.Dispatch<React.SetStateAction<string>>
  dataNascimento?: Dayjs | null
  setDataNascimento?: React.Dispatch<React.SetStateAction<Dayjs | null>>
  sexo?: string
  setSexo?: React.Dispatch<React.SetStateAction<string>>
  escolaridade?: string
  setEscolaridade?: React.Dispatch<React.SetStateAction<string>>
  escolaridadeId?: number
  setEscolaridadeId?: React.Dispatch<React.SetStateAction<number>>
  celular?: string
  setCelular?: React.Dispatch<React.SetStateAction<string>>
  telefone?: string
  setTelefone?: React.Dispatch<React.SetStateAction<string>>
  nacionalidade?: string
  setNacionalidade?: React.Dispatch<React.SetStateAction<string>>
  cep?: string
  setCep?: React.Dispatch<React.SetStateAction<string>>
  estadoNascimento?: string
  setEstadoNascimento?: React.Dispatch<React.SetStateAction<string>>
  estadoNascimentoID?: number
  setEstadoNascimentoID?: React.Dispatch<React.SetStateAction<number>>
  cidadeNascimento?: string
  setCidadeNascimento?: React.Dispatch<React.SetStateAction<string>>
  cidadeNascimentoID?: number
  setCidadeNascimentoID?: React.Dispatch<React.SetStateAction<number>>
  estado?: string
  setEstado?: React.Dispatch<React.SetStateAction<string>>
  estadoID?: number
  setEstadoID?: React.Dispatch<React.SetStateAction<number>>
  cidade?: string
  setCidade?: React.Dispatch<React.SetStateAction<string>>
  cidadeID?: number | string
  setCidadeID?: React.Dispatch<React.SetStateAction<number | string>>
  bairro?: string
  setBairro?: React.Dispatch<React.SetStateAction<string>>
  rua?: string
  setRua?: React.Dispatch<React.SetStateAction<string>>
  numero?: string
  setNumero?: React.Dispatch<React.SetStateAction<string>>
  complemento?: string
  setComplemento?: React.Dispatch<React.SetStateAction<string>>
  update?: number
  setUpdate?: React.Dispatch<React.SetStateAction<number>>
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
  open?: boolean
  listaCidades?: any[]
}

const FormMembroEquipe: React.FC<FormProps> = ({
  permissions,
  nomeCompleto,
  dataNascimento,
  email,
  sexo,
  escolaridade,
  escolaridadeId,
  celular,
  telefone,
  nacionalidade,
  cep,
  estadoNascimento,
  estadoNascimentoID,
  cidadeNascimento,
  cidadeNascimentoID,
  estado,
  estadoID,
  cidade,
  cidadeID,
  bairro,
  rua,
  numero,
  complemento,
  update,
  open,
  listaCidades,
  setNomeCompleto,
  setDataNascimento,
  setEmail,
  setSexo,
  setEscolaridade,
  setEscolaridadeId,
  setCelular,
  setTelefone,
  setNacionalidade,
  setCep,
  setEstadoNascimento,
  setEstadoNascimentoID,
  setCidadeNascimento,
  setCidadeNascimentoID,
  setEstado,
  setEstadoID,
  setCidade,
  setCidadeID,
  setBairro,
  setRua,
  setNumero,
  setComplemento,
  setUpdate,
  setLoading,
}) => {
  const [openEscolaridades, setOpenEscolaridades] = useState(false)
  const [listUF, setListUF] = useState([])
  const [listCity, setListCity] = useState([])
  const [openUFNasci, setOpenUFNasci] = useState(false)
  const [openCityNasci, setOpenCityNasci] = useState(false)
  const [openUF, setOpenUF] = useState(false)
  const [openCity, setOpenCity] = useState(false)
  const [openData, setOpenData] = useState(false)
  const [timeRenderizar, setTimeRenderizar] = useState(false)

  const handleDateChange = (date: Dayjs) => {
    setDataNascimento(date)
  }

  const handleDateBlur = (e) => {
    const dateString = e.target.value

    // Valida a data digitada e define no estado caso seja válida
    if (dayjs(dateString, 'DD/MM/YYYY', true).isValid()) {
      setDataNascimento(dayjs(dateString, 'DD/MM/YYYY'))
    }
  }

  const handleOpenChange = (status) => {
    setOpenData(status)
  }

  const listarEstados = async () => {
    const resp = await listUf()

    setListUF(resp)
  }

  function checkCep(cepValue) {
    loadingAlert({ msg: 'Buscando CEP...', id: 'cep' })
    setLoading(true)
    const cep = cepValue.replace(/\D/g, '')
    setTimeout(() => {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          if (data.bairro !== '' && data.logradouro !== '') {
            setBairro(data.bairro)
            setRua(data.logradouro)
            const estadoFiltrado = listUF?.filter((item) => item.nome === data?.estado)
            setEstado(data.estado)
            setEstadoID(estadoFiltrado[0]?.cod)
            const cidadeFiltrada = listaCidades?.filter((rec) => rec.nome === data?.localidade)
            setCidadeID(cidadeFiltrada[0]?.cod)
            setCidade(data.localidade)
          } else {
            const estadoFiltrado = listUF?.filter((item) => item.nome === data?.estado)
            setEstado(data.estado)
            setEstadoID(estadoFiltrado[0]?.cod)
            const cidadeFiltrada = listaCidades?.filter((rec) => rec.nome === data?.localidade)
            const cidadeFiltradaUF = cidadeFiltrada?.filter(
              (city) => city?.uf_nome === data?.estado
            )
            setCidadeID(cidadeFiltradaUF[0]?.cod)
            setCidade(data.localidade)
            setRua('')
            setBairro('')
            setNumero('')
            setComplemento('')
          }
        })
      destroyAlert('cep')
      setLoading(false)
    }, 300)
  }

  const handleChangeNascUF = (data) => {
    setEstadoNascimento(data)
    const resp = listaCidades.filter((item) => item.uf_nome === data)
    setListCity(resp)

    return resp
  }

  useEffect(() => {
    if (estadoNascimento !== '') {
      handleChangeNascUF(estadoNascimento)
    }
  }, [estadoNascimento])

  const handleChangeUF = (data) => {
    setEstado(data)
    const resp = listaCidades.filter((item) => item.uf_nome === data)
    setListCity(resp)

    return resp
  }

  useEffect(() => {
    if (estado !== '') {
      handleChangeUF(estado)
    }
  }, [estado])

  useEffect(() => {
    if (open && update <= 0) {
      listarEstados()
    }
  }, [open])

  useEffect(() => {
    if (update > 0 && open) {
      const filtrarEstadoNasc = listaCidades.filter((item) => item.cod === cidadeNascimentoID)
      setEstadoNascimento(filtrarEstadoNasc[0]?.uf_nome)
      listarEstados()
      setCidadeNascimento(filtrarEstadoNasc[0]?.nome)

      const filtrarEstado = listaCidades.filter((item) => item.cod === cidadeID)
      setEstado(filtrarEstado[0]?.uf_nome)
      setCidade(filtrarEstado[0]?.nome)

      const filtrarEscolaridade = listaEscolaridade.filter((item) => item.cod === escolaridadeId)
      setEscolaridade(filtrarEscolaridade[0]?.nome)
    }
  }, [open, update, cidadeNascimentoID, cidadeID])

  useEffect(() => {
    if (cep?.length === 9 && timeRenderizar) {
      checkCep(cep)
    }
  }, [cep])

  useEffect(() => {
    setTimeout(() => {
      setTimeRenderizar(true)
    }, 1200)
  }, [])

  return (
    <ContainerInputs>
      <div className='lines'>
        <Input
          label='Nome completo'
          icon={faUser}
          placeholder='Digite o nome completo'
          value={nomeCompleto}
          onChange={(e) => setNomeCompleto(e)}
          width={'100%'}
          height={'30%'}
          obrigatorio
          desabilitado={permissions?.acesso === 1}
        />

        <div className='data-nascimento'>
          <div style={{ display: 'flex', gap: '10', alignItems: 'center' }}>
            <label>Data nascimento</label>
            <p
              style={{
                margin: '0',
                padding: '0',
                fontSize: '10px',
                marginLeft: '3px',
                opacity: '0.7',
              }}
            >
              (Obrigatório)
            </p>
          </div>
          <DatePicker
            inputReadOnly={permissions?.acesso === 1}
            value={dataNascimento ? dayjs(dataNascimento) : null}
            onChange={handleDateChange}
            style={{ height: '100%' }}
            onBlur={handleDateBlur}
          />
        </div>

        <div className='radio-sexo'>
          <div style={{ display: 'flex', gap: '10', alignItems: 'center' }}>
            <label>Sexo</label>
            <p
              style={{
                margin: '0',
                padding: '0',
                fontSize: '10px',
                marginLeft: '3px',
                opacity: '0.7',
              }}
            >
              (Obrigatório)
            </p>
          </div>
          <Radio.Group
            value={sexo}
            onChange={(e) => setSexo(e.target.value)}
            className='radio-group'
            style={{
              pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',

              height: '100%',
            }}
            optionType='button'
            buttonStyle='solid'
            options={[
              {
                label: 'Masculino',
                value: 'M',
                style: { width: '50%', height: '95%', textAlign: 'center' },
              },
              {
                label: 'Feminino',
                value: 'F',
                style: { width: '50%', height: '95%', textAlign: 'center' },
              },
            ]}
          />
        </div>
      </div>
      <div className='lines'>
        <Input
          label='Email'
          icon={faEnvelope}
          placeholder='Digite o email'
          value={email}
          onChange={setEmail}
          width={'100%'}
          height={'30%'}
          obrigatorio
          desabilitado={permissions?.acesso === 1}
        />
      </div>
      <div className='lines'>
        <Select
          label='Escolaridade'
          icon={faBuilding}
          placeholder='Selecione a escolaridade'
          value={escolaridade}
          onChange={setEscolaridade}
          onChangeID={setEscolaridadeId}
          open={openEscolaridades}
          trigger={(e) => setOpenEscolaridades(e)}
          items={listaEscolaridade}
          descricaoItem='nome'
          idItem='cod'
          disabled={permissions?.acesso === 1}
        />

        <Input
          label='Celular'
          icon={faMobileScreenButton}
          placeholder='Digite o celular'
          value={phoneMask(celular)}
          onChange={setCelular}
          width={'100%'}
          maxLength={15}
          desabilitado={permissions?.acesso === 1}
        />
        <Input
          label='Telefone fixo'
          icon={faPhone}
          placeholder='Digite o Telefone fixo'
          value={phoneMask(telefone)}
          onChange={setTelefone}
          width={'100%'}
          maxLength={15}
        />
      </div>

      <div className='lines'>
        <Input
          label='Nacionalidade'
          icon={faGlobe}
          placeholder='Digite a nacionalidade'
          value={nacionalidade}
          onChange={setNacionalidade}
          width={'100%'}
          obrigatorio
          desabilitado={permissions?.acesso === 1}
        />

        <Select
          label='Estado nascimento'
          icon={faMapMarkedAlt}
          placeholder='Selecione o estado de nascimento'
          value={estadoNascimento}
          onChange={handleChangeNascUF}
          onChangeID={setEstadoNascimentoID}
          open={openUFNasci}
          trigger={setOpenUFNasci}
          items={listUF}
          descricaoItem='nome'
          idItem='cod'
          obrigatorio
          disabled={permissions?.acesso === 1}
        />

        <Select
          label='Cidade nascimento'
          icon={faHouseUser}
          placeholder='Selecione a cidade nascimento'
          value={cidadeNascimento}
          onChange={setCidadeNascimento}
          onChangeID={setCidadeNascimentoID}
          open={openCityNasci}
          trigger={setOpenCityNasci}
          items={listCity}
          descricaoItem='nome'
          idItem='cod'
          obrigatorio
          disabled={permissions?.acesso === 1}
        />
      </div>

      <div className='lines'>
        <Input
          label='CEP atual'
          icon={faLocationDot}
          placeholder='Digite o CEP'
          value={cepMask(cep)}
          onChange={setCep}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
          maxLength={9}
        />

        <Select
          label='Estado'
          icon={faMap}
          placeholder='Selecione o estado'
          value={estado}
          onChange={handleChangeUF}
          onChangeID={setEstadoID}
          open={openUF}
          trigger={setOpenUF}
          items={listUF}
          descricaoItem='nome'
          idItem='cod'
          disabled={permissions?.acesso === 1}
        />

        <Select
          label='Cidade'
          icon={faHouse}
          placeholder='Selecione a cidade'
          value={cidade}
          onChange={setCidade}
          onChangeID={setCidadeID}
          open={openCity}
          trigger={setOpenCity}
          items={listCity}
          descricaoItem='nome'
          idItem='cod'
          disabled={permissions?.acesso === 1}
        />
      </div>

      <div className='lines'>
        <Input
          label='Bairro'
          icon={faHouseChimneyWindow}
          placeholder='Digite o bairro'
          value={bairro}
          onChange={setBairro}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
        <Input
          label='Logradouro'
          icon={faRoad}
          placeholder='Digite o Endereço'
          value={rua}
          onChange={setRua}
          width={'100%'}
          obrigatorio
          desabilitado={permissions?.acesso === 1}
        />

        <Input
          label='Número'
          icon={faHashtag}
          placeholder='Digite o número'
          value={numero}
          onChange={setNumero}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
      </div>
      <div className='lines'>
        <Input
          label='Complemento'
          icon={faPlus}
          placeholder='Digite o complemento'
          value={complemento}
          onChange={setComplemento}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
      </div>
    </ContainerInputs>
  )
}

export default FormMembroEquipe

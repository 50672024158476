import React, { useEffect, useRef, useState } from 'react'

import { Alert, Button, Card, Col, Drawer, Form, Input, Row, Select, Space, Upload } from 'antd'

import { EyeFilled, UploadOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/lib/upload'
import { getPropriedade, postPropriedade, putPropriedade } from '../../services/Propriedades'

import { buscaCidade, listUf } from '../../services/Afins'
import { Notification } from '../notification'

import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import { getCalcario, ItensCalcario } from '../../services/recomendacao/calcario'
import Base64 from '../Base64Geral'
import { getTipoConsultor, ItensTipoConsultorTypes } from '../../services/consultoriaAgricola/tipoConsultor'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../utils/alert'

interface TypeProps {
  visible: boolean
  onClose: (data: boolean) => void
  update: number
  setUpdate: (data: number) => void
  refresh: (data: string) => void
  permissions: ItensPerUserLogged
}

const { Option } = Select

const Propriedades: React.FC<TypeProps> = (props) => {
  const { visible, onClose, update, refresh, setUpdate, permissions } = props

  const [form] = Form.useForm()

  const uploadFile = useRef(null)

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [ufList, setUfList] = useState([])
  const [cityList, setCityList] = useState([])
  const [cityDisable, setCityDisable] = useState(true)
  const [openDrawerPreviwFile, setOpenDrawerPreviwFile] = useState(false)
  const [fileOpen, setFileOpen] = useState('')
  const [selectedFile, setSelectedFile] = useState({})
  const [dataCalcario, setDataCalcario] = useState<ItensCalcario[]>([])
  const [dataConsultor, setDataConsultor] = useState<ItensTipoConsultorTypes[]>([])
  const [codConsultor, setCodConsultor] = useState<Number>()
  const tipoAcesso = localStorage.getItem('tipo-acesso');
  const [calcario_original, setCalcario_original] = useState<number|null>(null);
  const [calcario_novo, setCalcario_novo] = useState<number|null>(null);

  const hancleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setUpdate(0)
    setFileOpen('')
    form.resetFields()
    setCalcario_novo(null);
  }

  const handleGetPropriedade = async (data) => {
    try {
      loadingAlert({msg: 'Carregando propriedade...', id: 'load_propriedade'})


      const response = await getPropriedade(data)

      if (response.status === 200) {
        const dados = response.data.itens[0]

        setCalcario_original(dados?.cod_calcario)

        setFileOpen(dados.arquivo_base64)

        const codCity = await buscaCidade('', '', dados.cod_cidade)
        setCodConsultor(dados.cod_consultor)

        const codUf = codCity[0].cod_uf

        onChangeCity(codUf)

        form.setFieldsValue({ uf: codUf })

        form.setFieldsValue(dados)

        const areaFild = form.getFieldValue('area').replace('.', ',')

        form.setFieldsValue({ area: areaFild })

        destroyAlert('load_propriedade');
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? 'Falha ao obter propriedade!', 7000, 'load_propriedade');
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha ao obter propriedade!', 7000, 'load_propriedade');
          })
        }
      }
      else {
        destroyAlert();
      }
    }
  }

  const listCalcario = async () => {
    const resp = await getCalcario({})

    setDataCalcario(resp.data.itens)
  }

  const onChangeUf = async () => {
    const response = await listUf()

    return response
  }

  const onChangeCity = async (data) => {
    const response = await buscaCidade('', data, '')
    setCityList(response)
    setCityDisable(false)
  }

  const onChangeUpload = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      setSelectedFile(info.file.originFileObj)
    }
  }

  const onRemoveFile = () => {
    setSelectedFile({})
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const onFinish = async (data) => {
    try {
      // @ts-ignore
      if (selectedFile.uid) {
        data.arquivo = await Base64(selectedFile)
      } else {
        data.arquivo = ''
      }
      // console.log('dataFinish', data)
      loadingAlert({msg: 'Salvando...', id: 'save_propriedade'})

      const codCliente = localStorage.getItem('cod-cliente')
      if (update <= 0) {
        const response = await postPropriedade(data)
        if (response.status === 201) {
          successAlert('Propriedade cadastrada com sucesso!', 5000, 'save_propriedade');
          form.resetFields()
          refresh('')
        }
      } else {
        const response = await putPropriedade(codCliente, update, data)
        if (response.status === 200) {
          successAlert('Propriedade atualizada com sucesso!', 5000, 'save_propriedade');
          refresh('')
        }
      }
    } catch (error) {

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? 'Falha na operação!', 7000, 'save_propriedade');
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha na operação!', 7000, 'save_propriedade');
          })
        }
      }
      else {
        destroyAlert();
      }
    }
  }

  const drawerPreviewFile = () => {
    setOpenDrawerPreviwFile(true)
  }

  const listConsultor = async () => {
    const resp = await getTipoConsultor({ codCliente: null })

    setDataConsultor(resp?.data?.itens)
  }

  const handleChangeMask = (data) => {
    let area = data.target.value
    area = area.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    area = (area / 100).toFixed(2).replace('.', ',')
    form.setFieldsValue({ area })
  }

  useEffect(() => {
    setDrawerVisible(visible)
  }, [visible])

  useEffect(() => {
    if (update <= 0) {
      form.resetFields()
    } else {
      handleGetPropriedade(update)
    }
  }, [update])

  useEffect(() => {
    const fetch = async () => {
      const uf = await onChangeUf()
      setUfList(uf)
      listCalcario()
      listConsultor()
    }

    fetch()
  }, [])


  useEffect(() => {
    // console.log("Calcário original: ", calcario_original);
    // console.log("Calcário novo: ", calcario_novo);
  }, [calcario_novo])
  

  return (
    <>
      <Drawer
        title='Cadastro de Propriedade'
        style={{ position: 'absolute' }}
        placement='right'
        width='100%'
        onClose={hancleDrawerClose}
        open={drawerVisible}
        closeIcon={false}
        getContainer={false}
        footer={
          <Space
            style={{
              textAlign: 'left',
            }}
          >
            <Button
              hidden={permissions?.alteracao === 0 && permissions?.inclusao === 0}
              type='primary'
              htmlType='button'
              onClick={() => form.submit()}
            >
              {update ? 'Atualizar Propriedade' : 'Salvar'}
            </Button>
            <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
              Fechar
            </Button>
          </Space>
        }
      >
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <Row gutter={[8, 8]}>
            <Col span={9} flex='1 1 200px'>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                label='Nome'
                name='nome'
              >
                <Input readOnly={permissions?.acesso === 1} />
              </Form.Item>
            </Col>
            <Col flex='1 1 200px'>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                label='Matricula'
                name='matricula'
              >
                <Input readOnly={permissions?.acesso === 1} />
              </Form.Item>
            </Col>
            <Col flex='1 1 200px'>
              <Form.Item label='CCIR' name='ccir'>
                <Input readOnly={permissions?.acesso === 1} />
              </Form.Item>
            </Col>
            <Col flex='1 1 200px'>
              <Form.Item label='ITR' name='itr'>
                <Input readOnly={permissions?.acesso === 1} />
              </Form.Item>
            </Col>
            <Col flex='1 1 200px'>
              <Form.Item label='NIRF' name='nirf'>
                <Input readOnly={permissions?.acesso === 1} />
              </Form.Item>
            </Col>
            <Col flex='1 1 200px'>
              <Form.Item label='Inscrição estadual' name='inscricao_estadual'>
                <Input readOnly={permissions?.acesso === 1} />
              </Form.Item>
            </Col>

            <Col flex='0 1 200px'>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                label='Tipo'
                name='cod_tipo'
              >
                <Select
                  className={permissions?.acesso === 1 ? 'readOnly' : ''}
                  placeholder='Selecione um tipo'
                  popupMatchSelectWidth={false}
                >
                  <Option key='1' value={1}>
                    Próprio
                  </Option>
                  <Option key='2' value={2}>
                    Arrendado
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col flex='0 1 100px'>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                label='Área (ha)'
                name='area'
              >
                <Input onChange={handleChangeMask} />
              </Form.Item>
            </Col>

            <Col flex='0 1 75px'>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                label='Uf'
                name='uf'
              >
                <Select
                  showSearch
                  className={permissions?.acesso === 1 ? 'readOnly' : ''}
                  onChange={onChangeCity}
                  placeholder=''
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    option.children
                      // @ts-ignore
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ufList.map((data) => (
                    <Option key={data.cod} value={data.cod}>
                      {data.sigla}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col flex='0 1 200px'>
              <Form.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                label='Cidade'
                name='cod_cidade'
              >
                <Select
                  disabled={cityDisable}
                  className={permissions?.acesso === 1 ? 'readOnly' : ''}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      // @ts-ignore
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  popupMatchSelectWidth={false}
                  placeholder=''
                  style={{ width: '100%' }}
                >
                  {cityList.map((data) => (
                    <Option key={data.nome} value={data.cod}>
                      {data.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex='1 1 200'>
              <Form.Item
                rules={[
                  {
                    required: false,
                  },
                ]}
                label='Calcário'
                name='cod_calcario'
              >
                <Select
                  style={{ minWidth: '150px' }}
                  popupMatchSelectWidth={false}
                  onChange={(cod_calcario)=>setCalcario_novo(cod_calcario)}
                  options={dataCalcario.map((rec) => {
                    return { label: rec.nome, value: rec.cod }
                  })}
                />
              </Form.Item>
            </Col>
            {tipoAcesso === 'conta' && <Col flex='1 1 200'>
              <Form.Item
                rules={[
                  {
                    required: false,
                  },
                ]}
                label='Consultor Responsável'
                name='cod_consultor'
              >
                <Select
                  style={{ minWidth: '150px' }}
                  popupMatchSelectWidth={false}
                  value={codConsultor}
                  options={dataConsultor.map((rec) => {
                    return { label: rec.usuario_nome, value: rec.cod_usuario }
                  })}
                />
              </Form.Item>
            </Col>}
            
          </Row>
          
        {((calcario_original !== calcario_novo) && calcario_novo!==null) &&(
          <>
            <Alert
              message="Aviso"
              description="Caso possua resultados de OS já processados, será necessário reprocessá-los!"
              type="warning"
              showIcon
              // closable
            />
            <br/>
          </>
        )}
        

          <Col span={24} flex='1 1 200px'>
            <Form.Item name='arquivo'>
              <Card size='small' title='Documentos da Propriedade'>
                <Row gutter={[8, 8]}>
                  <Col>
                    <Upload
                      ref={uploadFile}
                      accept='application/pdf'
                      onChange={onChangeUpload}
                      onRemove={onRemoveFile}
                      maxCount={1}
                      multiple={false}
                      customRequest={dummyRequest}
                    >
                      <Button
                        disabled={permissions?.acesso === 1}
                        type='primary'
                        icon={<UploadOutlined />}
                      >
                        Arquivo
                      </Button>
                    </Upload>
                  </Col>

                  <Col>
                    {update > 0 && fileOpen !== '' ? (
                      <Button
                        className='info-button'
                        onClick={drawerPreviewFile}
                        type='primary'
                        icon={<EyeFilled />}
                      >
                        Visualizar Documento
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </Card>
            </Form.Item>
          </Col>

          {/* Hiddenn */}

          <Form.Item initialValue='1' hidden name='status'>
            <Input readOnly={permissions?.acesso === 1} />
          </Form.Item>

          <Form.Item hidden initialValue={localStorage.getItem('conta')} name='cod_conta'>
            <Input readOnly={permissions?.acesso === 1} />
          </Form.Item>
          <Form.Item hidden initialValue={localStorage.getItem('cod-cliente')} name='cod_cliente'>
            <Input readOnly={permissions?.acesso === 1} />
          </Form.Item>
          {/* fim Hiddenn */}
        </Form>
      </Drawer>
      <Drawer
        height='100%'
        placement='bottom'
        onClose={() => setOpenDrawerPreviwFile(false)}
        open={openDrawerPreviwFile}
        closeIcon={false}
        footer={
          <Button onClick={() => {setOpenDrawerPreviwFile(false)}} danger type='primary'>
            Fechar
          </Button>
        }
      >
        <object width='100%' height='100%' data={`data:application/pdf;base64,${fileOpen}`} />
      </Drawer>
    </>
  )
}

export default Propriedades
import { Link } from 'react-router-dom'
import { TtivosFinder } from './style'
import { Empty, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { faLock, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import secureLocalStorage from 'react-secure-storage'

//APP
import navigationConfig from '../../../../Configs/Navegacao'
import { gerarKey, removerAcentos } from '../../../../utils'

//ASSETS
import Input from '../../../CustomUi/input'
import ttivos from '../../../../assets/img/logo-ttivos-png.png'
import { TitleH2 } from '../../../CustomUi/titleH2'

//PROPS
interface Props {
  open: boolean
  onClose: Function
  onClickLink: Function
}

type Permissao = {
  acesso: number
  alteracao: number
  cod: number
  cod_perfil: number
  cod_rotina: number
  exclusao: number
  inclusao: number
  perfil_nome: string
  rotina_nome: string
}

export const GlobalSearch: React.FC<Props> = ({ open, onClose, onClickLink }) => {
  //STATES
  const [search, setSearch] = useState<string>('')
  const [navigateFilter, setNavigateFilter] = useState([])

  //CONST
  const permissoes: Permissao[] = secureLocalStorage.getItem('navigationRoutines') as Permissao[]

  function buscarItem(buscar_nome: string) {
    const ativoSemAcentos = removerAcentos(buscar_nome).toLowerCase()

    return function (item: any): boolean {
      function searchRecursive(item: any): boolean {
        const keywords_main = removerAcentos(item?.keywords || '').toLowerCase()

        // Verifica se o item atual tem uma correspondência
        const matchInCurrentItem = keywords_main.includes(ativoSemAcentos)

        // Se o item atual não tem correspondência, verifica nos submenus (recursivamente)
        const matchInSubmenu = item.submenu?.some((subitem) => searchRecursive(subitem))

        // Retorna true se encontrar correspondência no item atual ou em algum submenu
        return matchInCurrentItem || matchInSubmenu
      }

      return searchRecursive(item)
    }
  }

  function buscaritemSub(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const nomeSemAcentos = removerAcentos(String(item?.keywords))
      return nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) || !buscar_nome
    }
  }

  function obterRotina(cod_rotina: number): boolean {
    let rotina = (permissoes ?? []).find((item) => item.cod_rotina === cod_rotina)
    let tem_acesso = rotina?.acesso === 1

    if (tem_acesso) {
      return true
    }

    let tem_alguma_outra_permissao =
      rotina?.alteracao === 1 || rotina?.exclusao === 1 || rotina?.inclusao === 1

    return tem_alguma_outra_permissao
  }

  useEffect(() => {
    const filterNavigation = navigationConfig.map((menu) => {
      if (menu.submenu) {
        return {
          ...menu,
          submenu: menu.submenu.filter((sub) => !sub.visible), // Filtra submenus onde 'hidden' é false
        }
      }
      return menu // Retorna o menu como está se não houver submenu
    })
 
    setNavigateFilter(filterNavigation)
  }, [navigationConfig])

  return (
    <Modal
      open={open}
      onCancel={() => {
        onClose(false)
      }}
      footer={false}
      width={840}
      destroyOnClose
    >
      <TtivosFinder>
        <div className='TtivosFinder__header'>
          <img src={ttivos} className='TtivosFinder__header__logo' />
          <h1 className='TtivosFinder__header_title'>Finder</h1>
        </div>

        <Input
          // label="Buscar palavra chave"
          placeholder='Digite a palavra chave do item que você procura dentro do sistema'
          value={search}
          onChange={setSearch}
          autoFocus
        />

        <div className='TtivosFinder__results'>
          {navigateFilter
            .filter(buscarItem(search))
            .map(({ icon, title, description, path, submenu, key, id }) => {
              let hasAccess = obterRotina(Number(id))
              if (submenu.length === 0) {
                return (
                  <Link
                    to={!hasAccess ? '#' : path}
                    style={{ cursor: !hasAccess ? 'not-allowed' : 'pointer' }}
                    className='TtivosFinder__results__page'
                    onClick={
                      !hasAccess
                        ? () => {}
                        : () => {
                            onClickLink(key)
                            onClose(false)
                          }
                    }
                    key={key}
                    data-disabled={!hasAccess}
                    data-active={window.location.pathname === path}
                  >
                    <div className='TtivosFinder__results__page__icon'>
                      <FontAwesomeIcon icon={icon} />
                    </div>
                    <div className='TtivosFinder__results__page__info'>
                      <TitleH2
                        style={window.location.pathname === path ? { color: '#fff' } : undefined}
                      >
                        {title}
                      </TitleH2>
                      {description}
                    </div>
                    <div className='TtivosFinder__results__page__link'>
                      <FontAwesomeIcon icon={faUpRightFromSquare} />
                    </div>
                  </Link>
                )
              }

              return (
                <>
                  {submenu.filter(buscaritemSub(search)).map((sub, sub_index) => {
                    let hasAccess = obterRotina(Number(sub.id))
                    let isActive = window.location.pathname === sub.path
                    return (
                      <Link
                        to={!hasAccess ? '#' : sub.path}
                        className='TtivosFinder__results__page'
                        style={{ cursor: !hasAccess ? 'not-allowed' : 'pointer' }}
                        onClick={
                          !hasAccess
                            ? () => {}
                            : () => {
                                onClickLink(sub.key)
                                onClose(false)
                              }
                        }
                        key={gerarKey(sub_index * 70)}
                        data-disabled={!hasAccess}
                        data-active={isActive}
                      >
                        <div className='TtivosFinder__results__page__icon' data-sub={true}>
                          <FontAwesomeIcon icon={hasAccess ? icon : faLock} />
                        </div>
                        <div className='TtivosFinder__results__page__info'>
                          <TitleH2 style={isActive ? { color: '#fff' } : undefined}>
                            <span
                              style={{
                                color: !hasAccess
                                  ? 'var(--disabled-text-color)'
                                  : isActive
                                  ? '#C7FF98'
                                  : 'var(--primary-color)',
                                fontWeight: 600,
                                fontSize: 12,
                              }}
                            >
                              {title} ➔
                            </span>{' '}
                            {sub.title}
                          </TitleH2>
                          {sub.description}
                        </div>
                        <div className='TtivosFinder__results__page__link'>
                          <FontAwesomeIcon icon={faUpRightFromSquare} />
                        </div>
                      </Link>
                    )
                  })}
                </>
              )
            })}

          {navigationConfig.filter(buscarItem(search)).length === 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <>
                  Nenhuma correspondencia contrada para <b>'{search}'</b>
                </>
              }
            />
          )}
        </div>
      </TtivosFinder>
    </Modal>
  )
}

import { useQuery } from 'react-query';

//APP
import { api } from '../../../api';
import { errorAlert } from '../../../../utils/alert';

//=================================================================================>
// BUSCAR LISTA DE ELEMENTOS
//<=================================================================================

//TYPES
type Item__TypeCategoria = {
    cod: number | null,
    cod_categoria: number | null,
    cod_tipo: number | null,
    create_time: string | number,
    update_time: string | number,
    delete_time: string | number,
    categoria_nome: string | number,
    tipo_nome: string | number,
};
type Item = {
    cod: number | null,
    nome: string | number,
    cod_tipo: number | null,
    create_time: string | number,
    update_time: string | number,
    delete_time: string | number,
    outros_tipos: number | null,
    cod_categoria: number | null,
    geral: number | null,
    exibir_marca: number | null,
    teste_bandeja: number | null,
    cod_conta: number | null,
    cod_cliente: number | null,
    create_user: string | number,
    update_user: string | number,
    delete_user: string | number,
    update_time_strotime: number | null,
    categorias: Item__TypeCategoria[]
}

//INTERFACE
export interface CategoriasMarcas {
    itens: Item[]
}

export const useGetCategoriaMarca = () => {
    const queryKey: ['get-categoria-marca'] = [`get-categoria-marca`];

    const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<CategoriasMarcas, Error>(queryKey, async () => {
        return api.get(`tipos-gerais?exibir_marca=true&pagination=-1`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                errorAlert(error?.response?.data?.error ?? 'Falha ao obter lista de categorias!');
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_categorias: data, 
        carregando_categorias: isFetching, 
        refetch_data_categorias: refetch,
        isFetched: isFetchedAfterMount,
        isError: isError
    };
};
import React, { useEffect, useState } from 'react'
import { Button, Modal, Space, Table } from 'antd'

import { faAdd, faEdit, faPen, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'

import Icons from '../../../../Components/Icons'
import { CampoPrincipal, ContainerCultura, TabelaCulturas } from './styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormCadastarCultura from './components'
import {
  delCadastrarCultura,
  getCulturas,
  getTiposMedidas,
} from '../../../../services/cadastrarCultura'
import { AxiosResponse } from 'axios'
import { pagination } from '../../../../Components/Default'
import Icon from '@ant-design/icons/lib/components/Icon'
import { IconAlert } from '../../../../Components/iconsAlert'
import { Notification } from '../../../../Components/notification'
import { successAlert } from '../../../../utils/alert'

const CadastroCultura: React.FC = () => {
  const [visibleForm, setVisibleForm] = useState(false)
  const [tipoMedidas, setTipoMedidas] = useState([])
  const [culturas, setCulturas] = useState([])
  const [tempCulturas, setTempCulturas] = useState([])
  const [update, setUpdate] = useState(0)
  const [loadingTable, setLoadingTable] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const handleClickNewCultura = () => {
    setVisibleForm(true)
    setUpdate(0)
  }

  const handleCloseForm = (data) => {
    setVisibleForm(data)
    if (data) {
    } else {
      setUpdate(0)
      handleGetMedidas()
      setLoadingTable(true)
    }
  }

  const handleEditCultura = (cod) => {
    setUpdate(cod)
    setVisibleForm(true)
  }

  const handleClickTrash = (cod, nome) => {
    Modal.confirm({
      title: `Deseja remover a categoria ${nome}?`,
      icon: <IconAlert type='confirm' size={6} />,
      okText: 'Sim',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        const response = await delCadastrarCultura(cod)

        if (response.status === 200) {
          successAlert('Categoria removido com sucesso!');

          handleGetMedidas()
        }
      },
    })
  }

  const handleGetMedidas = async () => {
    const resp: AxiosResponse = await getTiposMedidas()
    setTipoMedidas(resp.data.itens)
    const response: AxiosResponse = await getCulturas({ page: currentPage, pagination: pageSize })
    setCulturas(response.data.itens)
    setTempCulturas(response.data.itens)
    setTotal(response.data.total)
    setLoadingTable(false)
  }

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  const handleSearch = (data) => {
    setLoadingTable(true)
    const dados = culturas
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setTempCulturas(response)
      setLoadingTable(false)
    }, 500)
  }

  useEffect(() => {
    handleGetMedidas()
    setLoadingTable(true)
  }, [])

  useEffect(() => {
    handleGetMedidas()
    setLoadingTable(true)
  }, [currentPage, pageSize])

  useEffect(() => {
    if (update === 0) {
      handleGetMedidas()
      setLoadingTable(true)
    }
  }, [update])

  return (
    <ContainerCultura>
      <FormCadastarCultura
        open={visibleForm}
        update={update}
        onClose={(data) => handleCloseForm(data)}
        tipoMedidas={tipoMedidas}
      />
      <CampoPrincipal>
        <div className='header__cadastrar'>
          <h2 className='title__header'>Cadastrar cultura</h2>
          <div className='camp__add'>
            <Button
              className='btn__register'
              icon={<FontAwesomeIcon icon={faAdd} />}
              onClick={handleClickNewCultura}
            >
              Cadastrar
            </Button>
            <div className='camp__search'>
              <Icons style={{ width: '10%' }} icon={faSearch}></Icons>
              <input
                onChange={handleSearch}
                className='input__search'
                placeholder='Buscar cultura'
              ></input>
            </div>
          </div>
        </div>
        <TabelaCulturas>
          <Table
            rowKey='cod'
            loading={loadingTable}
            scroll={{ y: 'calc(90vh - 275px)' }}
            size='small'
            dataSource={tempCulturas}
            pagination={{
              current: currentPage,
              total: total,
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
            onChange={(pagination) => handleTableChange(pagination)}
            columns={[
              {
                key: 1,
                title: 'Nome',
                dataIndex: 'nome',
              },
              {
                key: 2,
                title: 'Nome científico',
                dataIndex: 'nome_cientifico',
              },
              {
                key: 3,
                title: 'Unidade de medida',
                dataIndex: 'unidade_colheita_nome',
              },
              {
                key: 4,
                width: 90,
                title: 'Ações',
                align: 'center',
                dataIndex: 'cod',
                render: (data, record) => (
                  <Space>
                    <Button
                      shape='circle'
                      type='default'
                      onClick={() => handleEditCultura(data)}
                      icon={<Icon component={() => <Icons icon={faPen} />} />}
                    />
                    <Button
                      shape='circle'
                      type='primary'
                      danger
                      onClick={() => handleClickTrash(data, record.nome)}
                      icon={<Icon component={() => <Icons icon={faTrash} />} />}
                    />
                  </Space>
                ),
              },
            ]}
            footer={() => (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'right',
                }}
              >
                <b>Total geral: {culturas.length}</b>
              </div>
            )}
          />
        </TabelaCulturas>
      </CampoPrincipal>
    </ContainerCultura>
  )
}

export default CadastroCultura

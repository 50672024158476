import { Card, Col, Form, Input, Layout, Modal, Radio, Row, Select, Upload } from 'antd'
import { useEffect, useRef, useState } from 'react'

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import dayjs from 'dayjs'
import InputMask from 'react-input-mask'
import { v4 as uuid } from 'uuid'
import { useLogic } from '../../context/useLogic'
import { getCliente, postCliente, putCliente } from '../../services/Cliente'
import { Notification } from '../notification'
import { Container } from './styled'

import {
  buscaCidade,
  listEscolaridade,
  listEstadoCivil,
  listProfissao,
  listUf,
} from '../../services/Afins'
import { InputChangeEventHandler } from '../../types'
import base64 from '../Base64Geral'
import DatePicker from '../DatePiker'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../utils/alert'

const { Content } = Layout
const { Option } = Select

const Cliente = (props) => {
  const { responsive } = useLogic()
  // eslint-disable-next-line react/prop-types
  const { codCliente, typeSubmit, refresh, onClose, permissions } = props

  const uploadLogo = useRef(null)

  const [radioTipo, setRadioTipo] = useState('')
  const [radioSexo, setRadioSexo] = useState('')

  const [imageUrl, setImageUrl] = useState<any>(null)
  const [loadingImg, setLoadingImg] = useState(false)
  const [fileListImg, setFileListImg] = useState([])

  const [previewVisibleImg, setPreviewVisibleImg] = useState(false)

  const [toogleTipo, setToogleTipo] = useState({
    mask: '',
    label: 'CPF/CNPJ',
    fisico: true,
    juridico: true,
    labelF: 'Nome',
    labelJ: 'Nome Fantasia',
  })
  const [typeGroup, setTypeGroup] = useState(false)

  const [form] = Form.useForm()
  const handleChangeTipo = (e) => {
    e.preventDefault()
    setRadioTipo(e.target.value)
  }

  const handleChangeSexo = (e) => {
    e.preventDefault()
    setRadioSexo(e.target.value)
  }

  const handleChangeLowCase = (data) => {
    const { value } = data.target
    form.setFieldsValue({
      email: value.toLowerCase(),
    })
  }

  const onPreview = async () => {
    setPreviewVisibleImg(true)
  }

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Logo</div>
    </div>
  )

  const handleChangeUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoadingImg(true)
    }

    const status = info.file.status === 'removed' ? 'removed' : 'done'

    if (status === 'done') {
      // Get this url from response in real world.
      const temp = await base64(info.file.originFileObj)

      setImageUrl(temp)
      setFileListImg(info.fileList)
      setLoadingImg(false)
    } else {
      setLoadingImg(false)
    }
  }

  const onRemoveLogo = () => {
    setFileListImg([])
    setImageUrl(null)
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const onFinish = async (data) => {
    try {
      const eUrl = imageUrl === null ? null : imageUrl.split('://', 1)[0]

      loadingAlert({msg: 'Atualiando cliente...', id: 'atualizando'})

      if (eUrl === 'https' || imageUrl === null) {
        data.logo = null
      } else {
        data.logo = imageUrl
      }

      data.nascimento = dayjs(data.nascimento).format('YYYY-MM-DD')

      if (codCliente <= 0) {
        const res = await postCliente(data)
        if (res.status === 201) {
          successAlert('Cliente cadastrado com sucesso', 5000, 'atualizando');
          form.resetFields()
          refresh()
        }
      } else {
        const res = await putCliente(codCliente, data)

        if (res.status === 200) {
          successAlert('Cliente atualizado com sucesso', 5000, 'atualizando');
          refresh()
        }
      }
    } catch (error) {
      
      destroyAlert('atualizando');

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data.error ?? 'Falha na operação!')
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha na operação!')
          })
        }
      }
    }
  }

  useEffect(() => {
    if (radioTipo === 'J') {
      setToogleTipo({
        mask: '99.999.999/9999-99',
        label: 'CNPJ',
        fisico: false,
        juridico: true,
        labelF: '',
        labelJ: 'Nome Fantasia',
      })

      setTypeGroup(false)
    } else if (radioTipo === 'F') {
      setToogleTipo({
        mask: '999.999.999-99',
        label: 'CPF',
        fisico: true,
        juridico: false,
        labelF: 'Nome',
        labelJ: '',
      })
      setTypeGroup(false)
    } else if (radioTipo === 'G') {
      setToogleTipo({
        mask: '',
        label: 'GRUPO',
        fisico: false,
        juridico: false,
        labelF: 'Nome',
        labelJ: 'Nome',
      })
      setTypeGroup(true)
    }
  }, [radioTipo])

  useEffect(() => {
    form.resetFields()
    setFileListImg([])
    setImageUrl(null)
  }, [onClose])

  const [valueInputCity, setValueInputCity] = useState(undefined)

  const [dataUf, setDataUf] = useState([])
  const [dataCE, setDataCE] = useState([])
  const [dataEC, setDataEC] = useState([])
  const [dataPR, setDataPR] = useState([])
  const [dataCity, setDataCity] = useState([])

  useEffect(() => {
    async function fetch() {
      const resUF = await listUf()
      const resCE = await listEscolaridade()
      const resEC = await listEstadoCivil()
      const resPR = await listProfissao()

      setDataUf(resUF)
      setDataCE(resCE)
      setDataEC(resEC)
      setDataPR(resPR)
    }
    fetch()
  }, [])

  const [inputDisableCidade, setInputDisableCidade] = useState(true)
  const haldleChangeCidadeUf = async (cod) => {
    if (cod) {
      const resCity = await buscaCidade('', cod, '')
      setDataCity(resCity)

      setInputDisableCidade(false)
    } else {
      setInputDisableCidade(false)
    }
  }
  const optionsCity = dataCity.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.nome}
    </Option>
  ))
  const optionsUF = dataUf.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.sigla}
    </Option>
  ))
  const optionsCE = dataCE.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.nome}
    </Option>
  ))
  const optionsEC = dataEC.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.nome}
    </Option>
  ))

  const optionsPR = dataPR.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.nome}
    </Option>
  ))
  const handleChangeCity = (value) => {
    setValueInputCity({ value })
  }

  useEffect(() => {
    async function fetch() {
      // eslint-disable-next-line react/prop-types
      if (typeSubmit.type === 'PUT' || codCliente > 0) {
        setFileListImg([])
        form.resetFields()
        
        loadingAlert({msg: 'Carregando...'});
        
        const res = await getCliente(codCliente, '', '', '')
        const dados = res.data.itens[0]

        haldleChangeCidadeUf(dados?.cidade_cod_uf)

        setRadioTipo(dados?.tipo)

        dados.nascimento = dados.nascimento === null ? '' : dayjs(dados.nascimento)

        form.setFieldsValue(dados)

        const uid = uuid()

        const logomarca = {
          file: {
            uid,
            name: '',
            thumbUrl: dados.logo,
          },
          fileList: {
            uid,
            name: '',
            thumbUrl: dados.logo,
          },
        }
        setImageUrl(dados.logo)
        uploadLogo.current.onSuccess('done', logomarca.file)

        setFileListImg(dados.logo === '' ? [] : [logomarca.fileList])

        destroyAlert()
        // eslint-disable-next-line react/prop-types
      }
    }
    fetch()
  }, [codCliente])

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (!typeSubmit.submit && typeSubmit.type === 'POST') {
      setFileListImg([])

      form.resetFields()
    }
    // eslint-disable-next-line react/prop-types
    if (typeSubmit.submit) {
      const dados = form.getFieldsValue()

      onFinish(dados)
    }
  }, [typeSubmit])

  return (
    <Container>
      <Content>
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 20 }}
          layout='vertical'
          // eslint-disable-next-line react/prop-types
          style={{ pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto' }}
        >
          <Form.Item hidden initialValue={localStorage.getItem('conta')} name='cod_conta'>
            <Input type='text' />
          </Form.Item>
          <Form.Item hidden initialValue='1' name='status'>
            <Input type='text' />
          </Form.Item>
          <Row>
            <Col flex='1 1 100vw'>
              <Card size='small' title='Tipo de Cliente'>
                <Form.Item
                  rules={[{ required: true, message: 'Selecione o Tipo de Cadastro' }]}
                  required
                  name='tipo'
                >
                  <Radio.Group buttonStyle='outline' onChange={handleChangeTipo} value={radioTipo}>
                    <Radio name='tipo' value='F'>
                      Pessoa Fisica
                    </Radio>

                    <Radio name='tipo' value='J'>
                      Pessoa Jurídica
                    </Radio>

                    <Radio name='tipo' value='G'>
                      Grupo
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>
            </Col>
            <Card style={{ width: '100vw' }}>
              <Row gutter={[16, 0]}>
                <Col
                  flex='0 1 250px'
                  // span={responsive ? 24 : 4}
                  style={responsive && { justifyContent: 'center', display: 'flex' }}
                >
                  <Form.Item name='logo' style={{ height: 200 }}>
                    <ImgCrop
                      modalOk='Salvar'
                      modalTitle='Editar Logo'
                      aspect={500 / 500}
                      rotationSlider
                    >
                      <Upload
                        ref={uploadLogo}
                        className='img-antd'
                        accept='image/png, image/jpeg'
                        onChange={handleChangeUpload}
                        listType='picture-card'
                        customRequest={dummyRequest}
                        onPreview={onPreview}
                        fileList={fileListImg}
                        onRemove={onRemoveLogo}
                        maxCount={1}
                      >
                        {fileListImg.length < 1 && uploadButton}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                  <Modal
                    open={previewVisibleImg}
                    closeIcon={false}
                    title='Logo'
                    footer={null}
                    onCancel={() => setPreviewVisibleImg(false)}
                  >
                    <img alt='example' style={{ width: '100%' }} src={imageUrl} />
                  </Modal>
                </Col>
                <Col flex='1 1 600px'>
                  <Row gutter={[16, 0]}>
                    <Col hidden={!!(radioTipo === 'G' || radioTipo === 'F')} flex='1 1 500px'>
                      <Form.Item
                        hidden={!!(radioTipo === 'G' || radioTipo === 'F')}
                        rules={[
                          {
                            required: toogleTipo.juridico,
                            message: 'Preencha a Razão social',
                          },
                        ]}
                        label='Razão Social'
                        name='razao_social'
                      >
                        <Input disabled={typeGroup} />
                      </Form.Item>
                    </Col>
                    <Col flex='1 1 500px'>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Campo nome Invalido',
                          },
                        ]}
                        label={toogleTipo.fisico ? toogleTipo.labelF : toogleTipo.labelJ}
                        name='nome'
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col flex='1 1 200px'>
                      <Form.Item
                        label={!toogleTipo.fisico ? 'Fundado em' : 'Nacimento'}
                        name='nascimento'
                      >
                        <DatePicker style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>

                    <Col hidden={!!(radioTipo === 'G')} flex='1 1 300px'>
                      <Form.Item label={toogleTipo.label} name='cpf_cnpj' initialValue=''>
                        <InputMask disabled={typeGroup} mask={toogleTipo.mask}>
                          {/* @ts-ignore */}
                          {(inputProps: InputChangeEventHandler) => (
                            <Input key='cpf_cnpj' disabled={typeGroup} {...inputProps} />
                          )}
                        </InputMask>
                      </Form.Item>
                    </Col>
                    <Col hidden={!!(radioTipo === 'G' || radioTipo === 'F')} flex='1 1 200px'>
                      <Form.Item label='IE' name='inscricao_estadual'>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row hidden={!!(radioTipo === 'G' || radioTipo === 'J')} gutter={[16, 0]}>
                    <Col>
                      <Form.Item hidden={toogleTipo.juridico} label='Rg' name='rg'>
                        <Input disabled={typeGroup} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item hidden={toogleTipo.juridico} label='Órgão expedidor' name='uf_rg'>
                        <Input disabled={typeGroup} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item hidden={toogleTipo.juridico} label='Sexo' name='sexo'>
                        <Radio.Group
                          disabled={typeGroup}
                          buttonStyle='outline'
                          onChange={handleChangeSexo}
                          value={radioSexo}
                        >
                          <Radio key='a' name='sexo' value='M'>
                            Masculino
                          </Radio>

                          <Radio key='b' name='sexo' value='F'>
                            Feminino
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]} />
                </Col>
              </Row>

              <Col span={24}>
                <Row hidden={!!(radioTipo === 'G' || radioTipo === 'J')} gutter={[16, 0]}>
                  <Col flex='1 1 200px'>
                    <Form.Item label='Nacionalidade' name='nacionalidade'>
                      <Input disabled={typeGroup} />
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 200px'>
                    <Form.Item label='Escolaridade' name='cod_escolaridade'>
                      <Select
                        disabled={typeGroup}
                        showSearch
                        filterOption={(input, option) =>
                          String(option.children)
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {optionsCE}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 200px'>
                    <Form.Item label='Estado Civil' name='cod_estado_civil'>
                      <Select
                        disabled={typeGroup}
                        showSearch
                        filterOption={(input, option) =>
                          String(option.children)
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {optionsEC}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 200px'>
                    <Form.Item label='Profissão' name='cod_profissao'>
                      <Select
                        disabled={typeGroup}
                        showSearch
                        filterOption={(input, option) =>
                          String(option.children)
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {optionsPR}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col flex='1 1 200px'>
                    <Form.Item label='Celular' name='fone' initialValue=''>
                      <InputMask key='fone' mask='(99)99999-9999'>
                        {/* @ts-ignore */}
                        {(inputProps: InputChangeEventHandler) => (
                          <Input key='fone' {...inputProps} />
                        )}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 200px'>
                    <Form.Item label='Fone Fixo' name='fone2' initialValue=''>
                      <InputMask key='fone2' mask='(99)9999-9999'>
                        {/* @ts-ignore */}
                        {(inputProps: InputChangeEventHandler) => (
                          <Input key='fone2' {...inputProps} />
                        )}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col flex='2 1 400px'>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: 'Email Invalido',
                        },
                      ]}
                      label='Email'
                      name='email'
                    >
                      <Input onChange={handleChangeLowCase} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col flex='1 1 50px'>
                    <Form.Item label='Estado' name='cidade_cod_uf'>
                      <Select
                        showSearch
                        onChange={haldleChangeCidadeUf}
                        filterOption={(input, option) =>
                          String(option.children)
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {optionsUF}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 200px'>
                    <Form.Item label='Cidade' name='cod_cidade'>
                      <Select
                        disabled={inputDisableCidade}
                        onChange={handleChangeCity}
                        value={valueInputCity}
                        showSearch
                        filterOption={(input, option) =>
                          String(option.children)
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {optionsCity}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='0 1 200px'>
                    <Form.Item label='Cep' name='cep' initialValue=''>
                      <InputMask key='cep' mask='99999999'>
                        {/* @ts-ignore */}
                        {(inputProps: InputChangeEventHandler) => (
                          <Input key='cep' {...inputProps} />
                        )}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 400px'>
                    <Form.Item label='Endereço' name='endereco'>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col flex='0 1 100px'>
                    <Form.Item label='Número' name='endereco_numero'>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 200px'>
                    <Form.Item label='Bairro' name='bairro'>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col flex='1 1 200px'>
                    <Form.Item label='Complemento' name='complemento'>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col hidden={radioTipo === 'G'} flex='1 1 500px'>
                    <Form.Item
                      hidden={radioTipo === 'G'}
                      label='Outros Solicitantes'
                      name='solicitante'
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row />
              </Col>
            </Card>
          </Row>
        </Form>
      </Content>
    </Container>
  )
}

export default Cliente

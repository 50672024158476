import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, Select, Space } from 'antd'

import { AxiosResponse } from 'axios'
import { tiposSistema } from '../../../services/Afins'
import { Notification } from '../../notification'
import {
  getRecomendaAcaoAtivos,
  postRecomendaAcaoAtivos,
  putRecomendaAcaoAtivos,
  TypeRecomendaAcaoTipos,
} from '../../../services/recomendacao/ativosTipos'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../../utils/alert'

interface TypesRecomendaTipos {
  visible: boolean
  update: number
  onClose: (data: boolean) => void
}

const FormRecomendaTipos: React.FC<TypesRecomendaTipos> = (props) => {
  const { visible, update, onClose } = props

  const [form] = Form.useForm()

  const [dataTipos, setDataTipos] = useState([])
  const [visibleDrawer, setVisibleDrawer] = useState(false)

  const listTipos = async () => {
    const resp = await tiposSistema(1)

    setDataTipos(resp)
  }

  const editAtivo = async (cod) => {
    loadingAlert({msg: 'Carregando ativos...', id: 'load_ativos'})
    const resp: AxiosResponse<TypeRecomendaAcaoTipos> = await getRecomendaAcaoAtivos({ cod })

    form.setFieldsValue(resp.data.itens[0])
    destroyAlert('load_ativos');
  }

  const hancleDrawerClose = () => {
    onClose(false)
    form.resetFields()
  }

  const onFinish = async (data) => {
    try {
      loadingAlert({msg: 'Salvando...', id: 'save_ativos'});
      if (update <= 0) {
        const resp = await postRecomendaAcaoAtivos(data)

        if (resp.status === 201) {
          successAlert('Ativo salvo com sucesso!', 5000, 'save_ativos');
          onClose(true)
          form.resetFields()
        }
      } else {
        const resp = await putRecomendaAcaoAtivos(update, data)

        if (resp.status === 200) {
          successAlert('Ativo atualizado com sucesso!', 5000, 'save_ativos');
          onClose(true)
        }
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? 'Falha na operação!', 7000, 'save_ativos');
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha na operação!', 7000, 'save_ativos');
          })
        }
      }
      else {
        errorAlert('Falha na operação! (sem resposta)', 7000, 'save_ativos');
      }
    }
  }

  useEffect(() => {
    setVisibleDrawer(visible)
  }, [visible])

  useEffect(() => {
    if (update > 0) {
      editAtivo(update)
    }
  }, [update])

  useEffect(() => {
    listTipos()
  }, [])

  return (
    <Form form={form} onFinish={onFinish} layout='vertical'>
      <Drawer
        placement='left'
        style={{ position: 'absolute' }}
        getContainer={false}
        open={visibleDrawer}
        closeIcon={false}
        onClose={() => hancleDrawerClose()}
        footer={
          <Space>
            <Button type='primary' htmlType='submit'>
              Salvar
            </Button>
            <Button type='primary' danger onClick={() => hancleDrawerClose()}>
              Fechar
            </Button>
          </Space>
        }
      >
        <Form.Item label='Nome' name='nome'>
          <Input />
        </Form.Item>
        <Form.Item label='Tipo' name='cod_tipo'>
          <Select
            showSearch
            filterOption={(input, option) =>
              String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dataTipos.map((data) => (
              <Select.Option key={data.cod} value={data.cod}>
                {data.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Drawer>
    </Form>
  )
}

export default FormRecomendaTipos

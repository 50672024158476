/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react'

import { Button, Col, Drawer, Form, Input, Row } from 'antd'

import { faPen } from '@fortawesome/free-solid-svg-icons'
import { TalhaoItens, getTalhao, postTalhao, putTalhao } from '../../services/Talhao'

import { kml } from '@tmcw/togeojson'
import { useLogic } from '../../context/useLogic'
import { calculoAreaCoord, getBoundsNoArray } from '../../services/Afins'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import Icons from '../Icons'
import DesenhaTalhaoNew from '../desenhoTalhao/new'
import KMLUploader from '../kmlUpload'
import Maps from '../maps/polygon'
import { Container } from './styled'
import DiasDesdeData from '../DiasDesdeData'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../utils/alert'

interface TypeProps {
  visible: boolean
  onClose: (data: boolean) => void
  update: number
  setUpdate: (codPro?: number, codTalhao?: number) => void
  atualizar: (data?: number, data2?: string) => void
  codPro: number
  permissions?: ItensPerUserLogged
  dataTalhao: any
}

interface TypeEditPolygon {
  nome: string
  propriedadeCod: number
  propriedadeNome: string
  fitBounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral
  polygon: google.maps.LatLngLiteral[] | google.maps.LatLng[]
}

interface Coordinates {
  latitude: number
  longitude: number
}

const Talhaos: React.FC<TypeProps> = (props) => {
  const { visible, onClose, update, dataTalhao, atualizar, setUpdate, codPro, permissions } = props

  const { responsive } = useLogic()

  const [form] = Form.useForm()

  const uploadFile = useRef(null)

  const [selectedFile, setSelectedFile] = useState(null)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [disable, setDisable] = useState(true)
  const [propri, setPropri] = useState(false)

  const [modalDesenho, setModalDesenho] = useState(false)
  const [validoFile, setValidoFile] = useState(false)
  const [editPolygon, setEditPolygon] = useState<TypeEditPolygon>({
    polygon: [],
  } as TypeEditPolygon)
  const [coordsKml, setCoordsKml] = useState([])
  const [kmls, setKml] = useState<TalhaoItens>()
  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const hancleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setUpdate(0)
    form.resetFields()
    setKml(null)
    setCoordsKml([])
    setSelectedFile(null)
    setDisable(true)
    setValidoFile(false)
  }

  const handleGetTalhao = async () => {
    try {
      loadingAlert({msg: 'Carregando talhão...', id: 'load_talhao'})
      const response = await getTalhao(codPro, update)

      if (response.status === 200) {
        const dados = response.data.itens[0]

        setKml(dados)
        dados.arquivo = ''

        form.setFieldsValue(dados)

        const areaPolygon = dados.kml.coordenadas.map((item) => {
          return { lat: item.latitude, lng: item.longitude }
        })

        const calcArea = calculoAreaCoord([areaPolygon])
        if (calcArea > 0) {
          setValidoFile(true)
        } else {
          setValidoFile(false)
        }

        setEditPolygon({
          nome: dados.nome,
          propriedadeNome: '',
          propriedadeCod: dados.cod_propriedade,
          fitBounds: getBoundsNoArray(
            dados.kml.coordenadas.map((item) => {
              return { lat: item.latitude, lng: item.longitude }
            })
          ),
          polygon: dados.kml.coordenadas.map((item) => {
            return { lat: item.latitude, lng: item.longitude }
          }),
        })

        form.setFieldsValue({ area: calcArea })
        destroyAlert('load_talhao');
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        errorAlert(data?.error ?? 'Falha na operação!', 7000, 'load_talhao');
      }
      else {
        errorAlert('Falha na operação! (sem resposta)', 7000, 'load_talhao');
      }
    }
  }

  const listPropri = () => {
    handleGetTalhao()
    atualizar(codPro, '')
    setDisable(false)
  }

  const onFinish = async (data) => {
    loadingAlert({msg: 'Salvando...', id: 'save_talhao'})
    try {
      if (update <= 0) {
        const novoArquivo = data.arquivo ?? selectedFile

        const dadosAtualizados = {
          ...data,
          arquivo: novoArquivo,
        }
        const response = await postTalhao(codPro, dadosAtualizados)
        if (response.status === 201) {
          successAlert('Talhão cadastrado com sucesso!', 5000, 'save_talhao');
          form.resetFields()

          onClose(false)
          setKml(null)
          setSelectedFile(null)
          setCoordsKml([])
          setDisable(true)
          setUpdate(0)
          atualizar(codPro, '')
          onClose(false)
          setKml(null)
          setSelectedFile(null)
          setCoordsKml([])
          setDisable(true)
        }
      } else {
        const novoArquivo = selectedFile

        const dadosAtualizados = {
          ...data,
          arquivo: novoArquivo,
        }
        const response = await putTalhao(codPro, update, dadosAtualizados)

        if (response.status === 200) {
          successAlert('Talhão atualizado com sucesso!', 5000, 'save_talhao');

          onClose(false)
          setKml(null)
          setSelectedFile(null)
          setCoordsKml([])
          setDisable(true)
          setUpdate(0)
          atualizar(codPro, '')
          onClose(false)
          setKml(null)
          setSelectedFile(null)
          setCoordsKml([])
          setDisable(true)
        }
      }
    } catch (error) {

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? 'Falha na operação!', 7000, 'save_talhao');
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha na operação!', 7000, 'save_talhao');
          })
        }
      }
      else {
        errorAlert('Falha na operação! (sem resposta)', 7000, 'save_ativos');
      }
    }
  }

  const handleFileUpload = (rawData: string, base64Data) => {
    extractCoordinates(rawData)
    setSelectedFile(base64Data)
  }

  const extractCoordinates = async (kmlString: string) => {
    const parseXmlFromString = async (xmlString: string): Promise<Document> => {
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlString, 'application/xml')
      return xmlDoc
    }

    try {
      const domKml = await parseXmlFromString(kmlString)
      const dataGeoJson = kml(domKml)

      const talhoes = []

      const dados = dataGeoJson.features
      const filtrado = dados.filter((data) => data.geometry.type === 'Polygon')

      if (filtrado.length > 0) {
        for (let i = 0; i < filtrado.length; i += 1) {
          const fragment = filtrado[i]
          const talhaoPoly = []

          const coordinates = fragment.geometry.coordinates[0]

          for (let ii = 0; ii < coordinates.length; ii += 1) {
            const coord = coordinates[ii]

            talhaoPoly.push({ lat: coord[1], lng: coord[0] })
          }

          talhoes.push(talhaoPoly)
        }

        const areaCoord = talhoes[0].map((item) => {
          return { lat: item.lat, lng: item.lng }
        })

        const calcArea = calculoAreaCoord([areaCoord])
        if (calcArea <= 0) {
          errorAlert('Arquivo kml inválido!');
          setValidoFile(false)
          setTimeout(() => {
            setDisable(true)
          }, 1000)
        } else {
          setValidoFile(true)
          setTimeout(() => {
            setDisable(false)
          }, 1000)
        }

        form.setFieldsValue({ area: calcArea })

        if (update > 0) {
          setEditPolygon({
            nome: kmls.nome,
            propriedadeNome: '',
            propriedadeCod: kmls.cod_propriedade,
            fitBounds: getBoundsNoArray(editPolygon.polygon),
            polygon: areaCoord.map((item) => {
              return { lat: item.lat, lng: item.lng }
            }),
          })
        } else {
          setCoordsKml(areaCoord)
        }
      } else {
        errorAlert('Arquivo kml inválido!');
        setValidoFile(false)
      }
    } catch (error) {
      console.error('Erro ao processar XML:', error)
    }
  }

  // const coordinates: Coordinates[] = []

  // const regex = /<coordinates>([^<]+)<\/coordinates>/g

  // let match
  // while ((match = regex.exec(kmlString)) !== null) {
  //   const coordinatesString = match[1]

  //   const pairs = coordinatesString.trim().split(' ')
  //   pairs.forEach((pair) => {
  //     const [longitude, latitude] = pair.split(',').map((coord) => parseFloat(coord.trim()))
  //     if (!isNaN(longitude) && !isNaN(latitude)) {
  //       coordinates.push({ latitude, longitude })
  //     }
  //   })
  // }
  // const areaCoord = coordinates.map((item) => {
  //   return { lat: item.latitude, lng: item.longitude }
  // })

  // const calcArea = calculoAreaCoord([areaCoord])
  // if (calcArea <= 0) {
  //   Notification({
  //     message: 'Arquivo Kml inválido',
  //     type: 'error',
  //   })
  //   setValidoFile(false)
  //   setTimeout(() => {
  //     setDisable(true)
  //   }, 1000)
  // } else {
  //   setValidoFile(true)
  //   setTimeout(() => {
  //     setDisable(false)
  //   }, 1000)
  // }

  // form.setFieldsValue({ area: calcArea })

  // if (update > 0) {
  //   setEditPolygon({
  //     nome: kml.nome,
  //     propriedadeNome: '',
  //     propriedadeCod: kml.cod_propriedade,
  //     fitBounds: getBoundsNoArray(editPolygon.polygon),
  //     polygon: areaCoord.map((item) => {
  //       return { lat: item.lat, lng: item.lng }
  //     }),
  //   })
  // } else {
  //   setCoordsKml(areaCoord)
  // }

  const handleChangeMask = (data) => {
    let area = data.target.value
    area = area.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    area = (area / 100).toFixed(2).replace('.', ',')
    form.setFieldsValue({ area })
  }

  const handleEditContorno = () => {
    setEditPolygon({
      nome: kmls.nome,
      propriedadeNome: '',
      propriedadeCod: kmls.cod_propriedade,
      fitBounds: getBoundsNoArray(editPolygon.polygon),
      polygon: kmls.kml.coordenadas.map((item) => {
        return { lat: item.latitude, lng: item.longitude }
      }),
    })

    setModalDesenho(true)
  }

  useEffect(() => {
    setDrawerVisible(visible)
  }, [visible])

  useEffect(() => {
    if (update <= 0) {
      form.resetFields()
    } else {
      handleGetTalhao()
    }
  }, [update])

  useEffect(() => {
    if (visible) {
      const talhaoCheck = dataTalhao.filter((item) => item.cod === update)

      if (talhaoCheck.length > 0) {
        if (talhaoCheck[0].create_user) {
          setUsuarioQueCriou(talhaoCheck[0].create_user)

          const dateOnlyString = talhaoCheck[0].create_time.split(' ')[0]
          const timeOnlyString = talhaoCheck[0].create_time.split(' ')[1]

          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (talhaoCheck[0].update_user) {
          setUsuarioQueAlterou(talhaoCheck[0].update_user)

          const dateOnlyString = talhaoCheck[0].update_time.split(' ')[0]
          const timeOnlyString = talhaoCheck[0].update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [logUser, visible, usuarioQueAlterou, usuarioQueCriou, update, horarioDeCriacao, horarioDeAtualizacao])

  return (
    <Container>
      <Form form={form} layout='vertical' onFinish={onFinish}>
        <Drawer
          title={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <>{update ? 'Atualizar Talhão' : 'Cadastrar novo Talhão'}</>
              {logUser && update > 0 && usuarioQueAlterou !== '' ? (
                <>
                  <DiasDesdeData
                    dataCriacao={dataAlteracao}
                    onDataFromCreation={handleDataFromCreation}
                  />
                  <small style={{ opacity: '0.5', fontSize: '10px' }}>
                    atualizado por: {usuarioQueAlterou}{' '}
                    {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                      ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                      : `às ${horarioDeAtualizacao}h`}
                  </small>
                </>
              ) : !logUser && update > 0 ? (
                <>
                  {' '}
                  {usuarioQueCriou === '' && usuarioQueAlterou === '' ? null : (
                    <small style={{ opacity: '0.5', fontSize: '10px' }}>
                      criado por: {usuarioQueCriou}{' '}
                      {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                        ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                        : `às ${horarioDeCriacao}h`}
                    </small>
                  )}
                </>
              ) : null}
            </div>
          }
          style={{ position: 'absolute' }}
          placement='right'
          width='100%'
          onClose={hancleDrawerClose}
          open={drawerVisible}
          closeIcon={false}
          getContainer={false}
          footer={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Button type='primary' htmlType='submit'>
                {update ? 'Atualizar' : 'Salvar'}
              </Button>
              {'   '}
              <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>
          }
        >
          <Row gutter={[8, 0]}>
            <Col
              style={{
                pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
              }}
              span={24}
            >
              <Row gutter={[8, 0]}>
                <Col flex='1 1 400px'>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label='Nome'
                    name='nome'
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Col span={24}>
                <Row gutter={[8, 0]}>
                  <Col flex='2 2 400px'>
                    <Form.Item
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                      label='Área (ha)'
                      name='area'
                    >
                      <Input
                        style={{ cursor: 'not-allowed' }}
                        readOnly
                        onChange={handleChangeMask}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={24} flex='1 1 200px'>
                <Form.Item style={{ width: '300px' }} name='arquivo'>
                  {/* <Upload
                    ref={uploadFile}
                    accept='.kml'
                    onChange={testeUpload}
                    maxCount={1}
                    multiple={false}
                    customRequest={dummyRequest}
                  >
                    <Button type='primary' icon={<UploadOutlined />}>
                      Arquivo KML
                    </Button>
                  </Upload> */}
                  <KMLUploader
                    disable={disable}
                    onFileUpload={handleFileUpload}
                    setDisable={setDisable}
                    valido={validoFile}
                    setValido={setValidoFile}
                  ></KMLUploader>
                </Form.Item>
                <Col flex='1 1 600px'>
                  {update <= 0 ? (
                    <div className='map-content'>
                      {coordsKml.length > 0 && <Maps coord={coordsKml} />}
                    </div>
                  ) : validoFile == false ? null : (
                    <div className='map-content'>
                      <div className='btn-edit-talhao'>
                        <Button
                          style={{ backgroundColor: 'white', border: 'none' }}
                          hidden={responsive}
                          onClick={handleEditContorno}
                          icon={<Icons style={{ color: 'green' }} icon={faPen} />}
                        />
                      </div>
                      {editPolygon.polygon.length > 0 && <Maps coord={editPolygon.polygon} />}
                    </div>
                  )}
                </Col>
              </Col>
            </Col>
          </Row>

          {/* Hiddenn */}

          <Form.Item initialValue='1' hidden name='status'>
            <Input />
          </Form.Item>

          <Form.Item hidden initialValue={localStorage.getItem('conta')} name='cod_conta'>
            <Input />
          </Form.Item>
          <Form.Item hidden initialValue={localStorage.getItem('cod-cliente')} name='cod_cliente'>
            <Input />
          </Form.Item>
        </Drawer>
      </Form>
      <DesenhaTalhaoNew
        isOpen={modalDesenho}
        update={update}
        cultivavel={propri}
        onClose={setModalDesenho}
        onSuccess={listPropri}
        talhao={editPolygon}
      />
    </Container>
  )
}

export default Talhaos

import { errorAlert, loadingAlert, successAlert } from '../../utils/alert'
import { api } from '../api'

interface TypeQuery {
  embed?: string
  page?: number | 1
  pagination?: number | -1
}

export const getBiotecnologia = async (params: TypeQuery, array) => {
  api.defaults.headers.Acesso = 'admin'
 
  

  const response = await api.get(`/recomendacao/tecnologia?codCultura=${array === null ? '' : array}`, {
    params
  })

  return response
}

export const postBiotecnologia = async (data, setBioNome, setDescricaoBio, setCulturasSelects, setNomeResumido) => {
   api.defaults.headers.Acesso = 'admin'

  loadingAlert({msg: 'Cadastrando biotecnologia...', id: 'cadastro_biotecnologia'})

  const { nome, descricao, culturas, sigla } = data
  const response = api
    .post(`/recomendacao/tecnologia`, {
      nome: nome,
      sigla: sigla,
      descricao: descricao,
      culturas: culturas,
      
    })

    .then((): any => {
      successAlert('Biotecnologia cadastrada com sucesso!', 5000, 'cadastro_biotecnologia');
      setBioNome('')
      setDescricaoBio('')
      setNomeResumido('')
      setCulturasSelects([])
    })
    .catch(() => {
      errorAlert('Falha ao cadastrar biotecnologia!', 5000, 'cadastro_biotecnologia');
    })

  return response
}

export const putBiotecnologia = async (data, cod, setBioNome, setNomeResumido, setDescricaoBio, setCulturasSelects ,onClose) => {
  api.defaults.headers.Acesso = 'admin'

  loadingAlert({msg: 'Atualizando biotecnologia...', id: 'atualizar_biotecnologia'})
  
 const { nome, sigla, descricao, culturas, culturas_removers } = data
 const response = api
   .put(`/recomendacao/tecnologia/${cod}`, {
     nome: nome,
     sigla: sigla,
     descricao: descricao,
     culturas: culturas,
     culturas_remover: culturas_removers,
   })

   .then((): any => {
     successAlert('Biotecnologia atualizada com sucesso!', 5000, 'atualizar_biotecnologia');
     setBioNome('')
     setNomeResumido('')
     setDescricaoBio('')
     setCulturasSelects([])
     onClose(false)
   })
   .catch(() => {
     errorAlert('Falha ao atualizar biotecnologia!', 5000, 'atualizar_biotecnologia');
   })

 return response
}

export const delBioTecnologia = async (cod) => {

  loadingAlert({msg: 'Excluindo biotecnologia...', id: 'excluir_biotecnologia'})

  const response = api.delete(`/recomendacao/tecnologia/${cod}`)
  
  .then((): any => {
    successAlert('Biotecnologia excluída com sucesso!', 5000, 'excluir_biotecnologia');

  })
  .catch(() => {
    errorAlert('Falha ao excluir biotecnologia!', 5000, 'excluir_biotecnologia');
  })

  return response
}

import { ItemRequest } from "../../../../../../../../services/logs";
import { errorAlert, successAlert } from "../../../../../../../../utils/alert";

export async function copyJson(request_selected: ItemRequest) {
    
    const { contentType, host, origin, referer, userAgent } = request_selected?.descricao?.headers ?? {};

    let buildJson = {
        headers: {
            'contentType': contentType,
            'host': host,
            'origin': origin,
            'referer': referer,
            'userAgent': userAgent
        }
    };

    let stringfy = JSON.stringify(buildJson);

    try {
        await navigator.clipboard.writeText(stringfy);
        successAlert('JSON Headers copiado com sucesso!');
    } catch (err) {
        console.error("Falha ao copiar o JSON: ", err);
        errorAlert("Falha ao copiar o JSON: ");
    }
}

import { UseMutationResult, useMutation } from 'react-query';
import { api } from '../../api'; 
import { localConfig } from '../../../Configs/localConfig';
import { errorAlert, loadingAlert, successAlert } from '../../../utils/alert';

//=================================================================================>
// SALVAR PONTOS IMPORTADOS
//<=================================================================================

//TYPES
type Pontos = {
    cod_talhao: number;
    latitude: number;
    longitude: number;
    nome: string;
}

//INTERFACE
interface Params {
    pontos: Pontos[]
    refetchPontos: Function
}

export const usePostImportarPontos = (): {
    carregando_importar: boolean;
    mutate_importar: (params: Params) => void;
    isSuccess_importar: boolean
} => {

    
    const mutation: UseMutationResult<Response, Error, Params> = useMutation(
        async (params: Params) => {

            const { codCliente, conta } = localConfig()
            const { pontos, refetchPontos } = params;

            loadingAlert({msg: 'Importando...', id: 'loading_pontos'})

            if (pontos.length === 0) {
                console.error('Nenhum ponto foi informado ou são duplicados (provavelmente foi removido pelo filtro a fim de evitar duplicidade)');
                errorAlert('Os pontos já foram adicionados ou não fazem parte da propriedade!', 5000, 'loading_pontos')
                return null;
            }

            return api.post(`/monitoramento/padrao-ponto?codCliente=${codCliente}&codConta=${conta}`, {
                pontos: pontos,
            } )
                .then((response: any) => {
                    successAlert(`${pontos?.length} pontos importados com sucesso!`, 5000, 'loading_pontos');
                    refetchPontos?.();
                    return response;
                })
                .catch((error: any) => {
                    if (pontos?.[0]?.cod_talhao === null || pontos?.[0]?.cod_talhao === undefined) {
                        errorAlert('KML incompatível', 5000, 'loading_pontos')
                    }
                    else {
                        errorAlert(error?.response?.data?.error ?? 'Pontos repetidos', 5000, 'loading_pontos')
                    }
                    throw error;
                });
        }
    );

    return { 
        carregando_importar: mutation.isLoading, 
        mutate_importar: mutation.mutate,
        isSuccess_importar: mutation.isSuccess
    };
};

//=================================================================================>
// REMOVER PONTOS IMPORTADOS
//<=================================================================================

//TYPES

//INTERFACE
interface ParamsRemover {
    pontos_remover: number[]
    refetchPontos: Function
}

export const usePostRemoverPontos = (): {
    carregando_remover_pontos: boolean;
    mutate_remover_pontos: (params: ParamsRemover) => void;
    isSuccess_remover_pontos: boolean
} => {

    
    const mutation: UseMutationResult<Response, Error, ParamsRemover> = useMutation(
        async (params: ParamsRemover) => {

            const { codCliente, conta } = localConfig()
            const { pontos_remover, refetchPontos } = params;

            loadingAlert({msg: 'Excluindo pontos...', id: 'loading_remove'})

            return api.post(`/monitoramento/padrao-ponto?codCliente=${codCliente}&codConta=${conta}`, {
                pontos_remover: pontos_remover,
            } )
                .then((response: any) => {
                    successAlert(`${pontos_remover?.length} Pontos excluídos com sucesso!`, 5000, 'loading_remove');
                    refetchPontos?.();
                    return response;
                })
                .catch((error: any) => {
                    errorAlert((error?.response?.data?.error) ?? 'Falha ao excluir pontos!', 7000, 'loading_remove')
                    throw error;
                });
        }
    );

    return { 
        carregando_remover_pontos: mutation.isLoading, 
        mutate_remover_pontos: mutation.mutate,
        isSuccess_remover_pontos: mutation.isSuccess
    };
};
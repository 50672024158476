import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd'
import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import ViewPage from '../../../Components/ViewPage'
import { tryError } from '../../../services/Afins'
import { ItensAplicativoTypes, TypesAplicativo, getAplicativos } from '../../../services/Aplicativo'

import { PlusOutlined } from '@ant-design/icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Icons from '../../../Components/Icons'
import { getParamApps, postParamApps, putParamApps } from '../../../services/config/paramApp'
import { Container } from './styles'
import { loadingAlert, successAlert } from '../../../utils/alert'

const AppCampoConfig: React.FC = () => {
  const [form] = Form.useForm()
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [update, setUpdate] = useState(null)
  const [dataApp, setDataApp] = useState<ItensAplicativoTypes[]>([])
  const [checkTrajeto, setCheckTrajeto] = useState(false)
  const [checkMonitoramento, setCheckMonitoramento] = useState(false)

  const listApp = async () => {
    try {
      const resp: AxiosResponse<TypesAplicativo> = await getAplicativos({})
      const dados = resp.data.itens
      setDataApp(dados)
      if (dados.length === 1) {
        form.setFieldsValue({ cod_aplicativo: dados[0].cod })
        handleChangeAplicativo(dados[0].cod)
      }
    } catch (error) {
      tryError(error)
    }
  }

  const handleClickNew = () => {
    setVisibleDrawer(true)
  }

  const handleChangeAplicativo = async (data) => {
    form.resetFields()
    const resp = await getParamApps({ codAplicativo: data })

    const dados = resp.data.itens

    if (dados.length > 0) {
      delete dados[0].parametros.periodo_sincronizar_monitoramento

      setUpdate(dados[0].cod)

      form.setFieldsValue(dados[0])

      setCheckTrajeto(dados[0].parametros.trajeto_coleta)
      setCheckMonitoramento(dados[0].parametros.trajeto_monitoramento)
    } else {
      setUpdate(null)
      form.resetFields()
      form.setFieldValue('cod_aplicativo', data)
    }
  }

  const onFinish = async (data) => {
    try {
      loadingAlert({msg: 'Salvar parâmetro...', id: 'save_param'})
      const resp = update !== null ? await putParamApps(data, update) : await postParamApps(data)

      if (update === null) {
        successAlert('Parâmetro criado com sucesso!', 5000, 'save_param');

        setUpdate(resp.data.itens[0].cod)
      } else {
        successAlert('Parâmetro atualizado com sucesso!', 5000, 'save_param');
      }
    } catch (error) {
      tryError(error)
    }
  }

  useEffect(() => {
    listApp()
  }, [])

  return (
    <Container>
      <ViewPage
        newHide={true}
        title='Aplicativos mobile'
        btnClick={() => handleClickNew()}
        content={<></>}
      >
        <div className='form-param'>
          <Form onFinish={onFinish} layout='vertical' form={form} size='small'>
            <Form.Item name='cod_aplicativo' label='Aplicativo'>
              <Select
                style={{ maxWidth: '300px' }}
                optionFilterProp='label'
                onChange={handleChangeAplicativo}
                showSearch
                allowClear
                options={dataApp.map((rec) => {
                  return { label: rec.nome?.toUpperCase(), value: rec.cod, key: rec.cod }
                })}
              />
            </Form.Item>
            <Form.List name='parametros'>
              {(params, { add, remove }, { errors, warnings }) => {
                return (
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label='Velocidade máxima no campo'
                          initialValue={0}
                          name='velocidade_maxima'
                        >
                          <InputNumber style={{ width: 125 }} addonAfter='Km/h' min={0} max={100} />
                        </Form.Item>
                      </Col>
                      <Card style={{ marginRight: '10px' }} size='small' title='Monitoramento'>
                        <Form.Item
                          label='Mínimo de repetições no monitoramento'
                          initialValue={0}
                          name='minimo_repeticoes'
                        >
                          <InputNumber min={0} max={10} />
                        </Form.Item>

                        <Form.Item
                          label='Distância mínima para iniciar monitoramento'
                          initialValue={0}
                          name='distancia_minima_monitoramento'
                        >
                          <InputNumber
                            min={0}
                            max={100}
                            style={{ width: '125px' }}
                            addonAfter='m'
                          />
                        </Form.Item>
                        <Form.Item
                          label='Distância máxima para realocação de pontos no monitoramento'
                          initialValue={0}
                          name='distancia_maxima_realocacao_ponto_monitoramento'
                        >
                          <InputNumber
                            min={0}
                            max={100}
                            style={{ width: '125px' }}
                            addonAfter='m'
                          />
                        </Form.Item>
                        <Form.Item
                          label='Capturar trajeto no monitoramento'
                          name='trajeto_monitoramento'
                          initialValue={false}
                        >
                          <Switch
                            checked={checkMonitoramento}
                            onChange={setCheckMonitoramento}
                            checkedChildren='Sim'
                            unCheckedChildren='Não'
                          />
                        </Form.Item>
                      </Card>
                      <Card size='small' title='Coleta'>
                        <Form.Item
                          label='Distância mínima para iniciar coleta'
                          initialValue={0}
                          name='distancia_minima_coleta'
                        >
                          <InputNumber min={0} max={50} style={{ width: '125px' }} addonAfter='m' />
                        </Form.Item>

                        <Form.Item
                          label='Distância máxima para realocação de pontos na coleta'
                          initialValue={0}
                          name='distancia_maxima_realocacao_ponto_coleta'
                        >
                          <InputNumber
                            min={0}
                            max={200}
                            style={{ width: '125px' }}
                            addonAfter='m'
                          />
                        </Form.Item>
                        <Form.Item
                          label='Capturar trajeto na coleta'
                          name='trajeto_coleta'
                          initialValue={false}
                        >
                          <Switch
                            onChange={setCheckTrajeto}
                            checked={checkTrajeto}
                            checkedChildren='Sim'
                            unCheckedChildren='Não'
                          />
                        </Form.Item>
                      </Card>
                      <Col flex='0 1 600px'></Col>
                      <Col span={24}>
                        <Form.List name='observacoes_coleta'>
                          {(obs, { add, remove }) => {
                            return (
                              <div>
                                <span>Observações para coleta de solo</span>
                                {obs.map((rec, index) => {
                                  return (
                                    <Form.Item
                                      key={rec.key}
                                      rules={[
                                        {
                                          required: true,
                                          message: `Por favor insira a observação ${index}`,
                                        },
                                      ]}
                                      name={[index]}
                                    >
                                      <Input
                                        style={{ maxWidth: '800px' }}
                                        addonAfter={
                                          <div onClick={() => remove(index)}>
                                            <Icons icon={faTrash} />
                                          </div>
                                        }
                                      />
                                    </Form.Item>
                                  )
                                })}
                                {obs.length <= 0 && <br />}
                                <Button type='dashed' onClick={() => add()}>
                                  <PlusOutlined /> Adicionar Observação
                                </Button>
                              </div>
                            )
                          }}
                        </Form.List>
                      </Col>
                    </Row>
                  </Col>
                )
              }}
            </Form.List>
            <br />
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </ViewPage>
    </Container>
  )
}

export default AppCampoConfig

import styled from 'styled-components'

export const Container = styled.div`
  height: 89.7vh;
  overflow-y: hidden;

  .ant-tabs-nav-wrap {
    background-color: #5da57d;
  }

  .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    background-color: #5da57d;
    color: #ffffff;
    border: none;
    margin: 10px 10px 0 10px;
  }

  .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #ffffff;
  }

  .ant-tabs-content {
    margin-left: 15px;
    margin-right: 15px;
  }

  .avatar-uploader > .ant-upload {
    width: 200px;
    height: 200px;
  }

  .sexoRadios {
    margin-top: -7px;
    border: rgb(201, 201, 201) solid 1px;
    border-radius: 3px;
    padding-left: 15px;
  }
  .sexoRadios legend {
    margin-left: 1px;
    font-size: 12px;
    margin-bottom: -10px;
  }

  .uploadImg {
    display: flex;
    min-height: 200px;
    max-height: 200px;
    border: 1px dashed rgb(201, 201, 201);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
  }
  .uploadImg:hover {
    border: 1px dashed #5da57d;
    color: #5da57d;
  }
`

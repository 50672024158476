import { api } from '../../api';
import { localConfig } from '../../../Configs/localConfig';
import { UseMutationResult, useMutation } from 'react-query';
import { destroyAlert, errorAlert, loadingAlert, successAlert, warningAlert } from '../../../utils/alert';

//=================================================================================>
// DELETAR TALHÃO DE SAFRA
//<=================================================================================

interface ParamsDeleteTalhaoSafra {
    codTalhao: number,
    codPro: number,
    motivo?: string;
    confirmacao?: string;
    falhaAoExcluir?: Function;
    atualizarLista: Function;
    closePopup: Function;
}

export const useDeleteTalhaoSafra = (): {
    carregando_deletar_talhao_safra: boolean;
    error_deletar_talhao_safra: any,
    mutate_deletar_talhao_safra: (params: ParamsDeleteTalhaoSafra) => void;
} => {

    
    const mutation: UseMutationResult<Response, Error, ParamsDeleteTalhaoSafra> = useMutation(
        async (params: ParamsDeleteTalhaoSafra) => {
            const { codTalhao, atualizarLista, codPro, closePopup, falhaAoExcluir, motivo, confirmacao } = params;
            
            const config = localConfig();

            loadingAlert({msg: 'Excluindo...', id: 'loading'});

            return api.delete(`/talhao-safra/${codTalhao}?codConta=${config.conta}&codCliente=${config.codCliente}&codPropriedade=${codPro}&codSafra=${config.codSafra}`, 
            {
                data: !!motivo ? { motivo: motivo, confirmacao: confirmacao } : undefined, //NOSONAR
            })
                .then((response: any) => {
                    successAlert('Talhão excluído com sucesso!', 5000, 'loading');
                    atualizarLista();
                    closePopup();
                    return response;
                })
                .catch((error: Error) => {
                    //@ts-ignore
                    let error_response = error?.response?.data;
                    //@ts-ignore
                    let split_error = String(error?.response?.data?.error)?.split("\n");
                    
                    if (error_response?.delete) {
                        destroyAlert('loading');
                        if (!!falhaAoExcluir) {//NOSONAR
                            falhaAoExcluir?.(error_response?.error)
                        }else {
                            errorAlert(error_response?.error ?? 'Falha ao excluir talhão', 7000, 'loading');
                        }
                    }
                    else {
                        if (split_error.length > 1) {
                            for (const message_error of split_error) {
                                errorAlert(message_error, 7000, 'loading');
                            }
                      
                        }
                        warningAlert(split_error?.[0] ?? "É necessário remover as dependencias do talhão para poder deletá-lo", 7000, 'loading');
                    }
                    
                    throw error;
                });
        }
    );

    return { 
        carregando_deletar_talhao_safra: mutation.isLoading, 
        //@ts-ignore
        error_deletar_talhao_safra: mutation.error?.response?.data,
        mutate_deletar_talhao_safra: mutation.mutate 
    };
};

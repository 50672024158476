import React, { useEffect, useRef, useState } from 'react'
import { ContainerAcesso } from './styled'
import { Select } from '../../../CustomUi/Select'
import {
  faUserCheck,
  faUserGear,
  faUsersGear,
  faUserSlash,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons'
import { Alert, Radio } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosResponse } from 'axios'
import { getPerfil, ItensPerfilTypes, TypesPerfil } from '../../../../services/perfil'
import { TransferDirection } from 'antd/es/transfer'
import { Transfer } from 'antd/lib'
import { ClienteItensTypes, getCliente } from '../../../../services/Cliente'
import {
  delTipoEquipeLiderGerenciar,
  postTipoEquipeLiderGerenciar,
} from '../../../../services/consultoriaAgricola/equipLiderGerenciar'
import { Notification } from '../../../notification'
import {
  delTipoEquipeConsultorGerenciar,
  postTipoEquipeConsultorGerenciar,
} from '../../../../services/consultoriaAgricola/equipConsultorGerenciar'
import { tryError } from '../../../../services/Afins'
import modal from 'antd/es/modal'
import { useAccess } from '../../../../context/useAccess'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { loadingAlert, successAlert } from '../../../../utils/alert'

interface PropsFormPermissao {
  perfilAcessoConsultoria?: string
  perfilAcessoConsultoriaId?: number
  perfilAcessoAosClientesGeral?: string
  perfilAcessoAosClientesGeralId?: number
  perfilAcessoAosClientes?: string
  perfilAcessoAosClientesId?: number
  liderConsultores?: string
  liderConsultoresCopia?: string
  liderConsultoresID?: number
  nomeLider?: string
  nomeLiderCopia?: string
  dataClienteLiberados?: any[]
  acessoTodosClientes?: string
  clienteComAcesso?: any[]
  targetKeyAcesso: any[]
  comAcesso?: any[]
  semAcesso?: any[]
  dataLiders?: any[]
  dataConsultores?: any[]
  cargoTecnico?: boolean
  cargoAt?: boolean
  update?: number
  codConsultor?: number
  usuario?: string
  codFunc?: string | number
  possuiVinculo?: boolean
  updateKey?: number
  setPerfilAcessoConsultoria?: React.Dispatch<React.SetStateAction<string>>
  setPerfilAcessoConsultoriaId?: React.Dispatch<React.SetStateAction<number>>
  setPerfilAcessoAosClientesGeral?: React.Dispatch<React.SetStateAction<string>>
  setPerfilAcessoAosClientesGeralId?: React.Dispatch<React.SetStateAction<number>>
  setPerfilAcessoAosClientes?: React.Dispatch<React.SetStateAction<string>>
  setPerfilAcessoAosClientesId?: React.Dispatch<React.SetStateAction<number>>
  setAcessoTodosClientes?: React.Dispatch<React.SetStateAction<string>>
  setLiderConsultores?: React.Dispatch<React.SetStateAction<string>>
  setLiderConsultoresCopia?: React.Dispatch<React.SetStateAction<string>>
  setLiderConsultoresID?: React.Dispatch<React.SetStateAction<number>>
  setNomeLider?: React.Dispatch<React.SetStateAction<string>>
  setNomeLiderCopia?: React.Dispatch<React.SetStateAction<string>>
  setClienteComAcesso?: React.Dispatch<React.SetStateAction<[]>>
  setTargetKeyAcesso?: React.Dispatch<React.SetStateAction<[]>>
  setComAcesso?: React.Dispatch<React.SetStateAction<any[]>>
  setSemAcesso?: React.Dispatch<React.SetStateAction<any[]>>
  setPossuiVinculo?: React.Dispatch<React.SetStateAction<boolean>>
  setUpdateKey?: React.Dispatch<React.SetStateAction<number>>
  permissions?: ItensPerUserLogged
}

const FormAcessoPermissao: React.FC<PropsFormPermissao> = ({
  perfilAcessoConsultoria,
  perfilAcessoConsultoriaId,
  perfilAcessoAosClientesGeral,
  perfilAcessoAosClientesGeralId,
  perfilAcessoAosClientes,
  perfilAcessoAosClientesId,
  liderConsultores,
  liderConsultoresCopia,
  liderConsultoresID,
  acessoTodosClientes,
  dataClienteLiberados,
  nomeLider,
  nomeLiderCopia,
  targetKeyAcesso,
  comAcesso,
  semAcesso,
  update,
  cargoTecnico,
  dataLiders,
  dataConsultores,
  cargoAt,
  codFunc,
  codConsultor,
  usuario,
  possuiVinculo,
  updateKey,
  permissions,
  setPerfilAcessoConsultoria,
  setPerfilAcessoConsultoriaId,
  setPerfilAcessoAosClientesGeral,
  setPerfilAcessoAosClientesGeralId,
  setPerfilAcessoAosClientes,
  setPerfilAcessoAosClientesId,
  setAcessoTodosClientes,
  setLiderConsultores,
  setLiderConsultoresID,
  setLiderConsultoresCopia,
  setNomeLider,
  setNomeLiderCopia,
  setTargetKeyAcesso,
  setPossuiVinculo,
  setUpdateKey,
  setComAcesso,
  setSemAcesso,
}) => {
  const [listDataProfiles, setListDataProfiles] = useState<ItensPerfilTypes[]>([])
  const [listDataCliente, setListDataCliente] = useState<ClienteItensTypes[]>([])
  const [openPerFilConsultoria, setOpenPerFilConsultoria] = useState(false)
  const [openPerFilAosClientesGeral, setOpenPerFilAosClientesGeral] = useState(false)
  const [openPerFilAosClientes, setOpenPerFilAosClientes] = useState(false)
  const [openLiderConsultores, setOpenLiderConsultores] = useState(false)
  const [esconderTransfer, setEsconderTransfer] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [selecionouLider, setSelecionouLider] = useState(false)
  const [loading, setLoading] = useState(false)
  const [targetKey, setTargetKey] = useState(targetKeyAcesso)

  const { loggedInAs } = useAccess()

  const listProfiles = async () => {
    const resp: AxiosResponse<TypesPerfil> = await getPerfil({
      order: 'nome',
      embed: 'permissoes',
    })
    setListDataProfiles(resp.data.itens)
    setLoading(true)
  }

  const listCliente = async () => {
    const resp = await getCliente('', '', '', '')

    setListDataCliente(resp.data.itens)

  }

  const handleChangeLider = async (value) => {
    try {
      if (value && cargoTecnico === true) {
        const resp = await postTipoEquipeLiderGerenciar({
          cod_consultor: value,
          tecnicos: [codFunc],
        })

        if (resp.status === 201) {
          successAlert('Líder atualizado com sucesso!');

          setUpdateKey((prevKey) => prevKey + 1)
          setEsconderTransfer(true)
          setSelecionouLider(true)
        }
      } else if (value && cargoAt === true) {
        const resp = await postTipoEquipeConsultorGerenciar({
          cod_consultor: value,
          assistente_tecnico: [codFunc],
        })

        if (resp.status === 201) {
          successAlert('Líder atualizado com sucesso!');

          setUpdateKey((prevKey) => prevKey + 1)
          setEsconderTransfer(true)
          setSelecionouLider(true)
        }
      }
    } catch (error) {
      tryError(error)
    }
  }

  const handleClearLiderEquipe = (value) => {
    modal.confirm({
      title: `Deseja remover ${usuario} da equipe do ${nomeLider}?`,
      onCancel: () => {},
      onOk: async () => {
        try {
          if (cargoTecnico === true) {
            const resp = await delTipoEquipeLiderGerenciar(value, codFunc)

            if (resp.status === 200) {
              successAlert('Líder removido com sucesso!');
              setPossuiVinculo(false)
              setNomeLider('')
              setLiderConsultores('')
              setEsconderTransfer(false)
              setSelecionouLider(false)
              // Atualiza a chave de atualização para forçar a re-renderização
              setUpdateKey((prevKey) => prevKey + 1)
            }
          } else {
            const resp = await delTipoEquipeConsultorGerenciar(value, codFunc)

            if (resp.status === 200) {
              successAlert('Líder removido com sucesso');
              setPossuiVinculo(false)
              setNomeLider('')
              setLiderConsultores('')
              setEsconderTransfer(false)
              setSelecionouLider(false)
              // Atualiza a chave de atualização para forçar a re-renderização
              setUpdateKey((prevKey) => prevKey + 1)
            }
          }
        } catch (error) {
          tryError(error)
        }
      },
      okText: 'Sim',
      cancelText: 'Não',
      autoFocusButton: 'cancel',
      okType: 'primary',
      okButtonProps: { type: 'primary' },
      cancelButtonProps: { danger: true, type: 'primary' },
    })
  }

  const handleChangeMoveTransfer = (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    if (update > 0) {
      if (direction === 'left') {
        // setClienteRemove([...clienteRemove, ...moveKeys])

        const remover = comAcesso.map((rec, index) => {
          if (moveKeys.some((item) => item === rec) === false) {
            return rec
          }
        })
        setSemAcesso([...semAcesso, ...moveKeys])
        setComAcesso(remover.filter((item) => item !== undefined))
        setTargetKey(targetKeys)
      } else {
        const remover = semAcesso.map((rec, index) => {
          if (moveKeys.some((item) => item === rec) === false) {
            return rec
          }
        })
        // setClienteRemove(clienteRemove.filter((item) => item !== moveKeys[0]))
        setComAcesso([...comAcesso, ...moveKeys])
        setSemAcesso(remover.filter((item) => item !== undefined))
        setTargetKey(targetKeys)
      }
    } else {
      setTargetKey(targetKeys)
    }
  }

  useEffect(() => {
    loadingAlert({msg: 'Carregando...', autoClose: 5000});
    listProfiles()
    listCliente()
  }, [])

  useEffect(() => {
    if (update > 0) {
      const filterAcessoConta = listDataProfiles.filter(
        (item) => item.cod === perfilAcessoConsultoriaId
      )
      setPerfilAcessoConsultoria(filterAcessoConta[0]?.nome)

      const filterAcessoClienteGeral = listDataProfiles?.filter(
        (rec) => rec.cod === perfilAcessoAosClientesGeralId
      )
      setPerfilAcessoAosClientesGeral(filterAcessoClienteGeral[0]?.nome)

      const filterAcessoCliente = listDataProfiles?.filter(
        (rec) => rec.cod === perfilAcessoAosClientesId
      )
      setPerfilAcessoAosClientes(filterAcessoCliente[0]?.nome)
    }
  }, [loading])

  useEffect(() => {
  
    if (liderConsultoresCopia !== '' ) {
      if (liderConsultores === '') {
        handleClearLiderEquipe(liderConsultoresID)
      }
    }

  }, [liderConsultores])

  return (
    <ContainerAcesso>
      {possuiVinculo ? (
        <div className='lines'>
          <Alert
            key={updateKey}
            message={
              codConsultor === null ? (
                <span>
                  O {cargoTecnico !== false ? 'Técnico' : 'Assistente técnico'}{' '}
                  <strong>{usuario}</strong> não pertence a nenhuma equipe{' '}
                </span>
              ) : (
                <span>
                  O {cargoTecnico !== false ? 'Técnico' : 'Assistente técnico'}{' '}
                  <strong>{usuario}</strong> pertence a equipe do{' '}
                  {cargoTecnico === false ? 'consultor' : 'líder'}{' '}
                  <strong>{liderConsultores}</strong>
                </span>
              )
            }
            type='info'
            style={{
              maxWidth: '100%',
              width: '100%',
              backgroundColor: '#f6ffed',
              borderColor: '#5da57d',
              color: '#5da57d',
            }}
          />
        </div>
      ) : null}

      {loggedInAs?.type === 'CLIENTE' || loggedInAs?.type === 'CONTA_CLIENTE' ? null : (
        <div className='lines'>
          <Select
            label='Selecione um perfil de acesso a consultoria'
            icon={faUserGear}
            placeholder='Selecione um perfil de acesso a consultoria'
            value={perfilAcessoConsultoria}
            onChangeID={setPerfilAcessoConsultoriaId}
            onChange={setPerfilAcessoConsultoria}
            open={openPerFilConsultoria}
            trigger={setOpenPerFilConsultoria}
            items={listDataProfiles}
            descricaoItem={'nome'}
            idItem={'cod'}
            disabled={permissions?.acesso === 1}
          />
          <Select
            label='Selecione um perfil de acesso aos clientes (Opcional)'
            icon={faUsersGear}
            placeholder='Selecione um perfil de acesso aos clientes'
            value={perfilAcessoAosClientesGeral}
            onChange={setPerfilAcessoAosClientesGeral}
            onChangeID={setPerfilAcessoAosClientesGeralId}
            open={openPerFilAosClientesGeral}
            trigger={setOpenPerFilAosClientesGeral}
            items={listDataProfiles}
            descricaoItem={'nome'}
            idItem={'cod'}
            disabled={permissions?.acesso === 1}
          />
        </div>
      )}

      {cargoTecnico || cargoAt ? (
        <div className='lines'>
          <Select
            label={cargoTecnico == true ? 'Líder do Técnico' : 'Consultor do Assistente Técnico'}
            icon={faUserTie}
            placeholder='Selecione um perfil de acesso a consultoria'
            value={liderConsultores}
            onChangeID={handleChangeLider}
            onChange={setLiderConsultores}
            open={openLiderConsultores}
            trigger={setOpenLiderConsultores}
            items={cargoTecnico ? dataLiders : dataConsultores}
            descricaoItem={'label'}
            idItem={'value'}
            disabled={permissions?.acesso === 1}
          />
        </div>
      ) : null}

      {loggedInAs?.type === 'CLIENTE' || loggedInAs?.type === 'CONTA_CLIENTE' ? (
        <Select
          label='Selecione um perfil de acesso aos cliente'
          icon={faUsersGear}
          placeholder='Selecione um perfil de acesso aos clientes'
          value={perfilAcessoAosClientes}
          onChange={setPerfilAcessoAosClientes}
          onChangeID={setPerfilAcessoAosClientesId}
          open={openPerFilAosClientes}
          trigger={setOpenPerFilAosClientes}
          items={listDataProfiles}
          descricaoItem={'nome'}
          idItem={'cod'}
          disabled={permissions?.acesso === 1}
        />
      ) : null}

      {loggedInAs?.type === 'CLIENTE' || loggedInAs?.type === 'CONTA_CLIENTE' || selecionouLider ? null : (
        <div className='lines'>
          <div className='radio-sexo'>
            <label>Permitir acesso a todos os clientes?</label>
            <Radio.Group
              disabled={permissions?.acesso === 1}
              value={acessoTodosClientes}
              onChange={(e) => setAcessoTodosClientes(e.target.value)}
              style={{
                width: '100%',
                height: '100%',
              }}
              optionType='button'
              buttonStyle='solid'
              options={[
                {
                  label: 'Sim',
                  value: 'S',
                  style: { textAlign: 'center' },
                },
                {
                  label: 'Não',
                  value: 'N',
                  style: { textAlign: 'center' },
                },
              ]}
            />
          </div>
        </div>
      )}

      {esconderTransfer ? (
        <div>
          <label style={{ fontWeight: '450' }}>Clientes Liberados</label>
          <div className='lines-liberado'>
            {dataClienteLiberados.map((item) => {
              return <p>{item.nome}</p>
            })}
          </div>
        </div>
      ) : null}

      {acessoTodosClientes === 'S' ||
      esconderTransfer ||
      loggedInAs?.type === 'CLIENTE' ||
      loggedInAs?.type === 'CONTA_CLIENTE' ? null : (
        <div className='lines-sem-acesso'>
          <div style={{ width: '100%' }}>
            <Transfer
              selectAllLabels={[
                <b>
                  <FontAwesomeIcon
                    icon={faUserSlash }
                    style={{ opacity: '0.6', marginRight: '5px' }}
                  />
                  Clientes sem acesso
                </b>,
                <b>
                  <FontAwesomeIcon
                    icon={faUserCheck}
                    style={{ opacity: '0.6', marginRight: '5px' }}
                  />
                  Clientes com acesso
                </b>,
              ]}
              listStyle={{
                height: 'calc(100vh - 500px)',
                width: '48%',
              }}
              disabled={permissions?.acesso === 1}
              style={{ maxWidth: '100%' }}
              rowKey={(item) => String(item.cod)}
              dataSource={listDataCliente}
              render={(item) => (
                <span className='ant-transfer-list-content-item-text'>{item.nome}</span>
              )}
              targetKeys={targetKey}
              onChange={handleChangeMoveTransfer}
              className='custom-transfer'
            />
          </div>
        </div>
      )}
    </ContainerAcesso>
  )
}

export default FormAcessoPermissao
import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, Modal, Space, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { delGrupoServico, getGrupoServico } from '../../../../services/GrupoServicos'

import { Notification } from '../../../../Components/notification'

import FormGrupoServico from '../../../../Components/gruposervico'

import { Container } from './styled'
import { IconAlert } from '../../../../Components/iconsAlert'
import { pagination } from '../../../../Components/Default'
import ViewPage from '../../../../Components/ViewPage'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { useLogic } from '../../../../context/useLogic'
import { useHistory } from 'react-router'
import { errorAlert, successAlert } from '../../../../utils/alert'

const Servicos: React.FC = () => {

  const { checkSafra } = useLogic()
  const history = useHistory()

  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [updateUpdateServico, setUpdateUpdateServico] = useState<number>()

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const [dataServicoSer, setDataServicoSer] = useState([])

  const listarServico = async () => {
    try {
      setLoadingTable(true)
      const response = await getGrupoServico('', '', '')

      if (response.status === 200) {
        setDataServicoSer(response.data.itens)
        setLoadingTable(false)
      }
    } catch (error) {
      return error
    }
  }

  const handleClickVisuServico = async (data) => {
    setVisibleForm(true)
    setUpdateUpdateServico(data)
  }

  const handleTrashServico = async (data) => {
    const responsePro = await getGrupoServico(data, '', '')
    const nomePro = responsePro.data.itens[0].nome

    try {
      Modal.confirm({
        title: 'Excluir',
        icon: <IconAlert type='confirm' size={6} />,
        content: `Deseja realmente remover o Grupo de Serviço ${nomePro}?`,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delGrupoServico(data)

          if (responseDel.status === 200) {
            successAlert('Grupo de serviço excluido!');

            listarServico()
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        errorAlert(data?.error ?? 'Falha na operação');
      }
    }
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdateUpdateServico(0)
  }

  const colum: ColumnsType<any> = [
    {
      key: 1,
      title: 'Nome Grupo Serviço',
      dataIndex: 'nome',
      render: (data, dados) => (
        <>
          <Tag style={{ height: '12px' }} color={dados.cor} key={dados.cod}>
            {' '}
          </Tag>
          {data}
        </>
      ),
    },
    {
      key: 1,
      title: 'Profundidade',
      dataIndex: 'profundidade_nome',
    },
    {
      key: 2,
      title: 'Análises',
      dataIndex: 'analises',
      render: (data) => (
        <>
          {data.analises.map((tag) => {
            return (
              <Tag color='geekblue' key={tag.cod}>
                {tag.nome.toUpperCase()}
              </Tag>
            )
          })}
        </>
      ),
    },

    {
      title: 'Ações',
      dataIndex: 'cod',
      width: '90px',
      align: 'center',
      key: 'acao',
      render: (data) => (
        <Space>
          <Button
            shape='circle'
            hidden={permissions?.alteracao === 0 && permissions?.acesso === 0}
            onClick={() => handleClickVisuServico(data)}
            icon={<FontA icon={permissions?.acesso === 1 ? solid('eye') : solid('pen')} />}
            size='middle'
          />
          <Button
            shape='circle'
            onClick={() => handleTrashServico(data)}
            className='trash-button'
            hidden={permissions?.exclusao === 0}
            type='primary'
            icon={<FontA icon={solid('trash')} />}
            size='middle'
          />
        </Space>
      ),
    },
  ]

  useEffect(() => {
    listarServico()
  }, [])

  return (
    <Container>
      <ViewPage
        rotina={34}
        onPermission={setPermissions}
        title='Grupo de Serviço'
        btnClick={handleVisibleForm}
        content={
          <FormGrupoServico
            permissions={permissions}
            visible={visibleForm}
            setOnClose={setVisibleForm}
            update={updateUpdateServico}
            setUpdate={setUpdateUpdateServico}
            atualizar={listarServico}
          />
        }
      >
        <Table
          loading={loadingTable}
          pagination={pagination}
          dataSource={dataServicoSer}
          scroll={{ y: 'calc(90vh - 275px)' }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataServicoSer.length}</b>
            </div>
          )}
          bordered
          size='small'
          columns={colum}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickVisuServico(record.cod)
              },
            }
          }}
        />
      </ViewPage>
    </Container>
  )
}

export default Servicos

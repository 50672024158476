import { UploadProps } from 'antd'
import axios, { AxiosResponse } from 'axios'
import kriging from 'js-kriging'
import { flatten, orderBy, uniqBy } from 'lodash'
import logo from '../../assets/img/logo.png'
import pin from '../../assets/svg/marker.svg'
import pinSelect from '../../assets/svg/markerSelect.svg'

import { localConfig } from '../../Configs/localConfig'

import L from 'leaflet'
import { api } from '../api'
import { RegulagemCalculoItens } from '../regulagem'
import { destroyAlert, errorAlert } from '../../utils/alert'

interface TypesCidade {
  itens?: [
    {
      cod?: number
      cod_uf?: number
      codigo?: number
      nome?: string
      status?: number
      uf?: string
      uf_nome?: string
      uf_sigla?: string
    }
  ]
}

interface OverlayKrigingProps {
  World?: string
  Pontos?: string
  Colors?: string
  Acumulado?: boolean
  Diario?: boolean
  Variogram?: string
  ValVariogram?: string
}

export const buscaCidade = async (nome, codUf, codCity) => {
  const response: AxiosResponse<TypesCidade> = await api.get(
    `/cidade?nome=${nome}&cod_uf=${codUf}&cod=${codCity}&pagination=-1`
  )

  // @ts-ignore
  return response.data.itens
}

export const listEscolaridade = async () => {
  const response = await api.get('/escolaridade?pagination=-1')

  // @ts-ignore
  return response.data.itens
}

export const listEstadoCivil = async () => {
  const response = await api.get('/estado-civil?pagination=-1')

  // @ts-ignore
  return response.data.itens
}

export const listUf = async () => {
  const response = await api.get('/uf?pagination=-1')

  // @ts-ignore
  return response.data.itens
}

export const listProfissao = async () => {
  const conta = localStorage.getItem('conta')
  const cliente = localStorage.getItem('cod-cliente')
  const response = await api.get(`/profissao?codConta=${conta}&codCliente=${cliente}&pagination=-1`)

  // @ts-ignore
  return response.data.itens
}

export const listMedidas = async () => {
  const response = await api.get('/tipos-medida?pagination=-1')

  // @ts-ignore
  return response.data.itens
}

export const imgAuth = async (url) => {
  const { acesso } = localConfig()

  api.defaults.headers.Acesso = acesso

  const response: AxiosResponse = await api.get(url, {
    responseType: 'blob',
  })

  return response
}

export const tiposGerais = async (ordem = 'ordem', pagination = -1) => {
  const { acesso } = localConfig()

  api.defaults.headers.Acesso = acesso
  const response = await api.get('/cultura', {
    params: {
      ordem,
      pagination,
    },
  })

  // @ts-ignore
  return response.data.itens
}

export const tiposSistema = async (codTipo, params = {}) => {
  const response = await api.get(`/tipos-sistema?codTipo=${codTipo}`, { params: { ...params } })

  // @ts-ignore
  return response.data.itens
}
export const formatMoeda = (data) => {
  let valor = data

  if (data.length > 2) {
    valor += ''
    valor = parseInt(valor.replace(/[\D]+/g, ''), 10)
    valor += ''
    valor = valor.replace(/([0-9]{2})$/g, ',$1')
  }

  if (valor === 'NaN') {
    return ''
  }
  return valor
}

export const chandsData = (record: RegulagemCalculoItens[]) => {
  let resp = []
  record.forEach((data) => {
    let temp = []
    Object.entries(data).forEach(([key, value]) => {
      const check = key.split('')
      let array
      if (check[0] === 'r') {
        if (value !== null) {
          array = {
            tray: key,
            value,
          }
          temp = [...temp, array]
        }
      }
      return temp
    })

    const final = {
      cod: data.cod,
      cod_medicao: data.cod_medicao,
      largura: data.largura,
      cv: data.cv,
      minima: data.minima,
      maxima: data.maxima,
      media: data.media,
      sentido: data.sentido,
      qtde: data.qtde,
      sinc: data.sinc,
      status: data.status,
      chards: temp,
    }

    resp = [...resp, final]
  })
  return resp
}

const toText = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file, 'ISO-8859-1')
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const fileTypeText = async (file: any) => {
  try {
    const resp = await toText(file)

    return resp
  } catch (error) {
    return error
  }
}

export const CSVToJSON = async (textCsv) => {
  const lines = textCsv.split('\r')
  const keys = lines[0].split(';')

  let colum = []
  Object.entries(keys).forEach(([key, value]) => {
    const temp = {
      key,
      dataIndex: value,
      title: value.toString(),
      width: '100%',
    }

    colum = [...colum, temp]

    return colum
  })

  const rows = lines.slice(1).map((line) => {
    return line.split(';').reduce((acc, cur, i) => {
      const toAdd = {}
      toAdd[keys[i]] = cur
      return { ...acc, ...toAdd }
    }, {})
  })

  return { colum, rows }
}

export const getB64Type = (str: string) => {
  // substr(str, 11, strpos(str, ';') - 11);

  const split = String(str).split(':')
  const resp = String(split[1]).split(';')

  return resp[0]
}

export const capitalize = (str) => {
  if (typeof str !== 'string') {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.substr(1)
}

export const postNotification = () => {
  if (Notification.permission !== 'granted') {
    Notification.requestPermission()
  } else {
    const noti = new Notification('Teste', {
      // image: logo,
      icon: logo,
      body: 'Teste Body',
      badge: '1',
    })
    noti.onclick = () => {
      window.open('http://sysagro.app')
    }
  }
}

export const parseXml = (xml) => {
  let dom = null
  if (window.DOMParser) {
    try {
      dom = new DOMParser().parseFromString(xml, 'text/xml')
    } catch (e) {
      dom = null
    }
  } else if (window.ActiveXObject) {
    try {
      dom = new ActiveXObject('Microsoft.XMLDOM')
      dom.async = false
      if (!dom.loadXML(xml)) console.log(dom.parseError.reason + dom.parseError.srcText)
    } catch (e) {
      dom = null
    }
  } else console.log('cannot parse xml string!')
  return dom
}

export const calculoAreaCoord = (path) => {
  try {
    const area = window.google.maps.geometry.spherical.computeArea(path[0])
    const ha = parseFloat((area / 10000).toFixed(2))

    return ha
  } catch (error) {
    tryError(error)
  }
}

export const polyGetPath = (polygon: google.maps.Polygon | google.maps.Polyline) => {
  const coord = polygon.getPath().getArray()

  let array = []

  for (let i = 0; i < coord.length; i += 1) {
    const { lat, lng } = coord[i]

    array = [...array, { lat: lat(), lng: lng() }]
  }
  return array
}

export const centerMap = (coord: google.maps.LatLngLiteral[] | google.maps.LatLng[]) => {
  const bounds = new window.google.maps.LatLngBounds()

  coord.forEach((talhao) => {
    bounds.extend(talhao)
  })
  return bounds.getCenter()
}

export const getBounds = (coord) => {
  const bounds = new window.google.maps.LatLngBounds()

  coord.forEach((data) => {
    data.forEach((talhao) => {
      bounds.extend(talhao)
    })
  })

  return bounds
}

export const getBoundsAgrupamentoPropriedade = (coordinates: google.maps.LatLngLiteral[]) => {
  // Verifica se a API do Google Maps e as coordenadas estão definidas
  if (!window.google || !window.google.maps || !Array.isArray(coordinates)) {
    throw new Error('A API do Google Maps e um array de coordenadas são necessários.')
  }

  // Verifica se há pelo menos uma coordenada
  if (coordinates.length === 0) {
    throw new Error('Pelo menos uma coordenada é necessária para calcular os limites.')
  }

  // Cria uma instância de LatLngBounds da API do Google Maps
  const bounds = new window.google.maps.LatLngBounds()

  // Estende os limites com cada conjunto de coordenadas
  coordinates.forEach((coordinate) => {
    // Verifica se a coordenada tem as propriedades lat e lng
    if (coordinate && typeof coordinate.lat === 'number' && typeof coordinate.lng === 'number') {
      bounds.extend(coordinate)
    } else {
      console.warn('Coordenada inválida:', coordinate)
    }
  })

  // Retorna os limites calculados
  return bounds
}
export const getBoundVizualizarAgrupamento = (coordinates: google.maps.LatLngLiteral[]) => {
  // Verifica se a API do Google Maps e as coordenadas estão definidas
  if (!window.google || !window.google.maps || !Array.isArray(coordinates)) {
    throw new Error('A API do Google Maps e um array de coordenadas são necessários.')
  }

  // Verifica se há pelo menos uma coordenada
  if (coordinates.length === 0) {
    throw new Error('Pelo menos uma coordenada é necessária para calcular os limites.')
  }

  // Cria uma instância de LatLngBounds da API do Google Maps
  const bounds = new window.google.maps.LatLngBounds()

  // Estende os limites com cada conjunto de coordenadas
  coordinates.forEach((coordinate) => {
    // Verifica se a coordenada tem as propriedades lat e lng
    if (coordinate && typeof coordinate.lat === 'number' && typeof coordinate.lng === 'number') {
      bounds.extend(coordinate)
    } else {
      console.warn('Coordenada inválida:', coordinate)
    }
  })

  // Retorna os limites calculados
  return bounds
}

export const getBoundsNoArray = (coord) => {
  const bounds = new window.google.maps.LatLngBounds()

  coord.forEach((data) => {
    if (data.lat && data.lng) {
      bounds.extend(data)
    }
  })

  return bounds
}

export const customRequest: UploadProps = {
  customRequest: (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  },
}

export const isInPolygon = (qtdaVertices, vertices_x, vertices_y, longitude_x, latitude_y) => {
  let i = 0
  let j = 0
  let c: boolean | number = 0

  for (i = 0, j = qtdaVertices - 1; i < qtdaVertices; j = i + 1) {
    if (
      vertices_y[i] > latitude_y !== vertices_y[j] > latitude_y &&
      longitude_x <
        ((vertices_x[j] - vertices_x[i]) * (latitude_y - vertices_y[i])) /
          (vertices_y[j] - vertices_y[i]) +
          vertices_x[i]
    ) {
      c = !c
    }
  }

  return c
}

export const tryError = (error: any) => {
  if (error.response) {
    const { data } = error.response
    if (!data?.error[0]?.field) {
      destroyAlert();
      errorAlert(data?.error ?? 'Falha na operação!');
    } else {
      data.error.forEach((data) => {
        destroyAlert();
        errorAlert(data?.msg?.[0] ?? 'Falha na operação!');
      })
    }
    setTimeout(() => {
      destroyAlert();
    }, 5000);
    
    console.log(error)
    return error
  }
  else {
    setTimeout(() => {
      destroyAlert();
    }, 5000);
  }
  console.log(error)
}
export function containsPoint(point, polygon: google.maps.Polygon) {
  let crossings = 0
  const paths = polygon.getPaths()

  // for each edge
  for (let i = 0; i < paths.getLength(); i += 1) {
    const path = paths.getAt(i)
    for (let ii = 0; i < path.getLength(); ii += 1) {
      const a = path.getAt(ii)
      let j = ii + 1
      if (j >= path.getLength()) {
        j = 0
      }
      const b = path.getAt(j)
      if (rayCrossesSegment(point, a, b)) {
        crossings += 1
      }
    }
  }
  return crossings % 2 === 1

  function rayCrossesSegment(point, a, b) {
    let px = point.lng()
    let py = point.lat()
    let ax = a.lng()
    let ay = a.lat()
    let bx = b.lng()
    let by = b.lat()
    if (ay > by) {
      ax = b.lng()
      ay = b.lat()
      bx = a.lng()
      by = a.lat()
    }
    if (px < 0) {
      px += 360
    }
    if (ax < 0) {
      ax += 360
    }
    if (bx < 0) {
      bx += 360
    }

    if (py === ay || py === by) py += 0.00000001
    if (py > by || py < ay || px > Math.max(ax, bx)) return false
    if (px < Math.min(ax, bx)) return true

    const red = ax !== bx ? (by - ay) / (bx - ax) : Infinity
    const blue = ax !== px ? (py - ay) / (px - ax) : Infinity
    return blue >= red
  }
}

export async function buscaGoogleCity(city, uf) {
  const coord = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${city}+${uf}&key=${process.env.REACT_APP_TOKEN_GOOGLE_MAPS}`
  )
  return coord
}

export const filterTable = (
  _sorter: boolean,
  _filterSearch: boolean,
  dataIndex: string,
  data: any[],
  _filter = true
) => {
  const sorter = !_sorter
    ? null
    : (a, b) => {
        if (a[`${dataIndex}`] === null) {
          a[`${dataIndex}`] = ''
          b[`${dataIndex}`] = ''
        }
        return a[`${dataIndex}`].localeCompare(b[`${dataIndex}`])
      }
  const filterSearch = _filterSearch
  const onFilter = (value: string, record) => {
    return record[`${dataIndex}`].includes(value)
  }
  const filters = _filter
    ? uniqBy(
        orderBy(
          data.map((item) => {
            if (item[`${dataIndex}`] === null) {
              item[`${dataIndex}`] = ' - '
            }
            return { text: item[`${dataIndex}`], value: item[`${dataIndex}`] }
          }),
          'text'
        ),

        'text'
      )
    : null

  return { sorter, filterSearch, onFilter, filters }
}

export function medianOfArr(arr: number[], media: number) {
  let resp = 0

  for (let i = 0; i < arr.length; i += 1) {
    resp += arr[i]
  }
  return resp / media
}

export const optionsPolygon = {
  fillColor: 'cyan',
  strokeColor: 'cyan',
  strokeWeight: 4,
  fillOpacity: 0.45,
  clickable: true,
  draggable: false,
  editable: true,
  geodesic: false,
}
export const optionsMap: google.maps.MapOptions = {
  mapTypeId: 'hybrid',
  zoomControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
}

export function ddToDms(lat: number, lng: number) {
  let latResult
  let lngResult
  let dmsResult

  latResult = lat >= 0 ? 'N' : 'S'
  // Call to getDms(lat) function for the coordinates of Latitude in DMS.
  // The result is stored in latResult variable.

  latResult += getDms(lat)
  lngResult = lng >= 0 ? 'L' : 'O'

  // Call to getDms(lng) function for the coordinates of Longitude in DMS.
  // The result is stored in lngResult variable.
  lngResult += getDms(lng)

  return { latResult, lngResult }
}

function getDms(val) {
  let result

  val = Math.abs(val)

  const valDeg = Math.floor(val)
  result = `${valDeg}º`

  const valMin = Math.floor((val - valDeg) * 60)
  result += `${valMin}'`

  const valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000
  result += `${valSec}"`

  return result
}

export function dummyRequest(options) {
  const { onSuccess } = options
  setTimeout(() => {
    onSuccess('done')
  }, 500)
}

export const fileToArrayBuffer = (files) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    if (files.length > 0) {
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsArrayBuffer(files[0].originFileObj)
    }
  })

export const convertMinHoras = (minutos) => {
  const horas = Math.floor(minutos / 60)
  const min = minutos % 60
  const textoHoras = `00${horas}`.slice(-2)
  const textoMinutos = `00${min}`.slice(-2)

  return `${textoHoras}:${textoMinutos}`
}

export const objOrder = (data: {}) => {
  const ordered = Object.keys(data)
    .sort()
    .reduce((obj, key) => {
      obj[key] = data[key]
      return obj
    }, {})

  return ordered
}

export const navegadorUser = () => {
  const navigator = window.navigator.userAgent
  const split = window.navigator.userAgent.split(' ')
  let nameNavegador = ''
  let version = split[split.length - 1].split('/')[1]

  if (navigator.toLowerCase().indexOf('op') > -1) {
    nameNavegador = 'Opera'
    version = version
  } else if (navigator.indexOf('Edg') > -1) {
    nameNavegador = 'Edge'
    version = version
  } else if (navigator.indexOf('Firefox') > -1) {
    nameNavegador = 'Mozilla Firefox'
    version = version
  } else if (navigator.indexOf('Epiphany') > -1) {
    nameNavegador = 'Epiphany'
    version = version
  } else if (navigator.indexOf('Chrome') > -1) {
    nameNavegador = 'Google Chrome'
    version = version
  } else if (navigator.indexOf('Safari') > -1) {
    nameNavegador = 'Safari'
    version = version
  }

  return { nameNavegador, version }
}

export const geraKrikagem = async (
  coord: number[][][],
  points: { t: number[]; x: number[]; y: number[] }[],
  variograma: 'gaussian' | 'exponential' | 'spherical',
  colors: string[],
  sigma: number,
  pixel = 0.0001
) => {
  try {
    const OverlayKriging: OverlayKrigingProps = {
      Diario: false,
      Acumulado: true,
    }
    const canv = document.createElement('canvas')

    const world = coord

    const lats = flatten(world.map((path) => path.map((coord) => coord[0])))

    const longs = flatten(world.map((path) => path.map((coord) => coord[1])))

    const geraGrids = (pontos) =>
      pontos.map((pontos) => {
        const variogram = kriging.train(pontos.t, pontos.x, pontos.y, variograma, 0.0001, sigma)

        const grid = kriging.grid(world, variogram, pixel)

        return grid
      })

    const somaGrids = (grids) => {
      const somado = grids[0]
      let min = null
      let max = null
      grids.map((grid, gridI) => {
        if (OverlayKriging.Acumulado) {
          if (gridI < 1) return
        } else if (gridI < 0) {
          return grid.forEach((col, colI) => {
            col.forEach((valor, valorI) => {
              const valorSomado = (somado[colI][valorI] += valor)
              if (valorSomado < min || min == null) {
                min = valorSomado
              }
              if (valorSomado > max || max == null) {
                max = valorSomado
              }
            })
          })
        }
        somado.zlim[0] = min
        somado.zlim[1] = max
        return somado
      })

      return somado
    }

    let grid
    if (!OverlayKriging.Diario) {
      grid = somaGrids(geraGrids(points))
    } else {
      const variogram = kriging.train(1, 2, 3, variograma, 0.0001, 2)
      grid = kriging.grid(world, variogram, 0.0001)
    }

    const teste = kriging.plot(
      canv,
      grid,
      [Math.min(...lats), Math.max(...lats)],
      [Math.min(...longs), Math.max(...longs)],
      colors
    )

    const pngUrl = canv.toDataURL()

    const LatLng1 = new google.maps.LatLng(Math.min(...longs), Math.min(...lats))
    const LatLng2 = new google.maps.LatLng(Math.max(...longs), Math.max(...lats))

    const bounds = new google.maps.LatLngBounds(LatLng1, LatLng2)

    bounds.extend(LatLng1)
    bounds.extend(LatLng2)

    const OverlayView = {
      url: pngUrl,
      bounds: {
        ...bounds.toJSON(),
      },
      key: `${pngUrl}`,
    }

    return OverlayView
  } catch (error) {
    tryError(error)
  }
}

export const geraOverlayView = async (grid, lats, longs, colors) => {
  const canv = document.createElement('canvas')
  // console.log("grid teste: ", grid)
  kriging.plot(
    canv,
    grid,
    [Math.min(...lats), Math.max(...lats)],
    [Math.min(...longs), Math.max(...longs)],
    colors
  )

  const pngUrl = canv.toDataURL()

  const LatLng1 = new google.maps.LatLng(Math.min(...longs), Math.min(...lats))
  const LatLng2 = new google.maps.LatLng(Math.max(...longs), Math.max(...lats))

  const bounds = new google.maps.LatLngBounds(LatLng1, LatLng2)

  bounds.extend(LatLng1)
  bounds.extend(LatLng2)

  const OverlayView = {
    url: pngUrl,
    bounds: {
      ...bounds.toJSON(),
    },
    key: `${pngUrl}`,
    colors,
  }

  return { ...OverlayView }
}

export const iconDiv = (label) => {
  return new L.DivIcon({
    html: `<div class='icon-div-leaflet'> <b>${label}</b></div>`,
    iconSize: [40, 40],
    className: 'icon-div-box',
  })
}

export const iconPropsGoogle = (select = true) => {
  return { url: select ? pinSelect : pin, scaledSize: new google.maps.Size(40, 50) }
}

export function middleIndex(arr) {
  const length = arr.length
  let resp = 0
  if (length % 2 === 0) {
    resp = (length - 1) / 2
  } else {
    resp = Math.floor(length / 2)
  }
  return Math.round(resp)
}

export const decodeHtml = (html) => {
  var txt = document.createElement('textarea')
  txt.innerHTML = html
  const resp = txt.value
  txt.remove()
  return resp
}

export const cpfMask = (v) => {
  if (!v) return ''
  v = v?.replace(/\D/g, '')
  v = v?.replace(/(\d{3})(\d)/, '$1.$2')
  v = v?.replace(/(\d{3})(\d)/, '$1.$2')
  v = v?.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  return v
}

export function mascaraDecimal (num, casas = 2) {
  const parsed = parseFloat(num);
  const value = isNaN(parsed) ? 0 : Number(parsed.toFixed(casas));
  return String(value)
};

export const phoneMask = (value) => {
  if (!value) return '';
  return value
  .replace(/\D/g, '')            
  .replace(/(\d{2})(\d)/, '($1) $2') 
  .replace(/(\d{5})(\d)/, '$1-$2')  
  .replace(/(-\d{4})\d+?$/, '$1')
};

export const fixoMask = (value) => {
  if (!value) return '';
  return value
  .replace(/\D/g, '')            
  .replace(/(\d{2})(\d)/, '($1) $2') 
  .replace(/(\d{4})(\d)/, '$1-$2')  
  .replace(/(-\d{4})\d+?$/, '$1')
}

export function mascaraCNH(input) {
  let valor = input.value.replace(/\D/g, '')

  if (valor.length > 11) {
    valor = valor.slice(0, 11)
  }

  input.value = valor
}

export function formatarData(value) {
  if (!value) return ''

  if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
    return value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')
  }

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})(\d)/, '$1/$2')
  value = value.replace(/(\d{2})(\d)/, '$1/$2')

  return value
}

export const mascaraReais = (value) => {
  if (!value) return ''
  value = value.replace(/\D/g, '')

  value = value.replace(/(\d)(\d{2})$/, '$1,$2')

  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  // Adiciona o prefixo "R$"
  return value ? value : ''
}

export function tirarAdicionarPonto(value) {
  if (!value) return ''

  let cleanedValue = value?.replace(/\D/g, '')

  cleanedValue = cleanedValue?.replace(/(\d+)(\d{2})$/, '$1.$2')

  return cleanedValue
}

export function removerMaskOdom(value) {
  if (!value) return ''

  return value.replace(/\./g, '')
}

export function mascaraOdom(value) {
  if (!value) return ''
  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const removeMaskValor = (value) => {
  if (!value) return ''
  return value.replace(/\./g, '')
}

export const cepMask = (value) => {
  if (!value) return ''
  value = value?.replace(/\D/g, '')
  value = value?.replace(/(\d{5})(\d)/, '$1-$2')
  return value
}

export const generateColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'

  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)]
  }

  return color
}

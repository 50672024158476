import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'
import { errorAlert, loadingAlert, successAlert } from '../../utils/alert'

export interface TypesSharedFilesUser {
  itens: ItensSharedFilesUser[]
}

export interface ItensSharedFilesUser {
  nome: any
  cod: number
  cod_usuario: number
  arquivo: number
  suporte: boolean
  descricao: string
  visualizar_arquivo: string
  create_time?: string
}

type TypesQuery = {
  cod?: number
  pagination?: number
}

const url = '/compartilhamento-arquivo/compartilhados-suporte'

export const getSharedFilesUser = async (params: TypesQuery) => {
  const { codCliente, conta } = localConfig()
  api.defaults.headers.Acesso = 'admin'
  const resp: AxiosResponse<TypesSharedFilesUser> = await api.get(url, {
    params: {
      codCliente,
      codConta: conta,
      ...params,
      pagination: -1,
    },
  })

  return resp
}

export const postSharedFilesUser = async (data) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesSharedFilesUser> = await api.post(url, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const putSharedFilesUser = async (data, cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesSharedFilesUser> = await api.put(`${url}/${cod}`, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const delSharedFilesUser = async (cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesSharedFilesUser> = await api.delete(`${url}/${cod}`, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const delArquivoCompartilhado = async (cod) => {
  const { codCliente, conta } = localConfig()

  loadingAlert({msg: 'Excluindo...', id: 'loading'});

  const resp: AxiosResponse = await api.delete(`/compartilhamento-arquivo-adm/${cod}`, {
    params: {
      codCliente,
      codConta: conta,
    },
  }) .then((): any => {
    successAlert('Arquivo deletado com sucesso!', 7000, 'loading');
  })
  .catch((error) => {
    errorAlert('Falha ao deletar arquivo!', 7000, 'loading');
  })

  return resp
}
import PTbr from 'antd/lib/locale/pt_BR';
import React from 'react';
import { Provider } from 'react-redux';
import { VersionCheck } from 'react-version-check';
import { toast, ToastContainer } from 'react-toastify';
import { App as AppAntd, ConfigProvider } from 'antd';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import 'antd/dist/reset.css';
import "react-toastify/dist/ReactToastify.css";

//APP
import Views from './Views';
import packageJson from "../package.json";
import GlobalStyled from './GlobalStyled';
import { store } from './features'
import { UseAccess } from './context/useAccess'
import { LogicProvider as AppProvider } from './context/useLogic'

//STYLES
import './All.css';
import './global-variables.css';
import './global.css';


const App: React.FC = () => {
  const notify = () => toast('Wow so easy !');
  return (
      <Provider store={store}>
        <GlobalStyled />
        {/* <ServiceWorkerWrapper /> */}
        <VersionCheck currentVersion={packageJson.version} display={false} />
        <ConfigProvider
          locale={PTbr}
          theme={{
            token: { colorPrimary: '#5da57d' },
            components: {
              Menu: { subMenuItemBg: '#3B5063' },
              Card: { colorBorderSecondary: '#dfdfdf' },
            },
            hashed: true,
          }}
        >
          <AppAntd>
            <UseAccess>
              <AppProvider>
                <Router basename='/'>
                  <Switch>
                    <Route component={Views} />
                  </Switch>
                </Router>
              </AppProvider>
            </UseAccess>
          </AppAntd>
          <ToastContainer />
        </ConfigProvider>
        
      </Provider>
  )
}

export default App

import React, { useEffect, useRef, useState } from 'react'

import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  InputRef,
  List,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Typography,
} from 'antd'
import { AxiosResponse } from 'axios'

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Notification } from '../../notification'
import {
  getMoniEspecie,
  MoniEspecieItens,
  postMoniEspecie,
  putMoniEspecie,
  TypeMoniEspecie,
} from '../../../services/monitoramento/especie'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import Icons from '../../Icons'
import DiasDesdeData from '../../DiasDesdeData'
import { destroyAlert, errorAlert, loadingAlert, successAlert, warningAlert } from '../../../utils/alert'

interface TypesEspecie {
  update: number
  visible: boolean
  onClose: (data: boolean) => void
  permissions: ItensPerUserLogged
}

const MoniEspecie: React.FC<TypesEspecie> = (props) => {
  const { update, onClose, visible, permissions } = props

  const inputVariacao = useRef<InputRef>()

  const [drawerVisible, setDrawerVisible] = useState(false)

  const [imageUrl, setImageUrl] = useState(null)

  const [previewVisibleImg, setPreviewVisibleImg] = useState(false)

  const [loadingEdit, setLoadingEdit] = useState(false)
  const [dataCatalogo, setDataCatalogo] = useState<any>([])

  const [disable, setDisable] = useState(true)

  const [value, setValue] = useState()

  const [removeVariacao, setRemoveVariacao] = useState([])

  const [form] = Form.useForm<MoniEspecieItens>()

  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const editEstadio = async (data) => {
    loadingAlert({msg: 'Carregando...', id: 'get_moni_especi'})
    setLoadingEdit(true)
    setRemoveVariacao([])
    const response: AxiosResponse<TypeMoniEspecie> = await getMoniEspecie({
      cod: data,
      embed: 'variacao',
    })

    const dados = response.data.itens[0]
    let vari = []

    dados.variacao.forEach((data) => {
      vari = [
        ...vari,
        {
          cod: data.cod,
          nome: data.nome,
        },
      ]

      return vari
    })

    setDataCatalogo(dados)

    const valueEdit = {
      cod: dados.cod,
      nome: dados.nome,
      nome_cientifico: dados.nome_cientifico,
      cod_categoria: dados.cod_categoria,
      variacao: vari,
    }

    form.setFieldsValue(valueEdit)
    setLoadingEdit(false)
    destroyAlert('get_moni_especi');
  }

  const hancleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setImageUrl(null)
    form.resetFields()
    form.setFieldsValue({ variacao: [] })
  }

  const handleChanteNameVariacao = (data, index) => {
    const dados = form.getFieldValue('variacao')

    dados[index].nome = data

    form.setFieldsValue({ variacao: dados })
  }

  const onFinish = async (data) => {
    loadingAlert({msg: 'Salvando...', id: 'salvando_especie'})
    const updatedName = data.nome
      ? data.nome.replace(/,/g, ' / ').trim() // Adiciona espaços ao redor da barra
      : data

    const updatedData = { ...data, nome: updatedName }
    try {
      if (removeVariacao.length > 0) {
        data.variacao_remover = removeVariacao
      }

      if (update <= 0) {
        const response: AxiosResponse<TypeMoniEspecie> = await postMoniEspecie(updatedData)
        if (response.status === 201) {
          successAlert('Espécie cadastrada com sucesso', 5000, 'salvando_especie');
          onClose(true)
          form.resetFields()
          form.setFieldsValue({ variacao: [] })
          setImageUrl(null)
          setRemoveVariacao([])
        }
      } else {
        const response: AxiosResponse<TypeMoniEspecie> = await putMoniEspecie(update, updatedData)
        if (response.status === 200) {
          successAlert('Espécie atualizada com sucesso!', 5000, 'salvando_especie');

          const resp = response.data.itens[0]

          let vari = []

          resp.variacao.forEach((data) => {
            vari = [
              ...vari,
              {
                cod: data.cod,
                nome: data.nome,
              },
            ]

            return vari
          })

          form.setFieldsValue({ variacao: vari })
          onClose(true)
          setRemoveVariacao([])
        }
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data.error ?? 'Falha ao registrar espécie!', 5000, 'salvando_especie');
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha ao registrar espécie!', 5000, 'salvando_especie');
          })
        }
      }
      else {
        destroyAlert();
      }
    }
  }

  useEffect(() => {
    setDrawerVisible(visible)
  }, [visible])

  useEffect(() => {
    if (update > 0) {
      editEstadio(update)
    }
  }, [update])

  useEffect(() => {
    form.setFieldsValue({ variacao: [] })
  }, [])

  useEffect(() => {
    if (visible) {
      if (dataCatalogo) {
        if (dataCatalogo.create_user) {
          setUsuarioQueCriou(dataCatalogo.create_user)

          const dateOnlyString = dataCatalogo.create_time.split(' ')[0]
          const timeOnlyString = dataCatalogo.create_time.split(' ')[1]

          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (dataCatalogo.update_user) {
          setUsuarioQueAlterou(dataCatalogo.update_user)

          const dateOnlyString = dataCatalogo.update_time.split(' ')[0]
          const timeOnlyString = dataCatalogo.update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [logUser, visible, usuarioQueAlterou, dataCatalogo, usuarioQueCriou, update, horarioDeCriacao])


  return (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      <Drawer
        placement='left'
        title={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <>{update ? 'Atualizar' : 'Cadastrar'}</>
            {logUser && update > 0 && usuarioQueAlterou !== '' ? (
              <>
                <DiasDesdeData
                  dataCriacao={dataAlteracao}
                  onDataFromCreation={handleDataFromCreation}
                />
                <small style={{ opacity: '0.5', fontSize: '10px' }}>
                  atualizado por: {usuarioQueAlterou}{' '}
                  {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                    ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                    : `às ${horarioDeAtualizacao}h`}
                </small>
              </>
            ) : !logUser && update > 0 ? (
              <>
                {' '}
                {usuarioQueCriou === '' ? null : (
                  <small style={{ opacity: '0.5', fontSize: '10px' }}>
                    criado por: {usuarioQueCriou}{' '}
                    {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                      ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                      : `às ${horarioDeCriacao}h`}
                  </small>
                )}
              </>
            ) : null}
          </div>
        }
        width='400px'
        open={drawerVisible}
        closeIcon={false}
        onClose={hancleDrawerClose}
        style={{ position: 'absolute' }}
        bodyStyle={{ overflow: 'hidden' }}
        getContainer={false}
        footer={
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <Button
              hidden={permissions?.alteracao === 0 && permissions?.inclusao === 0}
              type='primary'
              htmlType='submit'
            >
              {update ? 'Atualizar catálogo' : 'Salvar'}
            </Button>
            {'   '}
            <Button danger onClick={hancleDrawerClose} type='primary' style={{ marginRight: 8 }}>
              Fechar
            </Button>
          </div>
        }
      >
        <Col style={{ pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto' }} span={24}>
          <Row hidden={!loadingEdit} gutter={[8, 55]}>
            <Col flex='0 1 200px'>
              <Skeleton.Input block active />
            </Col>
            <Col flex='1 1 200px'>
              <Skeleton.Input block active />
            </Col>
            <Col flex='1 1 200px'>
              <Skeleton.Input />
            </Col>

            <Col flex='1 1 200px'>
              <Space>
                <Skeleton.Input />
                <Skeleton.Button />
              </Space>
            </Col>

            <Col flex='1 1 200px'>
              <List loading />
            </Col>
          </Row>
          <Row hidden={loadingEdit} gutter={[8, 0]}>
            <Col flex='1 1 200px'>
              <Form.Item required name='nome' label='Nome'>
                <Input />
              </Form.Item>
            </Col>
            <Col flex='1 1 200px'>
              <Form.Item name='nome_cientifico' label='Nome Científico'>
                <Input />
              </Form.Item>
            </Col>
            <Col flex='1 1 200px'>
              <Form.Item required name='cod_categoria' label='Categoria'>
                <Select
                  options={[
                    { label: 'Praga', value: 1 },
                    { label: 'Planta Daninha', value: 2 },
                    { label: 'Doença', value: 3 },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col flex='1 1 100%'>
              <Form.List name='variacao'>
                {(list, { add, remove }) => {
                  const handleAdd = () => {
                    const { input, focus } = inputVariacao.current
                    const verifica = form
                      .getFieldValue('variacao')
                      ?.filter(
                        (data) => data.nome.toLowerCase().indexOf(input.value.toLowerCase()) !== -1
                      )

                    if (verifica.length <= 0) {
                      const data = {
                        cod: null,
                        nome: input.value,
                      }
                      add(data)

                      focus({ cursor: 'all', preventScroll: true })
                    } else {
                      warningAlert('Variação já inserida!');
                    }
                  }
                  const handleRemove = (index, cod) => {
                    if (cod !== null) {
                      setRemoveVariacao([...removeVariacao, cod])
                    }

                    remove(index)
                  }

                  const handleChange = (event) => {
                    if (event.target.value) {
                      setDisable(false)
                    } else {
                      setDisable(true)
                    }
                  }

                  return (
                    <>
                      <Row>
                        <Form.Item name='inputVariacao' label='Variação'>
                          <Space>
                            <Input
                              value={value}
                              onChange={handleChange}
                              ref={inputVariacao}
                              name='variacao'
                            />
                            <Button
                              disabled={disable}
                              onClick={handleAdd}
                              type='primary'
                              size='middle'
                            >
                              Inserir
                            </Button>
                          </Space>
                        </Form.Item>
                        <Divider type='vertical' />
                      </Row>
                      <Table
                        size='small'
                        scroll={{ y: 'calc(46.6vh - 50px)' }}
                        pagination={{
                          pageSize: 999999,
                          hideOnSinglePage: true,
                        }}
                        dataSource={form.getFieldValue('variacao')}
                        columns={[
                          {
                            key: 2,
                            dataIndex: 'nome',
                            title: 'Nome',
                            render: (data, record, index) => (
                              <Typography.Text
                                editable={{
                                  onChange: (data) => handleChanteNameVariacao(data, index),
                                }}
                              >
                                {data}
                              </Typography.Text>
                            ),
                          },

                          {
                            key: 3,
                            dataIndex: 'cod',
                            title: '',
                            width: '40px',
                            render: (cod, record, index) => (
                              <Button
                                size='small'
                                shape='default'
                                onClick={() => handleRemove(index, cod)}
                                type='primary'
                                danger
                                icon={<Icons icon={faTrash} />}
                              />
                            ),
                          },
                        ]}
                      />
                    </>
                  )
                }}
              </Form.List>
            </Col>
            <Form.Item name='status' hidden initialValue={1} />
          </Row>
        </Col>
      </Drawer>
      <Modal
        open={previewVisibleImg}
        title='Imagem'
        style={{ minWidth: '840px' }}
        footer={null}
        onCancel={() => setPreviewVisibleImg(false)}
      >
        <img alt='example' style={{ width: '100%' }} src={imageUrl} />
      </Modal>
    </Form>
  )
}

export default MoniEspecie

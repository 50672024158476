import { toast, Bounce, ToastPosition, Zoom } from "react-toastify";
import { loadingAlertJSX } from "./loading";

interface LoadingAlertParams {
    msg?: string;
    id?: string;
    autoClose?: number;
    position?: ToastPosition;
}
export function loadingAlert({msg, id, autoClose, position}: LoadingAlertParams) {

    loadingAlertJSX({msg, id, autoClose: autoClose ?? 30000000, position})
    
};

export function successAlert(message: string, autoClose?: number, loadingId?: string | number) {
    
    if (loadingId) {
        toast.dismiss(loadingId);
    }

    toast.success(message, {
        position: "top-right",
        autoClose: autoClose ?? 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
    
};

export function errorAlert(message: string, autoClose?: number, loadingId?: string | number, id?: string) {

    if (loadingId) {
        toast.dismiss(loadingId);
    }

    toast.error(message, {
        position: "top-right",
        autoClose: autoClose ?? 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        toastId: id
    });
};

export function infoAlert(message: string, autoClose?: number, loadingId?: string | number) {

    if (loadingId) {
        toast.dismiss(loadingId);
    }

    toast.info(message, {
        position: "top-right",
        autoClose: autoClose ?? 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
};

export function warningAlert(message: string, autoClose?: number, loadingId?: string | number) {

    if (loadingId) {
        toast.dismiss(loadingId);
    }

    toast.warning(message, {
        position: "top-right",
        autoClose: autoClose ?? 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
};

export function destroyAlert(toastId?: string) {
    toast.dismiss(toastId);
}
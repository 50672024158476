import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig';
import { errorAlert, loadingAlert, successAlert } from '../../utils/alert'

export interface TypeGetPatrimonios {
  itens: [PatrimoniosItens]
  total: number
}

interface TypeCodResponsavel {
  cod: number
  nome: string
}

interface TypeEngrenagem {
  cod: number
  posicao: number
  tipo: number
  quantidade_dentes: number
  nome_posicao: string
  nome_tipo: string
}

export interface PatrimoniosItens {
  origem_patrimonio?: string
  proprietario_origem?: string
  cod?: number
  nome?: string
  numeracao_crv?: string
  numeracao_cla?: string
  cod_tipo?: number
  cod_conta?: number
  ano?: any
  cod_cliente?: number
  status?: 0 | 1
  descricao?: string
  placa?: string
  frota?: string
  renavam?: string
  chassi?: string
  serie?: string
  ano_modelo?: string
  cod_resposavel?: number
  responsavel?: TypeCodResponsavel
  tipo_combustivel?: string
  condicao_aquisicao?: number
  cod_responsavel?: number
  cod_propriedade?: number
  cod_marca?: number
  modelo?: string
  imagem?: string | null
  tipo_nome?: string
  tipo_odom?: number
  marca_nome?: string
  imagem_url?: string
  imagem_base64?: string
  data_aquisicao?: any
  loading?: boolean
  valor: string
  numeracao_motor: string
  cor_predominante: string
  numero_linhas?: string
  espacamento_linhas?: string
  capacidade_tanque_calda?: string
  numero_discos?: string
  diametro_discos?: string
  odom_horimetro?: string
  engrenagem?: [TypeEngrenagem]
  motorizado?: number
  arquivos?: [
    {
      cod?: number
      cod_patrimonio?: number
      nome?: string
      arquivo_url?: string
    }
  ]
  tipo?: {
    cod?: number
    nome?: string
    cod_tipo?: number
    outros_tipos?: number
    cod_categoria?: number
    geral?: number
    exibir_marca?: 0 | 1
    categoria?: {
      cod?: number
      nome?: string
      cod_tipo?: number
      outros_tipos?: number
      cod_categoria?: string | null
      geral?: number
      exibir_marca?: 0 | 1
    }
  }
}

interface TypeParamGet {
  codConta?: number | string
  codCliente?: number | string
  codCategoria?: number
  cod?: number
  pagination?: number | -1
  page?: number | 15
}

export const getPatrimonio = async (params: TypeParamGet) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  params = {
    codConta: config.conta,
    codCliente: config.codCliente,
    ...params,
  }

  const response: AxiosResponse<TypeGetPatrimonios> = await api.get('/patrimonio', {
    params,
  })

  return response
}

export const getFilePatrimonioArr = async (url: string) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPatrimonios> = await api.get(url, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
    responseType: 'arraybuffer',
  })

  return response
}

export const getFilePatrimonioBlob = async (url: string) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPatrimonios> = await api.get(url, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
    responseType: 'blob',
  })

  return response
}

export const postPatrimonio = async (data, onClose, setLoadingButton) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPatrimonios> = await api
    .post('/patrimonio', data, {
      params: {
        codConta: config.conta,
        codCliente: localConfig().codCliente,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    .then((): any => {
      successAlert('Patrimônio cadastrado com sucesso!');
      onClose()
    })
    .catch((error) => {
      
      let msg_erro = error?.response?.data?.error;
      if (typeof msg_erro === 'string') {
        errorAlert(`${error?.response?.data?.error}`)
      }
      else {
        for (const item_erro of msg_erro) {
          errorAlert(`${item_erro?.msg?.[0] ?? 'falha ao salvar patrimonio'}`)
        }
      }
      
      setLoadingButton(false)
    })

  

  return response
}

export const putPatrimonio = async (codPatrimonio, data, eng, atualizar, setErro) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'multipart/form-data'

  loadingAlert({msg: 'Salvando...', id: 'save_patri'});

  const response: AxiosResponse<TypeGetPatrimonios> = await api
    .post(`/patrimonio/${codPatrimonio}`, data, {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    })
    .then((): any => {
      successAlert(eng === 'eng'
        ? 'Engrenagem atualizado com sucesso!'
        : 'Patrimônio atualizado com sucesso!', 5000, 'save_patri'
      )
      if (eng === '') {
        atualizar()
      }
    })
    .catch((error) => {
      console.log(error)
      
      let msg_erro = error?.response?.data?.error;
      if (typeof msg_erro === 'string') {
        errorAlert(`${error?.response?.data?.error}`, 5000, 'save_patri');
      }
      else {
        for (const item_erro of msg_erro) {
          errorAlert(`${item_erro?.msg?.[0] ?? 'falha ao editar patrimonio'}`, 5000, 'save_patri');
        }
      }
    })

  return response
}

export const pathPatrimonio = async (codPatrimonio, status) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPatrimonios> = await api.put(
    `/patrimonio/${codPatrimonio}`,
    status,
    {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    }
  )

  return response
}

export const delPatrimonio = async (cod, listPatrimonio) => {
  const config = localConfig()

  loadingAlert({msg: 'Excluindo...', id: 'delete'})

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPatrimonios> = await api
    .delete(`/patrimonio/${cod}`, {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    })
    .then((): any => {
      setTimeout(() => {
        successAlert('Patrimônio excluído com sucesso!', 5000, 'delete');
        listPatrimonio()
      }, 800)
    })
    .catch(() => {
      setTimeout(() => {
        errorAlert('Erro ao excluir patrimônio!', 5000, 'delete');
      }, 800)
    })

  return response
}

export const delEngrenagem = async (cod, atualizar, tipoPosicao) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  loadingAlert({msg: 'Excluindo...', id: 'delete_eng'});

  const response: AxiosResponse<TypeGetPatrimonios> = await api
    .delete(`/patrimonio-engrenagem/${cod}`, {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    })

    .then((): any => {
      setTimeout(() => {
        successAlert('Engrenagem excluida com sucesso!', 5000, 'delete_eng');
        atualizar(tipoPosicao)
      }, 800)
    })
    .catch(() => {
      setTimeout(() => {
        errorAlert('Falha ao excluir engrenagem!', 5000, 'delete_eng')
      }, 800)
    })

  return response
}

import { useQuery } from 'react-query';
import { api } from '../../api'
import { ParametroFertilidade__Itens } from '../get';
import { decodeHtml } from '../../Afins';
import { errorAlert, loadingAlert, successAlert } from '../../../utils/alert';

//=================================================================================>
// BUSCAR LISTA DE ELEMENTOS
//<=================================================================================

//TYPES
type Item__MapaFertilidade__Config__Secoes__Mapa = {
    tipo_mapa: number,
    visivel: boolean,
    observacao: string,
    profundidades: {
        profundidade: number,
        visivel: boolean,
        observacao: string
    }[]
}
type Item__MapaFertilidade__Config__Secoes = {
    secao: number, 
    ordem: number,
    visivel: boolean, 
    observacao: string,
    tipos_mapa: Item__MapaFertilidade__Config__Secoes__Mapa[]
}
export type Item__MapaFertilidade__Config = {
    id: number;
    elemento: number,
    nome: string,
    secoes: Item__MapaFertilidade__Config__Secoes[]
}
type Item__MapaFertilidade = {
    texto_introducao: string,
    configuracoes: Item__MapaFertilidade__Config[]
}
type Item = {
    cod_conta: number,
    mapa_fertilidade: Item__MapaFertilidade
}

//INTERFACE
export interface Elemento {
    itens: Item[]
}

export const usePostSalvarConfigBook = (
    codConta: number,
    texto_introducao: string, 
    configuracoes: Item__MapaFertilidade__Config[], 
    fecharDrawer: Function, 
    refetch_data_elementos: Function,
    elementos_base?: ParametroFertilidade__Itens[]
) => {
    const queryKey: ['post-parametros-fertilidade'] = [`post-parametros-fertilidade`];

    const elementos_com_parametros = elementos_base.filter((item)=> item.parametros.length > 0)

    const { isFetching, refetch } = useQuery<Elemento, Error>(queryKey, async () => {

        function reordenarArray() {

            function jaPossuiElemento(cod_elemento: number) {
                return configuracoes.find((item) => item.elemento === cod_elemento);
            }

            let criar = elementos_com_parametros.map((item, index)=> {
                if (jaPossuiElemento(item.cod)) {
                    return {
                        elemento: jaPossuiElemento(item.cod).elemento,
                        nome: jaPossuiElemento(item.cod).nome,
                        secoes: [{
                            observacao: jaPossuiElemento(item.cod).secoes?.[0]?.observacao,
                            secao: jaPossuiElemento(item.cod).secoes?.[0]?.secao,
                            tipos_mapa: jaPossuiElemento(item.cod).secoes?.[0]?.tipos_mapa,
                            visivel: jaPossuiElemento(item.cod).secoes?.[0]?.visivel,
                            ordem: index + 1 
                        }]
                    }
                }
                return {
                    elemento: item.cod,
                    nome: item.descricao,
                    secoes: [{
                        observacao: '',
                        secao: item.parametros?.[0]?.secao,
                        tipos_mapa: [],
                        // tipos_mapa: attrTipos_mapa(item.parametros?.[0]?.secao_nome, info_profundidades),
                        visivel: false,
                        ordem: index + 1 
                    }]
                }
            })
            
            return criar;
        }
        
        const nova = reordenarArray();

        loadingAlert({msg: 'Salvando...', id: 'loading'});

        return api.post(`parametros-fertilidade?codConta=${codConta}`, {
            texto_introducao: decodeHtml(texto_introducao),
            configuracoes: nova
        })
            .then(():any => {
                successAlert('Configurações atualizadas', 5000, 'loading');
                fecharDrawer(false);
            })
            .catch(() => {
                errorAlert('Falha ao alterar configurações', 5000, 'loading');
            })
    },
        {
            enabled: false,
        }
    );

    return { 
        carregando_salvar: isFetching, 
        callUsePostSalvarConfigBook: refetch
    };
};
import styled from 'styled-components'

interface Types {
  bg?: string
}

export const Container = styled.div<Types>`
  display: flex;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0 auto;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .login {
    background-color: #ffffff;
    width: 350px;
    padding: 30px 30px 0 30px;
    border-radius: 7px;

    img {
    }
    span {
    }
  }

  .update_distortion {
    position: fixed;
    width: 10px;
    height: 300vh;
    top: 0px;
    right: 0px;
    z-index: 999999;
    overflow: hidden;
    background: rgba(255,255,255,1);
    mix-blend-mode: difference;
    /* transform: rotate(0deg) translateX(-180%); */
    animation: animate_blender 7s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;

    @keyframes animate_blender {
      0% {
        transform: rotate(0deg) translateX(-180%) scaleX(20);
      }
      50% {
        transform: rotate(0deg) translateX(-20025%) scaleX(5);
      }
      100% {
        transform: rotate(0deg) translateX(-20050%) scaleX(20);
      }
    }
  }
`;

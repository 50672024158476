import React from 'react'
import { faFileAlt, faList } from '@fortawesome/free-solid-svg-icons'

//STYLES
import { BodyDrawer } from './styled'

//COMPONENTS
import Tabs from '../../../../../Components/Tabs'
import { HeaderDrawer } from '../../../../../Components/CustomUi/headerGaveta'
import { CategoriaForm } from './components/form_categoria'
import { FormularioPatrimonios } from './components/form_patrimonios'
import { TypeListaEngrenagem } from '../..'

interface PropsPatrimonios {
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  modelo: string
  setModelo: React.Dispatch<React.SetStateAction<string>>
  chassi: string
  setChassi: React.Dispatch<React.SetStateAction<string>>
  serie: string
  setSerie: React.Dispatch<React.SetStateAction<string>>
  frota: string
  setFrota: React.Dispatch<React.SetStateAction<string>>
  responsavelFrota: string
  setResponsavelFrota: React.Dispatch<React.SetStateAction<string>>
  responsavelFrotaId: number
  setResponsavelFrotaId: React.Dispatch<React.SetStateAction<number>>
  responsaveis: any[]
  dataAquisicao: string
  setDataAquisicao: React.Dispatch<React.SetStateAction<string>>
  valor: string
  setValor: React.Dispatch<React.SetStateAction<string>>
  anoModelo: string
  setAnoModelo: React.Dispatch<React.SetStateAction<string>>
  anoFabricacao: string
  setAnoFabricacao: React.Dispatch<React.SetStateAction<string>>
  tipoCombustivel: string
  setTipoCombustivel: React.Dispatch<React.SetStateAction<string>>
  tipoCombustivelId: number
  setTipoCombustivelId: React.Dispatch<React.SetStateAction<number>>
  obs: string
  setObs: React.Dispatch<React.SetStateAction<string>>
  placa: string
  setPlaca: React.Dispatch<React.SetStateAction<string>>
  renavam: string
  setRenavam: React.Dispatch<React.SetStateAction<string>>
  crv: string
  setCrv: React.Dispatch<React.SetStateAction<string>>
  cla: string
  setCla: React.Dispatch<React.SetStateAction<string>>
  tab_selecionada_saida?: 'Motriz' | 'Movida'
  setTab_selecionada_saida?: React.Dispatch<React.SetStateAction<'Motriz' | 'Movida'>>
  tab_selecionada_entrada?: 'Motriz' | 'Movida'
  setTab_selecionada_entrada?: React.Dispatch<React.SetStateAction<'Motriz' | 'Movida'>>
  tab_selecionada_atual?: string
  setTab_selecionada_atual?: React.Dispatch<React.SetStateAction<string>>
  tab_selecionada_recambio?: 'Motriz' | 'Movida'
  setTab_selecionada_recambio?: React.Dispatch<React.SetStateAction<'Motriz' | 'Movida'>>
  condicao?: string
  setCondicao?: React.Dispatch<React.SetStateAction<string>>
  categoria?: string
  setCategoria?: React.Dispatch<React.SetStateAction<string>>
  categoriaId?: number
  setCategoriaId?: React.Dispatch<React.SetStateAction<number>>
  categorias: any[]
  tipoCategoria?: string
  setTipoCategoria?: React.Dispatch<React.SetStateAction<string>>
  tipoCategoriaId?: number
  setTipoCategoriaId?: React.Dispatch<React.SetStateAction<number>>
  dataTipoCategorias?: any[]
  marca?: string
  setMarca?: React.Dispatch<React.SetStateAction<string>>
  marcaId?: number
  setMarcaId?: React.Dispatch<React.SetStateAction<number>>
  dataMarca?: any[]
  tab_motorizado?: boolean
  setTab_motorizado?: React.Dispatch<React.SetStateAction<boolean>>
  tipoMedidor?: string
  setTipoMedidor?: React.Dispatch<React.SetStateAction<string>>
  tipoMedidorId?: number
  setTipoMedidorId?: React.Dispatch<React.SetStateAction<number>>
  tipoOdom?: any[]
  odom_horimetro?: string
  setOdomHorimetro?: React.Dispatch<React.SetStateAction<string>>
  tipoOrigem?: string
  setTipoOrigem?: React.Dispatch<React.SetStateAction<string>>
  tipoOrigemId?: number
  setTipoOrigemId?: React.Dispatch<React.SetStateAction<number>>
  proprietarioOrigem?: string
  setProprietarioOrigem?: React.Dispatch<React.SetStateAction<string>>
  loading?: boolean
  loadingEngrenagensEntrada?: boolean
  loadingEngrenagensSaida?: boolean
  loadingEngrenagensRecambio?: boolean
  update?: number
  engrenagem?: any[]
  formDataEntrada: any[]
  formDataSaida: any[]
  formDataRecambio: any[]
  setFormDataEntrada: React.Dispatch<React.SetStateAction<any[]>>
  setFormDataSaida: React.Dispatch<React.SetStateAction<any[]>>
  setFormDataRecambio: React.Dispatch<React.SetStateAction<any[]>>
  status?: number
  duplicar: boolean
  atualizar?: Function
  handleChangeCategoria: Function
  lista_engrenagens: TypeListaEngrenagem[]
  setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>
  cod_patrimonio: number
  numeracao_motor: string
  setNumeracao_motor: React.Dispatch<React.SetStateAction<string>>
  setValidarEngrenagem: React.Dispatch<React.SetStateAction<boolean>>
  cor_predominante: string
  setCor_predominante: React.Dispatch<React.SetStateAction<string>>
  numeroDeLinhas: string
  setNumeroDeLinhas: React.Dispatch<React.SetStateAction<string>>
  espaçamentoDasLinhas: string
  setEspaçamentosDasLinhas: React.Dispatch<React.SetStateAction<string>>
  capacidade_tanque_calda: string
  setCapacidade_tanque_calda: React.Dispatch<React.SetStateAction<string>>
  numero_discos: string
  setNumero_discos: React.Dispatch<React.SetStateAction<string>>
  diametro_discos: string
  setDiametro_discos: React.Dispatch<React.SetStateAction<string>>
}

export const GavetaPatrimonios: React.FC<PropsPatrimonios> = ({
  update,
  categorias,
  nome,
  setNome,
  modelo,
  setModelo,
  chassi,
  setChassi,
  serie,
  setSerie,
  frota,
  setFrota,
  responsavelFrota,
  setResponsavelFrota,
  responsavelFrotaId,
  setResponsavelFrotaId,
  responsaveis,
  dataAquisicao,
  setDataAquisicao,
  valor,
  setValor,
  anoModelo,
  anoFabricacao,
  setAnoFabricacao,
  setAnoModelo,
  tipoCombustivel,
  setTipoCombustivel,
  setTipoCombustivelId,
  obs,
  setObs,
  placa,
  setPlaca,
  renavam,
  setRenavam,
  categoriaId,
  crv,
  setCrv,
  cla,
  setCla,
  tipoOrigemId,
  categoria,
  setCategoria,
  setCategoriaId,
  tipoCategoria,
  setTipoCategoria,
  setTipoCategoriaId,
  tipoCategoriaId,
  tipoOrigem,
  setTipoOrigem,
  tipoMedidor,
  tipoMedidorId,
  setTipoMedidorId,
  setTipoMedidor,
  condicao,
  setCondicao,
  marca,
  marcaId,
  setMarca,
  setMarcaId,
  dataMarca,
  dataTipoCategorias,
  loading,
  odom_horimetro,
  proprietarioOrigem,
  setProprietarioOrigem,
  setOdomHorimetro,
  setTab_motorizado,
  tab_motorizado,
  tipoOdom,
  duplicar,
  setTipoOrigemId,
  tab_selecionada_atual,
  setTab_selecionada_atual,
  lista_engrenagens,
  setLista_engrenagens,
  cod_patrimonio,
  numeracao_motor,
  setNumeracao_motor,
  setValidarEngrenagem,
  cor_predominante,
  setCor_predominante,
  numeroDeLinhas,
  setNumeroDeLinhas,
  espaçamentoDasLinhas,
  setEspaçamentosDasLinhas,
  capacidade_tanque_calda,
  setCapacidade_tanque_calda,
  numero_discos,
  setNumero_discos,
  diametro_discos,
  setDiametro_discos
}) => {
  return (
    <>
      <HeaderDrawer>
        {update <= 0
          ? 'Cadastrar patrimônio'
          : duplicar
          ? 'Duplicar cadastro'
          : 'Atualizar patrimônio'}
      </HeaderDrawer>
      <BodyDrawer>
        <Tabs
          tabs={[
            { descricao: 'Categoria', icone: faList },
            { descricao: 'Informações do patrimônio', icone: faFileAlt },
          ]}
          selecionado={tab_selecionada_atual}
          trackWidth={414}
          onChange={setTab_selecionada_atual}
        />
        {tab_selecionada_atual === 'Categoria' && (
          <CategoriaForm
            lista_engrenagens={lista_engrenagens}
            setLista_engrenagens={setLista_engrenagens}
            categoria={categoria}
            setCategoria={setCategoria}
            categoriaId={categoriaId}
            setCategoriaId={setCategoriaId}
            condicao={condicao}
            setCondicao={setCondicao}
            tipoCategoria={tipoCategoria}
            setTipoCategoria={setTipoCategoria}
            tipoCategoriaId={tipoCategoriaId}
            setTipoCategoriaId={setTipoCategoriaId}
            marca={marca}
            setMarca={setMarca}
            marcaId={marcaId}
            setMarcaId={setMarcaId}
            odom_horimetro={odom_horimetro}
            setOdomHorimetro={setOdomHorimetro}
            tab_motorizado={tab_motorizado}
            setTab_motorizado={setTab_motorizado}
            tipoMedidor={tipoMedidor}
            setTipoMedidor={setTipoMedidor}
            tipoMedidorId={tipoMedidorId}
            setTipoMedidorId={setTipoMedidorId}
            tipoOdom={tipoOdom}
            categorias={categorias}
            dataTipoCategorias={dataTipoCategorias}
            dataMarca={dataMarca}
            loading={loading}
            tipoOrigem={tipoOrigem}
            tipoOrigemId={tipoOrigemId}
            setTipoOrigem={setTipoOrigem}
            setTipoOrigemId={setTipoOrigemId}
            proprietarioOrigem={proprietarioOrigem}
            setProprietarioOrigem={setProprietarioOrigem}
            tipoCombustivel={tipoCombustivel}
            setTipoCombustivel={setTipoCombustivel}
            setTipoCombustivelId={setTipoCombustivelId}
            motorizado={tab_motorizado}
            cod_patrimonio={cod_patrimonio}
            setValidarEngrenagem={setValidarEngrenagem}
            numeroDeLinhas={numeroDeLinhas}
            setNumeroDeLinhas={setNumeroDeLinhas} 
            espaçamentoDasLinhas={espaçamentoDasLinhas}
            setEspaçamentosDasLinhas={setEspaçamentosDasLinhas}
            capacidade_tanque_calda={capacidade_tanque_calda}
            setCapacidade_tanque_calda={setCapacidade_tanque_calda}
            numero_discos={numero_discos}
            setNumero_discos={setNumero_discos}
            diametro_discos={diametro_discos}
            setDiametro_discos={setDiametro_discos}
          />
        )}
        {tab_selecionada_atual === 'Informações do patrimônio' && (
          <FormularioPatrimonios
            nome={nome}
            setNome={setNome}
            modelo={modelo}
            setModelo={setModelo}
            chassi={chassi}
            setChassi={setChassi}
            serie={serie}
            setSerie={setSerie}
            frota={frota}
            setFrota={setFrota}
            responsavelFrota={responsavelFrota}
            setResponsavelFrota={setResponsavelFrota}
            responsavelFrotaId={responsavelFrotaId}
            setResponsavelFrotaId={setResponsavelFrotaId}
            responsaveis={responsaveis}
            dataAquisicao={dataAquisicao}
            setDataAquisicao={setDataAquisicao}
            valor={valor}
            setValor={setValor}
            anoModelo={anoModelo}
            setAnoModelo={setAnoModelo}
            anoFabricacao={anoFabricacao}
            setAnoFabricacao={setAnoFabricacao}
            placa={placa}
            setPlaca={setPlaca}
            renavam={renavam}
            setRenavam={setRenavam}
            obs={obs}
            setObs={setObs}
            categoriaId={categoriaId}
            cla={cla}
            setCla={setCla}
            crv={crv}
            setCrv={setCrv}
            tipoOrigem={tipoOrigem}
            duplicar={duplicar}
            numeracao_motor={numeracao_motor}
            setNumeracao_motor={setNumeracao_motor}
            cor_predominante={cor_predominante}
            setCor_predominante={setCor_predominante}
            motorizado={tab_motorizado}
          />
        )}
      </BodyDrawer>
    </>
  )
}
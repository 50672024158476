import React, { useEffect, useState } from 'react'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { useLogic } from '../../../context/useLogic'
import { delSafra, getSafra, getSafraEstatisticas } from '../../../services/Safra'
import { formatarValor } from '../../../utils'
import dayjs from 'dayjs'
import { message, Modal } from 'antd'
import { IconAlert } from '../../../Components/iconsAlert'
import { Notification } from '../../../Components/notification'
import { tryError } from '../../../services/Afins'
import { ContainerNovoSafra } from './styles'
import ViewPage from '../../../Components/ViewPage'
import { TitleH1 } from '../../../Components/CustomUi/titleH1'
import { TabelaSafra } from './components/Tabela'
import FormSafra from '../../../Components/safra'
import { errorAlert, successAlert } from '../../../utils/alert'
import FormNovoSafra from './components/novoFormSafra'

const NovoSafra: React.FC = () => {
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState(null)
  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [updateUpdateSafra, setUpdateUpdateSafra] = useState<number>()
  const [codSafra, setCodSafra] = useState<number>()
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false)
  const [id_item_atual, setId_item_atual] = useState<number>(null)
  const [nome_item_atual, setNome_item_atual] = useState<string>('')
  const [fake_loading, setFake_loading] = useState<boolean>(false)
  const [listarSafraOrder, setListarSafraOrder] = useState([])
  const { lisSafra, setLisSafra } = useLogic()

  const listarSafra = async () => {
    try {
      setLoadingTable(true)
      const response = await getSafra({ page: page, pagination: pagination })

      if (response.status === 200) {
        setLisSafra(response.data.itens)
        setTotal(response.data.total)
      
        setListarSafraOrder(
            //@ts-ignore
          _.orderBy(response.data.itens, [(item) => new Date(item.data_final)], ['desc'])
        )
        setLoadingTable(false)
      }
    } catch (error) {
      return error
    }
  }

  const listTotalPropriedade = async () => {
    const response = await getSafraEstatisticas()
    const dados = response.data.itens

    //@ts-ignore
    const resultado = dados.reduce(
      (soma, item) => {
        return {
          total_talhoes: soma.total_talhoes + item.total_talhoes,
          total_area_talhoes: soma.total_area_talhoes + parseFloat(item.total_area_talhoes),
          total_propriedades: soma.total_propriedades + item.total_propriedades,
          total_sub_areas: soma.total_sub_areas + item.total_sub_areas,
          total_area_sub_areas: soma.total_area_sub_areas + parseFloat(item.total_area_sub_areas),
        }
      },
      {
        total_talhoes: 0,
        total_area_talhoes: 0,
        total_propriedades: 0,
        total_sub_areas: 0,
        total_area_sub_areas: 0,
      }
    )

    const resultadoFinal = [
      {
        totalizado_talhoes: resultado?.total_talhoes,
        totalizado_area_talhoes: formatarValor(resultado?.total_area_talhoes),
        totalizado_propriedades: resultado?.total_propriedades,
        totalizado_subAreas: resultado?.total_sub_areas,
        totalizado_area_subAreas: formatarValor(resultado?.total_area_sub_areas),
      },
    ]
    //@ts-ignore

    gerarTabela(dados)
    gerarTabela2(resultadoFinal, dados.length)
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: item?.total_talhoes, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'right' },
        {
          text: formatarValor(item?.total_area_talhoes),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
        { text: item?.total_sub_areas, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'right' },
        {
          text: formatarValor(item?.total_area_sub_areas),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
        {
          text: dayjs(item.data_inicio).format('DD/MM/YYYY'),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
        {
          text: dayjs(item.data_final).format('DD/MM/YYYY'),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*', '*'],
          body: [
            [
              { text: 'Safra', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Talhões', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área total', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Subáreas', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área subáreas', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Data de início', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Data final', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = dados?.map((item) => {
      return [
        { text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'right' },
        { text: item?.totalizado_talhoes, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'right' },
        {
          text: item?.totalizado_area_talhoes,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
        { text: item?.totalizado_subAreas, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'right' },
        {
          text: item?.totalizado_area_subAreas,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*'], // Definindo larguras das colunas
          body: [
            [
              { text: 'Total Safras', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Total Talhões', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Total de Área', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Total Subáreas', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              {
                text: 'Total área subáreas',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
            ],
            ...data,
          ],
          margin: [0, 50, 0, 0],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosResultado(table)
  }

  const handleClickVisuSafra = async (data) => {
    setVisibleForm(true)
    setUpdateUpdateSafra(data)
    setCodSafra(data)
  }

  const handleTrashSafra = async (data) => {
    setFake_loading(true)

    try {
      const responseDel = await delSafra(data)

      if (responseDel.status === 200) {
        listarSafra()
        successAlert('Safra excluída com sucesso!', 5000)
        setTimeout(() => {
          setFake_loading(false)
        }, 3000)
      }
    } catch (error) {
      errorAlert(`${error ?? 'Erro ao excluir safra!'}`, 7000)
    }
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdateUpdateSafra(0)
  }

  useEffect(() => {
    listarSafra()
    listTotalPropriedade()
  }, [])

  useEffect(() => {
    listarSafra()
  }, [page])

  return (
    <ContainerNovoSafra>
      <ViewPage
        title='Safra'
        btnClick={null}
        newHide
        rotina={26}
        onPermission={setPermissions}
        hiddeTitle
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
        content={
          // <FormSafra
          //   listSafra={lisSafra}
          //   codSafra={codSafra}
          //   setCodSafra={setCodSafra}
          //   permissions={permissions}
          //   visible={visibleForm}
          //   setOnClose={setVisibleForm}
          //   update={updateUpdateSafra}
          //   setUpdate={setUpdateUpdateSafra}
          //   atualizar={listarSafra}
          // />
          <FormNovoSafra
            listSafra={lisSafra}
            codSafra={codSafra}
            setCodSafra={setCodSafra}
            permissions={permissions}
            visible={visibleForm}
            setOnClose={setVisibleForm}
            update={updateUpdateSafra}
            setUpdate={setUpdateUpdateSafra}
            atualizar={listarSafra}
            loadingForm={false}
          />
        }
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Safra</TitleH1>
        </div>
        <TabelaSafra
          list_safras={listarSafraOrder}
          page={page}
          setPage={setPage}
          total={total}
          setPagination={setPagination}
          confirmar_exclusao={confirmar_exclusao}
          fake_loading={fake_loading}
          id_item_atual={id_item_atual}
          loading={loadingTable}
          setConfirmar_exclusao={setConfirmar_exclusao}
          setFake_loading={setFake_loading}
          setId_item_atual={setId_item_atual}
          setNome_item_atual={setNome_item_atual}
          nome_item_atual={nome_item_atual}
          deletar={handleTrashSafra}
          permissao={permissions}
          abrir={handleVisibleForm}
          editar={handleClickVisuSafra}
          dadosEstatisticas={dadosEstatisticas}
          dadosResultado={dadosResultado}
        />
      </ViewPage>
    </ContainerNovoSafra>
  )
}

export default NovoSafra

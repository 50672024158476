import React, { useEffect, useState } from 'react'

import { Button, Col, Drawer, Form, Input, Row, Select, Space } from 'antd'

import { getGrupoServico, postGrupoServico, putGrupoServico } from '../../services/GrupoServicos'

import { Notification } from '../notification'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import DiasDesdeData from '../DiasDesdeData'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../utils/alert'

const { Option } = Select

interface TypesGrupoServico {
  update: number
  visible: boolean
  setOnClose: (data: boolean) => void
  setUpdate: (data: number) => void
  atualizar: () => void
  permissions: ItensPerUserLogged
}

const GrupoServico: React.FC<TypesGrupoServico> = (props) => {
  const { update, visible, setOnClose, setUpdate, atualizar, permissions } = props

  const [dataGrupoAnalise, setDataGrupoAnalise] = useState<any>([])
  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const [form] = Form.useForm()

  const hancleDrawerClose = () => {
    setOnClose(false)
    setUpdate(0)
    form.resetFields()
  }

  const handleChangeServico = async (data) => {
    loadingAlert({msg: 'Carregando...', id: 'change_servico'});
    const response = await getGrupoServico(data === undefined ? '' : data, '', '')

    const dados = response.data.itens[0]

    setDataGrupoAnalise(dados)

    form.setFieldsValue(dados)

    let codAnalises = []
    dados.analises.analises.forEach((data) => {
      codAnalises = [...codAnalises, data.cod]
      return codAnalises
    })

    form.setFieldsValue({ cod_analises: codAnalises })

    destroyAlert('change_servico');
  }

  const onFinish = async (data) => {
    try {
      if (update === 0) {
        loadingAlert({msg: 'Salvando...', id: 'cadastro_grupo'})
        const response = await postGrupoServico(data)

        if (response.status === 201) {
          successAlert('Grupo de servico cadastrado com sucesso!', 5000, 'cadastro_grupo');
          form.resetFields()
          atualizar()
        }
      } else {
        loadingAlert({msg: 'Salvando...', id: 'editar_grupo'})
        const response = await putGrupoServico(update, data)

        if (response.status === 200) {
          successAlert('Grupo de servico atualizado com sucesso!', 5000, 'editar_grupo');
          atualizar()
        }
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? '');
          destroyAlert('cadastro_grupo');
          destroyAlert('editar_grupo');
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? '');
            destroyAlert('cadastro_grupo');
            destroyAlert('editar_grupo');
          })
        }
      }
      else {
        destroyAlert();
      }
    }
  }

  useEffect(() => {
    if (update !== 0) {
      handleChangeServico(update)
    }
  }, [update])

  useEffect(() => {
    if (visible) {
      if (dataGrupoAnalise) {
        if (dataGrupoAnalise.create_user) {
          setUsuarioQueCriou(dataGrupoAnalise.create_user)

          const dateOnlyString = dataGrupoAnalise.create_time.split(' ')[0]
          const timeOnlyString = dataGrupoAnalise.create_time.split(' ')[1]

          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (dataGrupoAnalise.update_user) {
          setUsuarioQueAlterou(dataGrupoAnalise.update_user)

          const dateOnlyString = dataGrupoAnalise.update_time.split(' ')[0]
          const timeOnlyString = dataGrupoAnalise.update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [
    logUser,
    visible,
    dataGrupoAnalise,
    usuarioQueAlterou,
    usuarioQueCriou,
    update,
    horarioDeCriacao,
  ])



  return (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      <Drawer
        title={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <>{update ? 'Atualizar Grupo de Serviço' : 'Cadastrar Grupo de Serviço'}</>
            {logUser && update > 0 && usuarioQueAlterou !== '' ? (
              <>
                <DiasDesdeData
                  dataCriacao={dataAlteracao}
                  onDataFromCreation={handleDataFromCreation}
                />
                <small style={{ opacity: '0.5', fontSize: '10px' }}>
                  atualizado por: {usuarioQueAlterou}{' '}
                  {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                    ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                    : `às ${horarioDeAtualizacao}h`}
                </small>
              </>
            ) : !logUser && update > 0 ? (
              <>
                {' '}
                {usuarioQueCriou === '' ? null : (
                  <small style={{ opacity: '0.5', fontSize: '10px' }}>
                    criado por: {usuarioQueCriou}{' '}
                    {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                      ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                      : `às ${horarioDeCriacao}h`}
                  </small>
                )}
              </>
            ) : null}
          </div>
        }
        style={{ position: 'absolute' }}
        placement='right'
        width='100%'
        onClose={hancleDrawerClose}
        open={visible}
        closeIcon={false}
        getContainer={false}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Button
                hidden={permissions?.inclusao === 0 && permissions?.alteracao === 0}
                type='primary'
                htmlType='submit'
              >
                {update ? 'Atualizar Grupo de Serviço' : 'Salvar'}
              </Button>
              {'   '}
              <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>

            <Space className='log-user'>
              <small style={{ opacity: '0.5' }}>
                {logUser && (
                  <>
                    Alterado por: <strong>Denilson Luiz Arguelho</strong>
                  </>
                )}
              </small>
            </Space>
          </div>
        }
      >
        <Row style={{ pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto' }} gutter={[8, 0]}>
          <Col flex='1 1 300px'>
            <Form.Item label='Nome' name='nome'>
              <Input />
            </Form.Item>
          </Col>
          <Col flex='1 1 100px'>
            <Form.Item label='Cor' name='cor'>
              <Input type='color' />
            </Form.Item>
          </Col>

          <Col flex='1 1 200px'>
            <Form.Item label='Profundidade' name='cod_profundidade'>
              <Select>
                <Option key='1' value={1}>
                  00 - 20
                </Option>
                <Option key='2' value={2}>
                  20 - 40
                </Option>
                <Option key='3' value={3}>
                  40 - 60
                </Option>
                <Option key='4' value={4}>
                  00 - 50
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex='1 1 400px'>
            <Form.Item label='Analises' name='cod_analises'>
              <Select mode='tags'>
                <Option key={1} value={1}>
                  MACRONUTRIENTES
                </Option>
                <Option key={2} value={2}>
                  MICRONUTRIENTES
                </Option>
                <Option key={3} value={3}>
                  ENXOFRE
                </Option>
                <Option key={4} value={4}>
                  POTÁSSIO TOTAL
                </Option>
                <Option key={5} value={5}>
                  GRANULOMETRIA
                </Option>
                <Option key={6} value={6}>
                  BORO
                </Option>
                <Option key={7} value={7}>
                  MATÉRIA ORGÂNICA
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Hiddenn */}

        <Form.Item initialValue='1' hidden name='status'>
          <Input />
        </Form.Item>
      </Drawer>
    </Form>
  )
}

export default GrupoServico

import axios, { AxiosResponse } from 'axios'
import { errorAlert } from '../utils/alert'

export interface TypesForecast {
  address: string
  currentConditions: TypeCurrentForecast
  days: [TypesForecastList]
  resolvedAddress: string
}

export interface TypeCurrentForecast {
  datetime: string
  datetimeEpoch: number
  temp: number
  feelslike: number
  humidity: number
  dew: number
  precip: number
  precipprob: number
  snow: number
  snowdepth: number
  preciptype: []
  windgust: number
  windspeed: number
  winddir: number
  pressure: number
  visibility: number
  cloudcover: number
  solarradiation: number
  solarenergy: number
  uvindex: number
  severerisk: number
  conditions: string
  icon: string
  stations: []
  source: string
  sunrise: string
  sunriseEpoch: number
  sunset: string
  sunsetEpoch: number
  moonphase: number
}

export interface TypesForecastList {
  datetime: string
  datetimeEpoch: number
  diaSemana: string
  diaDoMes: string
  tempmax: number
  tempmin: number
  temp: number
  feelslikemax: number
  feelslikemin: number
  feelslike: number
  dew: number
  humidity: number
  precip: number
  precipprob: number
  precipcover: number
  preciptype: []
  snow: number
  snowdepth: number
  windgust: number
  windspeed: number
  winddir: number
  pressure: number
  cloudcover: number
  visibility: number
  solarradiation: number
  solarenergy: number
  uvindex: number
  severerisk: number
  sunrise: string
  sunriseEpoch: number
  sunset: string
  sunsetEpoch: number
  moonphase: number
  conditions: string
  description: string
  icon: string
  stations: []
  source: string
}

const apiClima = axios.create({
  baseURL: 'https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/',
})

export const getClimaCodCity = async (city) => {
  try {
    const cidade = `${city}, BR`
    const response: AxiosResponse<TypesForecast> = await apiClima.get(
      `${cidade}?unitGroup=metric&include=days%2Ccurrent&key=89ARC9GWS93EWUEQ6K5XNHFH3&contentType=json&lang=pt`
    )

    return response
  } catch (error) {
    errorAlert('Falha ao localizar município!');
  }
}

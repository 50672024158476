import Icon from '@ant-design/icons';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome';
import { GoogleMap, InfoBox, Polygon } from '@react-google-maps/api';
import { Button, Form, Modal, Space, Switch, Table } from 'antd';

//STYLES
import { Container, ContainerMap, GoogleContainer } from './styled';

//APP
import { useLogic } from '../../../context/useLogic';
import { Notification } from '../../../Components/notification';
import { paleta_cores } from '../talhaoSafra/meta';
import { formatarValor } from '../../../utils';
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado';
import { centerMap, tryError } from '../../../services/Afins';
import { getSafra, SafraItens } from '../../../services/Safra';
import { getTalhao, TalhaoItens } from '../../../services/Talhao';
import { getTalhaoCultivavel, TalhaoCultivaveisItens } from '../../../services/TalhaoSafra';
import { delPropriedade, getPropriedade, PropriedadeItens, putPropriedade } from '../../../services/Propriedades';

//COMPONENTS
import ViewPage from '../../../Components/ViewPage';
import FomrPropriedade from '../../../Components/propriedade';
import GeneratePdfButton from '../../../Components/relatorio-component';
import { IconAlert } from '../../../Components/iconsAlert';
import { pagination } from '../../../Components/Default';
import { ColumnsType } from 'antd/lib/table';
import { Painel1Propriedade } from '../talhaoSafra/components/painel';
import { PainelTodasPropriedades } from '../talhaoSafra/components/painelTodasPropriedades';
import { destroyAlert, errorAlert, infoAlert, loadingAlert, successAlert } from '../../../utils/alert';

interface TypesMapa {
  zoom: number
  center: {
    lat: number
    lng: number
  }
  style?: { [key: string]: string }
}

interface TypesVisuPropri {
  name?: string
  cod?: number
  cor?: string
  areaPropriedade?: number
  talhoes?: TalhaoItens[]
}

const Propriedades = () => {

	//CONST
  	const [form] = Form.useForm();
	const { codCliente } = useLogic();

	const [dataPropriedade, setDataPropriedade] = useState([]);
	const [dataPropriedadeStatus, setDataPropriedadeStatus] = useState([]);
	const [tempPolygon, setTempPolygon] = useState<any>();
  	const [talhoesCultivaveis, setTalhoesCultivaveis] = useState<TalhaoCultivaveisItens[]>([]);
	const [polygon, setPolygon] = useState<google.maps.Polygon>();
	const [map, setMap] = useState<google.maps.Map>();
	const [loadingTable, setLoadingTable] = useState(false);
	const [visibleForm, setVisibleForm] = useState<boolean>(false);
	const [udatePropriedade, setUpdatePropriedade] = useState(0);
	const [codPropriedade, setCodPropriedade] = useState(0);
  	const [dataVisuPropridades, setDataVisuPropridades] = useState<TypesVisuPropri[]>([]);
  	const infoWindow = new window.google.maps.InfoWindow();
	const [visualizarPropriedade, setVisualizarPropriedade] = useState(false);
	const [visualizarPropriedadesCliente, setVisualizarPropriedadesCliente] = useState(false);
  	const [dataPolygon, setDataPolygon] = useState([]);
  	const [loadingStatus, setLoadingStatus] = useState(null);
  	const [hideStatus, setHideStatus] = useState(false);
  	const [dataSafra, setDataSafra] = useState<SafraItens[]>([]);
  	const [nomePropriedade, setNomePropriedade] = useState<string>();
  	const [loadingVisuPropriedades, setLoadingVisuPropriedades] = useState(false);
  	const [permissions, setPermissions] = useState<ItensPerUserLogged>({} as ItensPerUserLogged);
	//STATES - PAINEL
	const [exibir_area_talhao, setExibir_area_talhao] = useState<boolean>(false);
	const [exibir_nome_talhao, setExibir_nome_talhao] = useState<boolean>(false);
	const [exibir_area_talhao_propriedade, setExibir_area_talhao_propriedade] = useState<boolean>(false);
	const [exibir_nome_talhao_propriedade, setExibir_nome_talhao_propriedade] = useState<boolean>(false);
	const [distinguir_cor, setDistinguir_cor] = useState<boolean>(false);
	const [talhao_selecionado, setTalhao_selecionado] = useState<number | null>(null);
	const [dadosEstatisticas, setDadosEstatisticas] = useState([]);
	const [dadosResultado, setDadosResultado] = useState([]);
	const [cod_safra_atual, setCod_safra_atual] = useState<number|null>(null);

	const [propsMap, setPropsMapa] = useState<TypesMapa>({
		zoom: 2,
		center: {
		lat: 0,
		lng: 0,
		},
		style: { width: '100%', height: '78vh' },
	})

  const handleClickVisuPropriedade = (data) => {
    setVisibleForm(true)
    setUpdatePropriedade(data)
  }

  const handleClickPolygon = (
    e: google.maps.PolyMouseEvent,
    index,
    cliente = null,
    indexT = null
  ) => {
    if (tempPolygon) {
      tempPolygon.close()
    }
    const position = e.latLng.toJSON()
    let content
    if (cliente === null) {
      const talhao = talhoesCultivaveis.find((data, i) => i === index)

      content = `<h5><b>Nome</b>: ${talhao.nome} <br /> <b>Área</b>: ${talhao.area}/ha <br /> `

      const dados = JSON.parse(JSON.stringify(dataPolygon))

      dados.forEach((data, i) => {
        if (i === index) {
          data.cor = 'orange'
        } else {
          data.cor = form.getFieldValue('safra') !== 0 ? 'yellow' : 'cyan'
        }
      })

      setDataPolygon(dados)
    } else {
      const listTalhoesVis = [...dataVisuPropridades]

      listTalhoesVis.forEach((info, i) => {
        info.talhoes.forEach((record, ii) => {
          if (indexT === ii && index === i) {
            record.cor = 'orange'
          } else {
            record.cor = 'cyan'
          }
        })
      })
      content = `<h5>
                    <b>Propriedade</b>: ${cliente.name} <br /> 
                    <b>Área da propriedade</b>: ${cliente.areaPropriedade}/ha <br /> 
                    <br />
                    <b>Nome do talhão</b>: ${cliente.nome_talhao} <br />
                    <b>Área do talhão</b>: ${cliente.area}/ha <br />`
      setDataVisuPropridades([...listTalhoesVis])
    }
    // <b>Lat:</b> ${position.lat.toFixed(6)} <b>Lng:</b> ${position.lng.toFixed(6)}</h5>

    infoWindow.setContent(content)
    infoWindow.setPosition(position)
    infoWindow.open({
      anchor: polygon,
      map,
      shouldFocus: true,
    })
    setTempPolygon(infoWindow)
  }

  /*  Function que tem uma chamada no btn de delete, onde puxa os dados da propriedade para fazer a exclusão,
      btn que tambem chama uma modal de confirmação.




  */

  const handleTrashPropriedade = async (data, itemProp, record) => {
    const codCli = localStorage.getItem('cod-cliente')

    /*  const responsePro = await getPropriedade(data)
 
     const nomePro = responsePro.data.itens[0].nome */
    const { nome } = itemProp

    Modal.confirm({
      title: 'Excluir',
      icon: <IconAlert type='confirm' size={6} />,
      content: `Deseja realmente remover a Propriedade ${nome}?`,
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        try {
          const responseDel = await delPropriedade(data, codCli, itemProp)

          if (responseDel.status === 200) {
            successAlert('Propriedade excluida com sucesso!');

            listarPropriedades('')
          }
        } catch (error) {
          if (error.response) {
            const { data } = error.response
            errorAlert(data?.error ?? 'Falha na operação');
          }
        }
      },
    })
  }

  const handleClickVisualizarTodasPropriedades = async () => {
    let talhoes = []

    setLoadingVisuPropriedades(true)
    for (let i = 0; i < dataPropriedade.length; i += 1) {
      const propri = dataPropriedade[i]

      const resp = await getTalhao(propri.cod, '')

      if (resp?.data?.itens === undefined || resp?.data?.itens === null) {
        errorAlert('Falha ao carregar mapa da propriedade!')
        setLoadingVisuPropriedades(false)
        return null
      }

      if (resp.data.itens.length > 0) {
        resp.data.itens.forEach((data) => {
          data.cor = 'cyan'
        })
        talhoes = [
          ...talhoes,
          {
            cod: propri.cod,
            areaPropriedade: propri.area,
            name: propri.nome,
            talhoes: resp.data.itens,
          },
        ]
      }
    }

    let centeRs: google.maps.LatLngLiteral[] = []
    if (talhoes.length > 0) {
      talhoes[0].talhoes[0].kml.coordenadas.forEach((data) => {
        centeRs = [...centeRs, { lng: data.longitude, lat: data.latitude }]

        return centeRs
      })

      const center: google.maps.LatLngLiteral = centerMap(centeRs).toJSON()
      const tamanho_propriedades: number =
        talhoes.reduce((acumulador, item) => acumulador + Number(item.areaPropriedade), 0) ?? 0

      function zoomBaseadoNaArea() {
        if (tamanho_propriedades > 900) {
          return 12
        }
        if (tamanho_propriedades >= 700 && tamanho_propriedades < 900) {
          return 14
        }
        if (tamanho_propriedades >= 100 && tamanho_propriedades < 700) {
          return 15
        }
        if (tamanho_propriedades < 100) {
          return 16
        }

        return 11
      }

      setPropsMapa({ center, zoom: zoomBaseadoNaArea() })
    } else {
      infoAlert('Cliente não possui talhões');
      setLoadingVisuPropriedades(false)
      return
    }

    setDataVisuPropridades(talhoes)
    // setListaTalhoes(talhoes?.map(item=>item.talhoes));
    setVisualizarPropriedadesCliente(true)
    setLoadingVisuPropriedades(false)
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdatePropriedade(0)
  }

  /* Visualizar propriedade */
  const handleVisualizarPropri = async (data, info, cod) => {

    if (info.key === '0') {
      form.setFieldsValue({safra: 0})
    }
    else {
      form.setFieldsValue({safra: info.value})
    }
    

	// console.log("data: ", data);
	// console.log("info: ", info);
	// console.log("cod: ", cod);

    loadingAlert({msg: 'Carregando mapa...', id: 'mapa'});
    
    // const responsePro = await getPropriedade(data)
    const response =
      info.key === '0'
        ? await getTalhao(cod === '' ? codPropriedade : cod, '')
        : await getTalhaoCultivavel({ codPropriedade, codSafra: info.value })

    const dados = response.data.itens

    setTalhoesCultivaveis(dados)

    if (dados.length > 0) {
      let talhoes = []

      for (let i = 0; i < dados.length; i += 1) {
        const element = dados[i]
        let temp = []
        for (let ii = 0; ii < element.kml.coordenadas.length; ii += 1) {
          const coord = element.kml.coordenadas[ii]

          element.kml.coordenadas[ii].lng = coord.longitude
          element.kml.coordenadas[ii].lat = coord.latitude

          temp = [...temp, { lat: parseFloat(coord.lat), lng: parseFloat(coord.lng) }]
        }
        talhoes = [...talhoes, temp]
      }

      const centro = centerMap(talhoes[0])

      setPropsMapa({
        center: {
          lat: centro.lat(),
          lng: centro.lng(),
        },
        zoom: 11,
      })

      setDataPolygon(talhoes)
      setVisualizarPropriedade(true)
      destroyAlert('mapa');
    } else {
      infoAlert('Propriedade não possui talhões cadastrados', 5000, 'mapa');
      setDataPolygon([])
    }
  }

  /* Lista as propriedades */
  const listarPropriedades = async (cod, loading = false) => {
    if (loading) {
      setLoadingTable(true)
    }
    const response = await getPropriedade(cod)
    const dados = response.data.itens
    const resultado = dados.reduce(
      (soma, item) => {
        //@ts-ignore

        return {
          //@ts-ignore

          area: soma.area + parseFloat(item.area),
        }
      },
      {
        area: 0,
      }
    )

    const resultadoFinal = [
      {
        totalizado_area: formatarValor(resultado?.area),
      },
    ]

    setDataPropriedade(dados)
    gerarTabela(dados)
    gerarTabela2(resultadoFinal, dados.length)
    setNomePropriedade(dados[0]?.nome)

    setDataPropriedadeStatus(dados.filter((rec) => rec.status !== 0))
    setLoadingTable(false)
  }

  const listSafra = async () => {
    const resp = await getSafra({ pagination: 15, order: 'data_inicio' })

    setDataSafra(resp.data.itens)
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: formatarValor(item?.area),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
        {
          text: `${item?.cidade[0]?.nome}/${item?.cidade[0]?.uf_sigla}`,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 0,
          widths: ['*', '*', '*'],
          body: [
            [
              { text: 'Nome', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área/ha', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Município/UF', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = dados?.map((item) => {
      return [
        { text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: item?.totalizado_area, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 0,
          widths: ['*', '*'], // Definindo larguras das colunas
          body: [
            [
              {
                text: 'Total propriedades',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              { text: 'Total de Área', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
          margin: [0, 50, 0, 0],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosResultado(table)
  }

  const handleChangeSwitchStatus = async (cod, index, status: number) => {
    try {
      setLoadingStatus(index)
      await putPropriedade(codCliente, cod, {
        status: status === 1 ? 0 : 1,
      })

      listarPropriedades('')

      setLoadingStatus(null)
    } catch (error) {
      tryError(error)
      setLoadingStatus(null)
    }
  }

  const handleChangeSwitchOcultarInativo = (e) => {
    setHideStatus(e)
  }

  const onLoadMap = (e: google.maps.Map) => {
    setMap(e)
  }

  const onLoadPolygon = (e: google.maps.Polygon) => {
    setPolygon(e)
  }
  /* useEffect que fica observando a hora que o componente é montado, quando for montado ele vai reagir 
  fazendo com que seja listado as propriedade e as safras
  
  */
  useEffect(() => {
    listarPropriedades('', true)
    listSafra()

    form.setFieldsValue({
      safra: 0,
    })
  }, [])

  function tamanhoPropriedade(): number {
    let soma_area_talhoes: number = talhoesCultivaveis.reduce((acc: number, item: any)=> acc + Number(item.area), 0);
    return soma_area_talhoes;
  }

  /**Define a cor do talhão
   *
   * Se o talhão estiver selecionado retorna a cor `orange`
   *
   * Se a flag `distinguir_cor` for `true`, retorna uma cor única presente no array `paleta_cores[]`
   *
   * Se não estiver em nenhuma das consições anteriores, retorna a cor inicial `cyan`
   */
  function corAtualTalhao(cod_talhao: number, cor_inicial: string, index: number): string {
    if (talhao_selecionado === cod_talhao) {
      return 'orange'
    }
    return distinguir_cor ? paleta_cores?.[index] : cor_inicial
  }

  const colums: ColumnsType<PropriedadeItens> = [
    {
      title: (
        <Space style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          Ocultar inativos{' '}
          <Switch
            onChange={handleChangeSwitchOcultarInativo}
            size='default'
            checkedChildren='Sim'
            unCheckedChildren='Não'
          />
        </Space>
      ),
      align: 'left',
      children: [
        {
          title: 'Ativo',
          dataIndex: 'status',
          width: 70,
          align: 'center',
          key: 'status',
          render: (status, record, i) => {
            return (
              <Switch
                loading={loadingStatus === i}
                size='small'
                onChange={() => handleChangeSwitchStatus(record.cod, i, status)}
                checked={status === 1}
                checkedChildren='Sim'
                unCheckedChildren='Não'
              />
            )
          },
        },
        {
          title: 'Nome',
          dataIndex: 'nome',
          key: 'nome',
        },
        {
          title: 'Área/ha',
          dataIndex: 'area',
          key: 'area',
          render: (data) => {
            return <>{data.replace('.', ',')}</>
          },
        },
        {
          title: 'Município/UF',
          dataIndex: 'cidade',
          key: 'cidade',
          render: (data) => {
            return (
              <p>
                {data[0].nome}/{data[0].uf}
              </p>
            )
          },
        },
        {
          title: 'Ações',
          dataIndex: 'cod',
          width: '135px',
          key: 'acao',
          render: (data, record: any, itemProp) => (
            <Space>
              <Button
                shape='circle'
                onClick={() => handleClickVisuPropriedade(data)}
                icon={<FontA icon={solid('pen')} />}
                hidden={permissions?.alteracao === 0 && permissions?.acesso === 0}
                size='middle'
              />
              <Button
                shape='circle'
                onClick={() => {
                  setNomePropriedade(record.nome)
                  setCodPropriedade(record.cod)
				  setCod_safra_atual(data)
                  form.setFieldsValue({ safra: 0 })
                  handleVisualizarPropri(
                    data,
                    {
                      key: '0',
                      value: data,
                      children: 'Talhões da propriedade',
                    },
                    record.cod
                  )
                }}
                icon={<FontA icon={solid('map-marked')} />}
                size='middle'
              />
              <Button
                hidden={permissions?.exclusao === 0}
                shape='circle'
                onClick={() => handleTrashPropriedade(data, record, itemProp)}
                className='trash-button'
                type='primary'
                icon={<FontA icon={solid('trash')} />}
                size='middle'
              />
            </Space>
          ),
        },
      ],
    },
  ]

  return (
    <Container>
      <ViewPage
        rotina={25}
        onPermission={setPermissions}
        title='Propriedades'
        btnClick={handleVisibleForm}
        btnsExta={
          <>
            <Button
              icon={<Icon component={() => <FontA icon={solid('map-marked')} />} />}
              loading={loadingVisuPropriedades}
              onClick={handleClickVisualizarTodasPropriedades}
              type='primary'
            >
              Visualizar todas propriedades
            </Button>
            {localStorage.getItem('tipo-acesso') === 'conta' ? (
              <GeneratePdfButton
                dados={dadosEstatisticas}
                title={'Relatório propriedade'}
                totais={dadosResultado}
                disable={dataPropriedade.length <= 0}
              />
            ) : null}
          </>
        }
        content={
          <FomrPropriedade
            onClose={setVisibleForm}
            visible={visibleForm}
            update={udatePropriedade}
            setUpdate={setUpdatePropriedade}
            refresh={listarPropriedades}
            permissions={permissions}
          />
        }
      >
        <Table
          onRow={(record: any) => {
            return {
              onDoubleClick: () => {
                handleClickVisuPropriedade(record.cod)
              },
            }
          }}
          pagination={pagination}
          size='small'
          bordered
          loading={loadingTable}
          dataSource={hideStatus ? dataPropriedadeStatus : dataPropriedade}
          scroll={{ y: 'calc(90vh - 310px)', x: 'auto' }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataPropriedade.length}</b>
            </div>
          )}
          columns={colums}
        />
      </ViewPage>
      
      <Modal
        open={visualizarPropriedade}
        onCancel={() => setVisualizarPropriedade(false)}
        style={{ top: '10px', minWidth: '97%', height: '90vh' }}
        closable={false}
        footer={false}
      >
        <ContainerMap>
          {/* @ts-ignore */}
          <GoogleMap
            onLoad={onLoadMap}
            mapContainerClassName='floating'
            zoom={propsMap.zoom}
            mapContainerStyle={{
              width: '100%',
              height: '93vh',
            }}
            center={propsMap.center}
            options={{
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              mapTypeId: 'hybrid',
            }}
          >
            {dataPolygon.map((data, i) => {
              	const color =
					form.getFieldValue('safra') !== 0
					? {
						fillColor: corAtualTalhao(data.cod_talhao, 'yellow', i),
						strokeColor: corAtualTalhao(data.cod_talhao, 'yellow', i),
						}
					: {
						fillColor: corAtualTalhao(data.cod_talhao, 'cyan', i),
						strokeColor: corAtualTalhao(data.cod_talhao, 'cyan', i),
						}

				const talhao = talhoesCultivaveis.find((record, ii) => ii === i)
				const center = centerMap(data);

              return (
				<>
                <Polygon
                  paths={data}
                  onClick={(e) => handleClickPolygon(e, i)}
                  onLoad={onLoadPolygon}
                  options={color}
                />
                  {/* @ts-ignore */}
                  <InfoBox
                    options={{
                      closeBoxURL: '',
                      enableEventPropagation: true,
                    }}
                    position={center}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        width: '400px',
                        textShadow: '2px 2px 3px black',
                      }}
                    >
                      <h4>
                        <b
                          hidden={!exibir_nome_talhao_propriedade}
                          style={{ overflow: 'hidden', color: 'white' }}
                        >
                          {talhao?.nome}
                        </b>
                        <br />
                        <b
                          hidden={!exibir_area_talhao_propriedade}
                          style={{ overflow: 'hidden', color: 'white' }}
                        >
                          {` ${talhao?.area} ha`}
                        </b>
                      </h4>
                    </div>
                  </InfoBox>
					
				</>
              )
            })}


            <Painel1Propriedade
              exibir_area_talhao={exibir_area_talhao_propriedade}
              setExibir_area_talhao={setExibir_area_talhao_propriedade}
              exibir_nome_talhao={exibir_nome_talhao_propriedade}
              setExibir_nome_talhao={setExibir_nome_talhao_propriedade}
              onClose={() => {
                setVisualizarPropriedade(false)
                setTalhao_selecionado(null)
              }}
              dados_propriedades={talhoesCultivaveis}
              distinguir_cor={distinguir_cor}
              setDistinguir_cor={setDistinguir_cor}
              tamanhoPropriedade={tamanhoPropriedade()}
              margem={15}
              list_safra={dataSafra}
              handleVisualizarPropri={handleVisualizarPropri}
              isCadastroPropriedade
            />

          
          </GoogleMap>
        </ContainerMap>
      </Modal>


      <Modal
        open={visualizarPropriedadesCliente}
        onCancel={() => setVisualizarPropriedadesCliente(false)}
        style={{ top: '10px', minWidth: '97%' }}
        closable={false}
        footer={false}
      >
        <PainelTodasPropriedades
          exibir_area_talhao={exibir_area_talhao}
          setExibir_area_talhao={setExibir_area_talhao}
          exibir_nome_talhao={exibir_nome_talhao}
          setExibir_nome_talhao={setExibir_nome_talhao}
          onClose={() => setVisualizarPropriedadesCliente(false)}
          dados_propriedades={dataVisuPropridades}
          distinguir_cor={distinguir_cor}
          setDistinguir_cor={setDistinguir_cor}
          ocultarSafra
        />
        <GoogleContainer>
          {/* @ts-ignore */}
          <GoogleMap
            mapContainerClassName='floating'
            zoom={propsMap.zoom}
            mapContainerStyle={{
              width: '100%',
              height: '93vh',
            }}
            center={propsMap.center}
            options={{
              zoomControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              mapTypeId: 'hybrid',
            }}
          >
            {dataVisuPropridades.map((data, index) => {
              let poly = []
              for (let i = 0; i < data.talhoes.length; i += 1) {
                const talhoes = data.talhoes[i]

                let tempCoord: google.maps.LatLng[] = []
                for (let j = 0; j < talhoes.kml.coordenadas.length; j += 1) {
                  const coord = talhoes.kml.coordenadas[j]

                  tempCoord = [
                    ...tempCoord,
                    new google.maps.LatLng({
                      lat: coord.latitude,
                      lng: coord.longitude,
                    }),
                  ]
                }
                poly = [
                  ...poly,
                  {
                    cod_talhao: talhoes.cod,
                    cor: talhoes.cor,
                    nome_talhao: talhoes.nome,
                    area: talhoes.area,

                    coord: tempCoord,
                  },
                ]
              }
              return poly.map((info, ii) => {
                const final = { ...data, ...info }

                const center = centerMap(info?.coord)

                return (
                  <>
                    <Polygon
                      onClick={(e) => {
                        handleClickPolygon(e, index, final, ii)
                        setTalhao_selecionado(info.cod_talhao)
                      }}
                      onLoad={onLoadPolygon}
                      options={{
                        // fillColor: distinguir_cor ? paleta_cores?.[index] : 'cyan',
                        // strokeColor: distinguir_cor ? paleta_cores?.[index] : 'cyan'
                        fillColor: corAtualTalhao(info.cod_talhao, 'cyan', index),
                        strokeColor: corAtualTalhao(info.cod_talhao, 'cyan', index),
                      }}
                      paths={info.coord}
                    />
                    {/* @ts-ignore */}
                    <InfoBox
                      options={{
                        closeBoxURL: '',
                        enableEventPropagation: true,
                      }}
                      position={center}
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          width: '400px',
                          textShadow: '2px 2px 3px black',
                          display: 'inline-flex',
                          zIndex: 0,
                        }}
                      >
                        <h4>
                          <b
                            hidden={!exibir_nome_talhao}
                            style={{ overflow: 'hidden', color: 'white' }}
                          >
                            {info?.nome_talhao}
                          </b>
                          <br />
                          <b
                            hidden={!exibir_area_talhao}
                            style={{ overflow: 'hidden', color: 'white' }}
                          >
                            {` ${info?.area} ha`}
                          </b>
                        </h4>
                      </div>
                    </InfoBox>
                  </>
                )
              })
            })}
          </GoogleMap>
        </GoogleContainer>
      </Modal>
    </Container>
  )
}

export default Propriedades

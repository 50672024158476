import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import React, { Suspense, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import Loading from '../../Components/loading'
import { Notification } from '../../Components/notification'
import { APP_PREFIX_PATH } from '../../Configs/AppConfig'
import { useAccess } from '../../context/useAccess'
import { useLogic } from '../../context/useLogic'
import MapsKrikagem from './test/krikagem'
import Lab from '../app/laboratorio'
import { errorAlert, warningAlert } from '../../utils/alert'

export const AppViews: React.FC = () => {
  const { itens, setItens } = useLogic()
  const { navigate, logged } = useAccess()
  const [labMode, setMode] = useState<boolean>(false)
  const location = useLocation()

  const history = useHistory()

  const tokenDAta = itens[0]?.hash ? itens[0].hash : false
  const checkToken = async () => {
    const jwt: any = jwtDecode(tokenDAta as any)
    const validade = dayjs(jwt.exp * 1000).format() > dayjs().format()
    if (!validade) {
      warningAlert('Sessão Expirada! Faça o Login Novamente.');
      setItens([])
      history.push('/auth/login')
    }
  }
  if (!tokenDAta) {
    history.push('/auth/login')
  } else {
    checkToken()
  }

  useEffect(() => {
    setMode(location.pathname === '/lab')
    if (logged) {
      if (
        logged[0].acessoConta.clientes.length <= 0 &&
        !logged[0].acessoConta.contas[0].temAcesso
      ) {
        history.push('/auth')

        setTimeout(() => {
          errorAlert('Usuário sem permissão de acesso!');
        }, 100)
      }
    }
  }, [logged])
  return (
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        {navigate.map((nave) => {
          let rota

          if (!nave.hidden && nave.submenu.length <= 0) {
            rota = (
              <Route
                key={nave.key}
                path={`${APP_PREFIX_PATH}${nave.path}`}
                exact
                component={nave.component as any}
              />
            )
          } else {
            rota = nave.submenu.map((sub) => {
              if (sub.hidden) {
                return null
              }
              return (
                <Route
                  key={sub.id}
                  path={`${APP_PREFIX_PATH}${sub.path}`}
                  exact
                  component={sub.component as any}
                />
              )
            })
          }
          return rota
        })}
        <Route
          path={`${APP_PREFIX_PATH}/teste`}
          key={`${APP_PREFIX_PATH}/teste`}
          component={MapsKrikagem}
        ></Route>

        <Route
          key={`${APP_PREFIX_PATH}/logout`}
          path={`${APP_PREFIX_PATH}/logout`}
          component={() => {
            setItens([])

            history.push(labMode === true ? '/auth-lab/login' : '/auth')
            return <></>
          }}
        >
          {}
        </Route>
        <Route path={`${APP_PREFIX_PATH}/lab`} component={Lab} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)

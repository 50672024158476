
import { toast, ToastPosition, Zoom } from "react-toastify";

interface LoadingAlertParams {
    msg?: string;
    id?: string;
    autoClose?: number;
    position?: ToastPosition;
}

export function loadingAlertJSX({msg, id, autoClose, position}: LoadingAlertParams) {

    let new_msg = msg ?? 'Caregando...';
    let auto_destroy = autoClose ?? 30000;

    toast.loading(<div className="custom_toasty_loading">{msg}</div>, {
        position: position ?? "top-right",
        autoClose: auto_destroy ?? 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        //@ts-ignore
        transition: Zoom,
        className: 'gradient_toasty',
        toastId: id ?? 'loading_default',
        style: new_msg==='Caregando...' ? {width: 170} : undefined
    });

    setTimeout(() => {
        toast.dismiss(id ?? 'loading_default');
    }, auto_destroy);
    
};
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faLeaf, faWheatAlt, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button, DatePicker, Drawer, InputRef, Modal } from 'antd';

//STYLES
import { BodyDrawer } from './styles'
import { NavegacaoDrawer } from '../../../recomendacao/novoCompatibilidadeAtivo/components/gavetaCadastrar/styled'


//APP
import { tiposGerais } from '../../../../../services/Afins';
import { ItensPerUserLogged } from '../../../../../services/login/permissaoUsuarioLoado';
import { destroyAlert, errorAlert, loadingAlert } from '../../../../../utils/alert';
import { getSafra, getSafraEstatisticas, postSafra, putSafra } from '../../../../../services/Safra';


//COMPONENTS
import Input from '../../../../../Components/CustomUi/input'
import Carregando from '../../../../../Components/CustomUi/loading';
import ButtonCustom from '../../../../../Components/ButtonCustom';
import { Render } from '../../../../../Components/CustomUi/render';
import { Select } from '../../../../../Components/CustomUi/Select'
import { IconAlert } from '../../../../../Components/iconsAlert';
import { HeaderDrawer } from '../../../../../Components/CustomUi/headerGaveta';

interface PropsFormSafra {
  update: number
  visible: boolean
  setOnClose: (data: boolean) => void
  setUpdate: (data: number) => void
  setCodSafra: (data: number) => void
  atualizar: () => void
  permissions: ItensPerUserLogged
  listSafra: any
  codSafra: number
  loadingForm: boolean
}

const FormSafra: React.FC<PropsFormSafra> = ({
  update,
  visible,
  setOnClose,
  setUpdate,
  atualizar,
  permissions,
  listSafra,
  codSafra
}) => {

  const inputFoco = useRef<InputRef>();
  const isCliente = localStorage.getItem('cod-cliente') !== '' && localStorage.getItem('cod-cliente') !== undefined;

  const [getCultura, setGetCultura] = useState([])
  const [dadosCulturaSecundaria, setDadosCulturaSecundaria] = useState([])
  const [logUser, setLogUser] = useState(false)
  const [usuarioDeRegistro, setUsuarioDeRegistro] = useState('')
  const [dataDeRegistro, setDataDeRegistro] = useState('')
  const [criadoUpdate, setCriadoUpdate] = useState<number>()
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [desabilitar, setDesabilitar] = useState(false)

  const [safraNome, setSafraNome] = useState('')
  const [openCultura, setOpenCultura] = useState(false)
  const [cultura, setCultura] = useState('')
  const [culturaId, setCulturaId] = useState(null)
  const [openCulturaSecundaria, setOpenCulturaSecundaria] = useState(false)
  const [culturaSecundaria, setCulturaSecundaria] = useState('')
  const [culturaSecundariaId, setCulturaSecundariaId] = useState(null)
  const [dataInicio, setDataInicio] = useState<Dayjs | null>(null)
  const [dataFim, setDataFim] = useState<Dayjs | null>(null)
  const [loading_safra, setLoading_safra] = useState<boolean>(false);

  const hancleDrawerClose = () => {
    setOnClose(false)
    setUpdate(0)
    setDataDeRegistro('')
    setUsuarioDeRegistro('')
    setDadosEstatisticas([])
    setDadosCulturaSecundaria([])
    setDesabilitar(false)
    limparCampos()
  }

  function limparCampos() {
    setSafraNome('')
    setCultura('')
    setCulturaId(null)
    setCulturaSecundaria('')
    setCulturaSecundariaId(null)
    setDataInicio(null)
    setDataFim(null)
    setDadosEstatisticas([])
    setDadosCulturaSecundaria([])
  }

  const buscarDadosSafra = async (data) => {
    setLoading_safra(true);
    const response = await getSafra({ cod: data })
    const culturaSecundaria = getCultura.filter(
      (item) => item.cod === response?.data?.itens[0]?.cod_cultura_secundaria
    )
    const dados = response.data.itens[0]
    const dadosNome = response.data.itens.find((item) => item.cod === data)
    setSafraNome(dados?.nome)
    setCultura(dados?.cultura[0]?.nome)
    setCulturaId(dados?.cultura[0]?.cod)
    setCulturaSecundaria(culturaSecundaria[0]?.nome)
    setCulturaSecundariaId(culturaSecundaria[0]?.cod)
    setDataInicio(dayjs(dados.data_inicio))
    setDataFim(dayjs(dados.data_final))
    filterCulturaSecundaria(dadosNome?.nome)
    setLoading_safra(false);
  }

  const listTotalPropriedade = async (cod) => {
    const response = await getSafraEstatisticas()
    const filter = response.data.itens.filter((item) => item.cod === cod)
    setDadosEstatisticas(filter)
  }

  const tabela_dados_estatisticas: { header: string; row: string | number }[] = [
    { header: 'Possui Monitoramento', row: dadosEstatisticas?.[0]?.possui_monitoramento ? 'Sim' : 'Não' },
    { header: 'Total de propriedades', row: dadosEstatisticas?.[0]?.total_propriedades },
    { header: 'Total de talhões', row: dadosEstatisticas?.[0]?.total_talhoes },
    { header: 'Área total', row: dadosEstatisticas?.[0]?.total_area_talhoes + ' ha' },
    { header: "Quantidade de OS's", row: dadosEstatisticas?.[0]?.total_os }
  ]

  const listTiposGerais = async () => {
    const response = await tiposGerais()
    setGetCultura(response)
  }

  function limparString(str) {
    // Substitui números e caracteres especiais por espaços em branco
    const stringLimpa = str?.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s]/g, '')

    // Remove espaços extras e converte para minúsculas
    const stringFinal = stringLimpa?.trim().toLowerCase()

    return stringFinal
  }

  function detectarPalavrasEspecificas(texto, palavras) {
    const regex = new RegExp(`\\b(${palavras.join('|')})\\b`, 'gi')
    const resultado = texto?.match(regex)
    return resultado ? resultado[0] : null
  }

  function filterCulturaSecundaria(data) {
    setSafraNome(data)
    const nome = data
    const cult = getCultura.map((item) => item?.nome)
    const final = detectarPalavrasEspecificas(nome, cult)

    const filterNome = getCultura.filter((rec) => rec?.nome?.toLowerCase() !== final?.toLowerCase())
    setDadosCulturaSecundaria(filterNome)
  }

  const handleDateChangeInicio = (date: Dayjs) => {
    setDataInicio(date)
  }

  const handleDateChangeFim = (date: Dayjs) => {
    setDataFim(date)
  }

  function validarCampos() {
    if (!safraNome) {
      errorAlert('Preencha o campo nome!')
      setDesabilitar(false)
      return null
    }

    if (!cultura) {
      errorAlert('Preencha o campo cultura!')
      setDesabilitar(false)
      return null
    }

    // if (!culturaSecundaria) {
    //   errorAlert('Preencha o campo cultura secundária (consórcio ou associação)!')
    //   setDesabilitar(false)
    //   return null
    // }

    if (!dataInicio) {
      errorAlert('Preencha o campo data inicio!')
      setDesabilitar(false)
      return null
    }

    if (!dataFim) {
      errorAlert('Preencha o campo data fim!')
      setDesabilitar(false)
      return null
    }

    onFinish()
  }

  const onFinish = async () => {
    setDesabilitar(true)
    const data = {
      nome: safraNome,
      cod_cultura: culturaId,
      cod_cultura_secundaria: culturaSecundariaId,
      data_inicio: dayjs(dataInicio).format('DD/MM/YYYY'),
      data_final: dayjs(dataFim).format('DD/MM/YYYY'),
      status: 1,
    }

    const handleCriarSafra = async (data) => {
      const response = await postSafra(
        data,
        setDesabilitar,
        destroyAlert(),
        limparCampos,
        atualizar
      )

      Modal.destroyAll()
    }

    const cult = getCultura?.filter((item) => item?.cod === culturaId)

    const { nome } = cult?.[0]

    const nomeSafra = safraNome

    const nomeSafraFinal = limparString(nomeSafra)

    // Cria uma expressão regular dinâmica com a palavra contida em 'nome'
    const regex = new RegExp(`\\b${nome?.toLowerCase()}\\b`)

    // Encontra a correspondência usando a expressão regular
    const match = nomeSafraFinal?.toLowerCase().match(regex)

    if (update === 0) {
      if (!match) {
        Modal.confirm({
          title:
            'Parece que o nome que foi dado à safra não corresponde a cultura informada. Deseja continuar assim mesmo ou corrigir a cultura?',
          icon: <IconAlert type='warning' size={6} />,
          type: 'warning',
          okCancel: false,

          footer: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                style={{
                  marginRight: 8,

                  background: '#81b395',
                  borderColor: '#81b395',
                  color: '#fff',
                }}
                onClick={() => handleCriarSafra(data)}
              >
                Continuar
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  Modal.destroyAll()
                  setDesabilitar(false)
                }}
                style={{ marginLeft: 8 }}
              >
                Corrigir
              </Button>
            </div>
          ),
        })
      } else {
        handleCriarSafra(data)
      }
    } else {
      const handleAtualizarSafra = async () => {
        const response = await putSafra(update, data, atualizar, setDesabilitar,hancleDrawerClose )

        Modal.destroyAll()
      }

      if (!match) {
        Modal.confirm({
          title:
            'Parece que o nome que foi dado à safra não corresponde a cultura informada. Deseja continuar assim mesmo ou corrigir a cultura?',
          icon: <IconAlert type='warning' size={6} />,
          type: 'warning',
          okCancel: false,

          footer: (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                style={{
                  marginRight: 8,

                  background: '#81b395',
                  borderColor: '#81b395',
                  color: '#fff',
                }}
                onClick={() => handleAtualizarSafra()}
              >
                Continuar
              </Button>
              <Button type='primary' onClick={() => Modal.destroyAll()} style={{ marginLeft: 8 }}>
                Corrigir
              </Button>
            </div>
          ),
        })
      } else {
        handleAtualizarSafra()
      }
    }
  }

  useEffect(() => {
    if (update > 0) {
      buscarDadosSafra(update)
      listTotalPropriedade(update)
      setDesabilitar(false)
    } else {
      inputFoco.current?.focus({
        cursor: 'start',
      })
    }
  }, [update])

  useEffect(() => {
    listTiposGerais()
  }, [])

  useEffect(() => {
    if (update === 0) {
      setLogUser(false)
      setUsuarioDeRegistro('')
      setDataDeRegistro('')
    }
  }, [update])

  useEffect(() => {
    if (visible) {
      const safraCheck = listSafra.filter((item) => item.cod === codSafra)
      if (update > 0 && safraCheck[0].create_user) {
        setUsuarioDeRegistro(safraCheck[0].create_user)
        setDataDeRegistro(safraCheck[0].create_time)
        setLogUser(true)
        setCriadoUpdate(0)
      }

      if (update > 0 && safraCheck[0].update_user != null) {
        setUsuarioDeRegistro(safraCheck[0].update_user)
        setDataDeRegistro(safraCheck[0].update_time)
        setCriadoUpdate(1)
        setLogUser(true)
      }
    }
  }, [visible])

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      headerStyle={{ padding: '10px 10px' }}
      destroyOnClose
      placement='right'
      width='350px'
      onClose={hancleDrawerClose}
      open={visible}
      closeIcon={false}
      title={
        <NavegacaoDrawer>
          <ButtonCustom
            danger
            icon={<FontAwesomeIcon icon={faXmark} />}
            type='tertiary'
            onClick={hancleDrawerClose}
            style={{ marginLeft: -10 }}
          >
            Fechar
          </ButtonCustom>
        </NavegacaoDrawer>
      }
      footer={
          <ButtonCustom
            hidden={permissions?.alteracao === 0 && permissions?.inclusao === 0}
            type='primary'
            disabled={desabilitar}
            loading={desabilitar}
            onClick={validarCampos}
            icon={<FontAwesomeIcon icon={faFloppyDisk} />}
          >
            {update ? 'Atualizar Safra' : 'Salvar'}
          </ButtonCustom>
        }
      >
        <HeaderDrawer>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <>{update ? 'Atualizar Safra' : 'Cadastrar Safra'}</>

            <small style={{ opacity: '0.5', fontSize: '9px' }}>
              {logUser === true ? (
                <>
                  {' '}
                  {criadoUpdate > 0 ? 'Atualizado por:' : 'Criado por:'}{' '}
                  {`${usuarioDeRegistro} ${dayjs(dataDeRegistro).format('DD/MM/YYYY HH:mm:ss')}`}
                </>
              ) : null}
            </small>
          </div>
        </HeaderDrawer>

        <Render condition={loading_safra}>
            <Carregando 
              animation
              legenda='Carregando safra...'
              justifyContent='center'
              height={500}
            />
        </Render>
        
        <Render condition={!loading_safra}>
          <BodyDrawer>
            <Input
              label='Nome da safra'
              icon={faLeaf}
              placeholder={'Digite o nome da safra'}
              value={safraNome}
              onChange={filterCulturaSecundaria}
            />
            <Select
              value={cultura}
              onChange={setCultura}
              onChangeID={setCulturaId}
              open={openCultura}
              trigger={setOpenCultura}
              items={getCultura}
              descricaoItem={'nome'}
              idItem={'cod'}
              label='Cultura'
              placeholder='Selecione a cultura'
              icon={faWheatAlt}
            />

            <Select
              value={culturaSecundaria}
              onChange={setCulturaSecundaria}
              onChangeID={setCulturaSecundariaId}
              open={openCulturaSecundaria}
              trigger={setOpenCulturaSecundaria}
              items={dadosCulturaSecundaria}
              descricaoItem={'nome'}
              idItem={'cod'}
              label='Cultura secundária (consórcio ou associação)'
              placeholder='Selecione a cultura secundária'
              icon={faWheatAlt}
            />

            <div className='data-nascimento'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label>Data início</label>
              </div>
              <DatePicker
                inputReadOnly={permissions?.acesso === 1}
                value={dataInicio ? dayjs(dataInicio) : null}
                onChange={handleDateChangeInicio}
                style={{ height: '100%', borderRadius: '10px' }}
                placeholder='Selecione a data de inicial'
                format='DD/MM/YYYY'
              />
            </div>
            <div className='data-nascimento'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label>Data Fim</label>
              </div>
              <DatePicker
                inputReadOnly={permissions?.acesso === 1}
                value={dataFim ? dayjs(dataFim) : null}
                onChange={handleDateChangeFim}
                style={{ height: '100%', borderRadius: '10px' }}
                placeholder='Selecione a data final'
                format='DD/MM/YYYY'
              />
            </div>

            <Render condition={update > 0 && isCliente}>
              <div className='MD__content__coleta'>
                {tabela_dados_estatisticas.map(({ header, row }) => {
                  return (
                    <div key={header} className='MD__content__tabela_coleta__column'>
                      <div className='MD__c__tabela_coleta__column__header'>{header}</div>
                      <div className='MD__c__tabela_coleta__column__row'>{row}</div>
                    </div>
                  )
                })}
              </div>
            </Render>
          </BodyDrawer>
        </Render>
        
      </Drawer>
  )
}

export default FormSafra

import React, { useEffect, useState } from 'react'
import { ContainerAcesso } from '../acessosPermissoes/styled'
import { Select } from '../../../CustomUi/Select'
import {
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleXmark,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { Radio, Spin, Transfer } from 'antd'
import ButtonCustom from '../../../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Checkbox from '../../../CustomUi/checkbox'
import { ClienteItensTypes, getCliente } from '../../../../services/Cliente'
import { getPropriedade, getPropriedadeLiberada } from '../../../../services/Propriedades'
import { useLogic } from '../../../../context/useLogic'
import { TransferDirection } from 'antd/es/transfer'
import { postUserPropriedade } from '../../../../services/Usuario/propriedades'
import { tryError } from '../../../../services/Afins'
import { LoadingOutlined } from '@ant-design/icons'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import Carregando from '../../../CustomUi/loading'
import { destroyAlert, errorAlert, loadingAlert, warningAlert } from '../../../../utils/alert'

interface ProposFormAcessoPropriedade {
  clienteSelecionado?: string
  setClienteSelecionado?: React.Dispatch<React.SetStateAction<string>>
  clienteSelecionadoId?: number
  setClienteSelecionadoId?: React.Dispatch<React.SetStateAction<number>>
  listDataCliente?: any[]
  targetKeys?: any[]
  setTargetKeys?: React.Dispatch<React.SetStateAction<[]>>
  codFunc?: number
  allClients?: boolean
  accessCliente?: number
  limparInput?: boolean
  setLimparInput?: React.Dispatch<React.SetStateAction<boolean>>
  permissions?: ItensPerUserLogged
}

const FormAcessoPropriedade: React.FC<ProposFormAcessoPropriedade> = ({
  clienteSelecionado,
  setClienteSelecionado,
  clienteSelecionadoId,
  setClienteSelecionadoId,
  targetKeys,
  codFunc,
  allClients,
  accessCliente,
  listDataCliente,
  limparInput,
  permissions,
  setLimparInput,
}) => {
  const [listarPropriedades, setListarPropriedades] = useState([])
  const [listarPropriedadesAtual, setListarPropriedadesAtual] = useState([])
  const [listDataClienteLiberado, setListDataClienteLiberado] = useState<ClienteItensTypes[]>([])
  const [targetKeysPro, setTargetKeysPro] = useState([])
  const [openClienteSelecionado, setOpenClienteSelecionado] = useState(false)
  const [loadingTransferPro, setLoadingTransferPro] = useState(false)
  const [noInput, setNoInput] = useState(false)
  const [loading, setLoading] = useState(true)

  const { codCliente } = useLogic()
  const tipoAcesso = localStorage.getItem('tipo-acesso')
  const codClienteLocal = localStorage.getItem('cod-cliente')
  const clientesAtivos = listDataCliente.filter((cliente) => cliente.status === 1)

  function validarClientesLiberados() {
    let listClient = clientesAtivos
    let useHandle = false

    if (allClients && codClienteLocal !== '') {
      //setListDataClienteLiberado(listDataCliente)
      //handleChangeAccessPro(codCliente)
      useHandle = true
    } else {
      let data = []

      for (let i = 0; i < targetKeys.length; i += 1) {
        const chave = targetKeys[i]
        const cliente = clientesAtivos.filter((rec) => rec.cod === Number(chave))

        if (cliente.length > 0) {
          data = [...data, ...cliente]
        }
      }
      if (data.length > 0 && accessCliente == 0) {
        //setListDataClienteLiberado(data)
        listClient = data
      }
      if (data.length === 0 && codClienteLocal == '' && allClients === false) {
        setNoInput(true)
        listClient = []
        errorAlert('O usuário não possui nenhum cliente liberado!');
      }

      if (!allClients && codClienteLocal !== '') {
        useHandle = true
      }
    }

    setListDataClienteLiberado(listClient)

    if (useHandle) {
      handleChangeAccessPro(codCliente)
    }
  }

  const handleChangeAccessPro = async (codClientePropriedade) => {
    loadingAlert({msg: 'Carregando...', id: 'carregando_prop'})
    
    if (tipoAcesso === 'cliente') {
      codClientePropriedade = localStorage.getItem('cod-cliente')
    }
    try {
      // const resp = await getPropriedade('', '', codCliente)
      const resp = await buscarPropriedadeCliente(codClientePropriedade)
      const respL = await getPropriedadeLiberada(codFunc, codClienteLocal, codClientePropriedade)
      const pegarPropriedadesAtuais = listarPropriedades[codClientePropriedade]

      setListarPropriedadesAtual(pegarPropriedadesAtuais)
      // setListDataPropriedade(resp.data.itens)
      setTargetKeysPro(respL.data.itens.map((rec) => String(rec.cod)))

      destroyAlert('carregando_prop');
    } catch (error) {}
  }

  const buscarPropriedadeCliente = async (codCliente: number) => {
    try {
      if (!listarPropriedades[codCliente]) {
        const propriedadeInfo = await getPropriedade('', '', codCliente)
        listarPropriedades[codCliente] = propriedadeInfo.data.itens
      }
      return listarPropriedades[codCliente]
    } catch (error) {
      console.error('Ocorreu um erro ao buscar a propriedade do cliente:', error)
      throw error
    }
  }

  const handleChangeMoveTransferPro = async (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    try {
      setLoadingTransferPro(true)
      if (direction === 'left') {
        let data = {
          cod_usuario: codFunc,
          propriedades_remover: moveKeys,
        }

        if (targetKeys.length <= 0) {
          warningAlert('É necessário ao menos uma propriedade por cliente com acesso ao usuário!');
          setLoadingTransferPro(false)

          return
        }

        await postUserPropriedade({ ...data })
        setTargetKeysPro(targetKeys)
        setLoadingTransferPro(false)
      } else {
        let data = {
          cod_usuario: codFunc,
          propriedades: moveKeys,
        }
        await postUserPropriedade({ ...data })
        setTargetKeysPro(targetKeys)
        setLoadingTransferPro(false)
      }
    } catch (error) {
      tryError(error)
      setLoadingTransferPro(false)
    }
  }

  useEffect(() => {
    if (clienteSelecionadoId > 0) {
      handleChangeAccessPro(clienteSelecionadoId)
    }
  }, [clienteSelecionadoId])

  useEffect(() => {
    if (targetKeys.length > 0) {
      validarClientesLiberados()
    }
  }, [targetKeys])

  useEffect(() => {
    if (allClients) {
      validarClientesLiberados()
    }
  }, [allClients])

  useEffect(() => {
    if (!limparInput) {
      setNoInput(false)
    }
  }, [limparInput])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 600)
  }, [])

  if (loading) {
    return <Carregando justifyContent='center' height={500} legenda='Carregando...' animation />
  }

  return (
    <ContainerAcesso>
      <div className='lines'>
        {codClienteLocal === '' && noInput === false && listDataClienteLiberado.length > 0 ? (
          <Select
            label='Selecione o cliente'
            icon={faUsers}
            placeholder='Selecione o cliente'
            value={clienteSelecionado}
            onChange={setClienteSelecionado}
            onChangeID={setClienteSelecionadoId}
            open={openClienteSelecionado}
            trigger={setOpenClienteSelecionado}
            items={listDataClienteLiberado}
            descricaoItem={'nome'}
            idItem={'cod'}
            disabled={permissions?.acesso === 1}
          />
        ) : null}
      </div>

      <div className='lines-sem-acesso'>
        {listarPropriedadesAtual.length != 0 ? (
          <div>
            <Transfer
              disabled={permissions?.acesso === 1}
              selectAllLabels={[
                <b>
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    style={{ opacity: '0.5', marginRight: '5px' }}
                  />
                  Propriedades sem acesso
                  <Spin
                    size='small'
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, display: !loadingTransferPro && 'none' }}
                        spin
                      />
                    }
                  />
                </b>,

                <b>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{ opacity: '0.5', marginRight: '5px' }}
                  />
                  Propriedades com acesso
                  <Spin
                    size='small'
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 24, display: !loadingTransferPro && 'none' }}
                        spin
                      />
                    }
                  />
                </b>,
              ]}
              listStyle={{
                height: 'calc(100vh - 500px)',
                width: '50%',
              }}
              style={{ maxWidth: '100%' }}
              rowKey={(item) => String(item.cod)}
              dataSource={listarPropriedadesAtual}
              render={(item) => (
                <span className='ant-transfer-list-content-item-text'>{item.nome}</span>
              )}
              targetKeys={targetKeysPro}
              onChange={handleChangeMoveTransferPro}
              className='custom-transfer'
            />
          </div>
        ) : null}
      </div>
    </ContainerAcesso>
  )
}

export default FormAcessoPropriedade

import React, { useEffect, useState } from 'react'

import { Button, Modal, Space, Table } from 'antd'

import { AxiosResponse } from 'axios'

import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Notification } from '../../../../Components/notification'

import { Container } from './styled'
import {
  delMoniEspecie,
  getMoniEspecie,
  MoniEspecieItens,
  TypeMoniEspecie,
} from '../../../../services/monitoramento/especie'
import MoniEspecie from '../../../../Components/monitoramento/especie'
import { pagination } from '../../../../Components/Default'
import { IconAlert } from '../../../../Components/iconsAlert'
import ViewPage from '../../../../Components/ViewPage'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import Icons from '../../../../Components/Icons'
import { useLogic } from '../../../../context/useLogic'
import { useHistory } from 'react-router'
import { successAlert } from '../../../../utils/alert'

const PageEspecie: React.FC = () => {
  const { checkSafra } = useLogic()
  const history = useHistory()

  const [visibleForm, setVisibleForm] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const [update, setUpdate] = useState(0)

  const [dataListEstadio, setDataListEstadio] = useState<MoniEspecieItens[]>([])

  const [dataTableRelative, setDataTableRelative] = useState<MoniEspecieItens[]>([])

  const listEstadio = async () => {
    setLoadingTable(true)
    const response: AxiosResponse<TypeMoniEspecie> = await getMoniEspecie({})

    setDataListEstadio(response.data.itens)
    setDataTableRelative(response.data.itens)
    setLoadingTable(false)
  }

  const handleClickNewEstadio = () => {
    setVisibleForm(true)
    setUpdate(0)
  }

  const handleClickEdit = (data) => {
    setVisibleForm(true)

    setUpdate(data)
  }

  const handlesPesquisa = (data) => {
    setLoadingTable(true)
    const dados = dataListEstadio
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setDataTableRelative(response)
      setLoadingTable(false)
    }, 500)
  }

  const handleClickTrash = (cod, nome) => {
    Modal.confirm({
      title: `Deseja remover o espécie ${nome}?`,
      icon: <IconAlert type='confirm' size={6} />,
      okText: 'Sim',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        const response = await delMoniEspecie(cod)

        if (response.status === 200) {
          successAlert('Espécie excluida com sucesso!');

          listEstadio()
        }
      },
    })
  }

  const handleCloseForm = (data) => {
    setVisibleForm(data)
    listEstadio()
    if (!data) {
      setUpdate(0)
    }
  }
  useEffect(() => {
    listEstadio()
  }, [])

  return (
    <Container>
      <ViewPage
        rotina={35}
        onPermission={setPermissions}
        title='Catálogo de pragas, doenças e plantas daninhas'
        btnClick={handleClickNewEstadio}
        search
        inputChange={handlesPesquisa}
        content={
          <MoniEspecie
            permissions={permissions}
            onClose={(data) => handleCloseForm(data)}
            visible={visibleForm}
            update={update}
          />
        }
      >
        <Table
          loading={loadingTable}
          scroll={{ y: 'calc(90vh - 255px)' }}
          bordered
          pagination={pagination}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataListEstadio.length}</b>
            </div>
          )}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickEdit(record.cod)
              },
            }
          }}
          size='small'
          columns={[
            {
              key: 1,
              dataIndex: 'nome',
              title: 'Nome',
            },
            {
              key: 2,
              dataIndex: 'nome_cientifico',
              title: 'Nome científico',
            },
            {
              key: 3,
              dataIndex: 'categoria_nome',
              title: 'Tipo',
              filters: [
                {
                  text: <span>PRAGA</span>,
                  value: 'PRAGA',
                },
                {
                  text: <span>PLANTA DANINHA</span>,
                  value: 'PLANTA DANINHA',
                },
                {
                  text: <span>DOENÇA</span>,
                  value: 'DOENÇA',
                },
              ],
              onFilter: (value: string, record) => record.categoria_nome.startsWith(value),
            },
            {
              key: 4,
              dataIndex: 'cod',
              width: '90px',
              align: 'center',
              title: 'Ações',
              render: (data, record) => (
                <Space size='small'>
                  <Button
                    shape='circle'
                    hidden={permissions?.acesso === 0 && permissions?.alteracao === 0}
                    onClick={() => handleClickEdit(data)}
                    icon={<Icons icon={permissions?.acesso === 1 ? faEye : faPen} />}
                  />
                  <Button
                    shape='circle'
                    onClick={() => handleClickTrash(data, record.nome)}
                    hidden={permissions?.exclusao === 0}
                    type='primary'
                    danger
                    icon={<Icons icon={faTrash} />}
                  />
                </Space>
              ),
            },
          ]}
          dataSource={dataTableRelative}
        />
      </ViewPage>
    </Container>
  )
}

export default PageEspecie

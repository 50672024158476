import React, { useCallback, useEffect, useState } from 'react'
import Input from '../../../CustomUi/input'
import { faKey, faLock } from '@fortawesome/free-solid-svg-icons'
import { postVerificarSenha } from '../../../../services/editarPerfil'
import { Col, message } from 'antd'
import { Notification } from '../../../notification'
import { api } from '../../../../services/api'
import ButtonCustom from '../../../ButtonCustom'
import Carregando from '../../../CustomUi/loading'
import { Link } from 'react-router-dom'

interface PropsSenha {
  senha?: string
  setSenha?: React.Dispatch<React.SetStateAction<string>>
  senhaAnterior?: string
  setSenhaAnterior?: React.Dispatch<React.SetStateAction<string>>
  novaSenha?: string
  setNovaSenha?: React.Dispatch<React.SetStateAction<string>>
  confirmarSenha?: string
  setConfirmarSenha?: React.Dispatch<React.SetStateAction<string>>
  erroSenha?: boolean
  erroSenhaAtual?: boolean
}

const FormSenha: React.FC<PropsSenha> = ({
  senha,
  senhaAnterior,
  setSenhaAnterior,
  setSenha,
  novaSenha,
  setNovaSenha,
  confirmarSenha,
  setConfirmarSenha,
  erroSenha,
  erroSenhaAtual
}) => {
  // async function validarSenha(data) {
  //   const senhaAtual = {
  //     senha: data,
  //   }

  //   const response = await postVerificarSenha(senhaAtual, setSenhaValidada)
  // }

  // const handleClickForgotPass = (e) => {
  //   setFormEsqueceu(true)
  // }

  // const handleSubmitReset = async (data) => {
  //   // @ts-ignore
  //   const email = emailrec
  //   const request = {
  //     email,
  //     urlRedefinir: `${process.env.REACT_APP_LINK}/auth/reset`,
  //   }
  //   setLoading(true)

  //   try {
  //     const response = await api.post('/login/recuperar/solicitar', request)

  //     if (response.status === 200) {
  //       setLoading(false)
  //       Notification({
  //         message: `Redefinição de senha Enviada para o Email ${request.email}`,
  //         type: 'success',
  //       })
  //       setFormEsqueceu(false)
  //       setEsqueceu(false)
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       setLoading(false)
  //       Notification({
  //         message: error.response.data.error,
  //         type: 'error',
  //       })
  //     }
  //     setFormEsqueceu(true)
  //     setEsqueceu(true)
  //   }
  // }

  // useEffect(() => {
  //   const minLength = 4
  //   const delay = 1800

  //   const handler = setTimeout(() => {
  //     if (senha.length >= minLength) {

  //       validarSenha(senha)
  //     }
  //   }, delay)

  //   return () => clearTimeout(handler)
  // }, [senha])

  return (
    <div className='line'>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {/* <div style={{ textAlign: 'center' }}>
          <h3 style={{ fontSize: '15px', margin: 0, padding: 0 }}>Alterar senha</h3>
        </div> */}

        <Input
          label='Senha atual'
          icon={faLock}
          placeholder='Confirme a sua senha atual'
          value={senha}
          onChange={setSenha}
          width={'100%'}
          height={'30%'}
          type='password'
          error={erroSenhaAtual}
          errorMessage='A senha digitada está incorreta.'
        />

        <div className='campo__nova__senha'>
          <Input
            label='Nova senha'
            icon={faKey}
            placeholder='Digite a nova senha'
            value={novaSenha}
            onChange={setNovaSenha}
            width={'100%'}
            height={'30%'}
            type='password'
            error={erroSenha}
            errorMessage='As senhas devem ser iguais. Por favor, verifique os campos.'
          />
          <Input
            label='Confirmar senha'
            icon={faKey}
            placeholder='Confirmar senha'
            value={confirmarSenha}
            onChange={setConfirmarSenha}
            width={'100%'}
            height={'30%'}
            type='password'
            error={erroSenha}
             errorMessage='As senhas devem ser iguais. Por favor, verifique os campos.'
          />
        </div>
      </div>
    </div>
  )
}

export default FormSenha

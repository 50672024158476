import {
  faBuildingWheat,
  faClose,
  faMapLocationDot,
  faTents,
} from '@fortawesome/free-solid-svg-icons'
import { DataF, GoogleMap, Marker, Polygon, Polyline } from '@react-google-maps/api'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  Modal,
  Progress,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Switch,
  Table,
} from 'antd'
import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { ChartTreeMap, PolygonSvg, PolySubStuff } from '../../../assets/svg'
import { getBounds, getBoundsNoArray, objOrder, tryError } from '../../../services/Afins'
import {
  AgrupamentoTalhaoItens,
  getAgrupamentoTalhao,
  TypeGetAgrupamentoTalhao,
} from '../../../services/agrupamentoTalhao'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
// @ts-ignore
import {
  getPropriedade,
  PropriedadeItens,
  TypeGetPropriedade,
} from '../../../services/Propriedades'
import { getSubArea, SubAreaItens, TypeSubArea } from '../../../services/subArea'
import {
  getTalhaoCultivavel,
  TalhaoCultivaveisItens,
  TypeGetTalhao,
} from '../../../services/TalhaoSafra'
import DatePicker from '../../DatePiker'
import Icons from '../../Icons'

import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import dayjs, { Dayjs } from 'dayjs'
import { groupBy, orderBy, uniq } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { v4 as uid } from 'uuid'
import { PropsGeraBooking } from '..'
import { useAccess } from '../../../context/useAccess'
import { useLogic } from '../../../context/useLogic'
import { RootState } from '../../../features'
import { setListDates } from '../../../features/Monitoramento/Monitoramento.listDates'
import { percent, reset } from '../../../features/Monitoramento/Monitoramento.progress'
import {
  getTipoConsultor,
  ItensTipoConsultorTypes,
} from '../../../services/consultoriaAgricola/tipoConsultor'
import {
  getMonitoramento,
  ItensMonitoramentoTypes,
  LeiturasMonitoramentoType,
} from '../../../services/monitoramento'
import {
  getMoniNivelControleItem,
  MoniNivelItemControleItens,
} from '../../../services/monitoramento/nivelControleItens'
import {
  getAgrupamentoPropriedades,
  ItensAgrupamentoPropriedadeTypes,
} from '../../../services/Propriedades/agrupamentoPropriedade'
import BookMonitoramento, { TypeBookMonitoramento, TypesBookData } from '../../BookMonitoramento'
import { Notification } from '../../notification'
import Svg from '../../talhasvg'
import WaterMarker from '../../WaterMarker'
import { Container, Tools } from './style'
import { destroyAlert, errorAlert, loadingAlert } from '../../../utils/alert'

interface NovoMonitoramentoProps {
 visible: boolean
  onClose: (data: boolean) => void
  propriedade: PropriedadeItens
  nivelControle: MoniNivelItemControleItens[]
  geraBooking?: PropsGeraBooking
  onLoadingBook?: (data: boolean) => void
  finishBook?: (data: boolean) => void
  bookApp: number
  resumir: boolean
  ordenar?: boolean
}

interface MonitoramentoMapaCalorMonitoramentoType {
  ponto: number
  lat: number
  lng: number
  altitude?: string
  valorApurado: string
  colmo?: string
  repeticao?: any[]
  estadio: string
  codEstadio: number
  codNivelControleItem: number
  codEspVari: string
  codTalhao?: number
  nomeTalhao: string
  nomesVariacao?: string
  talhao_observacao: string
  valorNivel: string
  dataInit: string
  dataFim: string
  numRepeticao?: number
  horaInit: string
  horaFim: string
  nomeUsuario: string
  nivel: LeiturasMonitoramentoType['nivel_controle_item']
}

interface MonitoramentoMapCalorType {
  codTalhao?: number
  estadio?: string
  codEstadio?: number
  monitoramento?: MonitoramentoMapaCalorMonitoramentoType[][]
}

interface ListLinhasProps {
  ativo: boolean
  coord: [[google.maps.LatLngAltitudeLiteral]]
  fill: string
  name: string
}

const svgCoordPropiedade = (data) => {
  const dados = data

  let talhoes = []
  for (let i = 0; i < dados.talhao.length; i += 1) {
    const talhao = dados.talhao[i]

    let temp = []

    for (let ii = 0; ii < talhao.talhao_kml.coordenadas.length; ii += 1) {
      const coord = talhao.talhao_kml.coordenadas[ii]

      temp = [...temp, coord]
    }
    talhoes = [...talhoes, temp]
  }

  return talhoes
}

const GerarBook: React.FC<NovoMonitoramentoProps> = ({
  visible = true,
  finishBook,
  onLoadingBook,
}) => {
  const [form] = Form.useForm()
  const [tipoMonitoramento, setTipoMonitoramento] = useState(null)
  const [propriedades, setPropriedades] = useState<PropriedadeItens[]>([])
  const [agrupamentoPro, setAgrupamentoPro] = useState<ItensAgrupamentoPropriedadeTypes[]>([])
  const [talhoes, setTalhoes] = useState<TalhaoCultivaveisItens[]>([])
  const [visibleLinhas, setVisibleLinhas] = useState(false)
  const [abrirGerar, setAbrirGerar] = useState(false)
  const { checkSafra } = useLogic()
  const listDates = useSelector((state: RootState) => state.listDates)
  const dispatch = useDispatch()
  const progress = useSelector((state: RootState) => state.progress)
  const { nameCliente, nameSafra, codCultura } = useAccess()
  const [mapType, setMapType] = useState<google.maps.MapTypeId>(google.maps.MapTypeId.SATELLITE)
  const [openBooking, setOpenBooking] = useState(false)
  const [dataBooking, setDataBooking] = useState<TypesBookData>({} as TypesBookData)
  const [loadingBook, setLoadingBook] = useState(false)
  const resumirBook = true
  const [visibleOp, setVisibleOp] = useState(true)
  const [dataConsultor, setDataConsultor] = useState<ItensTipoConsultorTypes[]>([])
  const [totalizarBook, setTotalizar] = useState(true)
  const [mensagem_errro_pontos, setMensagem_errro_pontos] = useState<false>(false);

  const history = useHistory()

  const [listLinhas, setListLinhas] = useState<ListLinhasProps>({
    name: '',
    ativo: true,
    fill: '#d0ff00',
    coord: [] as any,
  } as ListLinhasProps)

  const [subarea, setSubarea] = useState<SubAreaItens[]>([])
  const [agrupamento, setAgrupamento] = useState<AgrupamentoTalhaoItens[]>([])
  const [markerDefault, setMarkerDefault] = useState<
    {
      cod?: number
      nome?: string
      cod_talhao: number
      latitude: number
      longitude: number
    }[]
  >([])

  const [map, setMap] = useState<google.maps.Map>({} as google.maps.Map)
  const [polygonTalhao, setPolygonTalhao] = useState<google.maps.Polygon>({} as google.maps.Polygon)
  const [selecionado, setSelecionado] = useState(true)
  const [dataMonitoramentoBooking, setDataMonitoramentoBooking] = useState<{
    [key: string]: MonitoramentoMapaCalorMonitoramentoType[]
  }>({})

  const [dadosData, setDadosData] = useState<{
    [key: string]: MonitoramentoMapaCalorMonitoramentoType[]
  }>({})

  const [polygonAgrupamento, setPolygonAgrupamento] = useState<google.maps.Polygon>(
    {} as google.maps.Polygon
  )
  const [polygonAgrupamentoPro, setPolygonAgrupamentoPro] = useState<google.maps.Polygon>(
    {} as google.maps.Polygon
  )
  const [polygonPropriedade, setPolygonPropriedade] = useState<google.maps.Polygon>(
    {} as google.maps.Polygon
  )

  const [_, setRender] = useState<any>()
  const [polygonSubArea, setPolygonSubArea] = useState<google.maps.Polygon>(
    {} as google.maps.Polygon
  )
  const [marker, setMarker] = useState<google.maps.Marker[]>([])
  const [listCheckBoxArray, setListCheckBoxArray] = useState<string[]>([])
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const [nivelControle, setNivelControle] = useState<MoniNivelItemControleItens[]>([])
  const [dateBook, setDateBook] = useState('')
  const [dataBookingTemp, setDataBookingTemp] = useState<TypesBookData>({} as TypesBookData)
  const [resultados, setResultados] = useState([])
  const [propriedadeSelect, setPropriedadeSelect] = useState<PropriedadeItens>(null)
  const [agrupamentoSelect, setAgrupamentoSelect] = useState<ItensAgrupamentoPropriedadeTypes[]>([])
  const [openBook, setOpenBook] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dadosLeitura, setDadosLeitura] = useState([])
  const [dataAtual, setDataAtual] = useState(null)
  const [dataInicial, setDataInicial] = useState(null)
  const [codConsultor, setCodConsultor] = useState('')
  const [codPro, setCodPro] = useState<number>()

  const [getCenter, setCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral>({
    lat: -18.4590515,
    lng: -64.3517889,
  })
  const [getZoom, setZoom] = useState<number>(4)
  const codCliente = null
  const codSafra = localStorage.getItem('codSafra')
  const tipoAcesso = localStorage.getItem('tipo-acesso')

  const handleCloseDrawer = () => {
    history.push('/home')
    polygonPropriedade.setPaths([])
    polygonTalhao.setPath([])
    polygonAgrupamento.setPaths([])
    polygonAgrupamentoPro.setPaths([])
    polygonSubArea.setPath([])
    setTipoMonitoramento(null)
    setDataMonitoramentoBooking({})
    setListCheckBoxArray([])
    setIndeterminate(false)
    setCheckAll(false)
    setMarkerDefault([])
    setMarker([])
    setAgrupamentoPro([])
    destroyAlert();
    map.setCenter({ lat: 0, lng: 0 })
    map.setZoom(3)
    form.resetFields()
  }

  const calcPorcentagem = (qtda, total) => {
    return parseFloat(((qtda * 100) / total).toFixed(0))
  }

  const mediaMapaCalor = (apurado, nc) => {
    apurado = parseFloat(apurado)
    nc = parseFloat(nc)

    const porcentagem = (apurado * 100) / nc

    let valorFinal = '0.0'
    let cor = '#01ff00'

    if (porcentagem == 0) {
      valorFinal = '0.0'
      cor = '#01ff00'
    } else if (porcentagem > 0 && porcentagem < 100) {
      valorFinal = '0.2'
      cor = '#fcff02'
    } else if (porcentagem >= 100 && porcentagem < 300) {
      valorFinal = '0.8'
      cor = '#fcb700'
    } else if (porcentagem > 300) {
      valorFinal = '1.0'
      cor = '#fb0303'
    }

    return { valorFinal, cor }
  }

  const listMonitoramento = async (codPro: number, dadosPro) => {
    setCodPro(codPro)
    loadingAlert({msg: 'Carregando monitoramento...', id: 'list_moni'})
    try {
      dispatch(reset())
      const pagination = 100
      let total = 0
      let totalPage = 1
      let page = 1
      let dados: ItensMonitoramentoTypes[] = []

      for (let i = 0; i < totalPage; i += 1) {
        const resp = await getMonitoramento({
          codPropriedade: codPro,
          embed:
            'monitoramento_repeticoes,monitoramento_leituras,monitoramento_leituras_nivel_controle_item',
          pagination,
          page,
        })
        dados = [...dados, ...resp.data.itens]

        if (i === 0) {
          total = resp.data.total
          totalPage = Math.ceil(total / pagination)
        }
        if (page >= 1) {
          dispatch(percent(calcPorcentagem(page, totalPage)))
        }
        page += 1
      }

      dispatch(percent(calcPorcentagem(page, totalPage)))

      dadosPro.talhao.map((rec) => {
        rec.monitoramento = []
        return rec
      })

      for (let i = 0; i < dadosPro.talhao.length; i += 1) {
        const talhao = dadosPro.talhao[i]

        const moni = dados.filter((rec) => rec.cod_talhao === talhao.cod)

        talhao.monitoramento = [...talhao.monitoramento, ...moni]
      }

      let monitoramento: MonitoramentoMapCalorType[] = []
      let dadosLeituras = []
      for (let i = 0; i < dadosPro.talhao.length; i += 1) {
        const talhao = dadosPro.talhao[i]
        let leituras = []

        let fim: MonitoramentoMapCalorType = { monitoramento: [] }

        for (let ii = 0; ii < talhao.monitoramento.length; ii += 1) {
          const moni = talhao.monitoramento[ii]
          if (moni.monitoramento_repeticao.length > 0) {
            fim = {
              codTalhao: moni.cod_talhao,
              monitoramento: moni.monitoramento_repeticao.map((rep) => {
                return rep.monitoramento_leitura.map((lei) => {
                  return {
                    codMonitoramento: moni.cod,
                    ponto: moni.ponto,
                    colmo: moni.colmo,
                    valorApurado: lei.valor,
                    estadio: moni.estadio_nome,
                    codEstadio: moni.cod_estadio,
                    numRepeticao: rep.repeticao,
                    nomeTalhao: talhao.nome,
                    talhao_observacao: moni.talhao_observacao,
                    nomeUsuario: moni.usuario_nome,
                    dataInit: moni.data_inicio,
                    codNivelControleItem: lei.cod_nivel_controle_item,
                    dataFim: moni.data_final,
                    altitude: moni.altitude,
                    horaInit: rep.hora_inicio,
                    totalizar: lei.nivel_controle_item[0].totalizar,
                    horaFim: rep.hora_final,
                    lat: Number(moni.latitude),
                    lng: Number(moni.longitude),
                    valorNivel: lei.nivel_controle_item[0].valor,
                    codEspVari: `${lei.nivel_controle_item[0].especie_nome}, ${lei.nivel_controle_item[0].cod_especie}, ${lei.nivel_controle_item[0].cod_variacao_especie}, ${lei.nivel_controle_item[0].totalizar}`,
                    nivel: lei.nivel_controle_item,
                    inverter: lei.nivel_controle_item[0].inverter_escala,
                  }
                })
              }),
            }
            leituras = [...leituras, fim]
          }
        }
        monitoramento = [...monitoramento, ...leituras]
      }

      setDadosLeitura(dadosLeituras)
      handleData(monitoramento)
    } catch (error) {
      errorAlert('Propriedade não monitorada nos últimos 30 dias!', 5000, 'list_moni');
    }
  }

  const loadingAlertFake = () => {
    loadingAlert({msg: 'Gerando book...', id: 'gerando_book'});
    if (loadingBook === false && abrirGerar === false) {
      destroyAlert('gerando_book');
    }
  }

  const handleData = (dados) => {
    let monitoramentoAll: MonitoramentoMapaCalorMonitoramentoType[][] = []

    const data = dados?.map((rec) => {
      if (rec.monitoramento !== undefined) {
        monitoramentoAll = [
          ...monitoramentoAll,
          ...rec.monitoramento?.map((leitura) => {
            return leitura.map((item) => {
              return {
                codMonitoramento: item.codMonitoramento,
                codTalhao: rec.codTalhao,
                codNivelControleItem: item.codNivelControleItem,
                ponto: item.ponto,
                colmo: item?.colmo,
                lat: item.lat,
                lng: item.lng,
                altitude: item.altitude,
                repeticao: item.repeticao,
                valorApurado: item.valorApurado,
                nomeTalhao: item.nomeTalhao,
                talhao_observacao: item.talhao_observacao,
                valorNivel: item.valorNivel,
                dataInit: item.dataInit,
                numRepeticao: item.numRepeticao,
                dataFim: item.dataFim,
                horaInit: item.horaInit,
                horaFim: item.horaFim,
                nomeUsuario: item.nomeUsuario,
                nivel: item.nivel,
                nomePraga: item.nivel[0].especie_nome,
                codEspVari: `${item.nivel[0].especie_nome}, ${item.nivel[0].cod_especie}, ${item.nivel[0].cod_variacao_especie}, ${item.nivel[0].totalizar}`,
                totalizar: item.nivel[0].totalizar,
                estadio: item.estadio,
                codEstadio: item.codEstadio,
              }
            })
          }),
        ]
      }

      return monitoramentoAll
    })
    let tempPraga: { [key: string]: MonitoramentoMapaCalorMonitoramentoType[] }[] = []
    let groupPraga: { [key: string]: MonitoramentoMapaCalorMonitoramentoType[] }[] = []
    let datas: {}
    let totalizar = {}

    monitoramentoAll.forEach((rec) => {
      rec.forEach((item) => {
        const cod = item.codEspVari.split(', ')
        let codE = `${cod[0]}, ${cod[1]}`
        const codV = `${cod[0]}, ${cod[1]}, ${cod[2]}`

        const dadosFilter = nivelControle.filter((item) => item.totalizar === 1)
        let codsTotalizar = []
        dadosFilter.forEach((item, index) => {
          if (
            item.totalizar === 1 &&
            item.variacao_especie_nome !== null &&
            item.cod_especie === parseInt(cod[1])
          ) {
            if (!codsTotalizar.includes(item.variacao_especie_nome)) {
              codsTotalizar.push(item.variacao_especie_nome)
            }
          }
        })
        let nomesFormatados = codsTotalizar.join(' + ')

        let codEspTotal =
          nomesFormatados == ''
            ? (codE += `, ${cod[3]}`)
            : (codE += `, ${nomesFormatados}` + `, ${cod[3]}`)

        if (cod[3] === '1') {
          if (totalizar[codE] === undefined) {
            totalizar[codEspTotal] = [
              { ...item, codEspVari: (cod[0] += `, ${cod[1]},` + `${nomesFormatados}`) },
            ]
          } else {
            totalizar[codEspTotal].push({
              ...item,
              codEspVari: (cod[0] += `, ${cod[1]}` + `${nomesFormatados}`),
            })
          }
        } else {
          if (totalizar[codV] === undefined) {
            totalizar[codV] = [item]
          } else {
            totalizar[codV].push(item)
          }
        }
      })

      tempPraga = [...tempPraga, groupBy(rec, 'codEspVari')]

      datas = { ...datas, ...groupBy(rec, 'dataInit') }

      return { tempPraga, datas, totalizar }
    })

    let temp = {}
    tempPraga.forEach((rec) => {
      Object.entries(rec).forEach(([key, value]) => {
        if (temp[key] !== undefined) {
          value.forEach((ar) => {
            temp[key].push(ar)

            return temp[key]
          })
        } else {
          temp = { ...temp, ...{ [key]: value } }
        }
        return temp
      })
      groupPraga = [temp]
      return groupPraga
    })

    const dataAtual = Object.keys(datas).map((date) => date)
    const sortedDates = [...dataAtual].sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    const dateInicial =
      sortedDates.length > 0 ? dayjs(sortedDates[sortedDates.length - 1]) : dayjs()

    setDataAtual(dateInicial)
    setDataInicial(dateInicial)

    setDataMonitoramentoBooking(totalizar)
    setDadosData(totalizar)
    dispatch(setListDates(orderBy(Object.keys(datas).map((rec) => rec)).reverse()))
    destroyAlert();
  }

  const handleSubmitBook = (data: { consultorName: string; dateBook: Dayjs }) => {
    //@ts-ignore
    handleClickGerarBooking(data)
    loadingAlertFake()
    form.resetFields()
    setListCheckBoxArray([])
    setCheckAll(false)
  }

  const handleClickGerarBooking = (data: PropsGeraBooking, dataFim = null) => {
    try {
      if (data) {
        const dados: TypesBookData = dataFim === null ? dataBookingTemp : dataFim
        let monitoramento: TypesBookData['monitoramento'] = []

        for (let i = 0; i < dados.monitoramento.length; i += 1) {
          const book = dados.monitoramento[i]

          let checked = []
          if (dataFim === null) {
            checked = checkedList.filter((rec) => rec === book.especieVariacao)
          } else {
            checked = data.listEspecieVariacao.filter((rec) => rec === book.especieVariacao)
          }

          if (checked.length > 0) {
            monitoramento = [...monitoramento, book]
          }
        }

        dados.nameConsultor = data.consultorName
        dados.codTalhao = data.codTalhao

        dados.monitoramento = monitoramento
        dados.date = dayjs.isDayjs(data.dateBook)
          ? dayjs(data.dateBook).format('DD/MM/YYYY')
          : data.dateBook

        setTimeout(() => {
          setDataBooking(dados)
          setOpenBooking(true)
          destroyAlert();

          setAbrirGerar(false)
          if (dataFim === null) {
            form.resetFields(['dateBook', 'consultorName'])
          }
        }, 500)
      }
    } catch (error) {
      tryError(error)
    }
  }

  const handleDisableDate = (date: Dayjs) => {
    const resp = listDates.value.filter(
      (rec) => dayjs(rec).format('DD/MM/YYYY') === date.format('DD/MM/YYYY')
    )
    return resp.length <= 0
  }

  const handleChangeRadio = () => {
    setTipoMonitoramento(2)
  }

  const listPropriedade = async () => {
    loadingAlert({msg: 'Carregando...', id: 'get_propri'})

    const resp: AxiosResponse<TypeGetPropriedade> = await getPropriedade(
      '',
      '',
      codCliente,

      codSafra
    )
    const dados = resp.data.itens

    setPropriedades(dados)
    destroyAlert('get_propri');
  }

  const listConsultor = async () => {
    const resp = await getTipoConsultor({ codCliente: null })

    setDataConsultor(resp?.data?.itens)
  }

  const handleChangeAllPraga = (list: CheckboxValueType[] = []) => {
    if (list.length === listCheckBoxArray.length) {
      setCheckedList(list)
      form.setFieldsValue({ listEspecieVariacao: list })
      setCheckAll(true)
    } else {
      const menorComprimento = Math.min(list.length, listCheckBoxArray.length)
      const listaParcial = list.slice(0, menorComprimento)
      setCheckedList(listaParcial)
      form.setFieldsValue({ listEspecieVariacao: listaParcial })
      setCheckAll(listaParcial.length == list.length)
    }
  }

  const handleCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked == true) {
      setLoading(true)

      form.setFieldsValue({ listEspecieVariacao: e.target.checked ? listCheckBoxArray : [] })
      setCheckedList(e.target.checked ? listCheckBoxArray : [])
      setIndeterminate(false)
      setCheckAll(e.target.checked)
      setLoading(false)
    } else {
      form.setFieldsValue({ listEspecieVariacao: [] })
      setCheckedList([])
      setIndeterminate(false)
      setCheckAll(e.target.checked)
      setLoading(false)
    }
  }

  const addTalhaoPro = async (cod) => {
    const filtrarCod = propriedades.find((item) => item.cod === cod)
    const dados = filtrarCod

    const pro = dados
    const res = await getTalhaoCultivavel({ codPropriedade: pro.cod, embed: 'sub_area' })
    const cultivavel = res.data.itens

    let subArea = []

    cultivavel.forEach((rec) => {
      subArea = [...subArea, ...rec.sub_area]

      return subArea
    })

    if (subArea.length > 0) {
      cultivavel.push(...subArea)
    }
    for (let ii = 0; ii < cultivavel.length; ii += 1) {
      const tal = cultivavel[ii]

      if (tal.cod_talhao !== null) {
        tal.isSubArea = true
      } else {
        tal.isSubArea = false
      }

      // @ts-ignore
      tal.kml.coordenadas = tal.kml.coordenadas.map((item) => {
        return { lat: item.latitude, lng: item.longitude }
      })
    }

    pro.talhao = cultivavel

    setPropriedadeSelect(dados)
    listMonitoramento(cod, dados)
  }

  const listNivelControle = async () => {
    const resp = await getMoniNivelControleItem({ pagination: -1 })
    setNivelControle(resp.data.itens)
  }

  const listarAgrupamentoPro = async () => {
    const cod = JSON.parse(localStorage.getItem('codCliente'))
    const resp = await getAgrupamentoPropriedades({
      cod: cod,
      embed: 'talhoes',
    })
    const dados = resp.data.itens
    setAgrupamentoPro(dados)
  }

  const listTalhao = async (value) => {
    const resp: AxiosResponse<TypeGetTalhao> = await getTalhaoCultivavel({
      codPropriedade: value,
      embed: 'padrao_pontos',
    })
    setTalhoes(resp.data.itens)
    return resp.data.itens
  }

  const listAgrupamento = async (value) => {
    const resp: AxiosResponse<TypeGetAgrupamentoTalhao> = await getAgrupamentoTalhao({
      embed: 'talhao',
      codPropriedade: value,
      pagination: -1,
    })
    setAgrupamento(resp.data.itens)
  }

  const handleChangePropriedade = async (value) => {
    loadingAlert({msg: 'Carregando propriedade...', autoClose: 5000})
    const talhoes = await listTalhao(value)

    const coord = talhoes.map((item) => {
      return item.kml.coordenadas.map((talhao) => {
        return { lat: talhao.latitude, lng: talhao.longitude }
      })
    })
    map.fitBounds(getBounds(coord), { left: 120 })
    polygonPropriedade.setPaths(coord)

    listAgrupamento(value)
    addTalhaoPro(value)
  }

  const handleChangeAgrupamentoPro = async (cod) => {
    const dados = agrupamentoPro.filter((item) => item.cod === cod)
    const dadosFilter = dados[0].talhoes
    const coord = dadosFilter.map((item) => {
      return item.kml.coordenadas.map((item) => {
        return { lat: item.latitude, lng: item.longitude }
      })
    })

    const cods = dadosFilter.map((item) => item.cod_talhao)
    const data = talhoes.filter((item) => {
      for (let i = 0; i < cods.length; i += 1) {
        const cod = cods[i]
        if (cod === item.cod) {
          return true
        }
      }
      return false
    })

    map.fitBounds(getBounds(coord), { left: 120 })
    polygonAgrupamentoPro.setPaths(coord)
    listMoniAgrupamento(cod, agrupamentoPro[0]?.talhoes)
    setAgrupamentoSelect(agrupamentoPro)
  }

  const listMoniAgrupamento = async (codAgru: number, dadosAgru) => {
    try {
      dispatch(reset())
      loadingAlert({msg: 'Carregando monitoramento...', id: 'carreg_monito', autoClose: 7000})
      const pagination = 100
      let total = 0
      let totalPage = 1
      let page = 1
      let dados: ItensMonitoramentoTypes[] = []

      for (let i = 0; i < totalPage; i += 1) {
        const resp = await getMonitoramento({
          codAgrupamentoPropriedade: codAgru,
          embed:
            'monitoramento_repeticoes,monitoramento_leituras,monitoramento_leituras_nivel_controle_item',
          pagination,
          page,
        })
        dados = [...dados, ...resp.data.itens]

        if (i === 0) {
          total = resp.data.total
          totalPage = Math.ceil(total / pagination)
        }
        if (page >= 1) {
          dispatch(percent(calcPorcentagem(page, totalPage)))
        }
        page += 1
      }

      dispatch(percent(calcPorcentagem(page, totalPage)))

      dadosAgru.map((rec) => {
        rec.monitoramento = []
        return rec
      })

      for (let i = 0; i < dadosAgru.length; i += 1) {
        const talhao = dadosAgru[i]

        const moni = dados.filter((rec) => rec.cod_talhao === talhao.cod)

        talhao.monitoramento = [...talhao.monitoramento, ...moni]
      }

      let monitoramento: MonitoramentoMapCalorType[] = []
      for (let i = 0; i < dadosAgru.length; i += 1) {
        const talhao = dadosAgru[i]
        let leituras = []

        let fim: MonitoramentoMapCalorType = { monitoramento: [] }

        for (let ii = 0; ii < talhao.monitoramento.length; ii += 1) {
          const moni = talhao.monitoramento[ii]
          if (moni.monitoramento_repeticao.length > 0) {
            fim = {
              codTalhao: moni.cod_talhao,
              monitoramento: moni.monitoramento_repeticao.map((rep) => {
                return rep.monitoramento_leitura.map((lei) => {
                  return {
                    ponto: moni.ponto,
                    colmo: moni.colmo,
                    valorApurado: lei.valor,
                    estadio: moni.estadio_nome,
                    codEstadio: moni.cod_estadio,
                    numRepeticao: rep.repeticao,
                    nomeTalhao: talhao.nome,
                    talhao_observacao: talhao.talhao_observacao,
                    nomeUsuario: moni.usuario_nome,
                    dataInit: moni.data_inicio,
                    codNivelControleItem: lei.cod_nivel_controle_item,
                    dataFim: moni.data_final,
                    altitude: moni.altitude,
                    horaInit: rep.hora_inicio,
                    totalizar: lei.nivel_controle_item[0].totalizar,
                    horaFim: rep.hora_final,
                    lat: Number(moni.latitude),
                    lng: Number(moni.longitude),
                    valorNivel: lei.nivel_controle_item[0].valor,
                    codEspVari: `${lei.nivel_controle_item[0].especie_nome}, ${lei.nivel_controle_item[0].cod_especie}, ${lei.nivel_controle_item[0].cod_variacao_especie}, ${lei.nivel_controle_item[0].totalizar}`,
                    nivel: lei.nivel_controle_item,
                    inverter: lei.nivel_controle_item[0].inverter_escala,
                  }
                })
              }),
            }
            leituras = [...leituras, fim]
          }
        }
        monitoramento = [...monitoramento, ...leituras]
      }
      handleData(monitoramento)
    } catch (error) {
      errorAlert('Propriedade não monitorada!', 5000, 'carreg_monito');
    }
  }

  const handleChangeTalhao = async (value) => {
    const resp: AxiosResponse<TypeGetTalhao> = await getTalhaoCultivavel({
      cod: value,
      embed: 'sub_area,padrao_pontos',
    })

    const dados = resp.data.itens[0]
    const coord = dados.kml.coordenadas.map((item) => {
      return { lat: item.latitude, lng: item.longitude }
    })

    if (tipoMonitoramento === 5) {
      form.resetFields(['cod_subarea'])
      polygonSubArea.setPath([])
    }

    polygonTalhao.setPath(coord)
    setSubarea(dados.sub_area)

    map.fitBounds(getBoundsNoArray(coord), { left: 320 })
  }

  const handleChangeTalhaoBook = (data: any[]) => {
    if (data.length > 0) {
      let dataBook = []
      const dados = JSON.parse(JSON.stringify(geraDataBooking(dateBook)))

      dados.monitoramento.forEach((rec) => {
        let filter = []

        data.forEach((d) => {
          filter = [...filter, ...rec.data.filter((item) => d === item.codTalhao)]
        })
        const temp = data.length <= 0 ? rec.data : filter

        if (temp.length > 0) {
          rec.data = temp
          dataBook = [...dataBook, rec]
        }
      })
      
      dados.monitoramento = [...dataBook]
      if ((dados?.monitoramento).length === 0) {
        errorAlert('É necessário pelo menos 3 pontos monitorados para gerar o mapa de calor!');
      }
      
      setDataBookingTemp({ ...dados })
      handleChangeAllPraga(dados.monitoramento.map((rec) => rec.especieVariacao))
    }

    if (data.length == 0) {
      form.setFieldsValue({ listEspecieVariacao: [] })
      setCheckedList([])
      setCheckAll(false)
      const geraBookT = false
      setDataBookingTemp({})
      handleChangeDataBooking(geraBookT, dateBook)
      setIndeterminate(false)
    }
  }

  const handleChangeDataBooking = async (geraBook = false, dateString) => {
    setLoading(true)
    form.setFieldsValue({ listEspecieVariacao: [] })
    setCheckedList([])
    setCheckAll(false)
    setIndeterminate(false)
    const dataFim = { ...geraDataBooking(dateString) }
    filterDataTalhao(dateString)
    form.resetFields(['codTalhao'])
    setDateBook(dateString)
    setDataBookingTemp(dataFim)

    setListCheckBoxArray(dataFim.monitoramento.map((rec) => rec.especieVariacao))
    setCheckedList(dataFim.monitoramento.map((rec) => rec.especieVariacao))
    form.setFieldsValue({
      listEspecieVariacao: dataFim.monitoramento.map((rec) => rec.especieVariacao),
    })
    setCheckAll(true)
    setLoading(false)
  }

  const geraDataBooking = (date) => {
    let tempPraga: TypesBookData['monitoramento'] = []

    if (dayjs.isDayjs(date)) {
      date = dayjs(date).format('DD/MM/YYYY')
    }

    Object.entries(dataMonitoramentoBooking).forEach(([keyD, value]) => {
      //@ts-ignore

      const cont = value.filter((rec) => dayjs(rec.dataInit).format('DD/MM/YYYY') === date)

      if (cont.length > 0) {
        let dataPraga: TypeBookMonitoramento['data'] = []

        const groupTalhao = groupBy(cont, 'codTalhao')

        function verifiqueQuantidadeDePontos(talhoesData: any): string[] {
          if (talhoesData===undefined || talhoesData===null || talhoesData?.length === 0) {
            return []
          }
          return Object.keys(talhoesData)
              .filter(key => talhoesData[key].length < 1)
              .map(key => talhoesData[key][0].nomeTalhao);
      }

      let talhoes_menor_que_3 = verifiqueQuantidadeDePontos(groupTalhao) ?? [];

        if (talhoes_menor_que_3.length > 0) {
          errorAlert(`Talhão "${talhoes_menor_que_3?.[0]}" precisa de ao menos 1 ponto para gerar o mapa!`, 5000, 'auto', 'erro_pontos')
        }

        Object.entries(groupTalhao).forEach(([k, rec]) => {
          const pontosIdw = groupBy(rec, 'codMonitoramento')
          const pontosNovos = Object.entries(pontosIdw).map(([chave, v]) => v)
          // if (pontosNovos.length > 2) {
          if (pontosNovos.length > 0) {
            const key = uid()
            const talhao = propriedadeSelect.talhao.find((item) => item.cod === Number(k))

            let values = []
            let arrayValue = []
            let estadios = []
            let idw = []

            Object.entries(pontosIdw).map(([chave, v]) => {
              const gRep: any = groupBy(v, 'numRepeticao')

              const repeticao = Object.entries(gRep).map(([key, val]: [any, any]) => {
                const valorApurado = val.reduce((a, b) => Number(a) + Number(b.valorApurado), 0)
                return {
                  numRepeticao: key,
                  valorApurado,
                }
              })
              values = [...values, ...v.map((item) => Number(item.valorApurado))]
              estadios = [...estadios, ...v.map((item) => item.estadio)]
              arrayValue = v.map((item) => Number(item.valorApurado))

              const valorMedia =
                resumirBook === true
                  ? arrayValue.reduce((prev, a) => prev + a, 0) / repeticao.length
                  : arrayValue.reduce((prev, a) => prev + a, 0) / arrayValue.length

              idw = [
                ...idw,
                [
                  v[0].lat,
                  v[0].lng,
                  valorMedia === 0
                    ? '0'
                    : mediaMapaCalor(valorMedia, Number(v[0].valorNivel).toFixed(1)).valorFinal,
                  chave,
                  valorMedia,
                ],
              ]
            })

            estadios = uniq(estadios)

            dataPraga = [
              ...dataPraga,
              {
                key,
                talhao: talhao?.nome,
                codTalhao: talhao?.cod,
                talhao_observacao: talhao?.observacao,
                estadios,
                area: talhao?.area,
                min: String(Math.min(...values)),
                max: String(Math.max(...values)),
                med: (values.reduce((a, b) => a + b) / values.length).toFixed(1),
                polygon: talhao?.kml.coordenadas.map((rec) => {
                  return { lat: rec.lat, lng: rec.lng }
                }),
                idw,
              },
            ]
          }
        })

        tempPraga = [...tempPraga, { especieVariacao: keyD, data: [...dataPraga] }]
        tempPraga = tempPraga.sort((a, b) => a.especieVariacao.localeCompare(b.especieVariacao))

        return tempPraga
      }
    })

    const dataFim: TypesBookData = {
      nameCliente: nameCliente,
      codTalhao: form.getFieldValue('codTalhao'),
      namePropriedade: propriedadeSelect.nome,
      nameConsultor: '',
      date: date,
      monitoramento: [...tempPraga.filter((item) => item.data.length > 0)],
    }

    return dataFim
  }

  // const organizarDados = (dados) => {
  //   const dadosOrganizados: TypesBookData['monitoramento'] = []

  //   dados.forEach((especie) => {
  //     especie.data.forEach((talhao) => {
  //       const novoItem = {
  //         data: [talhao],
  //         especieVariacao: especie.especieVariacao,
  //       }

  //       dadosOrganizados.push(novoItem)
  //     })
  //   })

  //   // Ordena os dados finais com base no nome do t
  //   dadosOrganizados.sort((a, b) => a.data[0].talhao.localeCompare(b.data[0].talhao))

  //   // Atualiza o estado com os dados organizados
  //   setFiltroOrdenarTalhao(dadosOrganizados)
  // }

  const filterDataTalhao = (data) => {
    const dadosExtraidos = []

    // Iterar sobre os objetos no estado
    for (const chave in dadosData) {
      if (dadosData.hasOwnProperty(chave)) {
        const arrayDeDados = dadosData[chave]
        const cont = arrayDeDados.filter((rec) => dayjs(rec.dataInit).format('DD/MM/YYYY') === data)
        // Adicionar os dados ao array de dados extraídos
        dadosExtraidos.push(...cont)
      }
    }

    const removerDuplicados = (array) => {
      const idsUnicos = new Set()
      return array.filter((item) => {
        if (!idsUnicos.has(item.codTalhao)) {
          idsUnicos.add(item.codTalhao)
          return true
        }
        return false
      })
    }

    const dadosFiltrados = removerDuplicados(dadosExtraidos)

    const dadosFilterFinal = dadosFiltrados.map((item) => {
      const coordenadas =
        tipoMonitoramento == 1
          ? agrupamentoSelect[0]?.talhoes.find((coord) => coord.cod === item.codTalhao)
          : propriedadeSelect.talhao.find((coord) => coord.cod === item.codTalhao)

      return {
        cod: item.codTalhao,
        data: dayjs(item.dataInit).format('DD/MM/YYYY'),
        nome: item.nomeTalhao,
        coordenadas: coordenadas.kml.coordenadas,
      }
    })

    setResultados(dadosFilterFinal)
  }

  const handleChangeAgrupamento = async (value) => {
    const resp: AxiosResponse<TypeGetAgrupamentoTalhao> = await getAgrupamentoTalhao({
      embed: 'talhao,padrao_pontos',
      cod: value,
      pagination: -1,
    })
    const dados: AgrupamentoTalhaoItens['talhao'] = resp.data.itens[0].talhao
    const coord = dados.map((item) => {
      return item.talhao_kml.coordenadas.map((item) => {
        return { lat: item.latitude, lng: item.longitude }
      })
    })

    const cods = dados.map((item) => item.cod_talhao)
    const data = talhoes.filter((item) => {
      for (let i = 0; i < cods.length; i += 1) {
        const cod = cods[i]
        if (cod === item.cod) {
          return true
        }
      }
      return false
    })

    map.fitBounds(getBounds(coord), { left: 120 })
    polygonAgrupamento.setPaths(coord)
  }

  const handleChangeSubarea = async (value) => {
    const resp: AxiosResponse<TypeSubArea> = await getSubArea({
      cod: value,
      embed: 'padrao_pontos',
    })

    const dados = subarea.find((item) => item.cod === value)
    const coord = dados.kml.coordenadas.map((item) => {
      return { lat: item.latitude, lng: item.longitude }
    })
    polygonSubArea.setPath(coord)
    map.fitBounds(getBoundsNoArray(coord), { left: 120 })
    setRender([])
  }

  // const handleUploadKml = async (
  //   file: UploadChangeParam,
  //   type: 'linha' | 'pTalhao' | 'pSubArea'
  // ) => {
  //   if (file.file.status === 'done') {
  //     const domKml = parseXml(await fileTypeText(file.file.originFileObj))
  //     const dataGeoJson = kml(domKml)

  //     const newArray = {
  //       ...listLinhas,
  //       name: '',
  //       ativo: true,
  //       coord: [] as any,
  //     }

  //     let newPontos: {
  //       cod: any
  //       latitude: any
  //       longitude: any
  //       nome: string
  //       cod_talhao: number
  //     }[] = []
  //     let pontosFora = 0

  //     switch (type) {
  //       case 'linha':
  //         dataGeoJson.features.forEach((data) => {
  //           if (data.geometry.type === 'LineString' || data.geometry.type === 'Polyline') {
  //             let newLatLng = []
  //             data.geometry.coordinates.forEach((latLng) => {
  //               newLatLng = [...newLatLng, { lat: latLng[1], lng: latLng[0] }]
  //               return { newLatLng }
  //             })
  //             const temp = newLatLng

  //             newArray.coord = [...newArray.coord, temp]
  //           }

  //           return newArray
  //         })
  //         setListLinhas(newArray)
  //         break
  //       case 'pTalhao':
  //         dataGeoJson.features.forEach((item) => {
  //           if (item.geometry.type === 'Point') {
  //             const latLng = {
  //               lat: Number(item.geometry.coordinates[1]),
  //               lng: Number(item.geometry.coordinates[0]),
  //             }
  //             if (google.maps.geometry.poly.containsLocation(latLng, polygonTalhao)) {
  //               newPontos = [
  //                 ...newPontos,
  //                 {
  //                   cod: null,
  //                   latitude: item.geometry.coordinates[1],
  //                   longitude: item.geometry.coordinates[0],
  //                   nome: item.properties?.name,
  //                   cod_talhao: form.getFieldValue('cod_talhao'),
  //                 },
  //               ]
  //             } else {
  //               pontosFora += 1
  //             }
  //           }
  //           return { newPontos, pontosFora }
  //         })

  //         if (pontosFora > 0) {
  //           message.destroy()
  //           message.warning(`${pontosFora} pontos estão fora do talhão`)
  //         }
  //         setMarkerDefault([...markerDefault, ...newPontos])
  //         break
  //       case 'pSubArea':
  //         break

  //       default:
  //         break
  //     }
  //   }
  // }

  // const dummyRequest = (options) => {
  //   const { onSuccess } = options
  //   setTimeout(() => {
  //     onSuccess('done')
  //   }, 500)
  // }
  useEffect(() => {
    const codPropriedade = form.getFieldValue('cod_propriedade')
    switch (tipoMonitoramento) {
      case 1: // agrupamento propriedade
        polygonAgrupamentoPro.setOptions({ fillColor: 'cyan', strokeColor: 'cyan' })

        polygonPropriedade.setOptions({ clickable: false })
        polygonTalhao.setOptions({ clickable: false })
        polygonAgrupamentoPro.setOptions({ clickable: true })
        polygonSubArea.setOptions({ clickable: false })

        polygonTalhao.setPath([])
        polygonSubArea.setPath([])
        polygonPropriedade.setPaths([])

        form.resetFields(['cod_subarea', 'cod_agrupamento', 'cod_talhao', 'cod_propriedade'])

        break

      case 2: // propriedade
        polygonPropriedade.setOptions({ fillColor: 'cyan', strokeColor: 'cyan' })
        if (codPropriedade !== undefined) {
          map.fitBounds(getBounds(polygonPropriedade.getPaths().getArray()), { left: 120 })
        }

        polygonPropriedade.setOptions({ clickable: true })
        polygonTalhao.setOptions({ clickable: false })
        polygonAgrupamento.setOptions({ clickable: false })

        polygonSubArea.setOptions({ clickable: false })

        polygonTalhao.setPath([])
        polygonSubArea.setPath([])
        polygonAgrupamento.setPaths([])
        polygonAgrupamentoPro.setPaths([])

        form.resetFields(['cod_subarea', 'cod_agrupamento', 'cod_talhao', 'cod_propriedade', 'cod'])
        setMarkerDefault([])

        break

      case 3: // agrupamento
        polygonPropriedade.setOptions({
          fillColor: 'white',
          strokeColor: 'white',
          clickable: false,
        })
        polygonAgrupamento.setOptions({ fillColor: 'cyan', strokeColor: 'cyan' })

        polygonTalhao.setOptions({ clickable: false })
        polygonAgrupamento.setOptions({ clickable: true })
        polygonSubArea.setOptions({ clickable: false })

        polygonTalhao.setPath([])
        polygonSubArea.setPath([])
        polygonAgrupamentoPro.setPaths([])

        form.resetFields(['cod_subarea', 'cod_agrupamento', 'cod_talhao', 'cod'])
        setMarkerDefault([])

        break

      case 4: // talhao
        polygonPropriedade.setOptions({ fillColor: 'white', strokeColor: 'white' })
        polygonTalhao.setOptions({ fillColor: 'cyan', strokeColor: 'cyan' })

        polygonPropriedade.setOptions({ clickable: false })
        polygonTalhao.setOptions({ clickable: true })
        polygonAgrupamento.setOptions({ clickable: false })
        polygonSubArea.setOptions({ clickable: false })

        polygonAgrupamento.setPaths([])
        polygonSubArea.setPath([])
        polygonAgrupamentoPro.setPaths([])

        form.resetFields(['cod_subarea', 'cod_agrupamento', 'cod_talhao', 'cod'])
        setMarkerDefault([])

        break

      case 5: // subarea
        polygonPropriedade.setOptions({ fillColor: 'white', strokeColor: 'white' })
        polygonTalhao.setOptions({ fillColor: 'cyan', strokeColor: 'cyan' })
        polygonSubArea.setOptions({ fillColor: 'orange', strokeColor: 'orange' })

        polygonPropriedade.setOptions({ clickable: false })
        polygonTalhao.setOptions({ clickable: false })
        polygonAgrupamento.setOptions({ clickable: false })
        polygonSubArea.setOptions({ clickable: true })

        polygonAgrupamento.setPaths([])
        polygonTalhao.setPath([])
        polygonAgrupamentoPro.setPaths([])

        form.resetFields(['cod_subarea', 'cod_agrupamento', 'cod_talhao', 'cod'])
        setMarkerDefault([])

        break

      default:
        break
    }
  }, [tipoMonitoramento])

  // useEffect(() => {
  // 
  //   if (dataMonitoramentoNoGroup.length > 0) {

  //   }
  // }, [dataMonitoramentoNoGroup, groupTotalizar])

  useEffect(() => {
    if (abrirGerar == false) {
      dispatch(reset())
    }
  }, [abrirGerar])

  useEffect(() => {
    setTimeout(() => {
      handleChangeRadio()
    }, 500)
  }, [])

  useEffect(() => {
    const idDaSafra = checkSafra('/monitoramento/gerarbook')

    if (idDaSafra === true) {
      listPropriedade()
      listarAgrupamentoPro()
      listNivelControle()
      listConsultor()
    }

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])

  useEffect(() => {
    onLoadingBook?.(loadingBook)
  }, [loadingBook])

  // useEffect(() => {
  //   if (loading == true) {
  //   } else {
  //     message.destroy()
  //   }
  // }, [loading])

  useEffect(() => {
    if (abrirGerar === true) {
      handleChangeDataBooking(false, dayjs(dataInicial).format('DD/MM/YYYY'))
    }
  }, [abrirGerar])

  return (
    <div style={{ position: 'absolute', overflow: 'none' }}>
      <Form form={form} size='middle' layout='vertical' wrapperCol={{ span: 24 }}>
        <Drawer
          open={visibleOp}
          closeIcon={false}
          onClose={handleCloseDrawer}
          placement='bottom'
          headerStyle={{ display: 'none' }}
          width='100%'
          contentWrapperStyle={{ minHeight: '100%' }}
          bodyStyle={{ padding: 0 }}
        >
          <Container>
            {/* @ts-ignore */}
            <GoogleMap
              onLoad={setMap}
              mapContainerStyle={{
                width: '100%',
                height: '100vh',
              }}
              options={{
                mapTypeId: 'hybrid',
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: false,
              }}
              center={getCenter}
              zoom={getZoom}
            >
              <Polygon options={{ zIndex: 9 }} onLoad={setPolygonPropriedade} />
              <Polygon options={{ zIndex: 10 }} onLoad={setPolygonTalhao} />
              <Polygon options={{ zIndex: 20 }} onLoad={setPolygonAgrupamento} />
              <Polygon options={{ zIndex: 40 }} onLoad={setPolygonAgrupamentoPro} />
              <Polygon options={{ zIndex: 30 }} onLoad={setPolygonSubArea} />

              {listLinhas?.coord.map((item) => (
                <Polyline
                  visible={visibleLinhas}
                  path={item}
                  options={{ strokeColor: listLinhas.fill, strokeWeight: 1, zIndex: 40 }}
                />
              ))}

              {markerDefault.map((item, i) => (
                // @ts-ignore
                <Marker draggable position={{ lat: item.latitude, lng: item.longitude }} />
              ))}

              <Tools>
                <Card
                  size='small'
                  style={{ backgroundColor: '#fff', borderRadius: '', width: '60%' }}
                  actions={[
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginLeft: '1.3rem',
                        marginRight: '1rem',
                      }}
                    >
                      <div
                        style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                      >
                        <Button
                          onClick={handleCloseDrawer}
                          type='primary'
                          style={{ backgroundColor: 'red', color: 'white' }}
                        >
                          Sair
                        </Button>
                        {progress.value >= 100 ? (
                          <Button
                            onClick={() => setAbrirGerar(true)}
                            type='primary'
                            style={{ marginRight: 8 }}
                          >
                            Gerar book
                          </Button>
                        ) : null}
                      </div>
                    </div>,
                  ]}
                >
                  {/* <Form.Item name='tipo' style={{ display: 'flex', justifyContent: 'center' }}>
                    <Radio.Group
                      onChange={handleChangeRadio}
                      optionType='button'
                      size='middle'
                      buttonStyle='solid'
                      style={{
                        display: 'flex',
                      }}
                      // options={[
                      //   {
                      //     label: (
                      //       <div className='btn-radio'>
                      //         Agrupamento de propriedades <Icons icon={faTents} size='2x' />
                      //       </div>
                      //     ),
                      //     value: 1,
                      //   },
                      //   {
                      //     label: (
                      //       <div className='btn-radio'>
                      //         Propriedade <Icons icon={faBuildingWheat} size='2x' />
                      //       </div>
                      //     ),
                      //     value: 2,
                      //   },
                      //   // {
                      //   //   label: (
                      //   //     <div className='btn-radio'>
                      //   //       Agrupamento <ChartTreeMap size={28} />
                      //   //     </div>
                      //   //   ),
                      //   //   value: 3,
                      //   // },
                      //   // {
                      //   //   label: (
                      //   //     <div className='btn-radio'>
                      //   //       Talhão <PolygonSvg size={31} />
                      //   //     </div>
                      //   //   ),
                      //   //   value: 4,
                      //   // },
                      //   // {
                      //   //   label: (
                      //   //     <div className='btn-radio'>
                      //   //       Subárea <PolySubStuff size={38} />
                      //   //     </div>
                      //   //   ),
                      //   //   value: 5,
                      //   // },
                      // ]}
                    />
                  </Form.Item> */}

                  {tipoMonitoramento === 2 && (
                    <Form.Item name='cod_propriedade' label='Propriedade'>
                      <Select
                        onChange={handleChangePropriedade}
                        showSearch
                        optionFilterProp='children'
                      >
                        {propriedades.map((item) => (
                          <Select.Option key={item.cod} value={item.cod}>
                            {item.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {tipoMonitoramento === 1 && selecionado === false ? (
                    <Form.Item name='cod' label='Agrupamento de propriedades'>
                      <Select
                        onChange={handleChangeAgrupamentoPro}
                        showSearch
                        optionFilterProp='children'
                      >
                        {agrupamentoPro.map((item) => (
                          <Select.Option key={item.cod} value={item.cod}>
                            {item.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null}

                  {tipoMonitoramento === 4 || (tipoMonitoramento === 5 && selecionado === false) ? (
                    <Form.Item name='cod_talhao' label='Talhão'>
                      <Select
                        onChange={handleChangeTalhao}
                        optionLabelProp='label'
                        optionFilterProp='label'
                        showSearch
                      >
                        {talhoes.map((item) => (
                          <Select.Option key={item.cod} value={item.cod} label={item.nome}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                gap: '1rem',
                              }}
                            >
                              {/* @ts-ignore */}
                              <Svg fill='green' coordinates={item.kml.coordenadas} />
                              <Divider type='vertical' /> {item.nome}
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null}

                  {tipoMonitoramento === 5 && selecionado === false ? (
                    <Form.Item name='cod_subarea' label='Subárea'>
                      <Select
                        onChange={handleChangeSubarea}
                        optionLabelProp='label'
                        optionFilterProp='label'
                        showSearch
                      >
                        {subarea.map((item) => (
                          <Select.Option key={item.cod} value={item.cod} label={item.nome}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                gap: '1rem',
                              }}
                            >
                              {/* @ts-ignore */}
                              <Svg fill='green' coordinates={item.kml.coordenadas} />
                              <Divider type='vertical' /> {item.nome}
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null}

                  {tipoMonitoramento === 3 && selecionado === false ? (
                    <Form.Item name='cod_agrupamento' label='Agrupamento de talhão'>
                      <Select
                        onChange={handleChangeAgrupamento}
                        optionLabelProp='label'
                        optionFilterProp='label'
                      >
                        {agrupamento.map((item) => {
                          const cord = svgCoordPropiedade(item)
                          return (
                            <Select.Option key={item.cod} value={item.cod} label={item.nome}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '100%',
                                  gap: '1rem',
                                }}
                              >
                                {/* @ts-ignore */}
                                <Svg multi fill='green' coordinates={cord} />
                                <Divider type='vertical' /> {item.nome}
                              </div>
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  ) : null}

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'end',
                      justifyContent: 'end',
                    }}
                  >
                    <Progress status='normal' percent={progress.value} strokeColor='green' />
                  </div>

                  {markerDefault.length > 0 ? (
                    <Table
                      dataSource={markerDefault}
                      pagination={false}
                      rowClassName='table-row'
                      style={{ maxHeight: '60%', overflow: 'auto' }}
                      scroll={{ y: 'calc(100vh - 410px)' }}
                      size='small'
                      columns={[
                        {
                          key: 1,
                          width: 45,
                          align: 'center',
                          title: ' ',
                          render: () => (
                            <Button ghost size='small' icon={<Icons icon={faMapLocationDot} />} />
                          ),
                        },
                        {
                          key: 2,
                          dataIndex: 'nome',
                          title: 'Nome',
                        },
                        {
                          key: 3,
                          dataIndex: 'latitude',
                          title: 'Lat',
                          render: (data) => <b style={{ fontSize: '9px' }}>{data}</b>,
                        },
                        {
                          key: 4,
                          dataIndex: 'longitude',
                          title: 'Long',
                          render: (data) => <b style={{ fontSize: '9px' }}>{data}</b>,
                        },
                      ]}
                    />
                  ) : null}
                </Card>
              </Tools>

              <WaterMarker placement='bottomRight' />
            </GoogleMap>
          </Container>

          <Modal
            forceRender
            open={abrirGerar}
            onCancel={() => {
              setAbrirGerar(false)
              polygonPropriedade.setPaths([])
              polygonTalhao.setPath([])
              polygonAgrupamento.setPaths([])
              polygonAgrupamentoPro.setPaths([])
              polygonSubArea.setPath([])
              setDataMonitoramentoBooking({})
              setCheckAll(false)
              setListCheckBoxArray([])
              setSelecionado(true)
              setMarkerDefault([])
              setMarker([])
              map.setCenter({ lat: 0, lng: 0 })
              map.setZoom(3)
              form.resetFields()
            }}
            footer={false}
            title='Gerar book'
            width={1000}
          >
            {' '}
            {abrirGerar == true ? (
              <Form layout='vertical' form={form} onFinish={handleSubmitBook}>
                <Col span={24}>
                  <Row gutter={[5, 5]}>
                    <Col span={5}>
                      <Form.Item
                        name='dateBook'
                        rules={[{ required: true, message: 'Por favor, insira a data' }]}
                        initialValue={dataAtual}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          defaultValue={dataAtual}
                          disabledDate={handleDisableDate}
                          onChange={(_, date) => handleChangeDataBooking(false, date)}
                          placeholder='Selecione uma data'
                        />
                      </Form.Item>

                      {tipoAcesso === 'conta' ? (
                        <Form.Item
                          name='consultorName'
                          rules={[{ required: true, message: 'Por favor, selecione o consultor' }]}
                          initialValue={codConsultor == '' ? null : codConsultor}
                        >
                          <Select
                            optionFilterProp='label'
                            defaultValue={codConsultor}
                            showSearch
                            value={codConsultor}
                            placeholder='Consultor responsável'
                            options={dataConsultor.map((data) => {
                              return { label: data.usuario_nome, value: data.usuario_nome }
                            })}
                          />
                        </Form.Item>
                      ) : null}

                      <Form.Item
                        name='codTalhao'
                        label={
                          <div>
                            Talhão{' '}
                            <small style={{ fontSize: '10px', opacity: '0.8' }}>(Opcional)</small>
                          </div>
                        }
                        initialValue={[]}
                      >
                        <Select
                          mode='multiple'
                          showSearch
                          onChange={handleChangeTalhaoBook}
                          optionFilterProp='label'
                          optionLabelProp='label'
                          placeholder='Selecione o talhão'
                        >
                          {resultados?.map((rec) => (
                            <Select.Option label={rec.nome} value={rec.cod}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {/* @ts-ignore */}
                                <Svg coordinates={rec.coordenadas} /> {rec.nome}
                              </div>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name='mapType'
                        label='Tipo de mapa'
                        initialValue={google.maps.MapTypeId.SATELLITE}
                      >
                        <Select
                          placeholder='Tipo terreno mapa'
                          // onChange={(data) => setMapType(data)}
                          options={[
                            { label: 'Terreno', value: google.maps.MapTypeId.SATELLITE },
                            { label: 'Sem terreno', value: google.maps.MapTypeId.TERRAIN },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      style={{
                        overflow: 'auto',
                        marginLeft: '20px',
                      }}
                      span={16}
                    >
                      <Checkbox
                        indeterminate={indeterminate}
                        onChange={handleCheckAllChange}
                        checked={checkAll}
                      >
                        Selecionar todos
                      </Checkbox>
                      <Form.Item
                        name='listEspecieVariacao'
                        rules={[{ required: true, message: 'Por favor, selecione uma espécie' }]}
                      >
                        <Checkbox.Group
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '15px',

                            height: '300px',

                            margin: '0',
                            padding: '0',
                          }}
                          value={checkedList}
                          onChange={handleChangeAllPraga}
                        >
                          {listCheckBoxArray.map((rec) => {
                            const ev = rec.split(', ')
                            const e = Number(ev[1])
                            const v = ev[2] === 'null' ? null : Number(ev[2])

                            const nivel = nivelControle.find(
                              (item) => item?.cod_especie === e && v === item?.cod_variacao_especie
                            )

                            return (
                              <Checkbox
                                key={uid()}
                                style={{
                                  marginLeft: '20px',
                                  width: '38%',
                                  margin: '0',
                                  padding: '0',
                                }}
                                className='check-box-all'
                                value={rec}
                              >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  {ev[3] === '1' || ev[2] === '1' ? (
                                    <div>{ev[0]}</div>
                                  ) : (
                                    <div>{nivel?.especie_nome}</div>
                                  )}

                                  {ev[3] === '1' || ev[2] === '1' ? (
                                    <small>{ev[2] === '1' ? null : ev[2].replace(/\/$/, '')}</small>
                                  ) : (
                                    <small>{nivel?.variacao_especie_nome}</small>
                                  )}
                                </div>
                              </Checkbox>
                            )
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Form.Item extra='*Isso pode levar alguns minutos dependendo da quantidade de informação'>
                  <Button loading={loadingBook} htmlType='submit' type='primary'>
                    Gerar
                  </Button>
                </Form.Item>
              </Form>
            ) : null}
          </Modal>
        </Drawer>
        <Modal
          forceRender
          open={openBook}
          onCancel={() => {
            setOpenBook(false)
            setCheckAll(false)

            setListCheckBoxArray([])
          }}
          footer={false}
          width={1000}
        >
          <BookMonitoramento
            mapType={mapType}
            nivelControleItens={nivelControle}
            onClose={(b) => {
              setOpenBooking(b)
              setDataBooking({})
              polygonPropriedade.setPaths([])
              polygonTalhao.setPath([])
              polygonAgrupamento.setPaths([])
              polygonAgrupamentoPro.setPaths([])
              polygonSubArea.setPath([])
              setDataMonitoramentoBooking({})
              setCheckAll(false)
              setIndeterminate(false)
              setSelecionado(true)
              setListCheckBoxArray([])
              setMarkerDefault([])
              setMarker([])
              map.setCenter({ lat: 0, lng: 0 })
              map.setZoom(3)
              form.resetFields()
            }}
            onLoading={setLoadingBook}
            finishBook={finishBook}
            open={openBooking}
            data={dataBooking}
            resumir={resumirBook}
            setOpenBook={setOpenBook}
            totalizar={totalizarBook}
            codPro={codPro}
          />
        </Modal>
      </Form>
    </div>
  )
}

export default GerarBook

import React, { Suspense } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import Loading from '../../Components/loading'
import { Notification } from '../../Components/notification'
import { ADMIN_PREFIX_PATH } from '../../Configs/AppConfig'
import { useLogic } from '../../context/useLogic'
import chekToken from '../../services/ChekToken'
import CadEstadios from './cadastros/cadestadios'
import CatPatrimonio from './config/catpatrimonio'
import CatProdutos from './config/catprodutos'
import PageElementos from './config/elementos'
import Marca from './config/marcas'
import UserTesterApp from './config/userTesterApp'
import RecomendaAtivos from './recomendacao/ativos'
import RecomendaTipos from './recomendacao/ativosTipos'
import RecomendaDadosQuimicos from './recomendacao/dadosQuimicos'
import RecomendaFormulacao from './recomendacao/formulacao'
import SharedFiles from './shareFiles'
import CadastroCultura from './config/cadastroCultura'
import CadastroMedidas from './config/cadastroMedidas'
import CadastroBio from './recomendacao/bioTecnologia'
import { Logs } from './logs'
import EditPermissoes from './permissoes'
import { warningAlert } from '../../utils/alert'

export const AdminViews: React.FC = () => {
  const { itens, setItens } = useLogic()

  const history = useHistory()

  const tokenDAta = itens[0]?.hash ? itens[0].hash : false
  const checkToken = async (token) => {
    const validade = await chekToken(token)
    if (!validade) {
      warningAlert('Sessão Expirada! Faça o login novamente!')
      setItens([])
      history.push('/auth/login')
    }
  }
  if (!tokenDAta) {
    history.push('/auth/login')
  } else {
    checkToken(tokenDAta)
  }
  return (
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        <Route path={`${ADMIN_PREFIX_PATH}/home`} component={null} />

        <Route path={`${ADMIN_PREFIX_PATH}/config/marcas`} component={Marca} />

        <Route path={`${ADMIN_PREFIX_PATH}/config/catpatrimonio`} component={CatPatrimonio} />

        <Route path={`${ADMIN_PREFIX_PATH}/config/catprodutos`} component={CatProdutos} />

        <Route path={`${ADMIN_PREFIX_PATH}/config/cadastrocultura`} component={CadastroCultura}/>

        <Route path={`${ADMIN_PREFIX_PATH}/config/cadastro-medidas`} component={CadastroMedidas}/>

        <Route path={`${ADMIN_PREFIX_PATH}/config/user-teste-app`} component={UserTesterApp} />

        <Route path={`${ADMIN_PREFIX_PATH}/config/estadios`} component={CadEstadios} />

        <Route path={`${ADMIN_PREFIX_PATH}/config/elementos`} component={PageElementos} />

        <Route path={`${ADMIN_PREFIX_PATH}/recomendacao/tipos`} component={RecomendaTipos} />

        <Route path={`${ADMIN_PREFIX_PATH}/recomendacao/ativos`} component={RecomendaAtivos} />

        <Route path={`${ADMIN_PREFIX_PATH}/recomendacao/cadastro-biotecnologia`} component={CadastroBio} />

        <Route path={`${ADMIN_PREFIX_PATH}/shared-files`} component={SharedFiles} />

        <Route path={`${ADMIN_PREFIX_PATH}/logs`} component={Logs} />

        <Route path={`${ADMIN_PREFIX_PATH}/permissoes`} component={EditPermissoes} />

        <Route
          path={`${ADMIN_PREFIX_PATH}/recomendacao/dadosquimicos`}
          component={RecomendaDadosQuimicos}
        />

        <Route
          path={`${ADMIN_PREFIX_PATH}/recomendacao/formulacao`}
          component={RecomendaFormulacao}
        />

        <Route
          path={`${ADMIN_PREFIX_PATH}/logout`}
          component={() => {
            setItens([])

            history.push('auth/login')
            return <></>
          }}
        >
          {}
        </Route>
        <Redirect from={`${ADMIN_PREFIX_PATH}`} to={`${ADMIN_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

// export default React.memo(AdminViews);
export default AdminViews

import styled from 'styled-components'

export const BodyDrawer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .campo__dois__inputs {
    display: flex;
    gap: 10px;
  }

  .data-nascimento {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;

    label {
      padding: 0;
      margin: 0;
      font-size: 13px;
      font-weight: 500;
      color: var(--text-color);
      display: inline-flex;
      gap: 5px;
    }
  }

  .header {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    background-color: rgb(224, 224, 224);
  }

  .MD__content__coleta {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    border: thin solid var(--border-color);
    overflow: hidden;
    border-radius: 5px;
    margin-top: 10px;

    div.MD__content__tabela_coleta__column {
      width: 100%;
      display: flex;
      border-bottom: thin solid var(--border-color);

      :last-child {
        border: none;
      }

      div.MD__c__tabela_coleta__column__header {
        width: 100%;
        min-height: 35px;
        background: #fafafa;
        border-right: thin solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 700;
        font-size: 13px;
        box-sizing: border-box;
        padding: 0 10px;
      }
      div.MD__c__tabela_coleta__column__row {
        width: 50%;
        height: 35px;
        overflow-y: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 13px;
        box-sizing: border-box;
        padding: 10px;
      }
    }
  }
`

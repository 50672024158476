import { ItemRequest } from "../../../../../../../../services/logs";
import { errorAlert, successAlert } from "../../../../../../../../utils/alert";

export async function copyJson(request_selected: ItemRequest) {
    
    const { body } = request_selected?.descricao ?? {};

    let stringfy = JSON.stringify(body);

    try {
        await navigator.clipboard.writeText(stringfy);
        successAlert('JSON body copiado com sucesso!');
    } catch (err) {
        console.error("Falha ao copiar o JSON: ", err);
        errorAlert("Falha ao copiar o JSON: ");
    }
}

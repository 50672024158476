import { ItemRequest } from "../../../../../../../../services/logs";
import { errorAlert, successAlert } from "../../../../../../../../utils/alert";

export async function copyJson(request_selected: ItemRequest) {
    
    const { httpCode, content } = request_selected?.descricao?.response ?? {};

    let buildJson = {
        'httpCode': httpCode,
        'content': content
    };

    let stringfy = JSON.stringify(buildJson);

    try {
        await navigator.clipboard.writeText(stringfy);
       successAlert('JSON copiado com sucesso')
    } catch (err) {
        console.error("Falha ao copiar o JSON: ", err);
        errorAlert("Falha ao copiar o JSON: ");
    }
}

import React, { useEffect, useRef, useState } from 'react'

import { GoogleMap, Polygon } from '@react-google-maps/api'
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  List,
  Menu,
  Modal,
  Progress,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd'

import Icon, { CloseOutlined, DownOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { calculoAreaCoord, getBounds } from '../../services/Afins'
import { geralKmlPolygon } from '../gerarKml'

import { postTalhao, TalhaoItens } from '../../services/Talhao'
import { postTalhaoCultivavel, TalhaoCultivaveisItens } from '../../services/TalhaoSafra'
import { Notification } from '../notification'
import Svg from '../talhasvg'
import { ContainerMap } from './styled'
import { gerarKey } from '../../utils'
import { PainelTalhoesPraSafra } from './components/painel'
import { destroyAlert, errorAlert, successAlert, warningAlert } from '../../utils/alert'

interface TypesMapa {
  zoom: number
  center:
    | {
        lat: number
        lng: number
      }
    | google.maps.LatLng
  mapContainerStyle?: { [key: string]: string }
}

interface TypesImport {
  // eslint-disable-next-line react/require-default-props
  safra?: boolean
  paths: any[]
  mapSet: TypesMapa
  nameTalhao: string
  propriedade: number
  dataPolygon: TypeDataTalhao[]
  onClose: () => void
  listTalhoesRecord: TalhaoItens[] | TalhaoCultivaveisItens[]
  tituloPainel?: string
}

interface TypeDataTalhao {
  alert?: boolean
  talhao: string
  area?: number
  ativo: boolean
  coord:
    | {
        lat: number
        lng: number
      }[]
    | google.maps.LatLng
}

const menu = (onAll, onInverte) => (
  <Menu>
    <Menu.Item onClick={onAll}>Selecionar todos</Menu.Item>
    <Menu.Item onClick={onInverte}>Inverter seleção atual</Menu.Item>
  </Menu>
)

const ImporteKmlTalhao: React.FC<TypesImport> = ({
  paths,
  mapSet,
  nameTalhao,
  propriedade,
  onClose,
  dataPolygon,
  listTalhoesRecord,
  safra = false,
  tituloPainel
}) => {
  const [form] = Form.useForm()

  const scrollTalhao = useRef<HTMLDivElement>()

  const [polygon, setPolygon] = useState<google.maps.Polygon>()
  const [area, setArea] = useState<number>()

  const [flutuantDiv, setFlutuantDiv] = useState(false)

  const [map, setMap] = useState<google.maps.Map>()

  const [polygonData, setPolygonData] = useState<TypeDataTalhao[]>(dataPolygon)

  const [listSelected, setListSelected] = useState<number>(null)

  const [postProgress, setPostProgress] = useState(0)
  const [progressModal, setProgressModal] = useState(false)

  const [propsMap, setPropsMapa] = useState<TypesMapa>(mapSet)

  const [coord, setCoord] = useState([])

  const [qtdaTalhoes, setQtdaTalhoes] = useState({ ativo: 0, inativo: 0 })

  const handleOnloadPolygon = (datapolygon: google.maps.Polygon) => {
    setPolygon(datapolygon)
  }

  const handleClickHiddenFlutuant = (data: boolean) => {
    setFlutuantDiv(!data)
  }

  const onFinish = async (new_polygonData: TypeDataTalhao[]) => {
    try {
      setProgressModal(true)

      let findRecord = []
      let cont = 0
      polygonData.map((poly) => {
        listTalhoesRecord.forEach((record) => {
          if (poly.talhao === record.nome && poly.ativo) {
            poly.alert = true
            cont += 1
          }
          return cont
        })

        findRecord = [...findRecord, poly]

        return { findRecord, cont }
      })

        const switchChecked = new_polygonData.filter((item) => item.ativo === true)
        let c = 0

        if (switchChecked.length === 0) {
          setProgressModal(false);
          warningAlert('Os talhões enviados já foram importados anteriormente!', 9000)
          warningAlert('Verifique na página "Talhões de Safra" que eles já estão listados! Caso não estejam, por favor, entre em contato com o suporte.', 10000)
          return null;
        }

        for (const item of new_polygonData) {
          const talhao = item
          if (talhao.ativo) {
            c += 1
            const kml = geralKmlPolygon(talhao.coord, talhao.talhao)

            const cripto = btoa(kml)

            const arquivo = `data:@file/xml;base64,${cripto}`

            const data = {
              nome: talhao.talhao,
              cod_propriedade: propriedade,
              arquivo,
              status: 1,
              area: calculoAreaCoord([talhao.coord]),
            }

            const response = safra
              ? await postTalhaoCultivavel(propriedade, data)
              : await postTalhao(propriedade, data)
            if (response.status === 201) {
              if (switchChecked.length > 0) {
                setPostProgress(calcPorcentagem(c, switchChecked.length))
              } else {
                setPostProgress(100)
              }
              if (switchChecked.length === c) {
                destroyAlert();
                successAlert(switchChecked.length > 1 ? 'Talhões cadastrados com sucesso ' : 'Talhão cadastrado com sucesso');
                setTimeout(() => {
                  onClose()
                  setProgressModal(false)
                  setCoord([])
                }, 500)
              }
            }
          }
        }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          destroyAlert();
          errorAlert(data?.error ?? 'Falha ao registrar talhão!');
        } else {
          destroyAlert();
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha ao registrar talhão!');
          })
        }
      }
      else {
        destroyAlert();
        errorAlert('Falha ao resgistrar talhão!')
      }
    }
  }

  const handleToogleSwitchTalhao = (
    data: boolean,
    index: number,
    talhao: string,
    coordData: { lat: number; lng: number }[] | google.maps.LatLng
  ) => {

    const dados = JSON.parse(JSON.stringify(polygonData))
    const poly = JSON.parse(JSON.stringify(coord))

    dados[index] = {
      ativo: !data,
      coord: coordData,
      talhao,
      alert: dados[index].alert,
      area: dados[index].area,
    }

    poly[index] = coordData
    setPolygonData(dados)
    setCoord(poly)
  }

  const handleQtda = () => {
    let ativo = 0
    let inativo = 0
    polygonData.forEach((data) => {
      if (data.ativo) {
        ativo += 1
      } else {
        inativo += 1
      }

      return { ativo, inativo }
    })

    setQtdaTalhoes({ ativo, inativo })
  }

  const handleChangeEditNameTalhao = (nome: string, data: TypeDataTalhao, index: number) => {
    const dados = JSON.parse(JSON.stringify(polygonData))
    const dadosRecord = listTalhoesRecord
    let find = []
    let findRecord = []
    dados.forEach((data) => {
      if (data.talhao.toLocaleLowerCase() === nome.toLocaleLowerCase()) {
        find = [...find, data]
      }
    })

    dadosRecord.forEach((data) => {
      if (data.nome.toLocaleLowerCase() === nome.toLocaleLowerCase()) {
        findRecord = [...findRecord, data]
      }
    })

    if (findRecord.length > 0) {
      warningAlert('Nome do talhão ja existe');
    } else {
      dados[index] = {
        alert: false,
        ativo: data.ativo,
        coord: data.coord,
        talhao: nome,
      }

      setPolygonData(dados)
    }
  }

  const calcPorcentagem = (qtda, total) => {
    if (qtda === 1) {
      return 100
    }
    return parseFloat(((qtda * 100) / total).toFixed(0))
  }

  const handleCloseModal = () => {
    Modal.warning({
      title: 'Deseja realmente cancelar?',
      okText: 'Sim',
      cancelText: 'Não',
      okCancel: true,
      onOk: () => onClose(),
    })
  }

  const handleClickListPolygon = (index, scroll: boolean) => {
    setListSelected(index)
    if (scroll) {
      const element = document.getElementById(`talhao_${index}`)
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const init = () => {
    setCoord(paths)
    setPropsMapa(mapSet)
    const ha = calculoAreaCoord(paths)
    setArea(ha)

    setPolygonData(dataPolygon)
  }

  const handleChangeCheckBoxSelectAll = (checked: boolean) => {
    const dados = [...polygonData]

    setPolygonData(
      dados.map((rec) => {
        rec.ativo = checked
        return rec
      })
    )
  }

  const handleChangeInvertAll = () => {
    const dados = [...polygonData]

    setPolygonData(
      dados.map((rec) => {
        rec.ativo = !rec.ativo
        return rec
      })
    )
  }

  useEffect(() => {
    form.setFieldsValue({
      area,
      nome: nameTalhao,
      cod_propriedade: propriedade,
    })
  }, [area])

  useEffect(() => {
    if (polygon) {
      init()
    }
  }, [paths])

  useEffect(() => {
    if (map) {
      map.fitBounds(getBounds(coord))
    }
  }, [polygon])

  useEffect(() => {
    setListSelected(null)
    setPostProgress(0)
  }, [onClose])

  useEffect(() => {
    handleQtda()
  }, [polygonData])

  useEffect(() => {
    setCoord(paths)
  }, [])

  return (
    <Col span={24}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {/* @ts-ignore */}
          <GoogleMap
            onLoad={setMap}
            onUnmount={setMap}
            options={{
              streetViewControl: false,
              mapTypeId: 'hybrid',
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            zoom={12}
            mapContainerStyle={{
              width: '100%',
              height: '90vh',
            }}
          >
            {coord.map((data, index) => {
              let cor
              if (polygonData[index].alert) {
                cor = '#ff4d4f'
              } else if (listSelected === index) {
                cor = 'orange'
              } else {
                cor = 'cyan'
              }
              return (
                <Polygon
                  key={gerarKey(index)}
                  path={data}
                  onLoad={handleOnloadPolygon}
                  onClick={() => handleClickListPolygon(index, true)}
                  options={
                    polygonData[index].ativo
                      ? {
                          fillColor: cor,
                          fillOpacity: listSelected === index ? 1 : null,
                          strokeColor: cor,
                          geodesic: false,
                        }
                      : {
                          fillColor: 'white',
                          fillOpacity: 0.6,
                          strokeColor: 'white',
                          geodesic: false,
                        }
                  }
                />
              )
            })}
          </GoogleMap>
        </Col>
        <PainelTalhoesPraSafra 
          list={polygonData}
          handleChangeEditNameTalhao={handleChangeEditNameTalhao}
          handleToogleSwitchTalhao={handleToogleSwitchTalhao}
          handleChangeCheckBoxSelectAll={handleChangeCheckBoxSelectAll}
          handleClickListPolygon={handleClickListPolygon}
          listSelected={listSelected}
          setListSelected={setListSelected}
          qtd_selecionados={qtdaTalhoes.ativo}
          onFinish={onFinish}
          onClose={onClose}
          setPolygonData={setPolygonData}
          list_talhoes={listTalhoesRecord}
          tituloPainel={tituloPainel}
        />
        {/* <ContainerMap style={{ width: flutuantDiv ? 'auto' : '325px' }}>
          <Card
            title={
              flutuantDiv ? (
                ''
              ) : (
                <Space>
                  {safra ? (
                    <>
                      {polygonData.length > 1
                        ? 'Importar talhões para safra 123'
                        : 'Importar talhão para safra'}
                    </>
                  ) : (
                    <>{polygonData.length > 1 ? 'Talhões' : 'Talhão'}</>
                  )}
                  <Dropdown
                    trigger={['click']}
                    overlay={menu(
                      () => handleChangeCheckBoxSelectAll(true),
                      () => handleChangeInvertAll()
                    )}
                    placement='bottomLeft'
                  >
                    <span style={{ fontSize: '.8em', cursor: 'pointer' }}>
                      <DownOutlined />
                    </span>
                  </Dropdown>
                </Space>
              )
            }
            size='small'
            bodyStyle={{ display: flutuantDiv ? 'none' : '' }}
            extra={
              <Button
                className='btn-hidden'
                onClick={() => handleClickHiddenFlutuant(flutuantDiv)}
                size='small'
                type='text'
                icon={flutuantDiv ? <MenuUnfoldOutlined /> : <CloseOutlined />}
              />
            }
            actions={
              !flutuantDiv
                ? [
                    <div title='Quantidade de talhões' className='card-action-talhao' key={'total'}>
                      <h5>Total</h5>
                      <h5>{polygonData.length}</h5>
                    </div>,
                    <div title='Quantidade de talhões ativos' key={'selecionados'}>
                      <h5>Selecionados</h5>
                      <h5>{qtdaTalhoes.ativo}</h5>
                    </div>,
                  ]
                : null
            }
          >
            <div
              style={{
                maxHeight: 'calc(100vh - 320px)',
                overflowY: 'auto',
                display: flutuantDiv ? 'none' : '',
              }}
            >
              <List>
                {polygonData.map((data, index) => {
                  data.area = calculoAreaCoord([data.coord])
                  const key = index + 1
                  return (
                    <List.Item
                      key={key}
                      style={{
                        background: listSelected === index ? '#00ffff4d' : '',
                      }}
                      onClick={() => handleClickListPolygon(index, false)}
                      extra={
                        <>
                          <div key={key} ref={scrollTalhao} id={`talhao_${index}`} />
                          <Icon
                            component={() => (
                              <Svg
                                // @ts-ignore
                                coordinates={data.coord}
                                fill={data.ativo ? '#70ce9f' : '#9b9b9b'}
                              />
                            )}
                          />
                        </>
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <Switch
                          onChange={() =>
                            handleToogleSwitchTalhao(data.ativo, index, data.talhao, data.coord)
                          }
                          checkedChildren='Sim'
                          unCheckedChildren='Não'
                          checked={data.ativo}
                        />
                        <div style={{ paddingLeft: '15px' }}>
                          {data.ativo ? (
                            <h5 style={{ marginBottom: '0' }}>
                              <Typography.Text
                                editable={{
                                  onChange: (nome) => handleChangeEditNameTalhao(nome, data, index),
                                }}
                              >
                                {data.talhao}
                              </Typography.Text>
                            </h5>
                          ) : (
                            <h5 style={{ marginBottom: '0' }}>{data.talhao}</h5>
                          )}

                          <small>{data.area} ha</small>
                          <br />
                          <small
                            hidden={!data.alert}
                            style={{
                              border: 'solid 1px #ff4d4f',
                              color: '#ff4d4f',
                              padding: '3px',
                              borderRadius: '5px',
                            }}
                          >
                            Nome já cadastrado
                          </small>
                        </div>
                      </div>
                    </List.Item>
                  )
                })}
              </List>
            </div>
          </Card>
          <div
            hidden={flutuantDiv}
            style={{
              padding: '10px',
              width: '315px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button disabled={qtdaTalhoes.ativo <= 0} onClick={onFinish} type='primary'>
              Salvar
            </Button>
            <Button onClick={handleCloseModal} danger type='primary'>
              Cancelar
            </Button>
          </div>
        </ContainerMap> */}
      </Row>
      <Modal
        title='Aguarde... importando talhões...'
        footer={false}
        closeIcon={false}
        closable={false}
        // onCancel={()=>setProgressModal(false)}
        width='387px'
        style={{ top: '10px' }}
        open={progressModal}
      >
        <h5>
          <Progress percent={postProgress} steps={19} strokeColor='#87d068' />
        </h5>
      </Modal>
    </Col>
  )
}

export default ImporteKmlTalhao

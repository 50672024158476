import React, { useEffect, useRef, useState } from 'react'

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Upload,
} from 'antd'

import AntdCrop from 'antd-img-crop'

import { v4 as uuid } from 'uuid'

import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'

import { UploadOutlined } from '@ant-design/icons'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { UploadChangeParam } from 'antd/lib/upload'

import { AxiosResponse } from 'axios'
import { Container } from './style'

import { getEquipe, TypesEquipe } from '../../services/Equipe'

import { Notification } from '../notification'

import { useLogic } from '../../context/useLogic'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import {
  delRepresentanteLegal,
  getRepresentanteLegal,
  postRepresentanteLegal,
  putRepresentanteLegal,
} from '../../services/RepresentanteLegal'
import base64 from '../Base64Geral'
import { IconAlert } from '../iconsAlert'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../utils/alert'

interface TypesProps {
  codCliente: number
  typeSubmit: {
    type: string
    submit: boolean
    tab: string
  }
  permissions: ItensPerUserLogged
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const { Option } = Select

const RepresentanteLegal: React.FC<TypesProps> = (props) => {
  const { codCliente, typeSubmit, permissions } = props
  const { responsive } = useLogic()

  const inputSolicitante = useRef()

  const uploadAssinatura = useRef(null)

  const [dataOptSelect, setDataOptSelect] = useState([])
  const [dataTableRepresentante, setDataTableRepresentante] = useState([])

  const [loadingSelect, setLoadingSelect] = useState(false)

  const [representanteLegal, setRepresentanteLegal] = useState(false)

  const [selectedFile, setSelectedFile] = useState(null)

  const [previewImage, setPreviewImage] = useState({
    image: null,
    visible: false,
    title: null,
  })

  const [updateRepresentante, setupdateRepresentante] = useState<number>(null)

  const [form] = Form.useForm()

  const handleSearchCliente = async (nome) => {
    setLoadingSelect(true)
    if (nome.length > 2) {
      const response: AxiosResponse<TypesEquipe> = await getEquipe({
        nome,
        codCliente,
      })

      setDataOptSelect(response.data.itens)
    } else {
      setDataOptSelect([])
    }
    setLoadingSelect(false)
  }

  const handleChangeCliente = (data, dados) => {
    form.setFieldsValue({
      usuario_nome: dados.children,
      cod_usuario: parseInt(data, 10),
      select_user: null,
    })

    // @ts-ignore
    inputSolicitante.current?.focus({
      cursor: 'start',
    })
  }

  const handleTrashRepresentante = async (data, dados) => {
    Modal.confirm({
      title: `Deseja Realmente excluir o representante ${dados.usuario_nome}`,
      icon: <IconAlert type='confirm' size={6} />,
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        const response = await delRepresentanteLegal(data, codCliente)

        if (response.status === 200) {
          successAlert(`Representante ${dados.usuario_nome} excluido com sucesso!`);
          listRepresentante()
        }
      },
    })
  }

  const handleEditRepresentante = async (data) => {
    loadingAlert({msg: 'Carregando...', id: 'load_inicial'});

    setupdateRepresentante(data)

    const response = await getRepresentanteLegal(data, codCliente)

    const dados = response.data.itens[0]

    const uid = uuid()

    if (dados.assinatura !== null) {
      const assinatura = {
        file: {
          uid,
          name: '',
          thumbUrl: dados.assinatura_base64,
          preview: dados.assinatura_base64,
          type: `image/${dados.assinatura.slice(-3)}`,
        },
        fileList: {
          uid,
          name: '',
          thumbUrl: dados.assinatura_base64,
          preview: dados.assinatura_base64,
          type: `image/${dados.assinatura.slice(-3)}`,
        },
      }

      setRepresentanteLegal(dados.representante === 1)
      uploadAssinatura.current.fileList[0] = assinatura.fileList
      uploadAssinatura.current.file = assinatura.file
      uploadAssinatura.current.onSuccess('done', assinatura.file)
    }

    form.setFieldsValue({
      usuario_nome: dados.usuario_nome,
      participacao: dados.participacao.replace('.', ','),
      solicitante: dados.solicitante,
      representante: dados.representante,
      observacao: dados.observacao,
    })

    destroyAlert('load_inicial');
  }

  const listRepresentante = async () => {
    const response = await getRepresentanteLegal('', codCliente)

    setDataTableRepresentante(response.data.itens)
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const onChangeUpload = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      setSelectedFile(info.file.originFileObj)
    }
  }

  const handleChageRepresentante = (data) => {
    setRepresentanteLegal(data)
  }

  const handlePreviewImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage({
      image: file.url || file.preview,
      visible: true,
      title: 'Assinatura',
    })
  }

  const onRemoveAssinatura = () => {
    setSelectedFile([])
  }

  const onFinish = async (data) => {
    try {
      if (data.usuario_nome !== undefined) {
        if (updateRepresentante === 0) {
          data.representante = data.representante ? 1 : 0

          if (selectedFile !== null) {
            const arquivo = await base64(selectedFile)
            data.assinatura = arquivo
          } else {
            data.assinatura = null
          }
          loadingAlert({msg: 'Salvando...', id: 'save_repre'})
          const response = await postRepresentanteLegal(data, codCliente)

          if (response.status === 201) {
            successAlert('Representante legal cadastrado com sucesso!', 5000, 'save_repre');
            form.resetFields()
            listRepresentante()
            setRepresentanteLegal(false)
            setSelectedFile(null)
          }
        } else {
          loadingAlert({msg: 'Salvando...', id: 'save_repre'})

          data.representante = data.representante ? 1 : 0

          const response = await putRepresentanteLegal(updateRepresentante, data, codCliente)

          if (response.status === 200) {
            successAlert('Representante legal atualizado com sucesso!', 5000, 'save_repre');

            listRepresentante()
            setupdateRepresentante(0)
            form.resetFields()
            setRepresentanteLegal(false)
            setSelectedFile(null)
          }
        }
      } else {
        errorAlert('Insera os dados do representante legal!');
      }
    } catch (error) {

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          errorAlert(data?.error ?? 'Falha na operação!', 7000, 'save_repre');
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha na operação!', 7000, 'save_repre');
          })
        }
      }
      else {
        errorAlert('Falha na operação! (sem resposta)', 7000, 'save_repre');
      }
    }
  }

  useEffect(() => {
    if (!typeSubmit.submit && typeSubmit.type === 'POST') {
      form.resetFields()
    }
    if (typeSubmit.submit || updateRepresentante === 0) {
      const dados = form.getFieldsValue()

      onFinish(dados)
    }
  }, [typeSubmit])

  useEffect(() => {
    listRepresentante()
    setupdateRepresentante(0)
  }, [])

  return (
    <Container>
      <Modal
        open={previewImage.visible}
        title={previewImage.title}
        footer={null}
        onCancel={() => setPreviewImage({ visible: false, image: null, title: null })}
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage.image} />
      </Modal>
      <Table
        size='small'
        rowKey='cod'
        pagination={{
          defaultPageSize: 4,
        }}
        scroll={{ y: 240, x: 'auto' }}
        dataSource={dataTableRepresentante}
        columns={[
          {
            key: 1,
            title: 'Nome Usuário',
            dataIndex: 'usuario_nome',
            render: (data) => <div style={{ minWidth: '100px' }}>{data}</div>,
          },
          {
            key: 2,
            title: 'Solicitante',
            dataIndex: 'solicitante',
          },
          {
            key: 3,
            title: 'Representante',
            dataIndex: 'representante',
            render: (data) => <div style={{ minWidth: '100px' }}>{data === 1 ? 'Sim' : 'Não'}</div>,
          },
          {
            key: 4,
            title: 'Participação',
            dataIndex: 'participacao',
            render: (data) => (
              <div style={{ minWidth: '100px' }}>
                {data === null ? '' : `${parseFloat(data).toString().replace('.', ',')}%`}
              </div>
            ),
          },
          {
            key: 5,
            title: 'Ações',
            dataIndex: 'cod',
            width: 90,
            render: (data, dados) => {
              return (
                <Space>
                  <Button
                    type='primary'
                    shape='circle'
                    className='info-button'
                    onClick={() => handleEditRepresentante(data)}
                    icon={<FontA icon={solid('pen')} />}
                    size='small'
                  />
                  <Button
                    shape='circle'
                    onClick={() => handleTrashRepresentante(data, dados)}
                    className='trash-button'
                    type='primary'
                    icon={<FontA icon={solid('trash')} />}
                    size='small'
                  />
                </Space>
              )
            },
          },
        ]}
      />
      <Card size='small'>
        <Form
          style={{ pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto' }}
          size='middle'
          onFinish={onFinish}
          layout='vertical'
          form={form}
        >
          <Col span={23}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Form.Item name='select_user' style={{ minWidth: '100%' }}>
                  <Select
                    placeholder='Pesquisar Usuário'
                    onSearch={handleSearchCliente}
                    onChange={handleChangeCliente}
                    style={{ minWidth: '78vw' }}
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={loadingSelect ? <Spin size='small' /> : null}
                  >
                    {dataOptSelect.map((data) => (
                      <Option key={data.cod_func} value={`${data.cod_func}`}>
                        {data.usuario_nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Row gutter={[16, 0]}>
                  <Col flex='1 1 300px'>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label='Nome Usuário'
                      name='usuario_nome'
                    >
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item hidden name='cod_usuario' />
                  </Col>
                  <Col flex='1 1 300px'>
                    <Form.Item label='Outros Solicitantes' name='solicitante'>
                      <Input ref={inputSolicitante} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={responsive ? 12 : 2} flex='1 1 70px'>
                    <Form.Item label='Porcentagem' name='participacao'>
                      <Input addonAfter='%' />
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 70px'>
                    <Form.Item label='Representante Legal?' name='representante'>
                      <Switch
                        onChange={handleChageRepresentante}
                        checked={representanteLegal}
                        checkedChildren='Sim'
                        unCheckedChildren='Não'
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col flex='1 1 300'>
                    <Form.Item name='assinatura'>
                      <AntdCrop aspect={200 / 75} modalTitle='Recordar Imagem' showGrid>
                        <Upload
                          ref={uploadAssinatura}
                          onRemove={onRemoveAssinatura}
                          accept='image/jpeg, image/png'
                          className='upload-list-inline'
                          listType='picture'
                          onChange={onChangeUpload}
                          onPreview={handlePreviewImage}
                          maxCount={1}
                          multiple={false}
                          customRequest={dummyRequest}
                        >
                          <Button type='primary' icon={<UploadOutlined />}>
                            Assinatura
                          </Button>
                        </Upload>
                      </AntdCrop>
                    </Form.Item>
                  </Col>
                  <Button id='btnSalvar' htmlType='submit' hidden />
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <Form.Item label='Observações' name='observacao'>
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Form>
      </Card>
    </Container>
  )
}

export default RepresentanteLegal

import { ItemRequest } from "../../../../../../../../services/logs";
import { errorAlert, successAlert } from "../../../../../../../../utils/alert";

export async function copyJson(request_selected: ItemRequest) {
    
    const { authUserEmail, authUserId, authUserName, tokenApi, tokenAuth } = request_selected?.descricao ?? {};

    let buildJson = {
        'authUserEmail': authUserEmail,
        'authUserId': authUserId,
        'authUserName': authUserName,
        'tokenApi': tokenApi,
        'tokenAuth': tokenAuth
    };

    let stringfy = JSON.stringify(buildJson);

    try {
        await navigator.clipboard.writeText(stringfy);
        successAlert('JSON copiado com sucesso!');''
    } catch (err) {
        console.error("Falha ao copiar o JSON: ", err);
        errorAlert('Falha ao copiar o JSON!');
    }
}
import React, { useEffect, useState } from 'react'
import { Button, Modal, Space, Table } from 'antd'
import Icon from '@ant-design/icons'
import { AxiosResponse } from 'axios'

import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import ViewPage from '../../../../Components/ViewPage'
import {
  delDadosQuimicos,
  getDadosQuimicos,
  TypeDadosQuimicos,
} from '../../../../services/recomendacao/dadosQuimicos'
import { pagination } from '../../../../Components/Default'
import { IconAlert } from '../../../../Components/iconsAlert'
import { Notification } from '../../../../Components/notification'
import FormDadosQuimicos from '../../../../Components/recomendacao/dadosQuimicos'
import Icons from '../../../../Components/Icons'
import { successAlert } from '../../../../utils/alert'

const RecomendaDadosQuimicos: React.FC = () => {
  const [visibleForm, setVisibleForm] = useState(false)
  const [update, setUpdate] = useState(0)

  const [dataDadosQuimicos, setDataDadosQuimicos] = useState([])
  const [tempDadosQuimicos, setTempDadosQuimicos] = useState([])

  const [loadingTable, setLoadingTable] = useState(false)

  const listDadosQuimicos = async () => {
    setLoadingTable(true)
    const resp: AxiosResponse<TypeDadosQuimicos> = await getDadosQuimicos({
      pagination: -1,
    })

    setDataDadosQuimicos(resp.data.itens)
    setTempDadosQuimicos(resp.data.itens)
    setLoadingTable(false)
  }

  const handleClickNewTipo = () => {
    setVisibleForm(true)
  }

  const handleClickEdit = (cod) => {
    setUpdate(cod)
    setVisibleForm(true)
  }

  const handleSearch = (data) => {
    setLoadingTable(true)
    const dados = dataDadosQuimicos
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setTempDadosQuimicos(response)
      setLoadingTable(false)
    }, 500)
  }

  const handleClickTrash = (cod, nome) => {
    Modal.confirm({
      title: `Deseja remover o ativo ${nome}?`,
      icon: <IconAlert type='confirm' size={6} />,
      okText: 'Sim',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        const response = await delDadosQuimicos(cod)

        if (response.status === 200) {
          successAlert('Ativo removido com sucesso!');

          listDadosQuimicos()
        }
      },
    })
  }

  const handleCloseForm = (data) => {
    setVisibleForm(data)
    if (data) {
      listDadosQuimicos()
    } else {
      setUpdate(0)
    }
  }

  useEffect(() => {
    listDadosQuimicos()
  }, [])

  return (
    <ViewPage
      title='Dados químicos'
      btnClick={handleClickNewTipo}
      search
      inputChange={handleSearch}
      content={
        <FormDadosQuimicos
          onClose={(data) => handleCloseForm(data)}
          visible={visibleForm}
          update={update}
        />
      }
    >
      <Table
        scroll={{ y: 'calc(90vh - 275px)' }}
        rowKey='cod'
        loading={loadingTable}
        size='small'
        dataSource={tempDadosQuimicos}
        pagination={pagination}
        columns={[
          {
            key: 1,
            title: 'Nome',
            dataIndex: 'nome',
          },
          {
            key: 2,
            title: 'Tipo',
            dataIndex: 'tipo_nome',
          },
          {
            key: 3,
            width: 90,
            title: 'Ações',
            dataIndex: 'cod',
            render: (data, record) => (
              <Space>
                <Button
                  shape='circle'
                  type='default'
                  onClick={() => handleClickEdit(data)}
                  icon={<Icon component={() => <Icons icon={faPen} />} />}
                />
                <Button
                  shape='circle'
                  type='primary'
                  danger
                  onClick={() => handleClickTrash(data, record.nome)}
                  icon={<Icon component={() => <Icons icon={faTrash} />} />}
                />
              </Space>
            ),
          },
        ]}
        footer={() => (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'right',
            }}
          >
            <b>Total geral: {dataDadosQuimicos.length}</b>
          </div>
        )}
      />
    </ViewPage>
  )
}

export default RecomendaDadosQuimicos

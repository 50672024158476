import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'
import { Dayjs } from 'dayjs'
import { Notification } from '../../Components/notification'
import { errorAlert, loadingAlert, successAlert } from '../../utils/alert'

export interface TypeGetSafra {
  itens: [SafraItens]
  total: number
}

export interface SafraItens {
  total_area_sub_areas: string
  total_sub_areas: number
  total_propriedades?: number
  cod?: number
  cod_conta?: number
  cod_cliente?: number
  cod_cultura?: number
  cod_cultura_secundaria?: number
  nome?: string
  data_inicio?: string | Dayjs
  data_final?: string | Dayjs
  cultura?: {
    cod?: number
    nome?: string
    cod_tipo?: number
    outros_tipos?: number
    cod_categoria?: number
    geral?: number
  }
  total_area_talhoes?: string
  total_talhoes?: number
}

interface TypeQuery {
  codConta?: number | string
  codCliente?: number | string
  cod?: number
  pagination?: number | -1
  page?: number 
  order?: 'data_inicio' | 'nome'
}

export const getSafra = async (params: TypeQuery) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetSafra> = await api.get('/safra', {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      ...params,
    },
  })
  return response
}

export const getSafraEstatisticas = async () => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetSafra> = await api.get(
    `/safra?codConta=${config.conta}&codCliente=${config.codCliente}&embed=estatisticas`
  )

  return response
}

export const getSafraConsultoria = async () => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetSafra> = await api.get(
    `/safra?codConta=${config.conta}&pagination=-1`
  )

  return response
}

export const postSafra = async (data, desabilitar, messageDestroy?: void, limparCampos?: Function, atualizar?: Function, ) => {
  const config = localConfig();

  loadingAlert({msg: 'Salvando safra...', id: 'save_safra'})

  const response: AxiosResponse<TypeGetSafra> = await api
    .post(`/safra?codConta=${config.conta}&codCliente=${config.codCliente}`, data)
    .then((): any => {
      successAlert('Safra cadastrada com sucesso!', 5000, 'save_safra');
      limparCampos?.()
      atualizar()
      desabilitar = false
    })
    .catch((error) => {
      errorAlert(`${error?.response?.data?.error?.[0]?.msg ?? 'Erro ao cadastrar safra!'}`, 7000, 'save_safra');
    })

  return response
}

export const putSafra = async (codSafra, data, atualizarPut,  setDesabilitar, hancleDrawerClose?:Function,) => {
  const config = localConfig();

  loadingAlert({msg: 'Salvando...', id: 'edit_safra'});

  const speak = (message) => {
    const utterance = new SpeechSynthesisUtterance(message)
    utterance.lang = 'pt-BR'
    window.speechSynthesis.speak(utterance)
  }

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetSafra> = await api
    .put(`/safra/${codSafra}?codConta=${config.conta}&codCliente=${config.codCliente}`, data)

    .then((): any => {
      successAlert('Safra atualizada com sucesso!', 5000, 'edit_safra');
      atualizarPut?.()
      hancleDrawerClose?.()
      setDesabilitar(false)
    })
    .catch((error) => {
      setDesabilitar(false)
      errorAlert(`${error?.response?.data?.error}`, 7000, 'edit_safra')
    })

  return response
}

export const delSafra = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetSafra> = await api.delete(
    `/safra/${cod}?codConta=${config.conta}&codCliente=${config.codCliente}`
  )

  return response
}

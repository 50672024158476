
//ASSETS
import mapLocation from '../../../../assets/svg/mapLocation.svg';
import ChartTreeMap from '../../../../assets/svg/ChartTreeMap.svg';
import PolygonSvg from '../../../../assets/svg/PolygonSvg.svg';
import PolySubStuff from '../../../../assets/svg/PolySubStuff.svg';
import { TalhaoCultivaveisItens } from '../../../../services/TalhaoSafra';
import { orderBy } from 'lodash';
import { errorAlert } from '../../../../utils/alert';

export function kmlToJson(kmlString: string): any {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(kmlString, "application/xml");

    // Inicializa a estrutura do JSON
    const json: any = {
        documentName: '',
        placemarks: [] as any[]
    };

    // Extrai o nome do documento
    const documentNameTag = xmlDoc.getElementsByTagName("name")[0];
    if (documentNameTag) {
        json.documentName = documentNameTag.textContent || '';
    }

    // Processa todas as tags Placemark
    const name_doc = xmlDoc.getElementsByTagName("name");
    const isTtivos = (name_doc?.[0]?.textContent)?.split(' - ')?.[0] == 'Ttivos';
    const placemarks = xmlDoc.getElementsByTagName("Placemark");

    for (let i = 0; i < placemarks.length; i++) {
        const placemark = placemarks[i];
        const placemarkObj: any = {
            indice: '',
            nome: '',
            nomeTalhao: '',
            comentario: '',
            cod: '',
            latitude: '',
            longitude: '',
            coordinates: []
        };

        // Extrai os dados do ExtendedData
        const extendedData = placemark.getElementsByTagName("ExtendedData")[0];
        const Point = placemark.getElementsByTagName("Point")[0];
        if (extendedData && isTtivos) {
            const dataElements = extendedData.getElementsByTagName("Data");
            for (let j = 0; j < dataElements.length; j++) {
                const dataElement = dataElements[j];
                const nameAttr = dataElement.getAttribute("name");
                const value = dataElement.getElementsByTagName("value")[0]?.textContent || '';

                if (nameAttr === "INDICE") placemarkObj.indice = value;
                if (nameAttr === "NAME") placemarkObj.name = value;
                if (nameAttr === "NOME") placemarkObj.nome = value;
                if (nameAttr === "NOME_TALHAO") placemarkObj.nomeTalhao = value;
                if (nameAttr === "COMENTARIO") placemarkObj.comentario = value;
                if (nameAttr === "LATITUDE") placemarkObj.latitude = value;
                if (nameAttr === "LONGITUDE") placemarkObj.longitude = value;
                if (nameAttr === "COD") placemarkObj.cod = value;
                if (nameAttr === "COD_TALHAO") placemarkObj.cod_talhao = value;
            }
        }
        else if (extendedData && !isTtivos) {
            const pointElement = Point.getElementsByTagName("coordinates");

            for (let j = 0; j < pointElement.length; j++) {
                const dataElement = pointElement[j];
                let valor_coordenada = (dataElement?.textContent)?.split(",");

                placemarkObj.latitude = valor_coordenada?.[1]
                placemarkObj.longitude = valor_coordenada?.[0];
            }
        }
        else if (Point) {
            const pointElement = Point.getElementsByTagName("coordinates");

            for (let j = 0; j < pointElement.length; j++) {
                const dataElement = pointElement[j];
                let valor_coordenada = (dataElement?.textContent)?.split(",");

                placemarkObj.latitude = valor_coordenada?.[1]
                placemarkObj.longitude = valor_coordenada?.[0];
            }
        }
        else {
            errorAlert('Não existe!');
        }

        // Extrai as coordenadas
        const coordinatesTag = placemark.getElementsByTagName("coordinates")[0];
        if (coordinatesTag) {
            const coordinatesStr = coordinatesTag.textContent?.trim() || '';
            const coordinatesArr = coordinatesStr.split(" ").map(coord => {
                const [lon, lat, alt] = coord.split(",").map(Number);
                return { lat, lon, alt };
            });
            placemarkObj.coordinates = coordinatesArr;
        }

        // Adiciona o Placemark processado ao JSON
        json.placemarks.push(placemarkObj);
    }

    return json;
}


export function converterKMLdeTerceiro(kml: string): any {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(kml, "application/xml");

    const placemarks = xmlDoc.getElementsByTagName("Placemark");
    const coordinatesArray = [];

    for (let i = 0; i < placemarks.length; i++) {
        const coordinates = placemarks[i]
            .getElementsByTagName("coordinates")[0]
            .textContent?.trim()
            .split(",");

        if (coordinates && coordinates.length >= 2) {
            const latitude = parseFloat(coordinates[0]);
            const longitude = parseFloat(coordinates[1]);
            coordinatesArray.push({ latitude, longitude });
        }
    }

    return coordinatesArray;
};

type TabsPontos = { icone: any, cod_id?: number, descricao: string, disabled?: boolean, disabledMsg?: string, task?: Function, width?: number };

export const tabs_pontos: TabsPontos[] = [
    {
        cod_id: 1,
        descricao: 'Propriedade',
        icone: mapLocation,
    },
    {
        cod_id: 2,
        descricao: 'Agrupamento',
        icone: ChartTreeMap,
    },
    {
        cod_id: 3,
        descricao: 'Talhões',
        icone: PolygonSvg,
    },
    {
        cod_id: 4,
        descricao: 'Subárea',
        icone: PolySubStuff,
    },
];


export const importarArquivoTalhao = (event: React.ChangeEvent<HTMLInputElement>, setJsonResult: Function) => {

    const file = event.target.files?.[0];

    if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
            const kmlString = e.target?.result as string;
            try {
                const json = kmlToJson(kmlString);
                setJsonResult(json);
            } catch (err) {
                console.log("Erro ao converter KML")
                errorAlert('Erro ao converter KML!');
            }
        };

        reader.onerror = function () {
            console.log("Erro ao ler o arquivo.")
            errorAlert('Erro ao ler o arquivo!');
        };

        reader.readAsText(file);
    } else {
        console.log("Nenhum arquivo selecionado.")
        errorAlert('Nenhum arquivo selecionado!');
    }


};

type MarkerType = {
    cod?: number
    nome?: string
    cod_talhao: number
    latitude: number
    longitude: number
}
type MarkerTypeOrdenado = {
    cod?: number
    nome?: string
    cod_talhao: number
    latitude: number
    longitude: number
    ordem: number,
    index_original: number,
}

export function ordernarListaTalhoes(list_markers: MarkerType[]): MarkerTypeOrdenado[] {

    let salvar_index_original = (list_markers ?? []).map((item, index)=> {
        return {
            ...item,
            index_original: index
        }
    })

    let nova_lista: MarkerTypeOrdenado[] = (salvar_index_original ?? []).map(({ nome, latitude, longitude, cod_talhao, cod, index_original }) => {
        let nome_split = (nome??'')?.split('|');
        return {
            nome: nome,
            latitude: latitude,
            longitude: longitude,
            cod_talhao: cod_talhao,
            cod: cod,
            ordem: Number(nome_split?.[nome_split?.length - 1]),
            index_original: index_original
        }
    });

    return orderBy((nova_lista || []), ['ordem'], ['asc'])

}
import styled from "styled-components";
import { useEffect, useState } from "react";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { clearCache } from "clear-cache";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//ASSETS
import iconRefresh from '../../../../../assets/img/iconRefresh.gif';
import ButtonCustom from "../../../../../Components/ButtonCustom";

//STYLES
const Popover = styled.div`
    width: 450px;
    background-color: #fff;
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 15px;
    gap: 15px;
    /* overflow: hidden !important; */
    animation: horizontal-shaking 1s forwards;
    z-index: 9999999;

    :hover {
        animation: none
    }

    @keyframes horizontal-shaking {
        0% { transform: translateX(0) }
        10% { transform: translateX(-7px) }
        20% { transform: translateX(7px) }
        30% { transform: translateX(-7px) }
        40% { transform: translateX(7px) }
        50% { transform: translateX(-7px) }
        60% { transform: translateX(7px) }
        70% { transform: translateX(-7px) }
        80% { transform: translateX(7px) }
        90% { transform: translateX(-7px) }
        100% { transform: translateX(0px) }
    }

    div.Popover__border {
        width: 100%;
        height: 10px;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(270deg, #61FF17, #3ddab4, var(--primary-color));
        background-size: 400% 400%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        -webkit-animation: AnimationName 3s ease infinite;
        -o-animation: AnimationName 3s ease infinite;
        animation: AnimationName 3s ease infinite;
    }

        @-webkit-keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        @-o-keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        @keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }

    div.Popover__icon {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;

        img {
            width: 100%;
            border-radius: 50%;
            /* opacity: 0; */
        }
    }

    div.Popover__info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 3px;
        margin-top: 5px;

        h4 {
            font-weight: 700;
            font-size: 17px;
            color: var(--text-color);
            margin: 0;
            background: linear-gradient(
                to right,
                var(--text-color) 20%,
                #28E901 30%,
                var(--text-color) 80%
            );
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            text-fill-color: transparent;
            background-size: 500% auto;
            animation: textShine 5s ease-in-out infinite alternate;
        }
        @keyframes textShine {
            0% {
                background-position: 0% 50%;
            }
            100% {
                background-position: 100% 50%;
            }
        }
        .animate {
            margin: 0;
            height: 24px;
            
            span {
                display: inline-block;
                font-weight: 700;
                font-size: 16.5px;
                color: var(--text-color);
            }

            span:nth-of-type(2) {
                animation-delay: .05s;
            }
            span:nth-of-type(3) {
                animation-delay: .1s;
            }
            span:nth-of-type(4) {
                animation-delay: .15s;
            }
            span:nth-of-type(5) {
                animation-delay: .2s;
            }
            span:nth-of-type(6) {
                animation-delay: .25s;
            }
            span:nth-of-type(7) {
                animation-delay: .3s;
            }
            span:nth-of-type(8) {
                animation-delay: .35s;
            }
            span:nth-of-type(9) {
                animation-delay: .4s;
            }
            span:nth-of-type(10) {
                animation-delay: .45s;
            }
            span:nth-of-type(11) {
                animation-delay: .5s;
            }
            span:nth-of-type(12) {
                animation-delay: .55s;
            }
            span:nth-of-type(13) {
                animation-delay: .6s;
            }
            span:nth-of-type(14) {
                animation-delay: .65s;
            }
            span:nth-of-type(15) {
                animation-delay: .7s;
            }
            span:nth-of-type(16) {
                animation-delay: .75s;
            }
            span:nth-of-type(17) {
                animation-delay: .8s;
            }
            span:nth-of-type(18) {
                animation-delay: .85s;
            }
            span:nth-of-type(19) {
                animation-delay: .9s;
            }
            span:nth-of-type(20) {
                animation-delay: .95s;
            }
            span:nth-of-type(21) {
                animation-delay: 1s;
            }

        }

        .drop span {
            color: var(--text-color);
            opacity: 0;
            animation: sideSlide 1s ease-in-out forwards;
        }

        @keyframes sideSlide {
            60% {
                transform: translate(25px, 0) scale(1);
                color: #2d3436;
            }

            80% {
                transform: translate(25px, 0) scale(1);
                color: #2d3436;
            }

            99% {
                transform: translate(0) scale(1.2);
                color: #31ff08;
            }

            100% {
                transform: translate(0) scale(1);
                opacity: 1;
                color: #2d3436;
            }
        }

        span {
            font-size: 14px;
            line-height: 14px;
            margin: 0;
            color: var(--text-color-light);
        }
    }

    .megaBall {
        min-width: 10px;
        min-height: 10px;
        /* border-radius: 50%; */
        /* background: radial-gradient(circle, rgba(0,128,0,0) 50%, rgb(255 255 255) 60%, rgba(0,128,0,0) 70%); */
        background: rgb(0,0,0);
        background: linear-gradient(137deg, rgba(0,0,0,0) 55%, rgba(255,255,255,1) 60%, rgba(0,0,0,0) 68%);
        position: absolute;
        bottom: 0;
        right: 0;
        transform-origin: bottom right;
        animation: expantion 7s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;

        @keyframes expantion {
            0% {
                transform: scale(200) translateX(50%);
            }
            100% {
                transform: scale(200)  translateX(-60%);
            }
        }
    }

`;

//PROPS 
interface Props {
    new_version?: string | any;
    setAnimation_blender?: Function;
}

export const PopupUpdate: React.FC<Props> = ({ new_version, setAnimation_blender }) => {

    //STATES
    const [loading, setLoading] = useState(false);
    const [title_gradient, setTitle_gradient] = useState<boolean>(false);
    const [animation_beam, setAnimation_beam] = useState<boolean>(false);

    const atualizarSistema = () => {
        setLoading(true);
        setAnimation_beam(true);
        setTimeout(() => {
            localStorage.setItem('last_handle_update', String(new Date()));
            localStorage.setItem('handle_version_updated', String(new_version))
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then((registrations) => {
                    for (let registration of registrations) {
                        registration.unregister();  // Desativa o service worker
                    }
                    clearCache(false);
                    window.location.href = window.location.origin + window.location.pathname + '?updated=' + new Date().getTime();
                });
            } else {
                clearCache(false);
                window.location.href = window.location.origin + window.location.pathname + '?updated=' + new Date().getTime();
            }
        }, 1000);
        
    };


    useEffect(() => {
        setTimeout(() => {
            setTitle_gradient(true);
        }, 2500);
    }, [])
    
    function animateBeam() {
        setAnimation_beam(true);
        setAnimation_blender?.(true);

        setTimeout(() => {
            setAnimation_beam(false);
            setAnimation_blender?.(false);
        }, 2000);
    }

    return (
        <Popover>
            <div className="Popover__border" />
            <div className="Popover__icon">
                <img src={iconRefresh} />
            </div>
            <div className="Popover__info">

                {title_gradient 
                ?   <h4>Nova versão disponível!</h4>
                :
                    <div className="animate drop">
                        <span>N</span><span>o</span><span>v</span><span>a</span> &nbsp;
                        <span>v</span><span>e</span><span>r</span><span>s</span><span>ã</span><span>o</span> &nbsp;
                        <span>d</span><span>i</span><span>s</span><span>p</span><span>o</span><span>n</span><span>i</span><span>v</span><span>e</span><span>l</span><span>!</span>
                    </div>
                }
                
                <span>Clique no botão abaixo para atualizar para a versão mais recente do sistema.</span>

                <ButtonCustom
                    // onClick={animateBeam}
                    onClick={atualizarSistema}
                    style={{ alignSelf: 'flex-end', height: 40 }}
                    icon={<FontAwesomeIcon icon={faRefresh} />}
                    loading={loading}
                    disabled={loading}
                >
                    Atualizar sistema
                </ButtonCustom>
            </div>

            {animation_beam && <div className="megaBall" />}
        </Popover>
    )
}
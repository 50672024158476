import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'
import { destroyAlert, errorAlert, successAlert } from '../../utils/alert'

export interface TypeGetLabAnalise {
  itens: [LabAnaliseItens]
}

export interface LabAnaliseItens {
  cod?: number
  cod_analise?: number
  cod_laboratorio?: number
  data?: string
  valor?: string
  status?: number
  laboratorio_nome?: string
  analise_nome?: string
  cor?: string
  cod_profundidade?: number
  profundidade_nome?: string
}

export const getLabAnalise = async (cod, codLaboratorio, codAnalise, nome) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetLabAnalise> = await api.get(
    `/laboratorio-analise?codConta=${config.conta}&cod=${cod}&codLaboratorio=${codLaboratorio}&codAnalise=${codAnalise}&nome=${nome}&pagination=-1`
  )

  return response
}

export const postLabAnalise = async (data, setLoadingAddAnalise?: Function) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetLabAnalise> = await api
    .post(`/laboratorio-analise?codConta=${config.conta}`, data)
    .then((): any => {
      destroyAlert()
      successAlert('Análise cadastrada com sucesso!')
      setLoadingAddAnalise?.(false)
    })
    .catch((error) => {
      destroyAlert()
      errorAlert(`${error?.response?.data?.error[0]?.msg[0]}`)
      setLoadingAddAnalise?.(false)
    })

  return response
}

export const putLabAnalise = async (cod, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetLabAnalise> = await api.put(
    `/laboratorio-analise/${cod}?codConta=${config.conta}`,
    data
  )

  return response
}

export const delLabAnalise = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetLabAnalise> = await api.delete(
    `/laboratorio-analise/${cod}?codConta=${config.conta}`
  )

  return response
}


import { AxiosResponse } from 'axios'
import { api } from '../../api'

import { localConfig } from '../../../Configs/localConfig'

export interface TypeCatPatrimonio {
  itens: [CatPatrimonioItens]
}

export interface CatPatrimonioItens {
  cod?: number
  nome?: string
  cod_tipo?: number
  outros_tipos?: number
  cod_categoria?: number
  geral?: number
  exibir_marca?: number
}

interface TypeGetCatPatrimonio {
  cod?: number
  codCategoria?: number
  principal?: number
  permiteMarca?: boolean | string
  order?: string
  pagination?: number
}

export const getCatPatrimonio = async (params: TypeGetCatPatrimonio) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeCatPatrimonio> = await api.get('/categoria-patrimonio', {
    params,
  })

  return response
}

export const postCatPatrimonio = async (data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeCatPatrimonio> = await api.post('/categoria-patrimonio', data)

  return response
}

export const putCatPatrimonio = async (cod, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  let new_data = {...data, cod_categoria: data.cod_categoria ? data.cod_categoria : null} 

  const response: AxiosResponse<TypeCatPatrimonio> = await api.put(
    `/categoria-patrimonio/${cod}`,
    new_data
  )

  return response
}

export const delCatPatrimonio = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeCatPatrimonio> = await api.delete(
    `/categoria-patrimonio/${cod}`
  )

  return response
}

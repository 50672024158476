import { api } from '../../api';
import { useQuery } from 'react-query';
import { localConfig } from '../../../Configs/localConfig'; 
import { errorAlert } from '../../../utils/alert';

//=================================================================================>
// BUSCAR LISTA DE ELEMENTOS
//<=================================================================================

//TYPES
export type Item__MapaFertilidade__Config__Secoes__Mapa = {
    tipo_mapa: number,
    visivel: boolean,
    observacao: string,
    profundidades: {
        profundidade: number,
        visivel: boolean,
        observacao: string
    }[]
}
export type Item__MapaFertilidade__Config__Secoes = {
    secao: number, 
    ordem: number,
    visivel: boolean, 
    observacao: string,
    tipos_mapa: Item__MapaFertilidade__Config__Secoes__Mapa[]
}
export type Item__MapaFertilidade__Config = {
    id: number;
    elemento: number,
    nome: string,
    secoes: Item__MapaFertilidade__Config__Secoes[],
    nova_ordem: number
}
export type Item__MapaFertilidade = {
    texto_introducao: string,
    configuracoes: Item__MapaFertilidade__Config[]
}
type Item = {
    cod_conta: number,
    mapa_fertilidade: Item__MapaFertilidade
}

//INTERFACE
export interface Elemento {
    itens: Item[]
}

export const useGetElementosFertilidade = () => {
    const queryKey: ['parametros-fertilidade'] = [`parametros-fertilidade`];

    const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<Elemento, Error>(queryKey, async () => {
        return api.get(`parametros-fertilidade?codConta=${localConfig()?.conta}&codCliente=${localConfig()?.codCliente}`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                errorAlert(error?.response?.data?.error ?? 'Falha ao obter lista de elementos!');
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_elementos: data, 
        carregando_elementos: isFetching, 
        refetch_data_elementos: refetch,
        isFetched: isFetchedAfterMount,
        isError: isError
    };
};

//=================================================================================>
// BUSCAR PARâMETROS DO ELEMENTO
//<=================================================================================

//TYPES
export type ParametroFertilidade__Itens__Params = {
    cod: number,
    cod_elemento: number,
    secao: number,
    tipo_mapa: number,
    profundidade: number,
    secao_nome: string,
    tipo_mapa_nome: string,
    profundidade_nome: string
}
export type ParametroFertilidade__Itens = {
    id: number,
    cod: number,
    nome: string | null,
    descricao: string | null,
    create_time?: string | null,
    update_time?: string | null,
    delete_time?: string | null,
    unidade?: string | null,
    uso_mapa?: boolean,
    ordem: number,
    nova_ordem: number,
    create_user?: string | null,
    update_user?: string | null,
    parametros: ParametroFertilidade__Itens__Params[]
}
//INTERFACE
interface ParametroFertilidade {
    itens: ParametroFertilidade__Itens[]
}

export const useGetParametrosFertilidadeRef = () => {
    const queryKey: ['ap-elemento-fertilidade'] = [`ap-elemento-fertilidade`];

    const { data, isFetching, isFetched } = useQuery<ParametroFertilidade, Error>(queryKey, async () => {
        return api.get(`ap-elemento-fertilidade?pagination=-1&embed=parametros&usoMapa=true&order=ordem`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                errorAlert(error?.response?.data?.error ?? 'Falha ao obter paramentros dos elementos!');
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false,
            keepPreviousData: true
        }
    );

    return { 
        data_paramentros: data, 
        carregando_parametros: isFetching, 
        isFetched
    };
};
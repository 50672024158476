import React, { useEffect, useState } from 'react'

import { Button, Drawer, Form, Input, Space } from 'antd'
import { tryError } from '../../services/Afins'
import { ItensElementosTypes, putElementos } from '../../services/Elementos'
import { Notification } from '../notification'
import { Container } from './styled'
import { successAlert } from '../../utils/alert'

type Props = {
  open: boolean
  onClose?: (b: boolean) => void
  element: ItensElementosTypes
  refresh?: () => void
}

const FormElementos: React.FC<Props> = (props) => {
  const { open, onClose, element, refresh } = props
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  const handleChangeUnidade = (value: string) => {
    const formattedText = value.replace(/\^/g, '**')

    form.setFieldsValue({ unidade: formattedText })
  }

  const onFinish = async (data) => {
    
    try {
      setLoading(true)
      await putElementos(data)

      refresh?.()
      setLoading(false);
      successAlert('Elemento alterado com sucesso!');
    } catch (error) {
      tryError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    form.setFieldsValue(element)
  }, [element])

  return (
    <Container>
      <Drawer
        getContainer={false}
        placement='left'
        open={open}
        onClose={() => onClose?.(false)}
        footer={
          <Space>
            <Button loading={loading} type='primary' onClick={() => form.submit()}>
              Salvar
            </Button>
            <Button type='primary' danger onClick={() => onClose?.(false)}>
              Fechar
            </Button>
          </Space>
        }
      >
        <Form layout='vertical' onFinish={onFinish} form={form}>
          <Form.Item hidden name='cod' />
          <Form.Item name='nome' label='Nome'>
            <Input />
          </Form.Item>
          <Form.Item name='descricao' label='Descrição'>
            <Input />
          </Form.Item>
          <Form.Item name='unidade' label='Unidade'>
            <Input onChange={(data) => handleChangeUnidade(data.target.value)} />
          </Form.Item>
        </Form>
      </Drawer>
    </Container>
  )
}
export default FormElementos

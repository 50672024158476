import React, { useEffect, useState } from 'react'
import { Radio, Tooltip } from 'antd'
import {
  faArrowsAltH,
  faDharmachakra,
  faDroplet,
  faGasPump,
  faGauge,
  faGear,
  faGripLines,
  faGripLinesVertical,
  faList,
  faListUl,
  faOilCan,
  faPlaneArrival,
  faTachometerAlt,
  faThList,
  faUser,
  faWater,
} from '@fortawesome/free-solid-svg-icons'

//STYLES
import { BodyDrawerCategoria } from '../../styled'

//APP
import { mascaraDecimal, mascaraOdom } from '../../../../../../../services/Afins'
import { apenasNumeros } from '../../../../../../../utils'
import { TypeListaEngrenagem } from '../../../..'
import { combustiveis, dataTipoOrigem } from '../../meta'

//COMPONENTS
import Input from '../../../../../../../Components/CustomUi/input'
import Carregando from '../../../../../../../Components/CustomUi/loading'
import { Render } from '../../../../../../../Components/CustomUi/render'
import { Select } from '../../../../../../../Components/CustomUi/Select'
import { Switcher } from '../../../../../../../Components/CustomUi/Switcher'
import { SecaoPlantadeira } from '../secaoPlantadeira'
import styled from 'styled-components'

interface PropsFormCategoria {
  condicao?: string
  setCondicao?: React.Dispatch<React.SetStateAction<string>>
  categoria?: string
  setCategoria?: React.Dispatch<React.SetStateAction<string>>
  categoriaId?: number
  setCategoriaId?: React.Dispatch<React.SetStateAction<number>>
  categorias: any[]
  tipoCategoria?: string
  setTipoCategoria?: React.Dispatch<React.SetStateAction<string>>
  tipoCategoriaId?: number
  setTipoCategoriaId?: React.Dispatch<React.SetStateAction<number>>
  dataTipoCategorias?: any[]
  marca?: string
  setMarca?: React.Dispatch<React.SetStateAction<string>>
  marcaId?: number
  setMarcaId?: React.Dispatch<React.SetStateAction<number>>
  dataMarca?: any[]
  tab_motorizado?: boolean
  setTab_motorizado?: React.Dispatch<React.SetStateAction<boolean>>
  tipoMedidor?: string
  setTipoMedidor?: React.Dispatch<React.SetStateAction<string>>
  tipoMedidorId?: number
  setTipoMedidorId?: React.Dispatch<React.SetStateAction<number>>
  tipoOdom?: any[]
  odom_horimetro?: string
  setOdomHorimetro?: React.Dispatch<React.SetStateAction<string>>
  tipoOrigem?: string
  setTipoOrigem?: React.Dispatch<React.SetStateAction<string>>
  tipoOrigemId?: number
  setTipoOrigemId?: React.Dispatch<React.SetStateAction<number>>
  proprietarioOrigem?: string
  setProprietarioOrigem?: React.Dispatch<React.SetStateAction<string>>
  loading?: boolean
  cod_patrimonio?: number
  tipoCombustivel?: string
  setTipoCombustivel?: React.Dispatch<React.SetStateAction<string>>
  setTipoCombustivelId?: React.Dispatch<React.SetStateAction<number>>
  motorizado: boolean
  lista_engrenagens: TypeListaEngrenagem[]
  setLista_engrenagens: React.Dispatch<React.SetStateAction<TypeListaEngrenagem[]>>
  setValidarEngrenagem: React.Dispatch<React.SetStateAction<boolean>>
  numeroDeLinhas: string
  setNumeroDeLinhas: React.Dispatch<React.SetStateAction<string>>
  espaçamentoDasLinhas: string
  setEspaçamentosDasLinhas: React.Dispatch<React.SetStateAction<string>>
  capacidade_tanque_calda: string
  setCapacidade_tanque_calda: React.Dispatch<React.SetStateAction<string>>
  numero_discos: string
  setNumero_discos: React.Dispatch<React.SetStateAction<string>>
  diametro_discos: string
  setDiametro_discos: React.Dispatch<React.SetStateAction<string>>
}

export const CategoriaForm: React.FC<PropsFormCategoria> = ({
  lista_engrenagens,
  setLista_engrenagens,
  categoria,
  setCategoria,
  categoriaId,
  setCategoriaId,
  categorias,
  tipoCategoria,
  setTipoCategoria,
  tipoCategoriaId,
  setTipoCategoriaId,
  marca,
  setMarca,
  setMarcaId,
  tab_motorizado,
  setTab_motorizado,
  dataTipoCategorias,
  dataMarca,
  tipoMedidor,
  setTipoMedidor,
  tipoMedidorId,
  setTipoMedidorId,
  tipoOdom,
  odom_horimetro,
  setOdomHorimetro,
  condicao,
  setCondicao,
  tipoOrigem,
  tipoOrigemId,
  proprietarioOrigem,
  setProprietarioOrigem,
  setTipoOrigem,
  setTipoOrigemId,
  loading,
  tipoCombustivel,
  setTipoCombustivel,
  setTipoCombustivelId,
  motorizado,
  cod_patrimonio,
  setValidarEngrenagem,
  numeroDeLinhas,
  setNumeroDeLinhas,
  espaçamentoDasLinhas,
  setEspaçamentosDasLinhas,
  capacidade_tanque_calda,
  setCapacidade_tanque_calda,
  numero_discos,
  setNumero_discos,
  diametro_discos,
  setDiametro_discos,
}) => {
  //STATES
  const [openCategoria, setOpenCategoria] = useState<boolean>(false)
  const [openTipoCategoria, setOpenTipoCategoria] = useState<boolean>(false)
  const [openMarca, setOpenMarca] = useState<boolean>(false)
  const [openTipoMedidor, setOpenTipoMedidor] = useState<boolean>(false)
  const [openTipoOrigem, setOpenTipoOrigem] = useState<boolean>(false)
  const [openTipoCombustivel, setOpenTipoCombustivel] = useState<boolean>(false)
  const [limiteLinhas, setLimiteLinhas] = useState<boolean>(false)
  const [limiteEspacamento, setLimiteEspacamento] = useState<boolean>(false)

  const ErrorSpan = styled.span`
    font-weight: 500;
    color: red;
    margin-top: -10px;
    font-size: 13px;
    line-height: 13px;
  `

  function mascaraDecimal(value) {
    let valor = value
    // valor = valor.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    // valor = (valor / 100).toFixed(2).replace('.', '.')
    valor = valor.replace(/\D/g, '')

    valor = valor ? (parseFloat(valor) / 100).toFixed(2) : '0.00'

    if (valor < '0.01' || valor > '10.00') {
      setLimiteEspacamento(true)
    } else {
      setLimiteEspacamento(false)
    }

    setEspaçamentosDasLinhas(valor)
  }

  function verificarLimiteLinhas(linhas: string) {
    let val = linhas.replace(/\D/g, '')
    let converter = parseFloat(linhas)

    if (converter <= 0 || converter > 100) {
      setLimiteLinhas(true)
    } else {
      setLimiteLinhas(false)
    }

    setNumeroDeLinhas(val)
  }

  useEffect(() => {
    if (odom_horimetro !== '') {
      setTab_motorizado(true)
    }
  }, [odom_horimetro])

  useEffect(() => {
    if (odom_horimetro !== '') {
      setTab_motorizado(true)
    }
  }, [odom_horimetro])

  return (
    <BodyDrawerCategoria>
      <Select
        value={categoria}
        onChange={setCategoria}
        onChangeID={setCategoriaId}
        open={openCategoria}
        trigger={setOpenCategoria}
        items={categorias}
        descricaoItem={'nome'}
        idItem={'cod'}
        icon={faListUl}
        placeholder='Selecione a categoria'
        label='Categoria'
      />

      <Render condition={loading && categoriaId > 0}>
        <Carregando
          justifyContent='center'
          height={120}
          size={'default'}
          legenda='Carregando tipo categorias...'
          animation
        />
      </Render>

      <Render condition={dataTipoCategorias.length > 0}>
        <div className='campo__dois__inputs'>
          <Select
            value={tipoCategoria}
            onChange={setTipoCategoria}
            onChangeID={setTipoCategoriaId}
            open={openTipoCategoria}
            trigger={setOpenTipoCategoria}
            items={dataTipoCategorias}
            descricaoItem={'nome'}
            idItem={'cod'}
            icon={faThList}
            placeholder='Selecione o tipo categoria'
            label='Tipo categoria'
          />

          <Render condition={dataMarca.length > 0}>
            <Select
              value={marca}
              onChange={setMarca}
              onChangeID={setMarcaId}
              open={openMarca}
              trigger={setOpenMarca}
              items={dataMarca}
              descricaoItem={'nome'}
              idItem={'cod'}
              icon={faList}
              placeholder='Selecione a marca'
              label='Marca'
            />
          </Render>
        </div>
      </Render>

      <Render condition={loading === false && categoriaId > 0}>
        <div className='campo__dois__inputs'>
          <div className='radio-motorizado'>
            <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
              <label style={{ paddingBottom: '3px', fontSize: '13px' }}>Condição aquisição</label>
            </div>
            <Radio.Group
              value={condicao}
              onChange={(e) => setCondicao(e.target.value)}
              className='radio-group'
              style={{
                height: '100%',
              }}
              optionType='button'
              buttonStyle='solid'
              options={[
                {
                  label: 'Novo',
                  value: '1',
                  style: { width: '50%', height: '85%', textAlign: 'center' },
                },
                {
                  label: 'Usado',
                  value: '2',
                  style: { width: '50%', height: '85%', textAlign: 'center' },
                },
              ]}
            />
          </div>
          <Select
            value={tipoOrigem}
            onChange={setTipoOrigem}
            onChangeID={setTipoOrigemId}
            open={openTipoOrigem}
            trigger={setOpenTipoOrigem}
            items={dataTipoOrigem}
            descricaoItem={'nome'}
            idItem={'cod'}
            icon={faList}
            placeholder='Selecione o tipo de origem'
            label='Tipo origem'
          />
        </div>
      </Render>

      <Render condition={!loading && categoriaId === 27 && tipoCategoriaId === 108}>
        <div className='campo__dois__inputs'>
          <div style={{ width: '100%' }}>
            <Input
              placeholder='Digite a quantidade de linhas'
              value={apenasNumeros(numeroDeLinhas)}
              onChange={verificarLimiteLinhas}
              label='Número de linhas'
              icon={faGripLinesVertical}
              maxLength={3}
              error={limiteLinhas}
              obrigatorio
            />

            <Render condition={limiteLinhas}>
              <Tooltip title='informar valor válido'>
                <ErrorSpan>informar valor de linhas válido, entre 1 e 100</ErrorSpan>
              </Tooltip>
            </Render>
          </div>
          <div style={{ width: '100%' }}>
            <Input
              placeholder='Digite o espaçamento das linhas'
              value={espaçamentoDasLinhas}
              onChange={mascaraDecimal}
              label='Espaçamento das linhas(m)'
              icon={faArrowsAltH}
              error={limiteEspacamento}
              obrigatorio
            />
            <Render condition={limiteEspacamento}>
              <Tooltip title='informar valor válido'>
                <ErrorSpan>informar o espaçamento de linhas válido, entre 0.01 e 10.00 m</ErrorSpan>
              </Tooltip>
            </Render>
          </div>
        </div>
      </Render>

      <Render
        condition={(!loading && tipoOrigemId === 2) || tipoOrigemId === 3 || tipoOrigemId === 4}
      >
        <Input
          placeholder='Digite o nome do proprietário '
          value={proprietarioOrigem}
          onChange={setProprietarioOrigem}
          label='Proprietário de origem'
          icon={faUser}
        />
      </Render>

      <Render condition={!loading && categoriaId === 27}>
        <div style={{ width: '31%', display: 'flex', alignItems: 'center' }}>
          <Switcher
            isChecked={tab_motorizado}
            trigger={setTab_motorizado}
            label='Tipo motorizado?'
          />
        </div>
      </Render>

      <Render
        condition={
          (categoriaId === 27 && tipoCategoriaId === 63) || //grade aradora
          tipoCategoriaId === 64 ||
          tipoCategoriaId === 65 ||
          tipoCategoriaId === 66
        }
      >
        <div className='campo__dois__inputs'>
          <Input
            placeholder='Digite o número de discos'
            value={apenasNumeros(numero_discos)}
            onChange={setNumero_discos}
            label='Número de discos'
            icon={faDharmachakra}
          />
          <Input
            placeholder='Digite o diâmetro dos discos'
            value={apenasNumeros(diametro_discos)}
            onChange={setDiametro_discos}
            label='Diâmetro dos discos(polegadas)'
            icon={faArrowsAltH}
          />
        </div>
      </Render>

      <Render
        condition={
          categoriaId === 524 || //avião agricola
          (categoriaId === 2 && tipoCategoriaId === 61) || // pulverizador de arrasto
          (categoriaId === 27 && tipoCategoriaId === 62) //  máquina pulverizador autopropelido
        }
      >
        <Input
          placeholder='Digite a capacidade do tanque da calda'
          value={apenasNumeros(capacidade_tanque_calda)}
          onChange={setCapacidade_tanque_calda}
          label='Capacidade do tanque de calda(Litros)'
          icon={faDroplet}
        />
      </Render>

      {/* {loading === false ? (
        categoriaId === 27 || categoriaId === 2 ? (
          categoriaId === 27 ? (
            <div style={{ width: '31%', display: 'flex', alignItems: 'center' }}>
              <Switcher
                isChecked={tab_motorizado}
                trigger={setTab_motorizado}
                label='Tipo motorizado?'
              />
            </div>
          ) : null
        ) : null
      ) : null} */}

      <Render
        condition={
          !loading &&
          (tab_motorizado === true ||
            categoriaId === 26 ||
            categoriaId === 2 ||
            categoriaId === 224 ||
            categoriaId === 223)
        }
      >
        <>
          <Select
            value={tipoMedidor}
            onChange={setTipoMedidor}
            onChangeID={setTipoMedidorId}
            open={openTipoMedidor}
            trigger={setOpenTipoMedidor}
            items={tipoOdom}
            descricaoItem={'label'}
            idItem={'value'}
            icon={faTachometerAlt}
            placeholder='Selecione o tipo de medidor'
            label='Tipo de medidor'
          />
          <Render condition={tipoMedidorId === 1 || tipoMedidorId === 2}>
            <Input
              placeholder={
                tipoMedidorId === 1
                  ? 'Digite o valor do hodômetro'
                  : 'Digite a leitura do horímetro'
              }
              value={mascaraOdom(odom_horimetro)}
              onChange={setOdomHorimetro}
              label={tipoMedidorId === 1 ? 'Odômetro' : 'Horímetro'}
              icon={faGauge}
            />
          </Render>
        </>
      </Render>

      <Render
        condition={
          categoriaId === 26 ||
          (categoriaId === 27 && motorizado === true) ||
          categoriaId === 2 ||
          categoriaId === 224 ||
          categoriaId === 223
        }
      >
        <Select
          value={tipoCombustivel}
          onChange={setTipoCombustivel}
          onChangeID={setTipoCombustivelId}
          open={openTipoCombustivel}
          trigger={setOpenTipoCombustivel}
          items={combustiveis}
          descricaoItem={'nome'}
          idItem={'cod'}
          icon={faGasPump}
          placeholder='Selecione o tipo de combustível'
          label='Tipo combustível'
        />
      </Render>

      <Render condition={!loading && tipoCategoriaId === 108}>
        <SecaoPlantadeira
          lista_engrenagens={lista_engrenagens}
          setLista_engrenagens={setLista_engrenagens}
          cod_patrimonio={cod_patrimonio}
          setValidarEngrenagem={setValidarEngrenagem}
        />
      </Render>
    </BodyDrawerCategoria>
  )
}
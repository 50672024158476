import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useRef } from "react";
import { faAngleDoubleRight, faAngleLeft, faAngleRight, faAngleUp } from "@fortawesome/free-solid-svg-icons";

//APP
import { generatePaginationv2 } from "./meta";
import { apenasNumeros, arredondarNumero, gerarKey, leadZero } from "../../../utils";

//STYLES
import { ContainerPagination } from "./style";

//COMPONENTS
import Checkbox from "../checkbox";
import { Render } from "../render";

//PROPS
interface Props {
    paginate: number;
    itensPage?: number;
    itensPagesOptions?: number[];
    onChangeItensPage?: Function;
    onChangePage?: Function;
    current_page?: number;
    setCurrent_page?: React.Dispatch<React.SetStateAction<number>>;
}

export const Pagination: React.FC<Props> = ({ paginate = 0, itensPage = 15, itensPagesOptions = [15, 30, 50, 75, 100], 
    onChangeItensPage, onChangePage, current_page = 1, setCurrent_page }) => {

    //REFs
    const refPerPage = useRef(null);

    //STATES - ITEMS PER PAGE
    const [itens_per_page, setItens_per_page] = useState<number>(itensPage);
    const [open_per_page, setOpen_per_page] = useState<boolean>(false);
    //STATES - PAGE
    const [go_to, setGo_to] = useState<string>('1');
    
    //CONST
    const list_pages = (generatePaginationv2(arredondarNumero((paginate / itens_per_page), 'up'), 7, current_page));

    useEffect(() => {
        let last_page = Math.floor(list_pages?.[list_pages.length - 1])
        if (Number(go_to) > last_page) {
            setGo_to(String(last_page + 1));
        }
    }, [go_to]);

    useEffect(() => {
        setGo_to(String(current_page))
    }, [current_page])
    
    const handleClickOutside: any = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (refPerPage.current && !refPerPage.current.contains(event.target)) {
            setOpen_per_page(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    function finalRange() {

        let final = itens_per_page * current_page

        return (final < paginate) ? final : paginate
    }

    return (
        <ContainerPagination>
            <div className="ContainerPagination__total">
                <b>{leadZero(itens_per_page * (current_page - 1) + 1)} - {finalRange()}</b> de {paginate} registros
            </div>


            <div className="ContainerPagination__pages">
                <Render condition={paginate >= 15}>
                    <div className="CP__pages__perPage" data-open={open_per_page}>
                        <button className="CP__pages__perPage__main" onClick={() => { setOpen_per_page(!open_per_page) }}>
                            {itens_per_page} por página
                            <FontAwesomeIcon icon={faAngleUp} />
                        </button>
                        <div className="CP__pages__perPage__options" data-open={open_per_page} ref={refPerPage}>
                            {(itensPagesOptions ?? []).map((item) => {
                                return (
                                    <button
                                        className="CP__pages__perPage__options__item"
                                        data-active={itens_per_page === item} key={item}
                                        onClick={() => {
                                            setItens_per_page(item);
                                            setOpen_per_page(false);
                                            setCurrent_page?.(1);
                                            setGo_to('1');
                                            onChangeItensPage?.(item);
                                        }}>
                                        <Checkbox isChecked={itens_per_page === item} trigger={() => { }} style={{ width: 'auto' }} />
                                        {item} por página
                                    </button>
                                )
                            })}
                        </div>
                    </div>

                    
                    <div className="CP__pages__pagination">
                        <button className="CP__pages__pagination__page" disabled={current_page===1} onClick={()=>setCurrent_page(current_page=> current_page - 1)}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>{ }
                        {(list_pages ?? []).map((item, index) => {
                            let pagina = Number((item + 1).toFixed())
                            return (
                                <button
                                    key={gerarKey(index)}
                                    className="CP__pages__pagination__page"
                                    onClick={() => { setCurrent_page?.(pagina); setGo_to(String(pagina)); onChangePage?.(pagina) }}
                                    data-active={pagina === current_page}
                                    disabled={pagina === -1}
                                >
                                    {pagina === -1 ? '...' : pagina}
                                </button>
                            )

                        })}
                        <button className="CP__pages__pagination__page" disabled={current_page === (Math.floor(list_pages?.[list_pages.length - 1]) + 1)} onClick={()=>setCurrent_page(current_page=> current_page + 1)}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    </div>

                    <div className="CP__pages__goTo">
                        <input
                            className="CP__pages__goTo__input"
                            value={apenasNumeros(go_to)}
                            onChange={(e) => setGo_to(e.target.value)}
                        />
                        <button className="CP__pages__goTo__btn" onClick={() => { setCurrent_page?.(Number(go_to)); onChangePage?.(Number(go_to)) }}>
                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </button>
                    </div>
                </Render>
            </div>
        </ContainerPagination>
    )
}
import React, { useState } from 'react'
import Input from '../../../CustomUi/input'
import { faCity, faEnvelope, faHashtag, faHouse, faHouseUser, faIdBadge, faLocationDot, faMap, faMicroscope, faMobileScreenButton, faPhone, faPlus } from '@fortawesome/free-solid-svg-icons'
import { cepMask, cpfMask, fixoMask, phoneMask } from '../../../../services/Afins'
import { Select } from '../../../CustomUi/Select'

interface PropsFormPessoaFisica {
  nome: string
  setNome: React.Dispatch<React.SetStateAction<string>>
  cpf: string
  setCpf: React.Dispatch<React.SetStateAction<string>>
  celular: string
  setCelular: React.Dispatch<React.SetStateAction<string>>
  foneFixo: string
  setFoneFixo: React.Dispatch<React.SetStateAction<string>>
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  uf: string
  setUf: React.Dispatch<React.SetStateAction<string>>
  ufId: number
  setUfId: React.Dispatch<React.SetStateAction<number>>
  cidade: string
  setCidade: React.Dispatch<React.SetStateAction<string>>
  cidadeId: number
  setCidadeId: React.Dispatch<React.SetStateAction<number>>
  cep: string
  setCep: React.Dispatch<React.SetStateAction<string>>
  endereco: string
  setEndereco: React.Dispatch<React.SetStateAction<string>>
  numero: string
  setNumero: React.Dispatch<React.SetStateAction<string>>
  bairro: string
  setBairro: React.Dispatch<React.SetStateAction<string>>
  complemento: string
  setComplemento: React.Dispatch<React.SetStateAction<string>>
  inputDisableCidade: boolean
  haldleChangeCidadeUf: React.Dispatch<React.SetStateAction<number | null>>
  dataUf: any[]
  dataCity: any[]
  dataCidadeFilter: any[]
}

export const FormPessoaFisicaLab: React.FC<PropsFormPessoaFisica> = ({
  nome,
  setNome,
  cpf,
  setCpf,
  celular,
  setCelular,
  foneFixo,
  setFoneFixo,
  email,
  setEmail,
  uf,
  setUf,
  ufId,
  setUfId,
  cidade,
  setCidade,
  cidadeId,
  setCidadeId,
  cep,
  setCep,
  endereco,
  setEndereco,
  numero,
  setNumero,
  bairro,
  setBairro,
  complemento,
  setComplemento,
  haldleChangeCidadeUf,
  inputDisableCidade,
  dataUf,
  dataCity,
  dataCidadeFilter
}) => {

  const [openUf, setOpenUf] = useState(false)
  const [openCidade, setOpenCidade] = useState(false)

  return (

    <>
      <Input
        placeholder='Digite o nome'
        value={nome}
        onChange={setNome}
        label='Nome'
        icon={faMicroscope}
      />
      <Input
        placeholder='000.000.000-00'
        value={cpfMask(cpf)}
        onChange={setCpf}
        label='CPF'
        icon={faIdBadge}
        maxLength={14}
      />
       <div className='campo__dois__inputs'>
            <Input
              placeholder='(00) 00000-0000'
              value={phoneMask(celular)}
              onChange={setCelular}
              label='Celular'
              icon={faMobileScreenButton}
              maxLength={15}
            />
            <Input
              placeholder='(00) 0000-0000'
              value={fixoMask(foneFixo)}
              onChange={setFoneFixo}
              label='Telefone Fixo'
              icon={faPhone}
              maxLength={14}
            />
          </div>

          <Input
            placeholder='Digite um email'
            value={email}
            onChange={setEmail}
            label='Email'
            icon={faEnvelope}
          />

          <div className='campo__dois__inputs'>
            <Input
              placeholder='Digite o cep'
              value={cepMask(cep)}
              onChange={setCep}
              label='CEP'
              icon={faLocationDot}
              maxLength={9}
            />
            <Select
              value={uf}
              onChange={setUf}
              onChangeID={haldleChangeCidadeUf}
              open={openUf}
              trigger={setOpenUf}
              items={dataUf}
              descricaoItem='sigla'
              idItem='cod'
              label='UF'
              placeholder='Selecione a uf do estado'
              icon={faMap}
            />
          </div>

          <div className='campo__dois__inputs'>
            <Select
              value={cidade}
              onChange={setCidade}
              onChangeID={setCidadeId}
              open={openCidade}
              trigger={setOpenCidade}
              items={dataCity.length > 0 ? dataCity : dataCidadeFilter}
              descricaoItem={'nome'}
              idItem={'cod'}
              label='Cidade'
              placeholder='Selecione a cidade'
              icon={faCity}
              disabled={inputDisableCidade}
            />
            <Input
              placeholder='Informe o bairro'
              value={bairro}
              onChange={setBairro}
              label='Bairro'
              icon={faHouse}
            />
          </div>

          <div className='campo__dois__inputs'>
            <Input
              placeholder='Informe o endereço'
              value={endereco}
              onChange={setEndereco}
              label='Endereço'
              icon={faHouseUser}
            />
            <Input
              placeholder='Informe o numero'
              value={numero}
              onChange={setNumero}
              label='Numero'
              icon={faHashtag}
            />
          </div>

          <Input
            placeholder='Informe o complemento'
            value={complemento}
            onChange={setComplemento}
            label='Complemento'
            icon={faPlus}
          />
    </>
  )
}

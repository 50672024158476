

export function verificarSeHouveAlteracoes(original: any, formulario: any, setOpenPopover: Function, onClose: Function) {

    function verificarEngrenagens() {
        if ((original?.engrenagem ?? []).length !== (formulario?.engrenagens ?? []).length) {
            return false;
        }

        let originais = (original?.engrenagem ?? []).map(item=> item.cod);
        let formularios = (formulario?.engrenagens ?? []).map(item=> item.cod);

        const originais_ordenados = [...originais].sort((a, b) => a - b);
        const formularios_ordenados = [...formularios].sort((a, b) => a - b);
        
        return originais_ordenados.every((value, index) => value === formularios_ordenados[index]);

    }

    function formatarString(valor_em_string: string): string {
        if (valor_em_string===''||valor_em_string===null||valor_em_string===undefined) {
            return '';
        }
        const numero = parseFloat(valor_em_string.replace('.', '').replace(',', '.'));
        return String(numero.toFixed(2));
    }

    let ano_fabricacao:     boolean = Number(original?.ano ?? 0) === Number(formulario?.ano_fabricacao ?? 0);
    let ano_modelo:         boolean = Number(original?.ano_modelo ?? 0) === Number(formulario?.ano_modelo ?? 0);
    let marca:              boolean = Number(original?.cod_marca ?? 0) === Number(formulario?.marca ?? 0);
    let tipo_medidor:       boolean = Number(original.tipo_odom ?? 0)  === Number(formulario?.tipo_medidor ?? 0);
    let tipo_categoria:     boolean = Number(original?.cod_tipo ?? 0)  === Number(formulario?.tipo_categoria ?? 0);
    let categoria:          boolean = Number(original?.tipo?.cod_categoria === null ? original?.tipo?.cod : original?.tipo?.cod_categoria ?? 0) === Number(formulario?.categoria ?? 0);
    let condicao_aquisicao: boolean = Number(original?.condicao_aquisicao ?? 0)  === Number(formulario?.condicao_aquisicao ?? 0);
    let responsavel_pela_frota:boolean = Number(original?.responsavel?.[0]?.cod ?? 0) === Number(formulario?.responsavel_pela_frota ?? 0);

    let frota:              boolean = String(original?.frota ?? '') === String(formulario?.frota ?? '');
    let placa:              boolean = String(original?.placa?? '') === String(formulario?.placa ?? '');
    let valor:              boolean = String(original?.valor?? '') === String(formulario?.valor ?? '');
    let dataAquisicao:      boolean = String(original?.data_aquisicao?? '') === String(formulario?.dataAquisicao ?? '');
    let renavam:            boolean = String(original?.renavam?? '').toUpperCase() === String(formulario?.renavam ?? '').toUpperCase();
    let tipo_origem:        boolean = String(original?.origem_patrimonio ?? '') === String(formulario?.tipo_origem ?? '');
    let tipo_combustivel:   boolean = String(original?.tipo_combustivel?? '') === String(formulario?.tipo_combustivel ?? '');
    let numeracao_do_motor: boolean = String(original?.numeracao_motor?? '') === String(formulario?.numeracao_do_motor ?? '');
    let numero_linhas: boolean = String(original?.numero_linhas?? '') === String(formulario?.numero_linhas ?? '');
    let espacamento_linhas: boolean = String(original?.espacamento_linhas?? '') === String(formulario?.espacamento_linhas ?? '');
    let capacidade_tanque_calda: boolean = String(original?.capacidade_tanque_calda?? '') === String(formulario?.capacidade_tanque_calda ?? '');
    let numero_discos: boolean = String(original?.numero_discos?? '') === String(formulario?.numero_discos ?? '');
    let diametro_discos: boolean = String(original?.diametro_discos?? '') === String(formulario?.diametro_discos ?? '');
    let proprietario_origem:boolean = String(original?.proprietario_origem?? '').toUpperCase() === String(formulario?.proprietario_origem ?? '').toUpperCase();
    let nome:   boolean = String(original?.nome ?? '')   === String(formulario?.nome ?? '');
    let serie:  boolean = String(original?.serie ?? '')  === String(formulario?.serie ?? '');
    let chassi: boolean = String(original?.chassi ?? '') === String(formulario?.chassi ?? '');
    let cla:    boolean = String(original?.numeracao_cla ?? '') === String(formulario?.cla ?? '');
    let crv:    boolean = String(original?.numeracao_crv ?? '') === String(formulario?.crv ?? '');
    let modelo: boolean = String(original?.modelo ?? '').toUpperCase() === String(formulario?.modelo ?? '').toUpperCase();
    let hodometro: boolean = String(original?.odom_horimetro ?? '') === String(formatarString(formulario?.hodometro) ?? '');
    let observacao: boolean = String(original?.descricao ?? '') === String(formulario?.observacao ?? '');

    // let tipo_motorizado: boolean = Boolean(original?.motorizado ?? 0) === Boolean(formulario?.tipo_motorizado ?? 0);
    let engrenagens:     boolean = verificarEngrenagens();

    const possui_campo_alterado: boolean = Object.values({
        categoria,
        tipo_categoria,
        marca,
        condicao_aquisicao,
        tipo_origem,
        tipo_combustivel,
        tipo_medidor,
        numeracao_do_motor,
        numero_linhas,
        espacamento_linhas,
        capacidade_tanque_calda,
        numero_discos,
        diametro_discos,
        valor,
        dataAquisicao,
        engrenagens,
        placa,
        proprietario_origem,
        renavam,
        nome,
        modelo,
        chassi,
        serie,
        cla,
        crv,
        frota,
        responsavel_pela_frota,
        ano_fabricacao,
        ano_modelo,
        hodometro,
        observacao
    }).includes(false);

    if (possui_campo_alterado) {
        setOpenPopover(true);
    }
    else {
        onClose(false);
    }

}

    // console.log("tipo_combustivel__ORIGINAL: ", String(original?.odom_horimetro?? ''));
    // console.log("tipo_combustivel__FORMULARIO: ", String(formatarString(formulario?.hodometro ?? '')?? ''));
    // console.log("frota: ", frota);
    // console.log("ano_fabricacao: ", ano_fabricacao);
    // console.log("ano_modelo: ", ano_modelo);
    // console.log("marca: ", marca);
    // console.log("tipo_medidor: ", tipo_medidor);
    // console.log("tipo_categoria: ", tipo_categoria);
    // console.log("categoria: ", categoria);
    // console.log("condicao_aquisicao: ", condicao_aquisicao);
    // console.log("responsavel_pela_frota: ", responsavel_pela_frota);
    // console.log("placa: ", placa);
    // console.log("valor: ", valor);
    // console.log("dataAquisicao: ", dataAquisicao);
    // console.log("renavam: ", renavam);
    // console.log("tipo_origem: ", tipo_origem);
    // console.log("tipo_combustivel: ", tipo_combustivel);
    // console.log("numeracao_do_motor: ", numeracao_do_motor);
    // console.log("proprietario_origem: ", proprietario_origem);
    // console.log("nome: ", nome);
    // console.log("serie: ", serie);
    // console.log("chassi: ", chassi);
    // console.log("cla: ", cla);
    // console.log("crv: ", crv);
    // console.log("modelo: ", modelo);
    // console.log("hodometro: ", hodometro);
    // console.log("tipo_motorizado: ", tipo_motorizado);
    // console.log("engrenagens: ", engrenagens);


export function verificarSeAlgoEstaPreenchido(formulario: any, setOpenPopover: Function, onClose: Function) {

    let ano_fabricacao:     boolean = Number(formulario?.ano_fabricacao ?? 0) > 0;
    let ano_modelo:         boolean = Number(formulario?.ano_modelo ?? 0) > 0;
    let marca:              boolean = Number(formulario?.marca ?? 0) > 0;
    let tipo_medidor:       boolean = Number(formulario?.tipo_medidor ?? 0) > 0;
    let tipo_categoria:     boolean = Number(formulario?.tipo_categoria ?? 0) > 0;
    let categoria:          boolean = Number(formulario?.categoria ?? 0) > 0;
    let condicao_aquisicao: boolean = Number(formulario?.condicao_aquisicao ?? 0) > 0;
    let respons_pela_frota: boolean = Number(formulario?.responsavel_pela_frota ?? 0) > 0;

    let frota:              boolean = String(formulario?.frota ?? '0') !== '';
    let placa:              boolean = String(formulario?.placa ?? '') !== '';
    let valor:              boolean = String(formulario?.valor ?? '') !== '';
    let dataAquisicao:      boolean = String(formulario?.dataAquisicao ?? '') !== '';
    let renavam:            boolean = String(formulario?.renavam ?? '').toUpperCase() !== '';
    let tipo_origem:        boolean = String(formulario?.tipo_origem ?? '') !== '';
    let tipo_combustivel:   boolean = String(formulario?.tipo_combustivel ?? '') !== '';
    let numeracao_do_motor: boolean = String(formulario?.numeracao_do_motor ?? '') !== '';
    let numero_linhas: boolean = String(formulario?.numero_linhas ?? '') !== '';
    let espacamento_linhas: boolean = String(formulario?.espacamento_linhas ?? '') !== '';
    let capacidade_tanque_calda: boolean = String(formulario?.capacidade_tanque_calda ?? '') !== '';
    let numero_discos: boolean = String(formulario?.numero_discos ?? '') !== '';
    let diametro_discos: boolean = String(formulario?.diametro_discos ?? '') !== '';
    let proprietari_origem: boolean = String(formulario?.proprietario_origem ?? '').toUpperCase() !== '';

    let nome:   boolean = String(formulario?.nome ?? '') !== '';
    let serie:  boolean = String(formulario?.serie ?? '') !== '';
    let chassi: boolean = String(formulario?.chassi ?? '') !== '';
    let cla:    boolean = String(formulario?.cla ?? '') !== '';
    let crv:    boolean = String(formulario?.crv ?? '') !== '';
    let modelo: boolean = String(formulario?.modelo ?? '').toUpperCase() !== '';
    let hodometro: boolean = String(formulario?.hodometro ?? '') !== '';
    let observacao: boolean = String(formulario?.observacao ?? '') !== '';

    let engrenagens:     boolean = (formulario?.engrenagens ?? []).length > 0;

    
    // console.log("frota: ", frota);
    // console.log("ano_fabricacao: ", ano_fabricacao);
    // console.log("ano_modelo: ", ano_modelo);
    // console.log("marca: ", marca);
    // console.log("tipo_medidor: ", tipo_medidor);
    // console.log("tipo_categoria: ", tipo_categoria);
    // console.log("categoria: ", categoria);
    // console.log("condicao_aquisicao: ", condicao_aquisicao);
    // console.log("responsavel_pela_frota: ", respons_pela_frota);
    // console.log("placa: ", placa);
    // console.log("valor: ", valor);
    // console.log("dataAquisicao: ", dataAquisicao);
    // console.log("renavam: ", renavam);
    // console.log("tipo_origem: ", tipo_origem);
    // console.log("tipo_combustivel: ", tipo_combustivel);
    // console.log("numeracao_do_motor: ", numeracao_do_motor);
    // console.log("proprietario_origem: ", proprietari_origem);
    // console.log("nome: ", nome);
    // console.log("serie: ", serie);
    // console.log("chassi: ", chassi);
    // console.log("cla: ", cla);
    // console.log("crv: ", crv);
    // console.log("modelo: ", modelo);
    // console.log("hodometro: ", hodometro);
    // console.log("engrenagens: ", engrenagens);

    const possui_campo_preenchido: boolean = Object.values({
        categoria,
        tipo_categoria,
        marca,
        condicao_aquisicao,
        tipo_origem,
        tipo_combustivel,
        tipo_medidor,
        numeracao_do_motor,
        numero_linhas,
        espacamento_linhas,
        capacidade_tanque_calda,
        numero_discos,
        diametro_discos,
        valor,
        dataAquisicao,
        engrenagens,
        placa,
        proprietari_origem,
        renavam,
        nome,
        modelo,
        chassi,
        serie,
        cla,
        crv,
        frota,
        respons_pela_frota,
        ano_fabricacao,
        ano_modelo,
        hodometro,
        observacao
    }).includes(true);

    if (possui_campo_preenchido) {
        setOpenPopover(true);
    }
    else {
        onClose(false);
    }

}
import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../utils/alert'

interface TypesPerfilUsuario {
  arquivo: string
  email: string
  senha: string
  celular: string
  camisa: string
  camiseta: string
  calcado: string
  calca: string
  cep: string
  estado: string
  cod_estado: number
  cidade: string
  cod_cidade: number
  logradouro: string
  numero: string
  complemento: string
}

export const postCadastroPerfil = async (data, limpar, setLoading) => {
  const config = localConfig();

  loadingAlert({msg: 'Salvando...', id: 'cadastro_perfil'})

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypesPerfilUsuario> = await api
    .post('/cadastro-usuario', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((): any => {
      setTimeout(() => {
        successAlert('Informações do perfil salvo com sucesso!', 5000, 'cadastro_perfil')
      }, 100)

      limpar()
      setLoading(false)
    })
    .catch((error) => {
      errorAlert(`${error?.response?.data?.error}`, 5000, 'cadastro_perfil')
    })

  return response
}

export const postVerificarSenha = async (senha, onFinish, setErroSenhaAtual, setSenhaAnterior) => {
  const config = localConfig()
  api.defaults.headers.Acesso = config.acesso

  loadingAlert({msg: 'Validando...', id: 'verificar_senha'})

  const response: AxiosResponse = await api
    .post('/cadastro-usuario/senha', senha)
    .then((): any => {
      onFinish()
    })
    .catch((error) => {
      setErroSenhaAtual(true)
      setSenhaAnterior(senha?.senha)
      destroyAlert('verificar_senha');
    })

  return response
}

export const getPerfilUsuario = async () => {
  const config = localConfig()
  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse = await api.get('/cadastro-usuario')

  return response
}

export const getFotoPerfil = async () => {
  const config = localConfig()
  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse = await api.get('/cadastro-usuario/imagem')

  return response
}

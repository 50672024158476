import { Transfer } from 'antd'
import { TransferDirection } from 'antd/es/transfer'
import { useEffect, useState } from 'react'
import { tiposSistema, tryError } from '../../services/Afins'
import { getClienteModulo, postClienteModulo } from '../../services/modulosCliente'
import { Notification } from '../notification'
import { Container } from './styled'
import { successAlert } from '../../utils/alert'

type Props = { codCliente: number; statusCliente: number }

const ClienteModulo = (props: Props) => {
  const { codCliente, statusCliente } = props
  const [dataModulos, setDataModulos] = useState([])
  const [dataClienteModulos, setDataClienteModulos] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [removeModulo, setRemoveModulo] = useState([])

  const listClieteModulo = async () => {
    setLoading(true)
  

    const [modulos, clienteModulo] = await Promise.all([
      /* lista os modulos a serem liberados e em perfil de acesso lista somente os que estao liberados para aquele cliente */
      tiposSistema(37),
      getClienteModulo({ codCliente }),
    ])

    setDataModulos(modulos)
    setDataClienteModulos(clienteModulo.data.itens.map((rec) => String(rec.cod_modulo)))
    setLoading(false)
  }


  
  const handleChangeMoveTransfer = async (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    try {
      setLoading(true)
      if (direction === 'right') {
        const keys = [...removeModulo, ...moveKeys]

        const resp = await postClienteModulo({ modulos: moveKeys, cod_cliente: codCliente })
        if (resp.data.itens.length > 0) {
          setRemoveModulo(keys)
          setDataClienteModulos(targetKeys)
          successAlert('Módulo liberado com sucesso!')
        }
        setLoading(false)
      } else {
        await postClienteModulo({ modulos_remover: moveKeys, cod_cliente: codCliente })
        setRemoveModulo(removeModulo.filter((item) => item !== moveKeys[0]))
        setDataClienteModulos(targetKeys)
        setLoading(false);
        successAlert('Módulo removido com sucesso!');
      }
    } catch (error) {
      setLoading(false)
      tryError(error)
    }
  }

  useEffect(() => {
    if (statusCliente === 1) {
      listClieteModulo()
    } else {
      setLoading(true)
      setDataClienteModulos([])
      setDataModulos([])
    }
  }, [codCliente])

  return (
    <Container>
      <Transfer
        disabled={loading}
        rowKey={(data) => String(data.cod)}
        dataSource={dataModulos}
        targetKeys={dataClienteModulos}
        render={(data) => <>{data.nome}</>}
        onChange={handleChangeMoveTransfer}
        selectAllLabels={[<b>Módulos</b>, <b>Módulos liberados</b>]}
        listStyle={{ width: '40%', height: 'calc(100vh - 240px)' }}
      />
    </Container>
  )
}

export default ClienteModulo

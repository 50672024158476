import { Modal } from 'antd'
import React, { useState } from 'react'
import ButtonCustom from '../../../../../Components/ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFloppyDisk, faGear, faGlobe, faLock, faXmark } from '@fortawesome/free-solid-svg-icons'
import Input from '../../../../../Components/CustomUi/input'
import TextArea from '../../../../../Components/CustomUi/textarea'
import { Select } from '../../../../../Components/CustomUi/Select'
import Checkbox from '../../../../../Components/CustomUi/checkbox'
import { postRotina } from '../../../../../services/rotina'
import { tipoUso } from '../../meta'
import { CampoModal } from './styled'
import { Notification } from '../../../../../Components/notification'
import { errorAlert } from '../../../../../utils/alert'

interface TypeModalRotina {
  abrir: boolean
  setAbrir: React.Dispatch<React.SetStateAction<boolean>>
  atualizar?: () => void
  dadosModulos?: any[]
}

const ModalCadastrarRotina: React.FC<TypeModalRotina> = ({
  abrir,
  setAbrir,
  atualizar,
  dadosModulos,
}) => {
  const [loading, setLoading] = useState(false)

  //FORM
  const [tituloNew, setTituloNew] = useState('')
  const [descricaoNew, setDescricaoNew] = useState('')

  //SELECT
  const [nomeTipoUso, setNomeTipoUso] = useState('')
  const [codTipoUso, setcodTipoUso] = useState<number>()
  const [openSelectTipo, setOpenSelectTipo] = useState(false)
  const [nomeModulo, setNomeModulo] = useState('')
  const [codModulo, setcodModulo] = useState<number>()
  const [openSelectModulo, setOpenSelectModulo] = useState(false)

  //CHECK
  const [checkAcesso, setCheckAcesso] = useState(false)
  const [checkAlteracao, setCheckAlteracao] = useState(false)
  const [checkInclusao, setCheckInclusao] = useState(false)
  const [checkExclusao, setCheckExclusao] = useState(false)

  function validar() {
    if (!tituloNew) {
      errorAlert('Preencha o campo nome!')
      setLoading(false)
      return null
    }
    if (!nomeTipoUso) {
      errorAlert('Selecione o tipo acesso!');
      setLoading(false)
      return null
    }

    if (!nomeModulo) {
      errorAlert('Selecione o módulo!');
      setLoading(false)
      return null
    }

    if (!checkAcesso && !checkAlteracao && !checkInclusao && !checkExclusao) {
      errorAlert('Selecione pelo menos um tipo de uso!');
      setLoading(false)
      return null
    }

    onFinish()
  }

  async function onFinish() {

    const data = {
      nome: tituloNew,
      descricao: descricaoNew,
      acesso: checkAcesso === true ? 1 : 0,
      alteracao: checkAlteracao === true ? 1 : 0,
      inclusao: checkInclusao === true ? 1 : 0,
      exclusao: checkExclusao === true ? 1 : 0,
      cod_uso: codTipoUso,
      cod_modulo: codModulo
    }
    setLoading(true)
    const resp = await postRotina(data, setLoading, close)
    atualizar()
  }

  function close() {
    setAbrir(!abrir)
    setTituloNew('')
    setDescricaoNew('')
    setNomeModulo('')
    setNomeTipoUso('')
    setcodModulo(null)
    setcodTipoUso(null)
    setCheckAcesso(false)
    setCheckAlteracao(false)
    setCheckInclusao(false)
    setCheckExclusao(false)
  }

  return (
    <Modal
      open={abrir}
      onCancel={close}
      footer={null}
      destroyOnClose
      title={
        <ButtonCustom
          danger
          icon={<FontAwesomeIcon icon={faXmark} />}
          type='tertiary'
          onClick={close}
          style={{ marginLeft: -10 }}
        >
          Fechar
        </ButtonCustom>
      }
      closeIcon={false}
      style={{
        top: 50,
        right: 130,
        position: 'fixed',
        width: '300px',
        margin: 0,
        padding: 0,
      }}
    >
      <CampoModal>
        <Select
          icon={faGear}
          placeholder='Selecione o módulo'
          label='Módulo'
          value={nomeModulo}
          onChange={setNomeModulo}
          onChangeID={setcodModulo}
          open={openSelectModulo}
          trigger={setOpenSelectModulo}
          items={dadosModulos}
          descricaoItem={'nome'}
          idItem={'cod_modulo'}
        />
        <Select
          icon={faGlobe}
          placeholder='Selecione o tipo de acesso'
          label='Tipo acesso'
          value={nomeTipoUso}
          onChange={setNomeTipoUso}
          onChangeID={setcodTipoUso}
          open={openSelectTipo}
          trigger={setOpenSelectTipo}
          items={tipoUso}
          descricaoItem={'nome'}
          idItem={'cod'}
        />
        <Input
          placeholder='Digite o nome'
          onChange={setTituloNew}
          value={tituloNew}
          label='Nome'
          icon={faLock}
        />

        <TextArea value={descricaoNew} onChange={setDescricaoNew} label='Descrição' maxLength={250} />
        <div className='campo__check'>
          <div className='check'>
            <label style={{marginBottom:'5px'}}>Tipo de uso</label>
            <Checkbox isChecked={checkAcesso} trigger={setCheckAcesso} label='Acesso' />
            <Checkbox isChecked={checkAlteracao} trigger={setCheckAlteracao} label='Alteração' />
            <Checkbox isChecked={checkInclusao} trigger={setCheckInclusao} label='Inclusão' />
            <Checkbox isChecked={checkExclusao} trigger={setCheckExclusao} label='Exclusão' />
          </div>
        </div>
        <div>
          <ButtonCustom
            onClick={validar}
            icon={<FontAwesomeIcon icon={faFloppyDisk} />}
            type='primary'
            loading={loading}
            disabled={loading}
          >
            Salvar
          </ButtonCustom>
        </div>
      </CampoModal>
    </Modal>
  )
}

export default ModalCadastrarRotina

import Icon, { EyeOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Drawer, Form, Input, Modal, Select, Space, Switch, Table } from 'antd';

//STYLES
import { Container } from './styled';

//APP
import { useLogic } from '../../../context/useLogic';
import { pagination } from '../../../Components/Default';
import { localConfig } from '../../../Configs/localConfig';
import { Notification } from '../../../Components/notification';
import { formatarValor } from '../../../utils';
import { getPropriedade } from '../../../services/Propriedades';
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado';
import { getTalhaoCultivavel, postObservacaoTalhaoSubArea } from '../../../services/TalhaoSafra';

//COMPONENTS
import Svg from '../../../Components/talhasvg';
import Icons from '../../../Components/Icons';
import SubArea from '../../../Components/subArea';
import ViewPage from '../../../Components/ViewPage';
import WaterMarker from '../../../Components/WaterMarker';
import GeneratePdfButton from '../../../Components/relatorio-component';
import { errorAlert, loadingAlert, successAlert } from '../../../utils/alert';

const { Option } = Select

const PageSubArea: React.FC = () => {
  const history = useHistory()
  const { checkSafra } = useLogic()

  const refSelectTalhao = useRef(null)

  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleSubAreaTalhao, setVisibleSubAreaTalhao] = useState(false)
  const [openModalObservacao, setOpenModalObservacao] = useState(false)
  const [talhoesSubArea, setTalhoesSubArea] = useState([])
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [observacao, setObservacao] = useState('')
  const [update, setUpdate] = useState(0)
  const [selectTodosTalhoesSubArea, setSelectTodosTalhoesSubArea] = useState(false)
  const [todosTalhoesSubArea, setTodosTalhoesSubArea] = useState([])
  const [codPropri, setcodPropri] = useState(null)

  const [dataTableRelative, setDataTableRelative] = useState([])
  const [dataPropriedade, setdataPropriedade] = useState([])
  const [dataTalhaoSafra, setDataTalhaoSafra] = useState([])
  const [subAreaData, setSubAreaData] = useState([])
  const [editTalhaoSafraSubArea, setEditTalhaoSafraSubArea] = useState([])
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])
  const [dadosNovos, setDadosNovos] = useState([])
  const [nomePropriedade, setNomePropriedade] = useState('')

  const [form] = Form.useForm()

  // console.log('visibleSubAreaTalhao', visibleSubAreaTalhao);

  const listPropriedade = async () => {
    const resp = await getPropriedade('')
    setdataPropriedade(resp.data.itens)
  }

  const listTalhaoSafra = async (codPropriedade) => {
    const { codSafra } = localConfig()
    setLoadingTable(true)
    const resp = await getTalhaoCultivavel({
      codSafra: Number(codSafra),
      embed: 'sub_area',
      codPropriedade,
    })
    setNomePropriedade(resp?.data?.itens[0]?.propriedade_nome)
    setDataTalhaoSafra(resp.data.itens)
    setDataTableRelative(resp.data.itens)
    setSubAreaData(resp.data.itens.map((item) => item.sub_area))
   
    //@ts-ignore
    const novodados = resp.data.itens.flatMap((item) =>
      item.sub_area.map((sub) => ({
        nome: item.nome,
        area: item.area,
        nome_sub: sub.nome,
        area_sub: sub.area,
      }))
    )
    setDadosNovos(novodados)

    const resultado = novodados.reduce(
      (soma, item) => {
        return {
          area: soma.area + parseFloat(item.area),
          area_subarea: soma.area_subarea + parseFloat(item.area_sub),
        }
      },
      {
        area: 0,
        area_subarea: 0,
      }
    )

    const resultadoFinal = [
      {
        totalizado_area: formatarValor(resultado?.area),
        totalizado_area_subarea: formatarValor(resultado?.area_subarea),
      },
    ]

    gerarTabela(novodados)
    gerarTabela2(resultadoFinal, novodados.length)
    setLoadingTable(false)
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: formatarValor(parseFloat(item?.area)),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
        { text: item?.nome_sub, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: formatarValor(parseFloat(item?.area_sub)),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*'],
          body: [
            [
              { text: 'Nome talhão', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área/ha', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Subárea', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área subárea/ha', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]

    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = dados?.map((item) => {
      return [
        { text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: item.totalizado_area,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
        // { text: lengthSub, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: item.totalizado_area_subarea,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'Total subáreas',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              {
                text: 'Total área',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              {
                text: 'Total área subáreas',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
            ],
            ...data,
          ],
          margin: [0, 50, 0, 0],
          alignment: 'center',
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]

    setDadosResultado(table)
  }

  const handleClickEdit = (data) => {
    const dados = [...dataTalhaoSafra]
    const find = dados.filter((info) => info.cod === data)

    setEditTalhaoSafraSubArea(find)
    setUpdate(data)
    setVisibleSubAreaTalhao(true)
  }

  const openModalObs = () => {
    setOpenModalObservacao(true)
  }

  const handleSelectTableRow = (cod) => {
    setTalhoesSubArea(cod)
  }

  const handleSelectTodosTalhoes = (data) => {
    setSelectTodosTalhoesSubArea(data)

    let talhoes = []

    const talhoesSubArea = dataTalhaoSafra.map((talhao) => {
      if (talhao.sub_area.length >= 1) {
        talhoes = [...talhoes, talhao.sub_area]
      }
      return talhoes
    })
    let cods = []

    talhoes.forEach((item) => {
      item.forEach((subItem) => {
        cods = [...cods, subItem.cod]
      })
    })

    setTodosTalhoesSubArea(cods)
  }

  const handleAddObservacao = async (values) => {
    try {
      setLoadingTable(true)
      loadingAlert({msg: 'Salvando observação...', id: 'obs'});

      const observacao = {
        observacao: values.observacao,
        talhoes: selectTodosTalhoesSubArea == false ? talhoesSubArea : todosTalhoesSubArea,
        todos: selectTodosTalhoesSubArea,
      }
      if (observacao.talhoes.length == 0) {
        errorAlert('Por favor selecione pelo menos um talhão!', 5000, 'obs');
      } else {
        const response = await postObservacaoTalhaoSubArea(codPropri, observacao)

        if (response.status === 200 && selectTodosTalhoesSubArea == true) {
          successAlert('Observação cadastrada para todos os talhões da safra!', 5000, 'obs');
        } else {
          if (observacao.talhoes.length == 1) {
            successAlert('Observação cadastrada com sucesso!', 5000, 'obs');
          } else {
            successAlert('Observação cadastrada para apenas os talhões da página atual!', 5000, 'obs');
          }
        }
      }
      listTalhaoSafra(codPropri)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }

    form.resetFields()
    // Fechar o modal
    setOpenModalObservacao(false)
  }

  const handleChangePropriedade = (data) => {
    setDataTalhaoSafra([])
    setcodPropri(data)
    listTalhaoSafra(data)
  }

  const handlesPesquisa = (data) => {
    setLoadingTable(true)
    const dados = dataTalhaoSafra
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setDataTableRelative(response)
      setLoadingTable(false)
    }, 500)
  }

  const handleCloseForm = (data) => {
    setVisibleSubAreaTalhao(data)
    setEditTalhaoSafraSubArea([])
    setUpdate(0)
  }

  useEffect(() => {
    listPropriedade()

    const idDaSafra = checkSafra('/talhao/subarea')

    if (!idDaSafra) {
      history.push('/home')
    }

    refSelectTalhao.current.focus()
  }, [])

  // console.log('dataTalhaoSafra', dataTalhaoSafra);

  return (
    <Container>
      <ViewPage
        title='Subárea'
        newHide
        rotina={30}
        onPermission={setPermissions}
        btnClick={() => setVisibleSubAreaTalhao(true)}
        content={null}
        search
        inputChange={handlesPesquisa}
        btnsExta={
          <>
            <Select
              style={{ width: 250 }}
              ref={refSelectTalhao}
              onChange={handleChangePropriedade}
              placeholder='Selecione uma propriedade'
              popupMatchSelectWidth={false}
              showSearch
              filterOption={(input, option) =>
                String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {dataPropriedade.map((data) => (
                <Option key={data.cod} value={data.cod}>
                  {data.nome}
                </Option>
              ))}
            </Select>

            <Button
              onClick={openModalObs}
              // disabled={codPro === 0}
              type='primary'
              icon={<EyeOutlined />}
            >
              Adicionar Observação
            </Button>
            {localStorage.getItem('tipo-acesso') === 'conta' ? (
              <GeneratePdfButton
                dados={dadosEstatisticas}
                title={'Relatório de subáreas'}
                totais={dadosResultado}
                disable={dadosNovos.length <= 0}
                titleSecundario={nomePropriedade}
              />
            ) : null}
          </>
        }
      >
        <Table
          rowKey='cod'
          pagination={pagination}
          loading={loadingTable}
          bordered
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickEdit(record.cod)
              },
            }
          }}
          size='small'
          columns={[
            {
              key: 0,
              dataIndex: 'kml',
              width: 80,
              title: 'Talhão',
              render: (data, record) => (
                <Icon
                  component={() => <Svg fill='#70ce9f' coordinates={record.kml.coordenadas} />}
                />
              ),
            },
            {
              key: 1,
              dataIndex: 'nome',
              title: 'Nome',
              render: (nome) => <div style={{ minWidth: 60 }}>{nome}</div>,
            },
            {
              key: 2,
              dataIndex: 'area',
              title: 'Área (ha)',
              render: (data) => <div style={{ minWidth: 90 }}>{`${data}/ha`}</div>,
            },
            {
              key: 4,
              dataIndex: 'cod',
              width: '56px',
              align: 'center',
              title: 'Ações',
              render: (data) => (
                <Space size='small'>
                  <Button
                    hidden={permissions?.alteracao === 0 && permissions?.acesso === 0}
                    shape='circle'
                    onClick={() => handleClickEdit(data)}
                    icon={<Icons icon={permissions?.acesso === 1 ? faEye : faPen} />}
                  />
                </Space>
              ),
            },
          ]}
          dataSource={dataTableRelative}
          scroll={{ y: 'calc(90vh - 275px)', x: 'auto' }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataTalhaoSafra.length}</b>
            </div>
          )}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <Table
                  rowKey='cod'
                  bordered
                  loading={loadingTable}
                  style={{ marginLeft: '89px', width: '600px' }}
                  scroll={{ y: '200px' }}
                  size='small'
                  pagination={{
                    pageSize: 999999,
                    hideOnSinglePage: true,
                  }}
                  rowSelection={
                    permissions?.exclusao === 1
                      ? {
                          type: 'checkbox',
                          selectedRowKeys: talhoesSubArea,
                          onChange: (data) => handleSelectTableRow(data),
                        }
                      : null
                  }
                  columns={[
                    {
                      key: 1,
                      dataIndex: 'kml',
                      width: '75px',
                      title: 'Subárea',
                      render: (data) => (
                        <Icon
                          component={() => (
                            <Svg fill='green' fillOpacity={0.5} coordinates={data?.coordenadas} />
                          )}
                        />
                      ),
                    },
                    {
                      key: 2,
                      dataIndex: 'nome',
                      title: 'Nome',
                      render: (nome) => <div style={{ minWidth: 80 }}>{nome}</div>,
                    },
                    {
                      key: 3,
                      dataIndex: 'area',
                      title: 'Área/ha',
                    },
                    {
                      key: 4, // Ajuste a chave para evitar conflitos com a chave 3
                      dataIndex: 'observacao',
                      title: 'Observação',
                    },
                  ]}
                  dataSource={record.sub_area}
                />
              </>
            ),
            rowExpandable: (record) => record.sub_area.length > 0,
          }}
        />
      </ViewPage>
      <Modal
        open={openModalObservacao}
        onCancel={() => setOpenModalObservacao(false)}
        footer={[
          <Button type='primary' danger onClick={() => setOpenModalObservacao(false)}>
            Fechar
          </Button>,
          <Button type='primary' onClick={() => form.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Card className='floating-safra' size='small'>
          <Form form={form} layout='vertical' onFinish={handleAddObservacao}>
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              label='Observação'
              name='observacao'
            >
              <Input onChange={({ target }) => setObservacao(target.value)} value={observacao} />
            </Form.Item>
            <Form.Item label='Deseja aplicar para todos talhöes da safra?'>
              <Switch
                onChange={(data) => handleSelectTodosTalhoes(data)}
                unCheckedChildren='Não'
                checkedChildren='Sim'
              />
            </Form.Item>
          </Form>
        </Card>
      </Modal>

      <Drawer
        forceRender
        open={visibleSubAreaTalhao}
        placement='bottom'
        headerStyle={{ display: 'none' }}
        width='100%'
        closeIcon={false}
        contentWrapperStyle={{ minHeight: '100%' }}
        bodyStyle={{ overflow: 'hidden', padding: 0 }}
      >
        <SubArea
          update={update}
          permissions={permissions}
          listTalhoesRecord={editTalhaoSafraSubArea}
          listTalhaoSafra={listTalhaoSafra}
          propriedade={codPropri}
          onClose={handleCloseForm}
          visible={visibleSubAreaTalhao}
        />
        <WaterMarker placement='bottomRight' />
      </Drawer>
    </Container>
  )
}

export default PageSubArea

import React, { useState } from 'react'
import Input from '../../../CustomUi/input'
import {
  faAddressCard,
  faBriefcase,
  faCity,
  faClipboardList,
  faEnvelope,
  faHashtag,
  faHouse,
  faHouseUser,
  faIdBadge,
  faLocationDot,
  faMap,
  faMicroscope,
  faMobileScreenButton,
  faPhone,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { cepMask, cpfMask, fixoMask, phoneMask } from '../../../../services/Afins'
import { Select } from '../../../CustomUi/Select'
import { apenasNumeros, maskCNPJ } from '../../../../utils'

interface PropsFormPessoaFisica {
  nomeFantasia: string
  setNomeFantasia: React.Dispatch<React.SetStateAction<string>>
  cnpj: string
  setCnpj: React.Dispatch<React.SetStateAction<string>>
  razaoSocial: string
  setRazaoSocial: React.Dispatch<React.SetStateAction<string>>
  iE: string
  setIe: React.Dispatch<React.SetStateAction<string>>
  celularJuridico: string
  setCelularJuridico: React.Dispatch<React.SetStateAction<string>>
  foneFixoJuridico: string
  setFoneFixoJuridico: React.Dispatch<React.SetStateAction<string>>
  emailJuridico: string
  setEmailJuridico: React.Dispatch<React.SetStateAction<string>>
  ufJuridico: string
  setUfJuridico: React.Dispatch<React.SetStateAction<string>>
  ufIdJuridico: number
  setUfIdJuridico: React.Dispatch<React.SetStateAction<number>>
  cidadeJuridico: string
  setCidadeJuridico: React.Dispatch<React.SetStateAction<string>>
  cidadeIdJuridico: number
  setCidadeIdJuridico: React.Dispatch<React.SetStateAction<number>>
  cepJuridico: string
  setCepJuridico: React.Dispatch<React.SetStateAction<string>>
  enderecoJuridico: string
  setEnderecoJuridico: React.Dispatch<React.SetStateAction<string>>
  numeroJuridico: string
  setNumeroJuridico: React.Dispatch<React.SetStateAction<string>>
  bairroJuridico: string
  setBairroJuridico: React.Dispatch<React.SetStateAction<string>>
  complementoJuridico: string
  setComplementoJuridico: React.Dispatch<React.SetStateAction<string>>
  inputDisableCidade: boolean
  haldleChangeCidadeUf: React.Dispatch<React.SetStateAction<number | null>>
  dataUf: any[]
  dataCity: any[]
  dataCidadeFilter: any[]
}

export const FormPessoaJuridicaLab: React.FC<PropsFormPessoaFisica> = ({
  nomeFantasia,
  setNomeFantasia,
  cnpj,
  setCnpj,
  razaoSocial,
  setRazaoSocial,
  iE,
  setIe,
  celularJuridico,
  setCelularJuridico,
  foneFixoJuridico,
  setFoneFixoJuridico,
  emailJuridico,
  setEmailJuridico,
  cepJuridico,
  setCepJuridico,
  ufJuridico,
  setUfJuridico,
  ufIdJuridico,
  setUfIdJuridico,
  cidadeJuridico,
  setCidadeJuridico,
  cidadeIdJuridico,
  setCidadeIdJuridico,
  bairroJuridico,
  setBairroJuridico,
  enderecoJuridico,
  setEnderecoJuridico,
  numeroJuridico,
  setNumeroJuridico,
  complementoJuridico,
  setComplementoJuridico,
  haldleChangeCidadeUf,
  inputDisableCidade,
  dataUf,
  dataCity,
  dataCidadeFilter,
}) => {
  const [openUf, setOpenUf] = useState(false)
  const [openCidade, setOpenCidade] = useState(false)

  return (
    <>
      <Input
        placeholder='Digite o cnpj'
        value={maskCNPJ(cnpj)}
        onChange={setCnpj}
        label='CNPJ'
        icon={faBriefcase}
      />
      <div className='campo__dois__inputs'>
        <Input
          placeholder='Digite a razão social'
          value={razaoSocial}
          onChange={setRazaoSocial}
          label='Razão social'
          icon={faAddressCard}
        />
        <Input
          placeholder='Digite o nome fantasia'
          value={nomeFantasia}
          onChange={setNomeFantasia}
          label='Nome fantasia'
          icon={faMicroscope}
        />
      </div>
      <Input
        placeholder='Digite o IE'
        value={apenasNumeros(iE)}
        onChange={setIe}
        label='IE'
        icon={faClipboardList}
      />
      <div className='campo__dois__inputs'>
        <Input
          placeholder='(00) 00000-0000'
          value={phoneMask(celularJuridico)}
          onChange={setCelularJuridico}
          label='Celular'
          icon={faMobileScreenButton}
          maxLength={15}
        />
        <Input
          placeholder='(00) 0000-0000'
          value={fixoMask(foneFixoJuridico)}
          onChange={setFoneFixoJuridico}
          label='Telefone Fixo'
          icon={faPhone}
          maxLength={14}
        />
      </div>

      <Input
        placeholder='Digite um email'
        value={emailJuridico}
        onChange={setEmailJuridico}
        label='Email'
        icon={faEnvelope}
      />

      <div className='campo__dois__inputs'>
        <Input
          placeholder='Digite o cep'
          value={cepMask(cepJuridico)}
          onChange={setCepJuridico}
          label='CEP'
          icon={faLocationDot}
        />
        <Select
          value={ufJuridico}
          onChange={setUfJuridico}
          onChangeID={haldleChangeCidadeUf}
          open={openUf}
          trigger={setOpenUf}
          items={dataUf}
          descricaoItem='sigla'
          idItem='cod'
          label='UF'
          placeholder='Selecione a uf do estado'
          icon={faMap}
        />
      </div>

      <div className='campo__dois__inputs'>
        <Select
          value={cidadeJuridico}
          onChange={setCidadeJuridico}
          onChangeID={setCidadeIdJuridico}
          open={openCidade}
          trigger={setOpenCidade}
          items={dataCity.length > 0 ? dataCity : dataCidadeFilter}
          descricaoItem={'nome'}
          idItem={'cod'}
          label='Cidade'
          placeholder='Selecione a cidade'
          icon={faCity}
          disabled={inputDisableCidade}
        />
        <Input
          placeholder='Informe o bairro'
          value={bairroJuridico}
          onChange={setBairroJuridico}
          label='Bairro'
          icon={faHouse}
        />
      </div>

      <div className='campo__dois__inputs'>
        <Input
          placeholder='Informe o endereço'
          value={enderecoJuridico}
          onChange={setEnderecoJuridico}
          label='Endereço'
          icon={faHouseUser}
        />
        <Input
          placeholder='Informe o numero'
          value={numeroJuridico}
          onChange={setNumeroJuridico}
          label='Numero'
          icon={faHashtag}
        />
      </div>

      <Input
        placeholder='Informe o complemento'
        value={complementoJuridico}
        onChange={setComplementoJuridico}
        label='Complemento'
        icon={faPlus}
      />
    </>
  )
}

import React, { useState } from 'react'
import {
  faBarcode,
  faCalendarDays,
  faCar,
  faCarSide,
  faCartShopping,
  faClipboardUser,
  faDharmachakra,
  faDollar,
  faFileAlt,
  faFillDrip,
  faFolder,
  faGear,
  faPen,
  faRectangleList,
  faScrewdriverWrench,
  faTicket,
} from '@fortawesome/free-solid-svg-icons'

//APP
import { apenasNumeros } from '../../../../../../../utils'
import { formatarData, mascaraReais } from '../../../../../../../services/Afins'

//COMPONENTS
import Input from '../../../../../../../Components/CustomUi/input'
import TextArea from '../../../../../../../Components/CustomUi/textarea'
import { Select } from '../../../../../../../Components/CustomUi/Select'
import { Render } from '../../../../../../../Components/CustomUi/render'
import { ColorPicker } from 'antd'
import { cores } from './meta'

//PROPS
interface PropsFormPatrimonios {
  nome?: string
  setNome?: React.Dispatch<React.SetStateAction<string>>
  modelo?: string
  setModelo?: React.Dispatch<React.SetStateAction<string>>
  chassi?: string
  setChassi?: React.Dispatch<React.SetStateAction<string>>
  serie?: string
  setSerie?: React.Dispatch<React.SetStateAction<string>>
  frota?: string
  setFrota?: React.Dispatch<React.SetStateAction<string>>
  responsavelFrota?: string
  setResponsavelFrota?: React.Dispatch<React.SetStateAction<string>>
  responsavelFrotaId?: number
  setResponsavelFrotaId?: React.Dispatch<React.SetStateAction<number>>
  responsaveis: any[]
  dataAquisicao?: string
  setDataAquisicao?: React.Dispatch<React.SetStateAction<string>>
  valor?: string
  setValor?: React.Dispatch<React.SetStateAction<string>>
  anoModelo?: string
  setAnoModelo?: React.Dispatch<React.SetStateAction<string>>
  anoFabricacao?: string
  setAnoFabricacao?: React.Dispatch<React.SetStateAction<string>>
  obs?: string
  setObs?: React.Dispatch<React.SetStateAction<string>>
  placa?: string
  setPlaca?: React.Dispatch<React.SetStateAction<string>>
  renavam?: string
  setRenavam?: React.Dispatch<React.SetStateAction<string>>
  crv?: string
  setCrv?: React.Dispatch<React.SetStateAction<string>>
  cla?: string
  setCla?: React.Dispatch<React.SetStateAction<string>>
  categoriaId?: number
  tipoOrigem: string
  duplicar: boolean
  numeracao_motor: string
  setNumeracao_motor: React.Dispatch<React.SetStateAction<string>>
  cor_predominante: string
  setCor_predominante: React.Dispatch<React.SetStateAction<string>>
  motorizado: boolean
}

export const FormularioPatrimonios: React.FC<PropsFormPatrimonios> = ({
  nome,
  setNome,
  modelo,
  setModelo,
  chassi,
  setChassi,
  serie,
  setSerie,
  frota,
  setFrota,
  responsavelFrota,
  setResponsavelFrota,
  responsavelFrotaId,
  setResponsavelFrotaId,
  responsaveis,
  dataAquisicao,
  setDataAquisicao,
  valor,
  setValor,
  anoModelo,
  anoFabricacao,
  setAnoFabricacao,
  setAnoModelo,
  obs,
  setObs,
  placa,
  setPlaca,
  renavam,
  setRenavam,
  categoriaId,
  crv,
  setCrv,
  cla,
  setCla,
  tipoOrigem,
  duplicar,
  numeracao_motor,
  setNumeracao_motor,
  cor_predominante,
  setCor_predominante,
  motorizado,
}) => {
  const [openResponsavel, setOpenResponsavel] = useState(false)
  const [openCor, setOpenCor] = useState(false)
  return (
    <>
      <Input
        placeholder={'Digite o nome'}
        value={nome}
        onChange={setNome}
        icon={faPen}
        label='Nome'
      />
      <div className='campo__dois__inputs'>
        <Input
          placeholder={'Digite o modelo'}
          value={modelo}
          onChange={setModelo}
          label='Modelo'
          icon={faCar}
        />
        <Input
          label='Chassi'
          placeholder={'Digite o número do chassi'}
          value={chassi}
          onChange={setChassi}
          icon={faGear}
          maxLength={17}
        />
      </div>
      {categoriaId === 26 || categoriaId === 223 ? (
        <div className='campo__dois__inputs'>
          <Input
            placeholder={'Digite a placa'}
            value={placa}
            onChange={setPlaca}
            label='Placa'
            icon={faRectangleList}
          />
          <Input
            label='Renavam'
            placeholder={'Digite o número do renavam'}
            value={renavam}
            onChange={setRenavam}
            icon={faFileAlt}
            maxLength={17}
          />
        </div>
      ) : null}
      {categoriaId === 26 || categoriaId === 223 ? (
        <div className='campo__dois__inputs'>
          <Input
            placeholder={'Digite o número do CRV'}
            value={apenasNumeros(crv)}
            onChange={setCrv}
            label='CRV'
            icon={faFolder}
          />
          <Input
            label='CLA'
            placeholder={'Digite o código do CLA '}
            value={apenasNumeros(cla)}
            onChange={setCla}
            icon={faTicket}
          />
        </div>
      ) : (
        <div className='campo__dois__inputs'>
          <Input
            placeholder={'Digite o número de série'}
            value={apenasNumeros(serie)}
            onChange={setSerie}
            label='Série'
            icon={faBarcode}
          />
        </div>
      )}
      <div className='campo__dois__inputs'>
        <Render
          condition={
            categoriaId === 26 ||
            categoriaId === 223 ||
            categoriaId === 224 ||
            categoriaId === 2 ||
            motorizado
          }
        >
          <Input
            label='Numeração do motor'
            placeholder='Informe a númeração do motor'
            value={numeracao_motor}
            onChange={setNumeracao_motor}
            icon={faDharmachakra}
          />
        </Render>
        <Render
          condition={
            categoriaId === 26 ||
            categoriaId === 223 ||
            categoriaId === 224 ||
            categoriaId === 2 ||
            categoriaId === 27
          }
        >
          <Input
            label='Cor predominante'
            placeholder='Selecione uma cor'
            icon={faFillDrip}
            value={cor_predominante}
            onChange={setCor_predominante}
            opcional
          />
        </Render>
      </div>
      <div className='campo__dois__inputs'>
        <Input
          placeholder={'Digite a frota'}
          value={frota}
          onChange={setFrota}
          label='Frota'
          icon={faCarSide}
        />
        <Select
          value={responsavelFrota}
          onChange={setResponsavelFrota}
          onChangeID={setResponsavelFrotaId}
          open={openResponsavel}
          trigger={setOpenResponsavel}
          items={responsaveis}
          descricaoItem={'usuario_nome'}
          idItem={'cod_func'}
          label='Responsável pela frota'
          placeholder='Selecione o responsável pela frota'
          icon={faClipboardUser}
        />
      </div>
      {tipoOrigem === 'Próprio' ? (
        <div className='campo__dois__inputs'>
          <Input
            placeholder={'Digite a data de aquisição'}
            value={formatarData(dataAquisicao)}
            onChange={setDataAquisicao}
            label='Data de aquisição'
            icon={faCartShopping}
            maxLength={10}
          />
          <Input
            label='Valor'
            placeholder={'Digite o valor'}
            value={mascaraReais(valor)}
            onChange={setValor}
            icon={faDollar}
          />
        </div>
      ) : null}
      <div className='campo__dois__inputs'>
        <Input
          label='Ano de fabricação'
          placeholder={'Digite o ano de fabricação'}
          value={anoFabricacao}
          onChange={setAnoFabricacao}
          icon={faScrewdriverWrench}
          maxLength={4}
        />
        <Input
          placeholder={'Digite o ano do modelo'}
          value={anoModelo}
          onChange={setAnoModelo}
          label='Ano modelo'
          icon={faCalendarDays}
          maxLength={4}
        />
      </div>

      <TextArea value={obs} onChange={setObs} label='Observação' maxLength={250} />
    </>
  )
}

import { AxiosResponse } from 'axios'
import { api } from '../api'

import { Dayjs } from 'dayjs'
import { localConfig } from '../../Configs/localConfig'
import { PropriedadeItens } from '../Propriedades'
import { destroyAlert, errorAlert, successAlert } from '../../utils/alert'

export interface TypeGetlaboratorio {
  itens: [OsItensTypes]
}

export interface OsItensTypes {
  cod?: number
  data?: string | Dayjs
  status?: number
  areaTalhao?: string
  nome_cliente?: string
  calcario_propriedade?: string
  cod_laboratorio?: number
  cod_propriedade?: number
  cod_talhao?: number
  cod_calcario?: number
  create_user?: string
  create_time?: string
  update_user?: string
  update_time?: string
  talhao_nome?: string
  safra_nome?: string
  cliente_nome?: string
  talhao_kml?: {
    coordenadas: { latitude: string; longitude: string }[]
    lookAt: { latitude: string; longitude: string }
    name?: string
  }
  cod_representante?: number
  data_liberada?: string | Dayjs
  data_coleta?: string | Dayjs
  talhao_area?: string
  propriedade_nome?: string
  representante?: [
    {
      cidade_nome?: string
      cod?: number
      cpf?: string
      participacao?: string
      representante?: number
      solicitante?: string
      endereco: string
      endereco_cep: string
      endereco_complemento: string
      uf_nome?: string
      uf_sigla?: string
      usuario_nome?: string
    }
  ]
  historicoStatus?: [
    {
      cod: number
      cod_pedido: number
      cod_usuario: number
      /**
       * @param {status} 1 = Aberto | 2 = Liberado para coleta | 3 = Coletada | 4 = Em análise | 5 = Análisada | 6 = Resultado com inconsistencia | 7 = Enviado para análise
       */
      status: 1 | 2 | 3 | 4 | 5 | 6 | 7
      status_nome: string
      usuario_nome: string
    }
  ]
  propriedade: PropriedadeItens[]
  cod_responsaveis?: [number]
  solicitante?: string
  cod_area_amostra?: number
  malha_de_pontos?: {
    type?: string
    features?: []
    fileName?: string
  }
  analises?: [
    {
      title?: string
      cod_analises?: [
        {
          cod: string
          valor: string
        }
      ]
      cod_profundidade?: number
      profundidade?: string
      id?: string
      pontos?: [
        {
          NOME?: string
          INDICE?: number
          COMENTARIO?: string
        }
      ]
      valor_analise?: string
    }
  ]
  responsaveis?: [
    {
      cod: number
      cod_pedido: number
      cod_responsavel: number
    }
  ]
}

export interface TypeStatus {
  itens: [StatusTypes]
}

export interface StatusTypes {
  cod?: number
  cod_pedido?: number
  status?: number
  cod_usuario?: number
  status_nome?: string
  data_hora?: string
  create_time?: string
  update_time?: string
  delete_time?: string
}

export interface TypeGetLaboratorio {
  itens: [LaboratorioItens]
}

export interface LaboratorioItens {
  cod: number
  cod_conta: number
  cod_cliente: number
  nome: string
  cpf_cnpj: string
  tipo: 'J' | 'F'
  razao_social: string
  endereco: string
  complemento: string
  cep: number
  cod_cidade: number
  fone: string
  fone2: string
  email: string
  status: 1 | 0
  cod_categoria: number
  endereco_numero: string
  bairro: string
  inscricao_estadual: number
  cidade: [
    {
      cod: number
      codigo: number
      nome: string
      uf: string
      cod_uf: number
      status?: 1 | 0
      uf_nome: string
      uf_sigla: string
    }
  ]
}

export const postLaboratorio = async (
  data,
  fecharGaveta?: Function,
  setLoadingSalvar?: Function
) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetLaboratorio> = await api
    .post(`/laboratorio?codConta=${config.conta}&codCliente=${config.codCliente}`, data)
    .then((): any => {
      destroyAlert()
      successAlert('Laboratório cadastrado com sucesso!')
      fecharGaveta()
      setLoadingSalvar?.(false)
    })
    .catch((error) => {
      destroyAlert()
      errorAlert(`${error?.response?.data?.error[0]?.msg[0]}`)
      setLoadingSalvar?.(false)
    })

  return response
}

export const putLaboratorio = async (
  codlaboratorio,
  data,
  fecharGaveta?: Function,
  setLoadingSalvar?: Function
) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetLaboratorio> = await api
    .put(
      `/laboratorio/${codlaboratorio}?codConta=${config.conta}&codCliente=${config.codCliente}`,
      data
    )
    .then((): any => {
      destroyAlert()
      successAlert('Laboratório atualizado com sucesso!')
      fecharGaveta()
      setLoadingSalvar?.(false)
    })
    .catch((error) => {
      destroyAlert()
      errorAlert(`${error?.response?.data?.error[0]?.msg[0]}`)
      setLoadingSalvar?.(false)
    })

  return response
}

export const delLaboratorio = async (codlaboratorio) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetLaboratorio> = await api.delete(
    `/laboratorio/${codlaboratorio}?codConta=${config.conta}&codCliente=${config.codCliente}`
  )

  return response
}

export const getPedLaboratorio = async (
  cod,
  token = null,
  codConta = null,
  codSafra = null,
  codCliente = null
) => {
  const config = localConfig()

  const tokenComplete = `Token ${process.env.REACT_APP_TOKEN_API}, Bearer ${token}`

  if (token !== null) {
    api.defaults.headers.Acesso = 'conta'
  } else {
    api.defaults.headers.Acesso = config.acesso
  }

  const response: AxiosResponse<TypeGetlaboratorio> = await api.get(`/pedido-laboratorio`, {
    params: {
      codConta: codConta === null ? config.conta : codConta,
      codSafra: codSafra === null ? config.codSafra : codSafra,
      codCliente: codCliente === null ? config.codCliente : codCliente,
      cod,
      pagination: -1,
    },
    headers: {
      ...(token !== null && { Authorization: tokenComplete }),
    },
  })

  return response
}

export const getPedLaboratorioStatus = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeStatus> = await api.get(
    `/pedido-historico-laboratorio?codConta=${config.conta}&codSafra=${config.codSafra}&codCliente=${config.codCliente}&codPedido=${cod}`
  )

  return response
}

export const getDownloadResult = async (url) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetlaboratorio> = await api.get(url, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      codSafra: config.codSafra,
    },
    responseType: 'blob',
  })

  return response
}

export const postPedLaboratorio = async (data) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetlaboratorio> = await api.post(
    `/pedido-laboratorio?codConta=${config.conta}&codSafra=${config.codSafra}&codCliente=${config.codCliente}`,
    data
  )

  return response
}

export const reprocessarOs = async (data, cod_cliente) => {
  const config = localConfig()
  const codLocal = localStorage.getItem('cod-cliente')

  const response: AxiosResponse = await api.get(
    `pedido-laboratorio/reprocessar/${data}?codConta=${config.conta}&codCliente=${
      codLocal === '' ? cod_cliente : config.codCliente
    }&codSafra=${config.codSafra}`,
    data
  )

  return response
}

export const putPedlaboratorio = async (cod, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetlaboratorio> = await api.put(
    `/pedido-laboratorio/${cod}?codConta=${config.conta}&codSafra=${config.codSafra}&codCliente=${config.codCliente}`,
    data
  )

  return response
}

export const putPedlaboratorioResultado = async (cod, arquivo) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetlaboratorio> = await api.put(
    `/pedido-laboratorio/enviar-arquivo-resultado/${cod}`,
    {
      arquivo,
    },
    {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
        codSafra: config.codSafra,
      },
    }
  )

  return response
}

export const putPedlaboratorioStatus = async (cod, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeStatus> = await api.put(
    `/pedido-laboratorio/atualizar-status/${cod}?codConta=${config.conta}&codSafra=${config.codSafra}&codCliente=${config.codCliente}`,
    { status: data }
  )

  return response
}

export const delPedLaboratorio = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetlaboratorio> = await api.delete(
    `/pedido-laboratorio/${cod}?codConta=${config.conta}&codSafra=${config.codSafra}&codCliente=${config.codCliente}`
  )

  return response
}

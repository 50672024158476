import styled from 'styled-components'

export const BodyDrawer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .campo__dois__inputs {
    display: flex;
    gap: 10px;
  }

  .campo__cor {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .footer {
    border-top: 1px solid var(--border-color);
    padding-top: 10px;
    height: 40px;
  }

  .radio-motorizado {
    width: 25%;
    display: flex;
    flex-direction: column;
  }

  .radio-group .ant-radio-button-wrapper-checked {
    background-color: #5da57d;
    border-color: #5da57d;
    color: white; /* Cor do texto quando selecionado */
  }
  /* Estilo quando o radio está checked e em hover */
  .radio-group .ant-radio-button-wrapper-checked:hover {
    background-color: #5da57d; /* Verde no hover quando está checked */
    border-color: #5da57d;
    color: white; /* Texto branco quando hover e checked */
  }

  /* Estilo quando o radio não está checked e em hover */
  .radio-group .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover {
    background-color: transparent; /* Mantém transparente no hover quando não está checked */
    border-color: #d9d9d9;
    color: #5da57d; /* Texto verde no hover quando não está checked */
  }
`
export const BodyDrawerCategoria = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 195px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 10px;
  box-sizing: border-box;
  padding-right: 10px;

  .campo__dois__inputs {
    display: flex;
    gap: 10px;
  }
`

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import { AxiosResponse } from 'axios';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Avatar, Button, Col, Empty, Form, Modal, Space } from 'antd';

//ASSETS
import lupaSearch from '../../../assets/svg/lupaBranca.svg';

//STYLES
import { Container } from './styled';

//APP
import { getPerfil } from '../../../services/perfil';
import { localConfig } from '../../../Configs/localConfig';;
import { removerAcentos } from '../../../utils';
import { getConsultaEmail } from '../../../services/ConsultaEmailUsuario';
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado';
import { TypesEquipe, delEquipe, getEquipe, postAddEquipe } from '../../../services/Equipe';
import { destroyAlert, errorAlert, loadingAlert, successAlert } from '../../../utils/alert';

//COMPONENTS
import Input from '../../../Components/CustomUi/input';
import ViewPage from '../../../Components/ViewPage';
import Carregando from '../../../Components/CustomUi/loading';
import NovaEquipe from '../../../Components/nova-equipe';
import ButtonCustom from '../../../Components/ButtonCustom';
import GeneratePdfButton from '../../../Components/relatorio-component';
import { Box } from '../../../Components/CustomUi/box';
import { Render } from '../../../Components/CustomUi/render';
import { Inline } from '../../../Components/CustomUi/inline';
import { RowItem } from '../../../Components/CustomUi/table/rowItem';
import { TitleH1 } from '../../../Components/CustomUi/titleH1';
import { RowTable } from '../../../Components/CustomUi/table/rowTable';
import { BodyTable } from '../../../Components/CustomUi/table/bodyTable';
import { HeaderTable } from '../../../Components/CustomUi/table/headerTable';
import { DeleteButton } from '../../../Components/CustomUi/deleteButton';

interface EmailFoundType {
  cod: number
  nome: string
  email: string
  cod_equipe: number | null
}

const Equipe: React.FC = () => {
  const [form] = Form.useForm()
  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [callOpen, setCallOpen] = useState(false)
  const [updateUpdateEquipe, setUpdateUpdateEquipe] = useState<number>()
  const [permissions, setPermissions] = useState<ItensPerUserLogged>()
  const [permissionsExtra, setPermissionsExtra] = useState<any>([])
  const [modalEmail, setModalEmail] = useState(false)
  const [email, setEmail] = useState('')
  const [emailNovo, setEmailNovo] = useState('')
  const [pagination, setPagination] = useState<number>(15)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState(null)
  const [buscarTabela, setBuscarTabela] = useState('')
  const [confirmar_exclusao, setConfirmar_exclusao] = useState<boolean>(false)
  const [id_item_atual, setId_item_atual] = useState<number>(null)
  const [nome_item_atual, setNome_item_atual] = useState<string>('')
  const [fake_loading, setFake_loading] = useState<boolean>(false)

  const [rows, setRows] = useState([])
  const [rowsRelative, setRowsRelative] = useState([])
  const [emailStatus, setEmailStatus] = useState(false)
  const [equipeGeral, setEquipeGeral] = useState([])
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])
  const [listarPerfils, setListarPerfils] = useState([])
  const [emailFound, setEmailFound] = useState<EmailFoundType[]>({} as EmailFoundType[])
  const tipoAcesso = localStorage.getItem('tipo-acesso')

  const listarEquipe = async () => {
    try {
      setLoadingTable(true)
      const response: AxiosResponse<TypesEquipe> = await getEquipe({
        pagination: pagination,
        page: page,
        status: 1,
        embed: `usuario`,
        nome: buscarTabela!== '' ? buscarTabela : undefined
      })
      setTotal(response.data.total)
      const listPerfis = await getPerfil({})
      setListarPerfils(listPerfis.data.itens)

      const dados = response.data.itens.map((rec) => {
        rec.nome_perfil_conta =
          rec.cod_perfil_conta !== null
            ? listPerfis.data.itens.find((item) => item.cod === rec.cod_perfil_conta).nome
            : null
        rec.nome_perfil_cliente_geral =
          rec.cod_perfil_cliente_geral !== null
            ? listPerfis.data.itens.find((item) => item.cod === rec.cod_perfil_cliente_geral).nome
            : null

        return rec
      })
      gerarTabela(response.data.itens)
      gerarTabela2([], response.data.itens.length)
      setRows(dados)
      setRowsRelative(dados)
      if (response.status === 200) {
        setLoadingTable(false)
      }
      setEquipeGeral(dados)
    } catch (error) {
      return error
    }
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.usuario_nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'left' },
        { text: item?.email, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'left' },
        {
          text: item?.usuario[0]?.nascimento
            ? dayjs(item?.usuario[0]?.nascimento).format('DD/MM/YYYY')
            : '',
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', 130],
          body: [
            [
              { text: 'Nome', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Email', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              {
                text: 'Data de nascimento',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
            ],
            ...data,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = [[{ text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' }]]

    const table = [
      {
        table: {
          headerRows: 1,
          widths: [179],
          body: [
            [
              {
                text: 'Total membros na equipe',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
            ],
            ...data,
          ],
          margin: [250, 0, 0, 0],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosResultado(table)
  }

  const handleClickVisuEquipe = async (data) => {
    setVisibleForm(true)
    setCallOpen(true)
    setUpdateUpdateEquipe(data)
  }

  const handlesPesquisa = (data) => {
    setLoadingTable(true)
    const dados = rows
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.usuario_nome.toLowerCase().indexOf(nome) !== -1)
      setRowsRelative(response)
      setLoadingTable(false)
    }, 500)
  }
  const handleTrashEquipe = async (data) => {
    setFake_loading(true)
    try {
      const responseDel = await delEquipe(data)

      if (responseDel.status === 200) {
        successAlert('Membro excluído com sucesso!');
        setTimeout(() => {
          setFake_loading(false)
        }, 3000)

        listarEquipe()
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        errorAlert(data?.error ?? 'Falha na operação');
        setTimeout(() => {
          setFake_loading(false)
        }, 3000)
      }
    }
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdateUpdateEquipe(0)
  }

  const handleIncludeEquipe = async (email) => {
    if (email != '') {
      loadingAlert({msg: 'Verificando email...', id: 'email', autoClose: 15000})
      setEmailStatus(false)
      const resp = await getConsultaEmail({ email })

      setEmailFound(resp.data.itens)
      setEmailStatus(true)
      destroyAlert('email');
    } else {
      errorAlert('Preencha o campo de pesquisa antes de buscar!', 5000, 'email');
    }
  }

  const handleAddEquipe = async (codUser) => {
    try {
      loadingAlert({msg: 'Inserindo usuário...', id: 'insert'});
      const resp = await postAddEquipe({ cod_usuario: codUser })

      if (resp.status === 200) {
        successAlert('Usuário inserido na equipe!', 5000, 'insert')
        listarEquipe()
        setModalEmail(false)
        setEmail('')
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response
        if (!data?.error[0]?.field) {
          errorAlert(data?.error ?? 'Falha ao inserir usuário!', 7000, 'insert');
        } else {
          data.error.forEach((data) => {
            errorAlert(data?.msg?.[0] ?? 'Falha ao inserir usuário!', 7000, 'insert');
          })
        }
      }
      else {
        errorAlert('Falha ao inserir usuário!', 7000, 'insert');
      }
    }
  }

  function buscarEquipe(buscar_nome: string) {
    const palavraSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
      const nomeSemAcentos = removerAcentos(String(item?.usuario_nome))
      const perfilConsultoriaSemAcentos = removerAcentos(String(item?.nome_perfil_conta))
      const perfilGeralSemAcentos = removerAcentos(String(item?.nome_perfil_cliente_geral))
      const perfilAcesso = removerAcentos(
        String(obterPerfilAcessoCliente(item?.cod_perfil_cliente))
      )
      return (
        nomeSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) ||
        perfilConsultoriaSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) ||
        perfilGeralSemAcentos.toLowerCase().includes(palavraSemAcentos.toLowerCase()) ||
        perfilAcesso.toLowerCase().includes(palavraSemAcentos.toLowerCase()) ||
        !buscar_nome
      )
    }
  }

  function mostrarFoto(foto) {
    let img
    if (foto === '') {
      img = null
    } else {
      img = foto
    }

    const nome = foto?.split(' ')
    return (
      <Avatar src={img}>
        <b>
          {nome[0]?.substring(0, 1).toUpperCase()}
          {nome[1]?.substring(0, 1).toUpperCase()}
        </b>
      </Avatar>
    )
  }

  // useEffect(() => {
  //   listarEquipe()
  // }, [])

  useEffect(() => {
    listarEquipe()
  }, [page, pagination])
  
  useEffect(() => {
    if (buscarTabela.length > 2) {
      setTimeout(() => {
        listarEquipe();
      }, 700); 
    }
    if (buscarTabela === '') {
        listarEquipe();
    }
  }, [buscarTabela])

  function obterPerfilAcessoCliente(cod_perfil_cliente: number): string {
    let perfilEncontrado = listarPerfils?.find((item) => item?.cod === cod_perfil_cliente)
    return perfilEncontrado?.nome ?? ''
  }


  return (
    <Container>
      <ViewPage
        rotina={4}
        onPermission={setPermissions}
        title='Equipe'
        inputChange={handlesPesquisa}
        rotinaExtra={[66, 77, 69]}
        onPermissionsExtra={setPermissionsExtra}
        hiddeTitle
        newHide
        cardBodyStyle={{ padding: 15 }}
        cardMainStyle={{ height: '87.5vh' }}
        content={
          // <FormEquipe
          //   permissions={permissions}
          //   acessoPermissoes={permissionsExtra}
          //   emailNew={email}
          //   visible={visibleForm}
          //   setOnClose={setVisibleForm}
          //   update={updateUpdateEquipe}
          //   setUpdate={setUpdateUpdateEquipe}
          //   atualizar={listarEquipe}
          //   equipeGeral={equipeGeral}
          //   callFunctionPropriedades={callOpen}
          //   setCloseCallFunction={setCallOpen}
          //   // handleSerachPropriedades={}
          // />
          <NovaEquipe
            abrir={visibleForm}
            permissons={permissions}
            onClose={setVisibleForm}
            atualizar={listarEquipe}
            emailNew={emailNovo}
            update={updateUpdateEquipe}
            equipeGeral={equipeGeral}
          ></NovaEquipe>
        }
      >
        <div style={{ marginBottom: '10px' }}>
          <TitleH1>Equipe</TitleH1>
        </div>
        <Box style={{ height: 'calc(100vh - 170px)', border: 'none' }} padding={0} borderless>
          {/* <TitleH2>Lista de patrimônios</TitleH2> */}

          <Inline alignItems='center'>
            <Input
              placeholder='Buscar membros da equipe, perfil consultoria ou perfil cliente geral'
              value={buscarTabela}
              onChange={setBuscarTabela}
            />
            <ButtonCustom
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => setModalEmail(true)}
            >
              Novo
            </ButtonCustom>
            <div>
              {localStorage.getItem('tipo-acesso') === 'conta' ? (
                <GeneratePdfButton
                  dados={dadosEstatisticas}
                  title={'Relatório de equipe'}
                  disable={equipeGeral.length <= 0}
                  totais={dadosResultado}
                />
              ) : null}
            </div>
          </Inline>
          <HeaderTable
            itens={
              localConfig().codCliente == ''
                ? [
                    { valor: 'Foto', width: '56px' },
                    { valor: 'Nome', width: 'calc(100% - 939px)' },
                    { valor: 'Perfil consultoria', width: '350px' },
                    { valor: 'Perfil cliente geral', width: '350px' },
                    { valor: 'Ações', width: '183px' },
                  ]
                : [
                    { valor: 'Foto', width: '56px' },
                    { valor: 'Nome', width: 'calc(100% - 589px)' },
                    { valor: 'Perfil de acesso', width: '350px' },
                    { valor: 'Ações', width: '183px' },
                  ]
            }
          />

          <BodyTable
            paginate={total}
            onChangeItensPage={setPagination}
            onChangePage={setPage}
            current_page={page}
            setCurrent_page={setPage}
            style={{
              height: 'calc(100vh - 170px)',
              // height: (list_patrimonios ?? []).length < 8 && 'calc(100vh - 170px)',
              position: 'relative',
              overflowX: 'hidden',
              overflowY: 'scroll',
              marginTop: -10,
            }}
          >
            <Render condition={!loadingTable}>
              {(rowsRelative ?? [])
                .filter(buscarEquipe(buscarTabela))
                .map(
                  (
                    {
                      cod,
                      foto,
                      usuario_nome,
                      nome_perfil_conta,
                      nome_perfil_cliente_geral,
                      cod_perfil_cliente,
                    },
                    index
                  ) => {
                    return (
                      <RowTable key={cod} onDoubleClick={() => handleClickVisuEquipe(cod)}>
                        <RowItem width={'55px'}>{mostrarFoto(usuario_nome)}</RowItem>
                        <RowItem
                          fontSize={13}
                          width={
                            localConfig().codCliente == ''
                              ? 'calc(100% - 932px)'
                              : 'calc(100% - 583px)'
                          }
                        >
                          {usuario_nome}
                        </RowItem>
                        {localConfig().codCliente == '' ? (
                          <>
                            <RowItem fontSize={13} width={'350px'}>
                              {nome_perfil_conta}
                            </RowItem>
                            <RowItem fontSize={13} width={'350px'}>
                              {nome_perfil_cliente_geral}
                            </RowItem>
                          </>
                        ) : (
                          <RowItem fontSize={13} width={'350px'}>
                            {obterPerfilAcessoCliente(cod_perfil_cliente)}
                          </RowItem>
                        )}

                        <RowItem
                          width={localConfig().codCliente == '' ? '177px' : '178px'}
                          gap={10}
                        >
                          <Inline>
                            <DeleteButton
                              open={confirmar_exclusao && id_item_atual === cod}
                              trigger={setConfirmar_exclusao}
                              onTrigger={() => {
                                setId_item_atual(cod)
                                setNome_item_atual(usuario_nome)
                              }}
                              loading={fake_loading}
                              message={`o item ${usuario_nome} será excluido!`}
                              deleteds={[usuario_nome]}
                              onDelete={() => {
                                handleTrashEquipe(cod)
                              }}
                              disabled={fake_loading}
                              disabledTrigger={permissions?.exclusao === 0 }
                            />
                            <ButtonCustom
                              icon={<FontAwesomeIcon icon={faEdit} />}
                              onClick={() => {
                                handleClickVisuEquipe(cod)
                              }}
                              disabled={permissions?.alteracao === 0 && permissions?.acesso === 0}
                            >
                              Editar
                            </ButtonCustom>
                          </Inline>
                        </RowItem>
                      </RowTable>
                    )
                  }
                )}
            </Render>

            <Render condition={rowsRelative.length === 0 && !loadingTable}>
              {buscarTabela === '' ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<> Nenhum registro disponível!</>}
                />
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <>
                      {' '}
                      <b>'{buscarTabela}'</b> não encontrado!
                    </>
                  }
                />
              )}
            </Render>

            <Render condition={loadingTable}>
              <Carregando
                animation
                legenda='Carregando membros da equipe...'
                justifyContent='center'
                height={400}
                size='default'
              />
            </Render>
          </BodyTable>
        </Box>

        <Modal
          open={modalEmail}
          onCancel={() => {
            setModalEmail(false)
            setEmailStatus(false)
            setEmail('')
          }}
          title='Insira um email válido'
          footer={false}
        >
          <Form form={form}>
            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'Email inválido',
                },
              ]}
            >
              <div style={{ display: 'flex' }}>
                <Input
                  onChange={(e) => {
                    setEmail(e)
                    setEmailNovo(e)
                  }}
                  value={email}
                  placeholder={''}
                />
                <Button
                  style={{
                    backgroundColor: '#5DA57D',
                    marginLeft: '5px',
                    width: '5%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => handleIncludeEquipe(email)}
                >
                  <img style={{ height: '70%' }} src={lupaSearch} />
                </Button>
              </div>
            </Form.Item>
            <Col>
              {emailStatus && email ? (
                emailFound.length > 0 ? (
                  emailFound[0]?.cod_equipe === null ? (
                    <Alert
                      type='success'
                      message='Usuário encontrado:'
                      description={
                        <Space direction='vertical'>
                          <Space direction='vertical'>
                            <b>{emailFound[0].nome}</b>
                          </Space>
                          Deseja incluir na equipe?{' '}
                        </Space>
                      }
                      showIcon
                      action={
                        <Space>
                          <Button onClick={() => handleAddEquipe(emailFound[0].cod)} type='primary'>
                            Sim
                          </Button>
                          <Button
                            onClick={() => {
                              setModalEmail(false)
                              setEmailStatus(false)
                              setEmail('')
                            }}
                            type='primary'
                            danger
                          >
                            Não
                          </Button>
                        </Space>
                      }
                    />
                  ) : (
                    <Alert
                      type='error'
                      message='Usuário já pertence a equipe!'
                      showIcon
                      description=' '
                      action={
                        <Space>
                          <Button
                            onClick={() => {
                              setModalEmail(false)
                              setEmailStatus(false)
                              setEmail('')
                            }}
                            type='primary'
                            danger
                          >
                            Sair
                          </Button>
                        </Space>
                      }
                    />
                  )
                ) : (
                  <Alert
                    type='info'
                    description=' '
                    message='Email não encontrado na base de dados ttivos, deseja incluir na equipe?'
                    showIcon
                    action={
                      <Space>
                        <Button
                          type='primary'
                          onClick={() => {
                            setModalEmail(false)
                            handleVisibleForm()
                            setEmailStatus(false)
                            setEmail('')
                          }}
                        >
                          Sim
                        </Button>
                        <Button
                          onClick={() => {
                            setModalEmail(false)
                            setEmailStatus(false)
                            setEmail('')
                          }}
                          type='primary'
                          danger
                        >
                          Não
                        </Button>
                      </Space>
                    }
                  />
                )
              ) : null}
            </Col>
          </Form>
        </Modal>
      </ViewPage>
    </Container>
  )
}

export default Equipe

import React, { useEffect, useState } from 'react'

import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'

import { Button, Modal, Space, Table } from 'antd'
import { AxiosResponse } from 'axios'
import { pagination } from '../../../../Components/Default'
import ViewPage from '../../../../Components/ViewPage'
import Perfil from '../../../../Components/config/perfil'
import { Notification } from '../../../../Components/notification'
import { ItensPerfilTypes, TypesPerfil, delPerfil, getPerfil } from '../../../../services/perfil'

import { orderBy } from 'lodash'
import Icons from '../../../../Components/Icons'
import { IconAlert } from '../../../../Components/iconsAlert'
import { tryError } from '../../../../services/Afins'
import { Container } from './styles'
import { errorAlert, successAlert } from '../../../../utils/alert'

const PagePerfis: React.FC = () => {
  const [dataTable, setDataTable] = useState<ItensPerfilTypes[]>([] as ItensPerfilTypes[])
  
  const [update, setUpdate] = useState<ItensPerfilTypes>({} as ItensPerfilTypes)
  const [loadingTable, setLoadingTable] = useState(false)
  const [visible, setVisible] = useState(false)
  const [possuiModulo, setPossuiModulo] = useState(false)

  const listPerfis = async () => {
    setLoadingTable(true)
    const resp = await getPerfil({ embed: 'permissoes' })

    setDataTable(resp.data.itens)
    setLoadingTable(false)
  }

  const handleClickViewProfile = (data: ItensPerfilTypes) => {
    setVisible(true)
    setUpdate(data)
  }

  const handleTrashPerfil = async (data) => {
 
    try {
      Modal.confirm({
        title: 'Excluir',
        icon: <IconAlert type='confirm' size={6} />,
        content: `Deseja realmente remover o perfil ${data.nome}?`,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          try {
            const responseDel = await delPerfil(data.cod)

            if (responseDel.status === 200) {
              successAlert('Perfil excluído com sucesso!');

              listPerfis()
            }
          } catch (error) {
            tryError(error)
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response
      
	      errorAlert(data?.error ?? 'Falha na operação');
      
    }
    }
  }

  useEffect(() => {
    if (!visible) {
      setUpdate({} as ItensPerfilTypes)
    }
  }, [visible])

  useEffect(() => {
    listPerfis()
  }, [])
  

  function handlePossuiModulo(moduloTrueOrFalse: boolean){
    setPossuiModulo(moduloTrueOrFalse)
  }
 
  
  return (
    <Container>
      <ViewPage
        rotina={6}
        content={
          <Perfil
            onClose={setVisible}
            update={update}
            visible={visible}
            onModulo={handlePossuiModulo}
            onFinish={() => listPerfis()}
          />
        }
        title='Perfis de acesso'
        btnClick={() =>  possuiModulo == true? setVisible(false): setVisible(true)}
        disableNew={possuiModulo == true? true : false}
      >
        <Table
          size='small'
          loading={loadingTable}
          pagination={pagination}
          dataSource={orderBy(dataTable, 'nome')}
          scroll={{ y: 'calc(90vh - 275px)' }}
          onRow={(record) => {
            return {
              onDoubleClick: () => handleClickViewProfile(record),
            }
          }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataTable.length}</b>
            </div>
          )}
          columns={[
            {
              key: 1,
              dataIndex: 'nome',
              title: 'Nome',
            },
            {
              title: 'Ações',
              dataIndex: 'cod',
              key: 'acao',
              width: '90px',
              render: (_, record) => (
                <Space>
                  <Button
                    shape='circle'
                    onClick={() => handleClickViewProfile(record)}
                    icon={<Icons icon={faPen} />}
                    size='middle'
                  />
                  <Button
                    shape='circle'
                    onClick={() => handleTrashPerfil(record)}
                    className='trash-button'
                    type='primary'
                    icon={<Icons icon={faTrash} />}
                    size='middle'
                  />
                </Space>
              ),
            },
          ]}
        />
      </ViewPage>
    </Container>
  )
}

export default PagePerfis
